import { UnitedStates } from 'components/common/form/CountryCodes';
import { WeddingDetails } from 'cards/reducers/samplesFlow/types';

export const stateFilter = (option: { [key: string]: string }): boolean =>
  !['AA', 'AE', 'AP'].includes(option.value);

export const stateDropdownOptions = UnitedStates.filter(stateFilter).map(option => ({
  label: option.title,
  value: option.value,
}));

export const extractCoupleNames = (
  weddingDetails: WeddingDetails
): { [key: string]: string } | null => {
  if (
    !(weddingDetails.fullName && weddingDetails.fullName.split(' ').length > 1) ||
    !(weddingDetails.partnerFullName && weddingDetails.partnerFullName.split(' ').length > 1)
  ) {
    return null;
  }

  const [ownerFirstName, ...ownerLastNames] = weddingDetails.fullName.split(' ');
  const [partnerFirstName, ...partnerLastNames] = weddingDetails.partnerFullName.split(' ');

  return {
    owner_first_name: ownerFirstName,
    owner_last_name: ownerLastNames.join(' '),
    partner_first_name: partnerFirstName,
    partner_last_name: partnerLastNames.join(' '),
  };
};

export const validateCoupleNames = (fullName: string | null, partnerFullName: string | null) => {
  if (fullName === null || partnerFullName === null) {
    return null;
  }

  const errorMessages: { [key: string]: string } = {};

  const [ownerFirstName, ...ownerLastNames] = fullName.split(' ');
  const [partnerFirstName, ...partnerLastNames] = partnerFullName.split(' ');

  if (ownerFirstName === '' || ownerLastNames.length === 0 || ownerLastNames[0] === '') {
    errorMessages.fullName = 'Please make sure you enter your full name';
  }

  if (partnerFirstName === '' || partnerLastNames.length === 0 || partnerLastNames[0] === '') {
    errorMessages.partnerFullName = "Please make sure you enter your partner's full name";
  }

  return Object.keys(errorMessages).length > 0 ? errorMessages : null;
};
