import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import type { WCardVariationColorSummaryView } from '@zola/svc-web-api-ts-client';
import { Link } from '@zola/zola-ui/src/components/Link';
import featureFlags from 'util/featureFlags';

// Don't import _tooltips.less in ProductColorPicker.module.less or its classes will be processed.
import '../../../../stylesheets/common/_tooltips.less';
import styles from './ProductColorPicker.module.less';

export interface ProductColorPickerProps {
  colors?: WCardVariationColorSummaryView[];
  activeColor?: string;
  maxColorsToShow?: number;
  link?: string;
  onSelect: (c: WCardVariationColorSummaryView) => void;
  swatchWidth?: number;
  onMoreClick?: () => void;
  className?: string;
}

function ProductColorPicker({
  colors,
  activeColor,
  maxColorsToShow,
  link,
  onSelect,
  swatchWidth,
  onMoreClick,
  className,
}: ProductColorPickerProps): React.ReactElement | null {
  const [anyColorIsExtraCustomizable, setAnyColorIsExtraCustomizable] = useState(false);
  const [showMoreLink, setShowMoreLink] = useState(false);
  const [visibleColors, setVisibleColors] = useState<WCardVariationColorSummaryView[]>([]);

  useEffect(() => {
    if (!colors) return;

    const nbExtraCustomizable = featureFlags.get('cardsExtraCustomization')
      ? colors.filter(color => color.extra_customizable).length
      : 0;
    const anyAreEC = nbExtraCustomizable > 0;
    setAnyColorIsExtraCustomizable(anyAreEC);

    if (!maxColorsToShow) {
      setVisibleColors(colors);
      setShowMoreLink(false);
    } else {
      // If the max is set, leave a space for the rainbow swatch
      const nbToShow = anyAreEC ? maxColorsToShow - 1 : maxColorsToShow;
      setVisibleColors(colors.length > nbToShow ? colors.slice(0, nbToShow) : colors);
      setShowMoreLink(colors.length > nbToShow);
    }
  }, [colors, maxColorsToShow]);

  if (visibleColors.length < 1) return null;

  const onlyColorAvailableIsEC = anyColorIsExtraCustomizable && visibleColors.length === 1;

  return (
    <div className={cx(styles.productColorPicker, className)}>
      {!onlyColorAvailableIsEC &&
        visibleColors.map(color => {
          return (
            <div
              key={color.value}
              className={cx(styles.colorWrapper, { [styles.active]: activeColor === color.value })}
              onClick={(): void => onSelect(color)}
            >
              <div
                className={styles.color}
                data-label={color.label}
                style={{ background: `#${color.value}`, width: swatchWidth, height: swatchWidth }}
              />
            </div>
          );
        })}
      {anyColorIsExtraCustomizable && (
        <div className={cx(styles.colorWrapper, { [styles.active]: onlyColorAvailableIsEC })}>
          <div
            className={cx(styles.color, styles.rainbow)}
            style={{ width: swatchWidth, height: swatchWidth }}
            data-tooltip="This design features extra customizable colors and fonts"
            data-label="Choose your own"
          />
        </div>
      )}
      {showMoreLink && link && (
        <Link
          noUnderline
          className={styles.moreLink}
          aria-label="view more color options"
          href={link}
        >
          More
        </Link>
      )}
      {showMoreLink && onMoreClick && (
        <Link
          noUnderline
          className={styles.moreLink}
          aria-label="view more color options"
          role="button"
          onClick={onMoreClick}
        >
          More
        </Link>
      )}
    </div>
  );
}

export default ProductColorPicker;
