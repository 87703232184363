import { fetchProjectMeta } from 'cards/actions/projectActions';
import { CARD_TYPES } from 'cards/constants/CardTypes';
import { getProjectUUID } from 'cards/selectors/cardProjectSelector';
import { findLeadCustomization } from 'cards/util/customization';
import { Just, get } from 'pratica';

// action types
export const actionTypes = {
  CUSTOM_NOTES_FETCH_FULFILLED: 'zola/cards/project/customization/CUSTOM_NOTES_FETCH_FULFILLED',
  CUSTOM_NOTES_FETCH_REJECTED: 'zola/cards/project/customization/CUSTOM_NOTES_FETCH_REJECTED',
  REVIEW_SUMMARY_FETCH_FULFILLED: 'zola/cards/project/customization/REVIEW_SUMMARY_FETCH_FULFILLED',
  SET_ACTIVE_CUSTOMIZATION_FIELD: 'zola/cards/project/customization/SET_ACTIVE_CUSTOMIZATION_FIELD',
  SET_ACTIVE_GUEST_GROUP_ID: 'zola/cards/project/customization/SET_ACTIVE_GUEST_GROUP_ID',
  SET_PENDING_CHANGES: 'zola/cards/project/customization/SET_PENDING_CHANGES',
};

// action creators
const getProject = uuid => fetch(`/web-api/v2/card-project/${uuid}`).then(res => res.json());

const getCustomNotes = uuid =>
  fetch(`/web-api/v2/card-project/guestdata/project/${uuid}`).then(res => res.json());

const reviewSummaryFulfilled = payload => ({
  type: actionTypes.REVIEW_SUMMARY_FETCH_FULFILLED,
  payload,
});

const customNotesFulfilled = payload => ({
  type: actionTypes.CUSTOM_NOTES_FETCH_FULFILLED,
  payload,
});

const customNotesRejected = () => ({ type: actionTypes.CUSTOM_NOTES_FETCH_REJECTED });

export const fetchReviewSummary = () => (dispatch, getState) => {
  const projectUUID = getProjectUUID(getState());

  if (!projectUUID) return Promise.reject();

  return getProject(projectUUID)
    .then(project => {
      const leadCustomization = findLeadCustomization(project.customizations);
      // We only use project meta in the reducer for TY cards
      const metaPromise =
        leadCustomization?.type === CARD_TYPES.thankyou
          ? dispatch(fetchProjectMeta(projectUUID, true))
          : Promise.resolve({});

      return metaPromise.then(meta => dispatch(reviewSummaryFulfilled([project, meta])));
    })
    .catch();
};

export const fetchCustomNotes = () => (dispatch, getState) =>
  Just(getState())
    .chain(get(['cards', 'project', 'uuid']))
    .cata({
      Just: uuid =>
        getCustomNotes(uuid)
          .then(data => dispatch(customNotesFulfilled(data)))
          .catch(() => dispatch(customNotesRejected())),
      Nothing: () => null,
    });

export const setActiveCustomizationField = customizationField => ({
  type: actionTypes.SET_ACTIVE_CUSTOMIZATION_FIELD,
  payload: customizationField,
});

export const setActiveGuestGroupId = guestGroupId => ({
  type: actionTypes.SET_ACTIVE_GUEST_GROUP_ID,
  payload: guestGroupId,
});

export const setPendingChanges = value => ({
  type: actionTypes.SET_PENDING_CHANGES,
  payload: value,
});
