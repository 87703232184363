import { ActionTypes } from '../actions/ordersActions';

const initialState = {
  busy: false,
  // orders: [],
  entities: {},
  allIds: [],
};

export default function ordersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.REQUEST_ORDERS: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVE_ORDERS: {
      const { normalizedOrders } = action.payload;
      return {
        ...state,
        busy: false,
        entities: {
          ...state.entities,
          ...normalizedOrders.entities,
        },
        allIds: normalizedOrders.result,
      };
    }
    case ActionTypes.RECEIVE_CANCELED_ORDER: {
      const { normalizedOrder } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          orders: {
            ...state.entities.orders,
            [normalizedOrder.result]: normalizedOrder.entities.orders[normalizedOrder.result],
          },
        },
      };
    }
    default:
      return state;
  }
}
