import type { WProfileView } from '@zola/svc-web-api-ts-client';

export const REQUEST_PROFILE = 'zola/profile/REQUEST_PROFILE';
export const RECEIVE_PROFILE = 'zola/profile/RECEIVE_PROFILE';
export const REQUEST_PROFILE_IMAGE_UPDATE = 'zola/profile/REQUEST_PROFILE_IMAGE_UPDATE';
export const RECEIVE_PROFILE_IMAGE_UPDATE = 'zola/profile/RECEIVE_PROFILE_IMAGE_UPDATE';

type RequestProfileActionType = {
  type: typeof REQUEST_PROFILE;
};

type ReceiveProfileActionType = {
  type: typeof RECEIVE_PROFILE;
  payload: WProfileView;
};

type RequestProfileImageUpdateActionType = {
  type: typeof REQUEST_PROFILE_IMAGE_UPDATE;
};

type ReceiveProfileImageUpdateActionType = {
  type: typeof RECEIVE_PROFILE_IMAGE_UPDATE;
  payload?: string;
};

export type ProfileActionTypes =
  | RequestProfileActionType
  | ReceiveProfileActionType
  | RequestProfileImageUpdateActionType
  | ReceiveProfileImageUpdateActionType;
