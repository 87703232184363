import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// actions
import {
  setPaperCustomizations,
  setStepKey,
  updateDesignCustomizations,
} from 'cards/reducers/samplesFlow/actions';

// selectors
import {
  getCardSuiteDetailsSet,
  getCardType,
  getDesignCustomizationsSet,
  getPaperCustomizations,
} from 'cards/selectors/samplesFlow';
import { getPricing } from 'cards/reducers/pricing/selectors';
import { getCardOrientation } from 'cards/reducers/pdp/selectors';

// components
import OptionSection from 'cards/components/CardDetail/OptionSection/OptionSection';

// utils
import { useIsMobile } from 'paper/hooks/isMobile';
import {
  enrichCardLevelOptions,
  enrichOptionsAccSelection,
  getCurrentOptionDimension,
  getCurrentOptionLabel,
  getEnabledOptionsValuesList,
  isComparisonModuleHidden,
  getTooltipMessagePerOption,
} from 'cards/components/CardDetail/AllOptionsSection/utils';
import { CardSuiteDetails } from 'cards/reducers/samplesFlow/types';
import { BASE_PAPER_SHAPES } from 'cards/constants/CardLevelOptions';
import Form from '../../Form';
import { STEPS } from '../../constants';
import { lookupSelectedVariation } from '../../../util/helpers';

import styles from './styles.module.less';

const STRETCHED_OPTION_KEYS = ['size', 'paper-type'];
const STRETCHED_OPTION_STYLES = {
  toggleList: styles.toggleList,
  item: styles.item,
  pdpToggleButton: styles.pdpToggleButton,
};

interface CustomizeStepProps {
  cardSuiteUUIDList: string[];
  isAdminView: boolean;
  isMagnet: boolean;
  showPaperTypeModal: (paperTypeModalProps: any) => void;
}

const CustomizeStep = ({
  isAdminView,
  isMagnet,
  showPaperTypeModal,
}: CustomizeStepProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const cardSuiteDetailsSet = useSelector(getCardSuiteDetailsSet);
  const cardType = useSelector(getCardType);
  const paperCustomizationsData = useSelector(getPaperCustomizations);
  const designCustomizationsSet = useSelector(getDesignCustomizationsSet);
  const cardOrientation = useSelector(getCardOrientation);
  const pricing = useSelector(getPricing);
  const { options: customizeOptions, activeSelections } = paperCustomizationsData;

  if (!customizeOptions || !cardType) return null;

  const optionKeys: string[] =
    activeSelections.size === 'postcard'
      ? ['size', 'paper-type', 'silhouette']
      : ['size', 'foil-color', 'paper-type', 'silhouette'];

  const nextBtnText = 'Next: Shipping Address';
  const nextStep = () => {
    Object.values(cardSuiteDetailsSet).forEach((cardSuiteDetails: CardSuiteDetails) => {
      const cardSuiteUUID = cardSuiteDetails.uuid;
      const { variation } = designCustomizationsSet[cardSuiteUUID];

      const selectedOptions =
        cardSuiteDetails.foil || cardSuiteDetails.letterpress
          ? variation.option_values
          : {
              ...variation.option_values,
              ...activeSelections,
              foil: activeSelections['foil-color'] !== 'none' ? 'custom' : 'none',
            };

      const newVariation = lookupSelectedVariation(selectedOptions, cardSuiteDetails, isMagnet);
      dispatch(updateDesignCustomizations(cardSuiteUUID, { variation: newVariation }));
    });

    dispatch(setStepKey(STEPS.SHIPPING));
  };
  const prevStep = () => dispatch(setStepKey(STEPS.PHOTO));

  const handleSetOptionValue = (option: any) => {
    if (option.value === 'postcard' || activeSelections.size === 'postcard') {
      const supportedPaperTypes = ['smooth', 'eggshell', 'recycled'];
      let paperType: string =
        activeSelections['paper-type'] !== null ? activeSelections['paper-type'] : 'smooth';

      if (supportedPaperTypes.includes(option.value)) {
        paperType = option.value;
      } else if (!supportedPaperTypes.includes(paperType)) {
        paperType = supportedPaperTypes[0];
      }

      dispatch(
        setPaperCustomizations({
          [option.type]: option.value,
          'foil-color': 'none',
          'paper-type': paperType,
          'reverse-printing': 'true',
          silhouette: 'square',
        })
      );
    } else {
      dispatch(
        setPaperCustomizations({
          [option.type]: option.value,
          'reverse-printing': 'false',
        })
      );
    }
  };

  return (
    <>
      <Form.Header
        currentStep={3}
        description={
          <>
            Please note that not all customizations are available on samples. To get the full
            experience, customize a proof or full order.
          </>
        }
        steps={4}
        title="Customize"
      />
      <div className={styles.wrapper}>
        {optionKeys.map(optionKey => {
          const uiData = customizeOptions[optionKey];

          if (!uiData) return null;

          const { key, options, label } = uiData;

          /*
           * Custom foil is costly to process
           * and samples are free. We're turning it
           * off for now.
           */
          if (key === 'foil-color') return null;

          let availableOptions = options;

          // Samples only allow for square or rounded corners
          if (key === 'silhouette') {
            availableOptions = availableOptions.map((option: any) => {
              return !BASE_PAPER_SHAPES.includes(option.value)
                ? { ...option, enabled: false }
                : option;
            });
          }

          const selection = availableOptions.find(
            (option: any) => option.value === activeSelections[optionKey]
          );
          const enabledOptionsValuesList = getEnabledOptionsValuesList(
            availableOptions,
            cardType,
            key
          );
          const cardTypeOptions = enrichCardLevelOptions(
            key,
            availableOptions,
            cardType,
            enabledOptionsValuesList,
            isMagnet ? 'magnet' : 'paper',
            isAdminView,
            false // Stamp foil is never available for samples
          );
          const enrichedOptions = enrichOptionsAccSelection(
            key,
            cardTypeOptions,
            activeSelections,
            cardType,
            pricing,
            isMagnet ? 'magnet' : 'paper'
          );

          const optionType = key;
          const currentSelection = {
            type: optionType,
            ...selection,
          };
          const currentLabel = getCurrentOptionLabel({
            selection,
            optionType,
            cardType,
            orientation: cardOrientation,
          });

          const shouldStretchButtons = STRETCHED_OPTION_KEYS.includes(optionKey);

          return (
            <OptionSection
              key={optionKey}
              cardType={cardType}
              title={label}
              options={enrichedOptions}
              selection={currentSelection}
              setOptionValue={handleSetOptionValue}
              styleOverrides={shouldStretchButtons ? STRETCHED_OPTION_STYLES : null}
              condensed
              currentLabel={currentLabel}
              currentDimension={getCurrentOptionDimension(selection, cardType, cardOrientation)}
              optionType={optionType}
              isMagnet={isMagnet}
              isLinkHidden={isComparisonModuleHidden(cardType, enabledOptionsValuesList)}
              showLinkOnMobile={isMobile}
              titleLink="Compare Our Paper Types"
              titleLinkAction={showPaperTypeModal}
              hoverDisabled
              tooltipMessage={getTooltipMessagePerOption(
                optionType,
                paperCustomizationsData?.activeSelections?.size,
                isMagnet
              )}
            />
          );
        })}
        <Form.ButtonWrapper>
          <Form.BackButton handleClick={prevStep} />
          <Form.Button btnText={nextBtnText} handleClick={nextStep} />
        </Form.ButtonWrapper>
      </div>
    </>
  );
};

export default CustomizeStep;
