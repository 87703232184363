import type { RootState } from 'reducers/index';
import type { ReversePrintingLayoutsState } from 'cards/reducers/reversePrintingLayouts';
import { createSelector } from 'reselect';

const getReversePrintingLayoutsState = (state: RootState): ReversePrintingLayoutsState =>
  state?.cards?.reversePrintingLayouts;

export const getCardTemplateLayoutUuid = createSelector(
  getReversePrintingLayoutsState,
  reversePrintingLayoutsState => reversePrintingLayoutsState.uuid
);

export const getLayouts = createSelector(
  getReversePrintingLayoutsState,
  reversePrintingLayoutsState => reversePrintingLayoutsState.layouts
);

export const getIsRecroppingInProgress = createSelector(
  getReversePrintingLayoutsState,
  reversePrintingLayoutsState => reversePrintingLayoutsState.isRecroppingInProgress
);
