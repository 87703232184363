import * as ActionTypes from '../actions/types/CartActionTypes';

const initialState = {
  busy: false,
  initialized: false,
  size: 0, // sometimes we will only have this
  items: [],
  totals: {},
  isFreeShippingEligible: false,
  freeShipping: null, // temporary
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_SIZE: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_SIZE: {
      return Object.assign({}, state, {
        busy: false,
        size: action.payload,
      });
    }
    case ActionTypes.REQUEST_CART: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_CART: {
      if (!action.payload) return state;
      return Object.assign({}, state, {
        busy: false,
        initialized: true,
        size: action.payload.items.length,
        items: action.payload.items,
        totals: action.payload.totals,
        isFreeShippingEligible: action.payload.is_free_shipping_eligible,
        freeShipping: action.payload.freeShipping,
        cartId: action.payload.cartId,
        registryId: action.payload.registryId,
      });
    }
    case ActionTypes.ADD_ITEM:
    case ActionTypes.ADDED_ITEM:
    case ActionTypes.UPDATE_ITEM_QUANTITY:
    case ActionTypes.DELETE_ITEM:
      return Object.assign({}, state, {});
    // case ActionTypes.UPDATE_SIZE: {
    //   return Object.assign({}, state, {
    //     size: action.payload.size
    //   });
    // }
    case ActionTypes.RESET_CART: {
      return initialState;
    }
    case ActionTypes.UNLOAD_CART: {
      return Object.assign({}, initialState, {
        size: state.size,
      });
    }
    default:
      return state;
  }
};

export default cartReducer;
