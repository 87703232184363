import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import LogRocket from 'logrocket';
import { continueExistingLogRocketSession } from '@zola-helpers/client/dist/es/util/logrocket/logRocketUtils';
import { TrackPageView } from 'components/util/TrackPageView';
import { ZolaThemeProvider } from '../src/ZolaThemeProvider';
import { useStore } from '../src/store/nextStore';
import './styles/global/bootstrap.min.css';
import './styles/global/global.less';
import './styles/global/cards.less';
import '../src/nextPolyfills';

export default function App({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState && JSON.parse(pageProps.initialReduxState));

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.LogRocket = LogRocket;
      continueExistingLogRocketSession();
    }
  }, []);

  return (
    <Provider store={store}>
      <ZolaThemeProvider>
        <TrackPageView />
        <div id="wedding-modal-root" />
        <div id="zola-wedding-container">
          <Component {...pageProps} />
        </div>
      </ZolaThemeProvider>
    </Provider>
  );
}
