import type {
  ThemeSearchRequest,
  WThemeListingSearchResultsView,
  WThemeSearchRequest,
  WThemeSearchView,
} from '@zola/svc-web-api-ts-client';
import type { PLPFilterOptions } from 'pages/WebsiteDesignsPLPSSR/helpers';

import ApiService from '@zola-helpers/client/dist/es/http/api';

const routePath = `/web-api/v2/theme/search/faceted`;

export const facetedThemeSearch = async (request: WThemeSearchRequest): Promise<WThemeSearchView> =>
  ApiService.post<WThemeSearchView>(`${routePath}`, request);

export type LocalThemeSearchRequest = { layout_types?: string } & Omit<
  ThemeSearchRequest,
  'colors' | 'motifs' | 'layout_types'
> &
  Omit<PLPFilterOptions, 'special_features'>;

export const websiteV2ThemeSearch = async (
  request: LocalThemeSearchRequest
): Promise<WThemeListingSearchResultsView> =>
  ApiService.post<WThemeListingSearchResultsView>('/web-api/v3/theme/search', request);
