import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ToggleButton from '../ToggleButton/ToggleButton';

import styles from './styles.module.less';

const TYPES_WITHOUT_HOVER = ['foil-color'];

const ToggleList = ({
  options,
  currentSelection,
  onToggle,
  onHover,
  styleOverrides,
  tooltipMessage,
  variation,
  narrow, // * TODO: delete after PDP 2.0 is launched
}) => {
  const isHoverDisabled = TYPES_WITHOUT_HOVER.includes(currentSelection.type);
  const isActive = useCallback(
    option => {
      if (currentSelection.metadata && option.metadata) {
        return (
          currentSelection.value === option.value && currentSelection.metadata === option.metadata
        );
      }

      return currentSelection.value === option.value;
    },
    [currentSelection]
  );

  return (
    <div
      className={cx(
        styles.toggleList,
        {
          [styles[variation]]: !!variation,
          [styles.narrow]: narrow,
        },
        styleOverrides && styleOverrides.toggleList
      )}
    >
      {options.map((option, index) => {
        if (option.hidden) return null;

        return (
          <div
            className={cx(
              styles.item,
              {
                [styles.narrow]: narrow,
              },
              styleOverrides && styleOverrides.item
            )}
            key={index}
          >
            <ToggleButton
              active={isActive(option)}
              icon={option.disabled ? option.iconDisabled : option.iconEnabled}
              iconStyle={option.iconStyle}
              label={option.label}
              metadata={option.metadata}
              styleOverrides={styleOverrides}
              subLabel={option.subTitle}
              value={option.value}
              type={currentSelection.type}
              disabled={option.disabled}
              onClick={option.disabled ? () => {} : onToggle}
              onHover={option.disabled || isHoverDisabled ? () => {} : onHover}
              tooltipMessage={tooltipMessage}
              narrow={narrow}
              variation={variation}
            />
          </div>
        );
      })}
    </div>
  );
};

ToggleList.propTypes = {
  currentSelection: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      subTitle: PropTypes.string,
      iconEnabled: PropTypes.node,
      iconDisabled: PropTypes.node,
    })
  ),
  onToggle: PropTypes.func,
  onHover: PropTypes.func,
  tooltipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  variation: PropTypes.string,
  narrow: PropTypes.bool,
  styleOverrides: PropTypes.shape({}),
};

ToggleList.defaultProps = {
  onToggle: () => {},
  onHover: () => {},
  narrow: false,
};

export default ToggleList;
