import moment from 'moment';

export const isSponsoredLink = (url = '') => {
  if (typeof url !== 'string') return false;

  const hasHttp = /(https|http):\/\/.+/g;
  const hasZolaDomain = /zola\.com|zola\.app|zola\.test-app.+/g;

  return hasHttp.test(url) && !hasZolaDomain.test(url);
};

export const getTaskGroup = (dueAt, now) => {
  const nowMoment = moment(now);
  const dueAtMoment = moment(dueAt);
  const pastDue = dueAtMoment.diff(nowMoment) <= 0;

  if (pastDue) {
    return nowMoment.format('MMMM YYYY');
  }

  return moment(dueAt).format('MMMM YYYY');
};

export const getDaysTillWeddingDate = (now, date) => {
  if (!now || !date) return null;

  const weddingDate = moment(date);
  const diff = weddingDate.diff(now, 'days', true);

  // round up 1 day if the diff returns a fraction of a day
  return diff > 0 ? Math.floor(diff) + 1 : 0;
};
