/**
 * @prettier
 */

import { useCallback, useState, useEffect } from 'react';
import _fromPairs from 'lodash/fromPairs';
import { isSingleSampleLimitExhausted, getCardSuiteDetails } from '../api';
import ApiService from '../../../../../../util/api';

export function useSingleSampleLimitExhausted(cardType) {
  const globalKey = 'ZOLA_INVITATIONS_SINGLE_SAMPLE_MODAL_SAMPLE_LIMIT_EXHAUSTED_PROMISE';

  const [isExhausted, setIsExhausted] = useState(false);

  const updateIsExhausted = useCallback(
    exhausted => isExhausted !== exhausted && setIsExhausted(exhausted),
    [setIsExhausted]
  );

  const fetchIsExhausted = useCallback(() => {
    window[globalKey] = isSingleSampleLimitExhausted(cardType);
    return window[globalKey].then(updateIsExhausted);
  }, [cardType, updateIsExhausted]);

  /**
   * Cache whether the sample limit is exhausted in a global variable
   */
  useEffect(() => {
    if (cardType) {
      if (window[globalKey] && window[globalKey].then) {
        window[globalKey].then(updateIsExhausted);
      } else {
        fetchIsExhausted();
      }
    }
  });

  return [isExhausted, fetchIsExhausted];
}

export const useCardSuiteDetails = (cardSuiteUUID, isAdminView) => {
  const [cardSuiteDetails, setCardSuiteDetails] = useState(null);

  useEffect(() => {
    getCardSuiteDetails(cardSuiteUUID, isAdminView).then(setCardSuiteDetails);
  }, [cardSuiteUUID, isAdminView]);

  return cardSuiteDetails;
};

export function useCardSuiteDetailsSet(cardSuiteUUIDList, isAdminView, isMulti) {
  const [cardSuiteDetailsSet, setCardSuiteDetailsSet] = useState({});

  useEffect(() => {
    if (isMulti && cardSuiteUUIDList) {
      Promise.all(
        cardSuiteUUIDList.map(cardSuiteUUID =>
          getCardSuiteDetails(cardSuiteUUID, isAdminView).then(suiteDetails => [
            cardSuiteUUID,
            suiteDetails,
          ])
        )
      )
        .then(_fromPairs)
        .then(setCardSuiteDetailsSet);
    }
  }, [(cardSuiteUUIDList || []).join('_'), isAdminView, isMulti]);

  return cardSuiteDetailsSet;
}

export function usePhotoObjectURL(photo) {
  const [photoURL, setPhotoURL] = useState();

  useEffect(() => {
    if (photo && photo.file) {
      setPhotoURL(URL.createObjectURL(photo.displayedBlob));
    }

    return () => photoURL && URL.revokeObjectURL(photoURL);
  }, [photo]);

  return photoURL;
}

export function useSpecialTypeSampleView(sampleSku) {
  const [skuData, setSkuData] = useState();

  useEffect(() => {
    if (!sampleSku) {
      setSkuData();
      return;
    }

    ApiService.post('/web-api/v1/products/find', { sku_ids: [sampleSku] }).then(data => {
      const newSkuData = skuData || {};

      if (
        data &&
        data[0] &&
        data[0].status &&
        data[0].product_look_views &&
        data[0].product_look_views[0] &&
        data[0].product_look_views[0].sku_previews &&
        data[0].product_look_views[0].sku_previews[0]
      ) {
        const preview = data[0].product_look_views[0].sku_previews[0];

        newSkuData.preview = preview;
      }

      if (
        data &&
        data[0] &&
        data[0].status &&
        data[0].product_look_views &&
        data[0].product_look_views[0] &&
        data[0].product_look_views[0].images &&
        data[0].product_look_views[0].images[0] &&
        data[0].product_look_views[0].images[0].aspect_ratios
      ) {
        const images = data[0].product_look_views[0].images[0].aspect_ratios;
        const imageKey = images['3x4'] ? '3x4' : '1x1';
        const image = images[imageKey] && images[imageKey].medium;

        newSkuData.image = image;
      }

      setSkuData(newSkuData);
    });
  }, [sampleSku, skuData]);

  return skuData;
}

export { default as useMostPopularSamplesFromFamily } from './useMostPopularSamplesFromFamily';
