export const REQUEST_WEDDING = 'zola/public/wedding/request';
export const RECEIVE_WEDDING = 'zola/public/wedding/receive';
export const REQUEST_PAGE = 'zola/public/wedding/request_page';
export const RECEIVE_PAGE = 'zola/public/wedding/receive_page';
export const NOT_FOUND = 'zola/public/wedding/notFound';
export const RECEIVE_GUEST_SEARCH = 'zola/public/wedding/RECEIVE_GUEST_SEARCH';
export const RESET_GUEST_SEARCH = 'zola/public/wedding/RESET_GUEST_SEARCH';
export const REQUEST_GUEST_RSVP = 'zola/public/wedding/REQUEST_GUEST_RSVP';
export const RECEIVE_GUEST_RSVP = 'zola/public/wedding/RECEIVE_GUEST_RSVP';
export const RESET_GUEST_RSVP = 'zola/public/wedding/RESET_GUEST_RSVP';
export const RECEIVE_WEBSITE_VISIBILITY = 'zola/public/wedding/RECEIVE_WEBSITE_VISIBILITY';
export const GUEST_RSVPD = 'zola/public/wedding/GUEST_RSVPD';
export const RECEIVE_GUEST_RSVP_SUCCESS = 'zola/public/wedding/RECEIVE_GUEST_RSVP_SUCCESS';
export const GET_GUEST_REQUESTS = 'zola/public/wedding/GET_GUEST_REQUESTS';
export const RECEIVE_GUEST_REQUEST_BY_UUID = 'zola/public/wedding/RECEIVE_GUEST_REQUEST_BY_UUID';
export const RSVP_REQUEST_SUBMITTED = 'zola/public/wedding/RSVP_REQUEST_SUBMITTED';
export const REVEAL_ACCESS_FORM = 'zola/public/wedding/REVEAL_ACCESS_FORM';
export const REVEAL_ADD_GUEST_FORM = 'zola/public/wedding/REVEAL_ADD_GUEST_FORM';
export const GUEST_SUCCESSFULLY_SUBMITTED = 'zola/public/wedding/GUEST_SUCCESSFULLY_SUBMITTED';
export const TRIGGER_NOTIFICATION_ACTION = 'zola/public/wedding/TRIGGER_NOTIFICATION_ACTION';
export const REVEAL_GUEST_FORM = 'zola/public/wedding/REVEAL_GUEST_FORM';
export const HIDE_ACCESS_FORM = 'zola/public/wedding/HIDE_ACCESS_FORM';
export const RECEIVE_GUEST_COUNT = 'zola/public/wedding/RECEIVE_GUEST_COUNT';
export const RESET_GUEST_GROUP_V2 = 'zola/public/wedding/RESET_GUEST_GROUP_V2';
export const STORE_MOBILE_TOKEN = 'zola/public/wedding/STORE_MOBILE_TOKEN';
export const SHOW_RSVP_SUMMARY = 'zola/public/wedding/SHOW_RSVP_SUMMARY';
export const HANDLE_RSVP_MODAL_ACTION = 'zola/public/wedding/HANDLE_RSVP_MODAL_ACTION';
export const RECEIVE_BANNER_LIST = 'zola/public/wedding/RECEIVE_BANNER_LIST';
export const RECEIVE_VIRTUAL_EVENT_PAGE = 'zola/public/wedding/RECEIVE_VIRTUAL_EVENT_PAGE';
export const HIDE_TOP_NAVIGATION = 'zola/public/wedding/HIDE_TOP_NAVIGATION';
export const RECEIVE_WEDDING_THEME = 'zola/public/wedding/RECEIVE_WEDDING_THEME';
export const TOGGLE_POI_MAPS = 'zola/public/wedding/TOGGLE_POI_MAPS';
