import type {
  ThemeFacetView,
  ThemeSortView,
  WThemeFacetView,
  WThemeGroupSearchResultsView,
  WThemeGroupSearchResultView,
  WThemeSearchRequest,
  WThemeSearchView,
  WThemeSortView,
  WThemeListingSearchResultsView,
} from '@zola/svc-web-api-ts-client';
import type { LocalThemeSearchRequest } from 'pages/WebsiteDesignsPLPSSR/api/themes';
import { AppDispatch, RootState } from 'reducers/index';
import { facetedThemeSearch, websiteV2ThemeSearch } from '../api/themes';
import { PLPFilterOptions } from '../helpers';

// Action Types
export const RECEIVE_THEME_SEARCH = 'websitePlp/receiveThemeSearch';
export const UPDATE_THEME_FILTERS = 'websitePlp/updateThemeFilters';
export const UPDATE_THEME_SORT = 'websitePlp/updateThemeSort';
export const REQUEST_THEMES = 'websitePlp/requestThemes';
export const CLEAR_THEMES = 'websitePlp/clearThemes';

export type ReceiveThemeSearchAction = {
  type: typeof RECEIVE_THEME_SEARCH;
  payload: {
    busy: false;
    facets: (ThemeFacetView | WThemeFacetView)[];
    sorts: (WThemeSortView | ThemeSortView)[];
    displayable_total?: number;
    theme_groups: (WThemeGroupSearchResultView | WThemeGroupSearchResultsView)[];
    hasMore: boolean;
  };
};

export type UpdateThemeFiltersAction = {
  type: typeof UPDATE_THEME_FILTERS;
  payload: {
    filters: PLPFilterOptions;
  };
};

export type UpdateThemeSortAction = {
  type: typeof UPDATE_THEME_SORT;
  payload: {
    applied_sort: WThemeSearchRequest.SortKeyEnum;
  };
};

export type RequestThemesAction = {
  type: typeof REQUEST_THEMES;
};

export type ClearThemesAction = {
  type: typeof CLEAR_THEMES;
};

// Actions
export const receiveThemeSearch = (
  searchResult: WThemeSearchView | WThemeListingSearchResultsView
): ReceiveThemeSearchAction => {
  const { displayable_total, facets, limit, sorts, theme_groups, total } = searchResult;
  return {
    type: RECEIVE_THEME_SEARCH,
    payload: {
      busy: false,
      displayable_total,
      facets,
      hasMore: total >= limit,
      sorts,
      theme_groups,
    },
  };
};

export const updateFilters = (newFilters: PLPFilterOptions): UpdateThemeFiltersAction => ({
  type: UPDATE_THEME_FILTERS,
  payload: {
    filters: newFilters,
  },
});

export const updateSort = (sortKey: WThemeSearchRequest.SortKeyEnum): UpdateThemeSortAction => ({
  type: UPDATE_THEME_SORT,
  payload: {
    applied_sort: sortKey,
  },
});

const requestThemes = (): RequestThemesAction => ({
  type: REQUEST_THEMES,
});

export const clearThemes = (): ClearThemesAction => ({
  type: CLEAR_THEMES,
});

export const fetchThemes = (searchRequest: WThemeSearchRequest) => (
  dispatch: AppDispatch,
  getState: () => RootState
): Promise<void> => {
  const isFetching = getState().websitePlp.busy;
  if (!isFetching) {
    dispatch(requestThemes());
    return facetedThemeSearch(searchRequest).then(themeSearchView => {
      dispatch(receiveThemeSearch(themeSearchView));
    });
  }
  return Promise.resolve();
};

export const fetchThemesWebsiteV2 = (searchRequest: LocalThemeSearchRequest) => (
  dispatch: AppDispatch,
  getState: () => RootState
): Promise<void> => {
  const isFetching = getState().websitePlp.busy;
  if (!isFetching) {
    dispatch(requestThemes());
    return websiteV2ThemeSearch(searchRequest).then(themeSearchView => {
      dispatch(receiveThemeSearch(themeSearchView));
    });
  }
  return Promise.resolve();
};

export type WebsitePlpActions =
  | ReceiveThemeSearchAction
  | UpdateThemeFiltersAction
  | RequestThemesAction
  | ClearThemesAction
  | UpdateThemeSortAction;

export const websitePlpActions = {
  receiveThemeSearch,
  updateFilters,
  clearThemes,
};
