import { getQuantityRangeWithProof } from 'cards/util/cardQuantityHelper';

export function getQuantityOptionsFromProjectMeta(cardType, projectMeta, maxQty = 400) {
  let quantityOptions;

  if (projectMeta && typeof projectMeta.minimum_quantity === 'number') {
    quantityOptions = [];

    const minQty = projectMeta.minimum_quantity;
    for (let i = minQty; i <= maxQty; i += 10) {
      quantityOptions.push({ label: i.toString(), value: i });
    }
  } else {
    quantityOptions = getQuantityRangeWithProof(cardType);
  }

  return quantityOptions;
}
