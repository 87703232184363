import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import {
  CARD_TYPES,
  AVAILABLE_PAPER_OPTIONS_BY_CARD_TYPE,
  AVAILABLE_MAGNET_OPTIONS_BY_CARD_TYPE,
  AVAILABLE_TYPE_OPTIONS_BY_CARD_TYPE,
  AVAILABLE_PAPER_OPTIONS_BY_STAMPED_CARD_TYPE,
} from 'cards/constants/CardTypes';
import {
  BASE_PAPER_TYPES,
  PRODUCT_OPTION_DETAILS,
  TYPE_OPTION_DETAILS,
  DISABLED_MSG,
  MAGNET_DISABLED_MSG,
} from 'cards/constants/CardLevelOptions';
import {
  CARD_SIZES,
  DISABLED_FOLDED_PAPER,
  DISABLED_POSTCARD_PAPER,
  SIZES_WITHOUT_SHAPES,
} from 'cards/constants/Cards';
import { ORIENTATION } from 'cards/types';
import _maxBy from 'lodash/maxBy';
import { matchOptionDetails } from 'cards/util/productDetail';
import FeatureFlags from '../../../../util/featureFlags';

// check if the card is the Menu one
export const isMenuCard = (optionType, cardType) =>
  optionType === 'size' && cardType === CARD_TYPES.menu;

// Get a list of the option values that are enabled
export const getEnabledOptionsValuesList = (options, cardType, optionType) => {
  return options.reduce((acc, curr) => {
    if (curr.enabled && curr.value !== 'digital') {
      acc.push(isMenuCard(optionType, cardType) ? `menu-${curr.value}` : curr.value);
    }
    return acc;
  }, []);
};

// Provide visible option list according to card type
export const enrichCardLevelOptions = (
  key,
  availableOptions,
  cardType,
  enabledOptionsValuesList,
  activeMediumKey,
  isAdminView,
  isStampFoilCard,
  orientation
) => {
  if (key === 'color') {
    if (!FeatureFlags.get('cardsExtraCustomization')) return availableOptions;

    const extraCustomizableColors = availableOptions.filter(
      o => o.metadata?.['extra-customizable']
    );

    // Only show the rainbow swatch if there is a SINGLE extra-customizable color
    if (extraCustomizableColors.length !== 1) return availableOptions;

    const extraCustomizableOption = extraCustomizableColors[0];
    const otherOptions = availableOptions.filter(o => o !== extraCustomizableOption);
    const rainbowSwatchOption = {
      ...extraCustomizableOption,
      metadata: {
        ...extraCustomizableOption.metadata,
        showRainbowSwatch: true,
      },
    };

    // Always display the rainbow swatch last
    return [...otherOptions, rainbowSwatchOption];
  }

  function getVisibleOptionList() {
    if (key === 'type') {
      return AVAILABLE_TYPE_OPTIONS_BY_CARD_TYPE[cardType];
    }
    if (activeMediumKey === 'magnet') {
      return AVAILABLE_MAGNET_OPTIONS_BY_CARD_TYPE[cardType][key];
    }
    if (isStampFoilCard) {
      return AVAILABLE_PAPER_OPTIONS_BY_STAMPED_CARD_TYPE[cardType][key];
    }
    return isAdminView && AVAILABLE_PAPER_OPTIONS_BY_CARD_TYPE.ADMIN[cardType]
      ? AVAILABLE_PAPER_OPTIONS_BY_CARD_TYPE.ADMIN[cardType][key]
      : AVAILABLE_PAPER_OPTIONS_BY_CARD_TYPE[cardType][key];
  }

  const visibleOptionsList = getVisibleOptionList();
  if (!visibleOptionsList) {
    return [];
  }

  return visibleOptionsList.map(option => {
    const finalOption =
      option === CARD_SIZES.folded && orientation === ORIENTATION.portrait
        ? `${option}-portrait`
        : option;
    const activeDetails =
      key === 'type' ? TYPE_OPTION_DETAILS[option] : PRODUCT_OPTION_DETAILS[finalOption];
    // If not enabled for card-type set option as disabled
    // If a silhouette is not enabled for the classic card size, set option as hidden
    return key === 'silhouette'
      ? {
          ...activeDetails,
          disabled: !enabledOptionsValuesList.includes(option),
          hidden: FeatureFlags.get('newPaperShapes') && !enabledOptionsValuesList.includes(option),
        }
      : { ...activeDetails, disabled: !enabledOptionsValuesList.includes(option), hidden: false };
  });
};

export const getOptionPricing = (pricing, cardType, medium, size, option) => {
  if (pricing && cardType && medium && size && option) {
    const sizePricing = _get(pricing, [cardType, medium, size]);

    if (sizePricing) {
      return option === 'size' ? sizePricing : sizePricing[option];
    }
  }
  return null;
};

export const getMaxOptionPrice = variations =>
  (variations && variations.length && _maxBy(variations, 'price_cents').price_cents) || 0;

export const getOptionPrice = (
  pricing,
  cardType,
  optionType,
  optionValue,
  activeSize,
  activeMediumKey
) => {
  const seachForSize = optionType === 'size' ? optionValue : activeSize;
  const sizePricing = getOptionPricing(
    pricing,
    cardType,
    activeMediumKey,
    seachForSize,
    optionType
  );
  let result;
  if (sizePricing && optionType && optionValue) {
    result = optionType === 'size' ? sizePricing.delta : sizePricing[optionValue];
  }
  return result || 0;
};

export const enrichOptionsAccSelection = (
  key,
  cardTypeOptions,
  activeSelection,
  cardType,
  pricing,
  activeMediumKey
) => {
  return cardTypeOptions.map(option => {
    const { size } = activeSelection;
    const { value } = option;
    const optionPrice = getOptionPrice(pricing, cardType, key, value, size, activeMediumKey);
    if (
      (FeatureFlags.get('cardsDisableMagnets') && key === 'type' && value === 'magnet') ||
      // some cards sizes can only have a square silhouette
      (key === 'silhouette' && value !== 'square' && SIZES_WITHOUT_SHAPES.includes(size)) ||
      // postcard never has these paper-types
      (size === 'postcard' && key === 'paper-type' && DISABLED_POSTCARD_PAPER.includes(value)) ||
      // holiday folded does not have round corners
      (cardType === CARD_TYPES.holiday &&
        size === 'folded-petite' &&
        key === 'silhouette' &&
        value === 'rounded') ||
      // Folded petite size does not have these paper-types
      (size === 'folded-petite' && key === 'paper-type' && DISABLED_FOLDED_PAPER.includes(value))
    ) {
      return {
        ...option,
        disabled: true,
        price: `+ ${(optionPrice / 100).toFixed(2).toString()} Ea.`,
      };
    }

    return {
      ...option,
      price: `+ ${(optionPrice / 100).toFixed(2).toString()} Ea.`,
    };
  });
};

export const sectionIsHidden = (
  optionType,
  isStampFoilCard,
  cardType,
  enrichedOptions,
  activeSelection,
  isLetterpress
) => {
  return (
    // Stamp foil STD cards do not display options for size or type
    (cardType === CARD_TYPES.std && isStampFoilCard && ['size', 'type'].includes(optionType)) ||
    // Stamp foil HC cards do not display options for type
    (cardType === CARD_TYPES.holiday && isStampFoilCard && optionType === 'type') ||
    // Letterpress cards don't display foil-color options
    (isLetterpress && optionType === 'foil-color') ||
    // Letterpress cards don't display type options
    (isLetterpress && optionType === 'type') ||
    // Postcard size -> no custom foil options
    (optionType === 'foil-color' && activeSelection.size === 'postcard') ||
    // Empty options aren't displayed
    enrichedOptions.length < 1 ||
    // We only show SINGLE options for extra-customizable cards
    (enrichedOptions.length === 1 && !enrichedOptions[0].metadata?.['extra-customizable']) ||
    // Hide the shape section for a card when only square corners are enabled
    (optionType === 'silhouette' &&
      enrichedOptions.filter(option => option.hidden === false).length === 1)
  );
};

export const getCurrentOptionDimension = (selection, optionType, orientation) => {
  if (!selection) {
    return null;
  }
  const optionMatch = matchOptionDetails(selection.value, optionType, orientation);
  return (optionMatch && optionMatch.subTitle) || null;
};

export const getCurrentOptionLabel = ({ selection, optionType, cardType, orientation }) => {
  if (!selection) {
    return '';
  }
  const optionMatch = matchOptionDetails(selection.value, optionType, orientation);
  return isMenuCard(optionType, cardType)
    ? `${selection.value.replace('x', ' x ')} in.`
    : (optionMatch && optionMatch.label) || selection.label || '';
};

export const isComparisonModuleHidden = (cardType, options) => {
  return cardType === CARD_TYPES.ctd && _isEqual(options, BASE_PAPER_TYPES);
};

export const getTooltipMessagePerOption = (optionType, activeSize, isMagnet = false) => {
  if (optionType === 'paper-type' && activeSize === CARD_SIZES.postcard) {
    /*
     * A specific message can be added for a specfic property
     * in the DISABLED_MSG object (i.e. pearlized is out of stock)
     * see src/cards/components/CardDetail/ToggleButton/ToggleButton.jsx for usage
     *
     * But for the postcard size, the specific messaging for paper-type does not applies
     * since the paper type(s) concerned are not available by default.
     * Hence enforcing the tooltip message to consider only the default case for messaging.
     */

    return DISABLED_MSG[optionType].default;
  }
  return isMagnet ? MAGNET_DISABLED_MSG[optionType] : DISABLED_MSG[optionType];
};
