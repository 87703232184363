import _without from 'lodash/without';

import { ActionTypes } from '../actions/digitalDraftsActions';

const initialState = {
  busy: false,
  entities: {
    customizations: {},
    drafts: {},
  },
  allIds: [],
  isDraftInCartWarning: false,
};

export default function digitalDraftsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.DIGITAL_REQUEST_DRAFTS: {
      return {
        ...state,
        busy: true,
      };
    }
    case ActionTypes.DIGITAL_RECEIVE_DRAFTS: {
      const { drafts } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          ...drafts.entities,
        },
        allIds: drafts.result,
        busy: false,
      };
    }
    case ActionTypes.DIGITAL_RECEIVE_DELETE_DRAFT: {
      const { projectUUID } = action.payload;
      const allIds = _without(state.allIds, projectUUID);
      return {
        ...state,
        allIds,
      };
    }
    case ActionTypes.DIGITAL_ADD_REMOVED_ORDER: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.drafts.entities,
        },
        allIds: action.payload.drafts.result,
      };
    }
    default:
      return state;
  }
}
