import { normalize } from 'normalizr';
import _isEmpty from 'lodash/isEmpty';
import { ORDERED_CUSTOMIZATION_TYPES } from 'cards/constants/CardTypes';
import { findLeadCustomization } from '../util/customization';
import ApiService from '../../util/api';
import { openModal } from './modalActions';

import { draftListSchema } from '../schemas';
import { getAllDrafts } from '../selectors/draftsSelector';
import { getInvitesAccountId } from '../../selectors/user/userSelectors';

export const ActionTypes = {
  REQUEST_DRAFTS: 'zola/card/card_project/REQUEST_DRAFTS',
  RECEIVE_DRAFTS: 'zola/card/card_project/RECEIVE_DRAFTS',
  RECEIVE_DELETE_PROJECT: 'zola/cards/card_project/RECEIVE_DELETE_PROJECT',
  RESET_DRAFT_IN_CART_WARNING: 'zola/cards/card_project/RESET_DRAFT_IN_CART_WARNING',
  SET_DRAFT_IN_CART_WARNING: 'zola/cards/card_project/SET_DRAFT_IN_CART_WARNING',
};

function requestDrafts() {
  return {
    type: ActionTypes.REQUEST_DRAFTS,
  };
}

function receiveDrafts(drafts) {
  return {
    type: ActionTypes.RECEIVE_DRAFTS,
    payload: {
      drafts,
    },
  };
}

function receiveDeleteProject(projectUUID) {
  return {
    type: ActionTypes.RECEIVE_DELETE_PROJECT,
    payload: {
      projectUUID,
    },
  };
}

function getLastDraftEditDate({ primary_customization: customization }) {
  return customization ? new Date(customization.edited_at || customization.updated_at) : new Date();
}

export function fetchDrafts() {
  return dispatch => {
    dispatch(requestDrafts());
    return ApiService.get('/web-api/v2/card-project/projects/drafts').then(json => {
      const mappedDrafts = json
        .map(draft => ({
          ...draft,
          customizations: draft.customizations.sort(
            (a, b) =>
              ORDERED_CUSTOMIZATION_TYPES.indexOf(a.type) -
              ORDERED_CUSTOMIZATION_TYPES.indexOf(b.type)
          ),
          primary_customization: findLeadCustomization(draft.customizations),
        }))
        .sort((a, b) => getLastDraftEditDate(b) - getLastDraftEditDate(a)); // sort drafts from most recent edit to oldest
      const normalizedDrafts = normalize(mappedDrafts, draftListSchema);
      return dispatch(receiveDrafts(normalizedDrafts));
    });
  };
}

export function deleteProject(projectUUID) {
  return dispatch => {
    const requestBody = {
      projectUUID,
    };
    return ApiService.delete(`/web-api/v2/card-project/${projectUUID}`, requestBody).then(() =>
      dispatch(receiveDeleteProject(projectUUID))
    );
  };
}

export function handleDeleteProjectError(error) {
  return dispatch => {
    error.response.json().then(({ message }) => {
      if (message && message.indexOf('already in a cart') !== -1) {
        dispatch(
          openModal(
            'ERROR',
            {
              title: 'Cannot Delete Draft',
              message:
                'This draft cannot be deleted because it is currently in your cart, which could be within another browser window or device. Please remove it from the cart in order to delete it.',
            },
            { size: 'md' }
          )
        );
      }
    });
  };
}

export const maybeFetchDrafts = () => (dispatch, getState) => {
  const state = getState();
  const drafts = getAllDrafts(state);
  const hasInvitesAccount = getInvitesAccountId(state);

  if (hasInvitesAccount && _isEmpty(drafts)) {
    return dispatch(fetchDrafts());
  }

  return null;
};

export function resetDraftInCartWarning() {
  return {
    type: ActionTypes.RESET_DRAFT_IN_CART_WARNING,
  };
}

export function setDraftInCartWarning(isDraftInCartWarning) {
  return {
    type: ActionTypes.SET_DRAFT_IN_CART_WARNING,
    payload: {
      isDraftInCartWarning,
    },
  };
}
