import { createSelector } from 'reselect';
import type { RootState } from 'reducers/index';
import type { GuestListReducerStateType } from 'reducers/guestList';

export const getGuestListState = (state: RootState): GuestListReducerStateType => state.guestList;

export const selectGuests = createSelector(getGuestListState, state => state.guests);

export const getGuestSearch = createSelector(selectGuests, guests => guests.search);

export const getIsGuestListBusy = createSelector(selectGuests, guests => guests.busy);

export const getIsGuestListInitialized = createSelector(selectGuests, guests => guests.initialized);

export const getGuestGroupsById = createSelector(selectGuests, guests => guests.byId);

export const getGuestGroupIds = createSelector(selectGuests, guests => guests.orderedIds);
