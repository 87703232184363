import { ActionTypes } from '../actions/fileActions';

const initialState = {
  busy: false,
  filePreview: {},
};

const getUpdatedState = (state, contentKey, data) => ({
  ...state,
  filePreview: {
    ...state.filePreview,
    [contentKey]: data,
  },
});

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FILE_UPLOADING: {
      const { contentKey } = action.payload;
      return getUpdatedState(state, contentKey, { busy: true });
    }
    case ActionTypes.FILE_UPLOADED: {
      const { contentKey, fileData } = action.payload;
      return getUpdatedState(state, contentKey, { ...fileData, busy: false });
    }
    case ActionTypes.UPLOAD_INTERRUPTED: {
      const { contentKey } = action.payload;
      return getUpdatedState(state, contentKey, { busy: false });
    }
    default:
      return state;
  }
};

export default fileReducer;
