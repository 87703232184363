import React from 'react';

// components
import Form from '../Form';

const ITEM_IN_CART =
  'Your cart contains items and they need to be removed or checked out before you can start customizing your free sample!';
const LIMIT_REACHED =
  'If you liked our printing quality and want to proof a customized card, you can order a quantity of 1 instead.';

const redirectToCheckout = () => {
  window.location.href = '/cart/checkout';
};

interface WarningProps {
  exitFlow: () => void;
  samplesAvailable: boolean;
}

const Warning = ({ exitFlow, samplesAvailable }: WarningProps): JSX.Element => {
  return samplesAvailable ? (
    <>
      <Form.Header description={ITEM_IN_CART} title="Ooops! Please check your cart" />
      <Form.ButtonWrapper>
        <Form.Button btnText="Continue Shopping" handleClick={exitFlow} variant="secondary" />
        <Form.Button btnText="Go To Checkout" handleClick={redirectToCheckout} />
      </Form.ButtonWrapper>
    </>
  ) : (
    <>
      <Form.Header description={LIMIT_REACHED} title="Whoa there! You've hit the maximum" />
      <Form.ButtonWrapper>
        <Form.Button btnText="Got It" handleClick={exitFlow} />
      </Form.ButtonWrapper>
    </>
  );
};

export default Warning;
