import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import { ActionTypes } from '../actions/notesActions';

const initialState = {
  activeGuestGroupUUID: null,
  guestsWithCustomNote: null,
  notesPerGuestGroupUUID: {},
  isBusy: false,
};

const notes = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.REQUEST_GUEST_NOTE:
    case ActionTypes.UPDATE_GUEST_NOTE:
    case ActionTypes.REQUEST_GUEST_DEFAULT_NOTE: {
      return {
        ...state,
        isBusy: true,
      };
    }
    case ActionTypes.RECEIVE_GUESTS_WITH_CUSTOM_NOTE: {
      let notesPerGuest = { ...state.notesPerGuestGroupUUID };
      _forEach(action.payload, value => {
        const customNotePerGuest = {
          [value.guest_group_uuid]: {
            customNote: value.data,
          },
        };
        notesPerGuest = { ...notesPerGuest, ...customNotePerGuest };
      });
      return {
        ...state,
        guestsWithCustomNote: action.payload,
        notesPerGuestGroupUUID: {
          ...notesPerGuest,
        },
      };
    }
    case ActionTypes.RECEIVE_GUEST_NOTE: {
      const { data, guest_group_uuid, type } = action.payload;
      return {
        ...state,
        isBusy: false,
        activeGuestGroupUUID: _isEmpty(data) ? null : guest_group_uuid,
        notesPerGuestGroupUUID: {
          ...state.notesPerGuestGroupUUID,
          [guest_group_uuid]: {
            ...state.notesPerGuestGroupUUID[guest_group_uuid],
            [type]: data,
          },
        },
      };
    }
    case ActionTypes.SET_ACTIVE_GUEST_GROUP_UUID: {
      const { guestGroupUUID } = action.payload;
      return {
        ...state,
        isBusy: false,
        activeGuestGroupUUID: guestGroupUUID,
      };
    }
    case ActionTypes.RESET_GUESTS_NOTES: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default notes;
