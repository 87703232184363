import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.less';

const DetailBox = ({
  show,
  children,
  title,
  img,
  detailBoxOverrideClass,
  detailBoxTitleOverrideClass,
}) => {
  if (!show || !title) {
    return null;
  }

  return (
    <div className={cx(styles.detailBox, detailBoxOverrideClass)}>
      {img && <img src={img} alt={title} className={styles.imageBlock} />}
      {title && (
        <div className={cx(styles.detailBoxTitle, detailBoxTitleOverrideClass)}>{title}</div>
      )}
      <div className={styles.detailBoxDescription}>{children}</div>
    </div>
  );
};

DetailBox.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

DetailBox.defaultProps = {
  show: false,
};

export default DetailBox;
