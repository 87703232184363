import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

// actions
import { deleteUploadcareImage, setUploadcareImage } from 'cards/reducers/samplesFlow/actions';

// components
import { Link } from '@zola/zola-ui/src/components/Link';
import { DeleteIcon } from '@zola/zola-ui/src/components/SvgIcons/DeleteIcon';
import { DownloadIcon } from '@zola/zola-ui/src/components/SvgIcons/Download';

// utils
import { openUploadcareModal } from 'components/common/UploadcareWidget';
import { UploadcareFile } from 'cards/reducers/samplesFlow/types';
import { CARD_UPLOAD_TABS } from '../../constants';

import styles from './styles.module.less';

const messages = ['Looking good ✨', 'Beautiful choice 🙌🏻', 'All the feels 💌'];

interface UploadcareProps {
  cardSuiteUUID: string;
  cardSuiteUUIDList: string[];
  previousUploadcareFile: UploadcareFile;
}

const Uploadcare = ({
  cardSuiteUUID,
  cardSuiteUUIDList,
  previousUploadcareFile,
}: UploadcareProps): JSX.Element => {
  const dispatch = useDispatch();

  const [messageIndex, setMessageIndex] = useState(0);

  const handleClick = useCallback(() => {
    const saveUploadcareImage = (uploadcareImage: UploadcareFile) => {
      dispatch(setUploadcareImage(cardSuiteUUID, cardSuiteUUIDList, uploadcareImage));
      setMessageIndex((messageIndex + 1) % messages.length);
    };

    openUploadcareModal({
      analyticsProduct: 'CARDS',
      analyticsLocation: 'SINGLE_SAMPLE_FLOW',
      closeViaOverlay: false,
      onUploadcareFileReady: saveUploadcareImage,
      tabs: CARD_UPLOAD_TABS,
    });
  }, [dispatch, cardSuiteUUID, cardSuiteUUIDList, messageIndex]);

  if (previousUploadcareFile) {
    return (
      <>
        <div className={styles.message}>{messages[messageIndex]}</div>
        <div className={styles.ctaWrapper}>
          <button
            className={styles.cta}
            onClick={() => dispatch(deleteUploadcareImage(cardSuiteUUID))}
          >
            <DeleteIcon className={styles.icon} height={20} width={20} /> Remove Photo
          </button>
          <button className={styles.cta} onClick={handleClick}>
            <DownloadIcon className={styles.icon} flip="vertical" height={20} width={20} /> Change
            Photo
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.uploadLink}>
        <Link arrow onClick={handleClick}>
          Upload a Photo
        </Link>
      </div>
      <div className={styles.text}>Optional</div>
    </>
  );
};

export default Uploadcare;
