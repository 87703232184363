import { BASE_QUANTITY_RANGE, ADDITIONAL_QUANTITIES } from 'cards/constants/Cards';
import { CARD_TYPES } from 'cards/constants/CardTypes';

type QuantityRangeWithProof = {
  label: string;
  value: number;
};

export const getQuantityRange = (cardType: string): Array<number> =>
  cardType === CARD_TYPES.place
    ? [...ADDITIONAL_QUANTITIES, ...BASE_QUANTITY_RANGE].sort((a, b) => a - b)
    : BASE_QUANTITY_RANGE;

export const getQuantityRangeWithProof = (cardType: string): Array<QuantityRangeWithProof> => {
  const options = getQuantityRange(cardType).map(quantity => ({
    label: quantity.toString(),
    value: +quantity,
  }));
  options.unshift({ label: '1 (Proof)', value: 1 });

  return options;
};
