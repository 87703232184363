import type { CardTemplateBackgroundView } from '@zola/svc-web-api-ts-client';
import {
  ExtraCustomizationActions,
  RECEIVE_BACKGROUND_ASSETS,
} from 'cards/actions/extraCustomization';

export interface ExtraCustomizationState {
  backgroundAssets: CardTemplateBackgroundView[];
}

const initialState: ExtraCustomizationState = {
  backgroundAssets: [],
};

const extraCustomizationReducer = (
  state = initialState,
  action: ExtraCustomizationActions
): ExtraCustomizationState => {
  switch (action.type) {
    case RECEIVE_BACKGROUND_ASSETS: {
      return {
        ...state,
        backgroundAssets: action.payload,
      };
    }
    default:
      return state;
  }
};

export default extraCustomizationReducer;
