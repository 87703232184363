import { createSelector } from 'reselect';
import _find from 'lodash/find';
import _minBy from 'lodash/minBy';
import { getCardSettings } from 'cards/util/cardSettingsUtils';

// Small array of possible shot keys for all card types
// only the ones that are present will be displayed inside the PDP
const IMAGE_TYPES_KEYS = [
  'pdp_front_dbsided',
  'pdp_front_envelope',
  'pdp_back_envelope',
  'pdp_rsvp_envelope',
  'pdp_envelope_options',
  'pdp_postcard',
  'pdp_front_envelope_alt',
  'pdp_back_envelope_alt',
  'pdp_inside',
  'pdp_magnet',
  'pdp_full_suite',
  'pdp_papers',
  'pdp_sizes',
  'pdp_corners',
  'pdp_foil',
  'pdp_website',
  'pdp_note',
];

export const getIsAdminView = state => state.cards.cardCatalog.isAdminView;
export const getCards = state => state.cards.cardCatalog.cards;
export const getCardUUIDsByVariationUUID = state =>
  state.cards.cardCatalog.cardUUIDsByVariationUUID;
export const getRecentlyViewedSuites = state => state.cards.cardCatalog.recentlyViewed;

// SELECTORS FOR PLP
export const getCardCatalogBusy = state => state.cards.cardCatalog.busy;
export const getSuitesCount = state => state.cards.cardCatalog.suites.count;
export const getOrderedCardSuiteUUIDs = state => state.cards.cardCatalog.suites.orderedUUIDs;
export const getCardSuitesByUUID = state => state.cards.cardCatalog.suites.byUUID;
export const getDisplayableSearchTotal = state => state.cards.cardCatalog.suites.displayableTotal;
export const getIsInitialSearch = state => state.cards.cardCatalog.initial;
export const getSearchFilters = state => state.cards.cardCatalog.filters;
export const getCollaborators = state => state.cards.cardCatalog.collaborators;
export const getLoadedStartIndex = state => state.cards.cardCatalog.suites.loadedStartIndex;
export const getLoadedEndIndex = state => state.cards.cardCatalog.suites.loadedEndIndex;

export const getCardSuitesAsArray = createSelector(
  getCardSuitesByUUID,
  getOrderedCardSuiteUUIDs,
  (cardSuitesByUUID, orderedCardSuiteUUIDs) =>
    orderedCardSuiteUUIDs.map(uuid => cardSuitesByUUID[uuid])
);

export const getSuiteLeadCards = createSelector(getCardSuitesAsArray, cardSuites =>
  cardSuites.map(cardSuite => ({
    ...cardSuite.lead_card,
    suiteUUID: cardSuite.uuid,
    suiteName: cardSuite.name,
    collaborator: cardSuite.collaborator,
    thirdParty: cardSuite.third_party,
    singleSampleAvailable: cardSuite.single_sample_available,
    singleSampleSku: cardSuite.single_sample_sku,
    description: cardSuite.description,
  }))
);

export const getThemeMatchedCardsByUUID = state =>
  state.cards.cardCatalog.suites.themeMatchedCards.byUUID;
export const getOrderedThemeMatchedCardUUIDs = state =>
  state.cards.cardCatalog.suites.themeMatchedCards.orderedUUIDs;

export const getThemeMatchedCardsAsArray = createSelector(
  getThemeMatchedCardsByUUID,
  getOrderedThemeMatchedCardUUIDs,
  (themeMatchedCardsByUuid, orderedThemeMatchedCardUuids) =>
    orderedThemeMatchedCardUuids.map(uuid => themeMatchedCardsByUuid[uuid])
);

export const getThemeMatchedCards = createSelector(getThemeMatchedCardsAsArray, cardSuites =>
  cardSuites.map(cardSuite => ({
    ...cardSuite.lead_card,
    suiteUUID: cardSuite.uuid,
    suiteName: cardSuite.name,
    collaborator: cardSuite.collaborator,
    thirdParty: cardSuite.third_party,
    singleSampleAvailable: cardSuite.single_sample_available,
    singleSampleSku: cardSuite.single_sample_sku,
    description: cardSuite.description,
    isThemeMatchedCard: cardSuite.isThemeMatchedCard,
  }))
);

export const getAllMotifs = state => state.cards.cardCatalog.motifs;

export const getIsFilteredCardSearch = createSelector(
  getSearchFilters,
  getAllMotifs,
  (searchFilters, allMotifs) => {
    const {
      lead_card_type: cardType,
      color: activeColor,
      multiColor,
      seo_colors: seoColors,
      is_portrait: isPortrait,
      is_landscape: isLandscape,
      has_foil: hasFoil,
      has_magnet: hasMagnet,
      has_custom_photo: hasCustomPhoto,
      has_no_custom_photo: hasNoCustomPhoto,
      is_vow_renewal: isVowRenewal,
      featured_artist: featuredArtist,
      motifs: selectedMotifs,
      silhouettes: selectedSilhouettes,
      is_letterpress: isLetterpress,
    } = searchFilters;
    const { hasMotifs } = getCardSettings(cardType);
    const motifsLoaded = allMotifs.length > 0;

    return Boolean(
      hasCustomPhoto ||
        hasNoCustomPhoto ||
        activeColor !== null ||
        (!!multiColor && Array.isArray(multiColor) && multiColor.length > 0) ||
        (!!selectedSilhouettes &&
          Array.isArray(selectedSilhouettes) &&
          selectedSilhouettes.length > 0) ||
        (!!seoColors && Array.isArray(seoColors) && seoColors.length > 0) ||
        (hasMotifs && motifsLoaded && selectedMotifs.length > 0) ||
        hasMagnet ||
        hasFoil ||
        featuredArtist ||
        isPortrait ||
        isLandscape ||
        isLetterpress ||
        isVowRenewal
    );
  }
);

// SELECTORS FOR PDP
export const getDetailSuite = state => state.cards.cardCatalog.detailSuite;
export const getLeadCard = state => state.cards.cardCatalog.detailSuite.leadCard;
export const getInitialVariationColor = state => state.cards.cardCatalog.initialVariationColor;
export const getSetRelatedCardsFamilyName = state => state.cards.cardCatalog.relatedCardsFamilyName;
export const getRelatedDraftsFromSuite = state => state.cards.cardCatalog.relatedDraftsFromSuite;
const getThemeGroupsForCards = state => state.cards.cardCatalog.themeGroupsForCards;
const getRelatedCardsFromLeadSuite = state => state.cards.cardCatalog.relatedCardsFromSuite;
const getRelatedCardsByDesigner = state => state.cards.cardCatalog.relatedCardsByDesigner;
const getRelatedCardsByMotif = state => state.cards.cardCatalog.relatedCardsByMotif;
const getSelectedMedium = state => state.cards.cardCatalog.detailSuite.selectedMedium;
const getDigitalCard = state => state.cards.cardCatalog.detailSuite.digitalCard;

export const getDetailSuiteName = createSelector(getDetailSuite, detailSuite => {
  if (detailSuite && detailSuite.name) {
    return detailSuite.name;
  }
  return null;
});

export const getDetailSuiteLeadCard = createSelector(
  getDetailSuite,
  detailSuite => detailSuite.leadCard
);

export const getMediumLeadCard = createSelector(
  getSelectedMedium,
  getDetailSuite,
  getDigitalCard,
  // INVITES-1235: defensive check for existing digital variations, remove if we're confident on BE INV-1235 work
  (selectedMedium, detailSuite, digitalCard) =>
    selectedMedium === 'DIGITAL' &&
    digitalCard &&
    digitalCard.variations &&
    digitalCard.variations.length > 0
      ? digitalCard
      : detailSuite.leadCard
);

export const getVariationOptions = createSelector(
  getDetailSuite,
  detailSuite => detailSuite.variationOptions
);

const getLeadCardVariations = createSelector(getMediumLeadCard, leadCard => leadCard.variations);

export const getOptionGroupsByKey = createSelector(
  getSelectedMedium,
  getDetailSuite,
  getDigitalCard,
  // INVITES-1235: defensive check for existing digital variations, remove if we're confident on BE INV-1235 work
  (selectedMedium, detailSuite, digitalCard) =>
    selectedMedium === 'DIGITAL' &&
    digitalCard &&
    digitalCard.variations &&
    digitalCard.variations.length > 0
      ? detailSuite.digitalOptionGroupsByKey
      : detailSuite.leadOptionGroupsByKey
);

// INV-905: filter non-digital options when finding digital variations
export const getActiveVariation = createSelector(
  getLeadCardVariations,
  getVariationOptions,
  getSelectedMedium,
  (variations, variationOptions, medium) => {
    const selectedOptions = variationOptions;
    // before finding a match, update the options based on medium
    if (selectedOptions.size !== 'magnet') {
      delete selectedOptions.finish;
    } else if (selectedOptions.size === 'magnet') {
      delete selectedOptions['paper-type'];
    }
    if (medium === 'DIGITAL') {
      delete selectedOptions['paper-type'];
      selectedOptions.size = 'petite';
    }
    // if postcard, assume 'reverse-printing' is true
    if (selectedOptions['reverse-printing'] && selectedOptions.size === 'postcard') {
      // return the active variation
      return _find(variations, {
        option_values: { ...selectedOptions, 'reverse-printing': 'true' },
      });
    }

    // * this is needed when returning from magnet size, the paper-type being deleted (see line 141 above)
    // * UI default on Smooth, the according variation should be picked up
    if (selectedOptions.size !== 'magnet' && !selectedOptions['paper-type']) {
      selectedOptions['paper-type'] = 'smooth';
    }

    if (selectedOptions.size === 'folded-petite') {
      delete selectedOptions['reverse-printing'];
    }
    // * when switching back from folded-petite size, the reverse-printing being deleted (see line just above)
    // * Should default back on the reverse-printing false specifically, to pick the right default variation
    if (selectedOptions.size !== 'folded-petite' && !selectedOptions['reverse-printing']) {
      selectedOptions['reverse-printing'] = 'false';
    }

    // return the active variation
    return _find(variations, {
      option_values: { ...selectedOptions },
    });
  }
);

export const getActiveVariationImages = createSelector(getActiveVariation, activeVariation =>
  IMAGE_TYPES_KEYS.reduce((result, imageKey) => {
    if (activeVariation && activeVariation.image_urls[imageKey]) {
      result.push(activeVariation.image_urls[imageKey]);
    }
    return result;
  }, [])
);

const getColorOptions = createSelector(getOptionGroupsByKey, optionGroupsByKey => {
  if (optionGroupsByKey.color && optionGroupsByKey.color.options) {
    return optionGroupsByKey.color.options;
  }
  return null;
});

export const getActiveColorOptionLabel = createSelector(
  getColorOptions,
  getVariationOptions,
  (colorOptions, variationOptions) => {
    const color = _find(
      colorOptions,
      colorOption => colorOption.value.toUpperCase() === variationOptions.color.toUpperCase()
    );
    return color.label;
  }
);

export const getMinimumPriceCents = createSelector(getLeadCardVariations, variations => {
  const minPriceVariation = _minBy(variations, variation => variation.price_cents);
  return minPriceVariation.price_cents;
});

export const getThemeGroupsForLeadCard = createSelector(
  getLeadCard,
  getThemeGroupsForCards,
  (leadCard, themeGroups) => {
    if (leadCard && themeGroups && themeGroups[leadCard.uuid]) {
      return themeGroups[leadCard.uuid];
    }
    return null;
  }
);

export const getRelatedCardsFromCurrentSuite = createSelector(
  getRelatedCardsFromLeadSuite,
  relatedCardsFromSuite => {
    if (relatedCardsFromSuite) {
      return relatedCardsFromSuite.map(relatedCardFromSuite => ({
        ...relatedCardFromSuite.lead_card,
        suiteUUID: relatedCardFromSuite.uuid,
        suiteName: relatedCardFromSuite.name,
      }));
    }
    return null;
  }
);

export const getRelatedCardsByCurrentDesigner = createSelector(
  getRelatedCardsByDesigner,
  relatedCardsByDesigner => {
    if (relatedCardsByDesigner) {
      return relatedCardsByDesigner.map(relatedCardByDesigner => ({
        ...relatedCardByDesigner.lead_card,
        suiteUUID: relatedCardByDesigner.uuid,
        suiteName: relatedCardByDesigner.name,
      }));
    }
    return null;
  }
);

export const getRelatedCardsByCurrentMotif = createSelector(
  getRelatedCardsByMotif,
  relatedCardsByMotif => {
    if (relatedCardsByMotif) {
      return relatedCardsByMotif.map(relatedCardByMotif => ({
        ...relatedCardByMotif.lead_card,
        suiteUUID: relatedCardByMotif.uuid,
        suiteName: relatedCardByMotif.name,
      }));
    }
    return null;
  }
);
