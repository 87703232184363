import React from 'react';
import cx from 'classnames';
import { ptToPx, CARD_BLEED_PT } from 'cards/util';
import { shouldHideStampedFoilImage } from '@zola-helpers/client/dist/es/util/cards';
import { getImageUrl } from '../../../../util/imageHelper';
import { usePhotoObjectURL } from '../util/hooks';
import styles from './styles.module.less';

const ImageElement = ({
  element,
  border = false,
  pixelCrop = undefined,
  photo = undefined,
  uploadcareFile = undefined,
  className = undefined,
  isRounded = false,
  isFolded = false,
}) => {
  const photoURL = usePhotoObjectURL(photo);
  const shouldHide = shouldHideStampedFoilImage(element?.tag, 'gold');

  if (!element || shouldHide) {
    return null;
  }

  const { customizable, overlay, layer = 0 } = element;
  const isFoldedCardElement = isFolded && !customizable && !overlay;

  const elemHight = isFoldedCardElement ? element.height / 2 : element.height;

  /*
   * Because our templates dimensions includes the bleed value
   * it's deducted on the preview container context for Sample preview / Digital canvas preview.
   * Thus, elements positions needs to be adjusted.
   */
  const elementLeftPos = element.x - CARD_BLEED_PT;
  const elementTopPos = (isFoldedCardElement ? elemHight : element.y) - CARD_BLEED_PT; // Only landscape folded are supported for now

  const divStyle = {
    zIndex: layer,
    left: `${elementLeftPos}pt`,
    top: `${elementTopPos}pt`,
    width: `${element.width}pt`,
    height: `${elemHight}pt`,
  };

  let imgURL;
  const croppedImageStyle = {};

  if (uploadcareFile) {
    // Uploadcare image
    imgURL = uploadcareFile.cdnUrl;
  } else if (photo && photo.valid && pixelCrop && photoURL) {
    // Blob image
    const { filledSize } = photo;

    const backgroundScale = {
      w: ptToPx(element.width) / filledSize,
      h: ptToPx(elemHight) / filledSize,
    };

    const cropScale = { w: filledSize / pixelCrop.width, h: filledSize / pixelCrop.height };

    const offset = {
      x: -pixelCrop.x * backgroundScale.w * cropScale.w,
      y: -pixelCrop.y * backgroundScale.h * cropScale.h,
    };

    croppedImageStyle.backgroundPosition = `${offset.x}px ${offset.y}px`;
    croppedImageStyle.backgroundSize = `${Math.round(100 * cropScale.w)}%`;

    imgURL = photoURL;
  } else {
    // Default element image
    imgURL = getImageUrl(element.image_id);
  }

  croppedImageStyle.backgroundImage = `url(${imgURL})`;
  croppedImageStyle.backgroundPosition = isFoldedCardElement ? 'bottom' : '';

  return (
    <div
      className={cx(styles.imageLayer, className, {
        [styles.rounded]: isRounded,
        [styles.border]: border,
      })}
      style={{ ...divStyle, ...croppedImageStyle }}
    />
  );
};

export default ImageElement;
