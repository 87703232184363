import { PRODUCT_OPTION_DETAILS } from 'cards/constants/CardLevelOptions';

/*
 * All our templates dimensions adds bleed value
 * The bleed value is fixed: 0.125 inch.
 * e. g. For petite size, of which final dimensions are 4.25 x 5.5,
 * the template will be 4.5 x 5.75 inches.
 */
export const CARD_BLEED_PT = 9; // 0.125 inch.

const PX_TO_PT_RATIO = 16 / 12;

export const ptToPx = pt => pt * PX_TO_PT_RATIO;
export const pxToPt = px => px / PX_TO_PT_RATIO;

export const petite = { w: 552, h: 608 }; // px value
export const petiteV2 = { w: 585, h: 639 }; // this is the size adjusted for padding in image, so that image renders same size as customization preview
export const size5x7 = { w: 723, h: 740 }; // px value

const BASE_MARGIN = 40;
const SIDE_PANEL_WIDTH = 380;

export const getCustomizationFlowMargin = () => {
  return window.matchMedia('(max-width: 767px)').matches
    ? BASE_MARGIN
    : SIDE_PANEL_WIDTH + BASE_MARGIN;
};

// Mobile preview scaling
export const mobileScaleFactor = (windowWidth, cardWidth) => {
  // NOTE: this returns a string, not a number
  if (windowWidth >= cardWidth) return '1';
  return (windowWidth / cardWidth).toFixed(2);
};

// Calculates the pixel amount of white space when something is shrunk
export const scaledMargin = (scaleFactor, dimensionLength) =>
  Math.floor(((1 - parseFloat(scaleFactor)) * dimensionLength) / 2);
export const scaledStyle = (scaleFactor, height) => ({
  transform: `scale(${scaleFactor})`,
  margin: `-${Math.floor(((1 - parseFloat(scaleFactor)) * height) / 2)}px 0`,
});

/* COLOR CALCULATIONS */
export const hexToRgb = hex => {
  // Assumes NO Triplet RGB values
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
//  return 8-bit brightness value, SOURCE: https://www.w3.org/TR/AERT/#color-contrast
export const getBrightness = rgbObj =>
  rgbObj && (rgbObj.r * 299 + rgbObj.g * 587 + rgbObj.b * 114) / 1000;

// 60 * 1000 ms -> 60 seconds
export const autoSaveTimeSpan = 60 * 1000;
export const autoSaveMaxSpan = 3600 * 1000;

export const pageName = type => {
  switch (type) {
    case 'INVITATION_ENVELOPE': {
      return 'envelope';
    }
    case 'RSVP_ENVELOPE': {
      return 'rsvpEnvelope';
    }
    case 'RSVP': {
      return 'rsvp';
    }
    case 'THANK_YOU_INSIDE': {
      return 'detailInside';
    }
    case 'THANK_YOU_WRITE_NOTES': {
      return 'detailWrite';
    }
    case 'PLACE_ASSIGN': {
      return 'detailAssign';
    }
    default: {
      return 'detailFront';
    }
  }
};

// Utils for Drafts / Orders
export const formatCardName = str => {
  const arr = str.split(' - ');
  // INV-905
  // TODO: defensive check string for wrong string segment
  return arr.slice(0, -1).join(' - ');
};

export const formatCardNameWithMedium = str => {
  const cardTypeWithMedium = formatCardName(str);

  const cardType = cardTypeWithMedium.split(' ');

  if (cardType[0] === 'Paper') {
    cardType.shift();
    return cardType.join(' ');
  }

  return cardTypeWithMedium;
};

// Utils for set the cursor position
// https://www.endyourif.com/set-cursor-position-of-textarea-with-javascript/
const setSelectionRange = (input, selectionStart, selectionEnd) => {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(selectionStart, selectionEnd);
  } else if (input.createTextRange) {
    const range = input.createTextRange();
    range.collapse(true);
    range.moveEnd('character', selectionEnd);
    range.moveStart('character', selectionStart);
    range.select();
  }
};

export const setCaretToPos = (input, pos) => {
  setSelectionRange(input, pos, pos);
};

export const customFoilColorLabel = foilColor =>
  PRODUCT_OPTION_DETAILS[foilColor.toLowerCase()].label;
