// Images
import DoubleThick from 'assets/images/invitations/pdp-hover/pdp_paperDoubleThick.jpg';
import Square from 'assets/images/invitations/pdp-hover/pdp_squareHover.jpg';
import Rounded from 'assets/images/invitations/pdp-hover/pdp_roundedHover.jpg';
import Smooth from 'assets/images/invitations/pdp-hover/pdp_paperSmooth.jpg';
import Natural from 'assets/images/invitations/pdp-hover/pdp_paperNatural.jpg';
import TripleThick from 'assets/images/invitations/pdp-hover/pdp_paperTripleThick.jpg';
import Linen from 'assets/images/invitations/pdp-hover/pdp_paperLinen.jpg';
import Pearlescent from 'assets/images/invitations/pdp-hover/pdp_paperPearlescent.jpg';
import Recycled from 'assets/images/invitations/pdp-hover/pdp_paperRecycled.jpg';
import Petite from 'assets/images/invitations/pdp-hover/pdp_petiteHover.jpg';
import Classic from 'assets/images/invitations/pdp-hover/pdp_classicHover.jpg';
import Postcard from 'assets/images/invitations/pdp-hover/pdp_postcardHover.jpg';
import Eggshell from 'assets/images/invitations/pdp-hover/pdp_paperEggshell.jpg';
import Folded from 'assets/images/invitations/pdp-hover/pdp_foldedHover.jpg';
import FoilGold from 'assets/images/invitations/pdp-hover/pdp_foilGoldHover.jpg';
import FoilRosegold from 'assets/images/invitations/pdp-hover/pdp_foilRosegoldHover.jpg';
import FoilSilver from 'assets/images/invitations/pdp-hover/pdp_foilSilverHover.jpg';

// Icons
import PaperIcon from 'assets/images/invitations/paper.svg';
import FoldedIcon from 'assets/images/invitations/folded.svg';
import MagnetIcon from 'assets/images/invitations/magnet.svg';
import RoundedIcon from 'assets/images/invitations/paper-shapes/rounded.svg';
import SquareIcon from 'assets/images/invitations/paper-shapes/square.svg';
import TicketIcon from 'assets/images/invitations/paper-shapes/ticket.svg';
import ScallopedIcon from 'assets/images/invitations/paper-shapes/scalloped.svg';
import ArchIcon from 'assets/images/invitations/paper-shapes/arch.svg';
import DiamondIcon from 'assets/images/invitations/paper-shapes/diamond.svg';
import SizePetiteIcon from 'assets/images/invitations/size-petite.svg';
import SizeClassicIcon from 'assets/images/invitations/size-classic.svg';
import PostcardIcon from 'assets/images/invitations/postcard.svg';
import FoilNoneIcon from 'assets/images/invitations/foilNone.svg';
import FoilGoldIcon from 'assets/images/invitations/foilGold.svg';
import FoilRosegoldIcon from 'assets/images/invitations/foilRosegold.svg';
import FoilSilverIcon from 'assets/images/invitations/foilSilver.svg';
import FoilIceBlueIcon from 'assets/images/invitations/foilIceBlue.svg';
import FoilBlackIcon from 'assets/images/invitations/foilBlack.svg';
import FoilBlueIcon from 'assets/images/invitations/foilBlue.svg';
import FoilBronzeIcon from 'assets/images/invitations/foilBronze.svg';
import FoilGlitteryGoldIcon from 'assets/images/invitations/foilGlitteryGold.svg';
import FoilGlitterySilverIcon from 'assets/images/invitations/foilGlitterySilver.svg';
import ClassicDisabled from 'assets/images/invitations/classicDisabled.svg';
import PostcardDisabled from 'assets/images/invitations/postcardDisabled.svg';
import FoldedDisabled from 'assets/images/invitations/foldedDisabled.svg';
import RoundedDisabled from 'assets/images/invitations/paper-shapes/roundedDisabled.svg';
import SquareDisabled from 'assets/images/invitations/paper-shapes/squareDisabled.svg';
import ScallopedDisabled from 'assets/images/invitations/paper-shapes/scallopedDisabled.svg';
import ArchDisabled from 'assets/images/invitations/paper-shapes/archDisabled.svg';
import TicketDisabled from 'assets/images/invitations/paper-shapes/ticketDisabled.svg';
import DiamondDisabled from 'assets/images/invitations/paper-shapes/diamondDisabled.svg';
import Menu4x8 from 'assets/images/invitations/menu4x8t@1x.svg';
import Menu5x7 from 'assets/images/invitations/menu5x7@1x.svg';

export const TYPE_OPTION_DETAILS = {
  paper: {
    value: 'paper',
    label: 'Paper',
    iconEnabled: PaperIcon,
    iconDisabled: PaperIcon,
    price: 0,
  },
  magnet: {
    value: 'magnet',
    label: 'Magnet',
    iconEnabled: MagnetIcon,
    iconDisabled: MagnetIcon,
    price: 0,
  },
};

// to be displayed in the hover boxes
export const PRODUCT_OPTION_DETAILS = {
  // Size
  petite: {
    value: 'petite',
    image: Petite,
    label: 'Petite',
    subTitle: '4.25 x 5.5 in.',
    price: 0,
    description: 'Includes FREE guest addressing and envelopes!',
    replaceFree: true,
    iconEnabled: SizePetiteIcon,
    iconDisabled: SizePetiteIcon,
    hasDetails: true,
  },
  // Magnet size
  magnet: {
    value: 'magnet',
    image: Petite,
    label: 'Petite',
    subTitle: '4.25 x 5.5 in.',
    price: 0,
    replaceFree: false,
    iconEnabled: SizePetiteIcon,
    iconDisabled: SizePetiteIcon,
    hasDetails: false,
  },
  '5x7': {
    value: '5x7',
    image: Classic,
    label: 'Classic',
    subTitle: '5 x 7 in.',
    price: 0,
    description: 'Includes FREE guest addressing and envelopes!',
    replaceFree: true,
    iconEnabled: SizeClassicIcon,
    iconDisabled: ClassicDisabled,
    hasDetails: true,
  },
  'menu-4x8': {
    value: '4x8',
    label: '4 x 8 in.',
    subTitle: '4 x 8 in.',
    price: 0,
    replaceFree: true,
    iconEnabled: Menu4x8,
    iconDisabled: Menu4x8,
    hasDetails: true,
  },
  'menu-5x7': {
    value: '5x7',
    label: '5 x 7 in.',
    subTitle: '5 x 7 in.',
    price: 0,
    replaceFree: true,
    iconEnabled: Menu5x7,
    iconDisabled: Menu5x7,
    hasDetails: true,
  },
  postcard: {
    value: 'postcard',
    image: Postcard,
    label: 'Postcard',
    subTitle: '4.25 x 5.5 in.',
    price: 0,
    description: 'Includes FREE guest addressing!',
    replaceFree: true,
    iconEnabled: PostcardIcon,
    iconDisabled: PostcardDisabled,
    hasDetails: true,
  },
  'folded-petite': {
    value: 'folded-petite',
    image: Folded,
    label: 'Folded',
    subTitle: '4.25 x 5.5 in.',
    price: 0,
    description: 'Includes FREE guest addressing and envelopes!',
    replaceFree: true,
    iconEnabled: FoldedIcon,
    iconDisabled: FoldedDisabled,
    iconStyle: { width: '40px' },
    hasDetails: true,
  },
  'folded-petite-portrait': {
    value: 'folded-petite',
    image: Folded,
    label: 'Folded',
    subTitle: '5.5 x 4.25 in.',
    price: 0,
    description: 'Includes FREE guest addressing and envelopes!',
    replaceFree: true,
    iconEnabled: FoldedIcon,
    iconDisabled: FoldedDisabled,
    iconStyle: { width: '40px', transform: 'rotate(-90deg)' },
    hasDetails: true,
  },
  'folded-placecard-standard': {
    value: 'folded-placecard-standard',
    label: 'Folded',
    subTitle: '3.5 x 2 in.',
    price: 0,
    replaceFree: true,
    iconEnabled: FoldedIcon,
    iconDisabled: FoldedDisabled,
    hasDetails: true,
  },
  // Paper Type
  smooth: {
    value: 'smooth',
    image: Smooth,
    label: 'Smooth',
    price: 0,
    description: 'Feels like a lightweight business card with a smooth, creamy surface',
    hasDetails: true,
  },
  eggshell: {
    value: 'eggshell',
    image: Eggshell,
    label: 'Eggshell',
    price: 0,
    description: 'Feels like soft construction paper, but slightly thicker',
    hasDetails: true,
  },
  recycled: {
    value: 'recycled',
    image: Recycled,
    label: 'Recycled',
    price: 0,
    description: 'Feels like a sturdy postcard with a soft, smooth surface',
    hasDetails: true,
    imageLabel: 'Luxe',
  },
  pearlized: {
    value: 'pearlized',
    image: Pearlescent,
    label: 'Pearlescent',
    price: 0,
    description: 'Feels like a glossy postcard with a slight metallic sheen',
    hasDetails: true,
    imageLabel: 'Luxe',
  },
  linen: {
    value: 'linen',
    image: Linen,
    label: 'Linen',
    price: 0,
    description: 'Feels like a flexible postcard that mimics the look of linen fabric',
    hasDetails: true,
    imageLabel: 'Luxe',
  },
  felt: {
    value: 'felt',
    image: Natural,
    label: 'Natural',
    price: 0,
    description: 'Feels like a sturdy postcard with a rugged, leathery surface',
    hasDetails: true,
    imageLabel: 'Premium',
  },
  'double-thick': {
    value: 'double-thick',
    image: DoubleThick,
    label: 'Double Thick',
    price: 0,
    description: "Feels like it's twice as thick as a credit card, with a slight bend",
    hasDetails: true,
    imageLabel: 'Premium',
  },
  'triple-thick': {
    value: 'triple-thick',
    image: TripleThick,
    label: 'Triple Thick',
    price: 0,
    description: "Feels like a hard notebook cover that's nearly unbendable",
    hasDetails: true,
    imageLabel: 'Premium',
  },
  // Finish
  glossy: {
    value: 'glossy',
    label: 'Semi-gloss',
    price: 0,
  },
  matte: {
    value: 'matte',
    label: 'Matte',
    price: 0,
  },
  // Shape
  square: {
    value: 'square',
    image: Square,
    label: 'Square',
    price: 0,
    iconEnabled: SquareIcon,
    iconDisabled: SquareDisabled,
    hasDetails: true,
  },
  rounded: {
    value: 'rounded',
    image: Rounded,
    label: 'Rounded',
    price: 0.35,
    iconEnabled: RoundedIcon,
    iconDisabled: RoundedDisabled,
    hasDetails: true,
  },
  ticket: {
    value: 'ticket',
    label: 'Ticket',
    price: 0.35,
    iconEnabled: TicketIcon,
    iconDisabled: TicketDisabled,
    hasDetails: true,
  },
  scalloped: {
    value: 'scalloped',
    label: 'Scalloped',
    price: 0.35,
    iconEnabled: ScallopedIcon,
    iconDisabled: ScallopedDisabled,
    hasDetails: true,
  },
  arch: {
    value: 'arch',
    label: 'Arch',
    price: 0.35,
    iconEnabled: ArchIcon,
    iconDisabled: ArchDisabled,
    hasDetails: true,
  },
  diamond: {
    value: 'diamond',
    label: 'Diamond',
    price: 0.35,
    iconEnabled: DiamondIcon,
    iconDisabled: DiamondDisabled,
    hasDetails: true,
  },
  // Foil color
  none: {
    value: 'none',
    label: 'No Foil',
    price: 0,
    iconEnabled: FoilNoneIcon,
  },
  gold: {
    value: 'gold',
    image: FoilGold,
    label: 'Gold',
    price: 0,
    description: 'Customize the text on your cards with gorgeous custom foil.',
    iconEnabled: FoilGoldIcon,
    hasDetails: true,
  },
  copper: {
    value: 'copper',
    image: FoilRosegold,
    label: 'Rose Gold',
    price: 0,
    description: 'Customize the text on your cards with gorgeous custom foil.',
    iconEnabled: FoilRosegoldIcon,
    hasDetails: true,
  },
  silver: {
    value: 'silver',
    image: FoilSilver,
    label: 'Silver',
    price: 0,
    description: 'Customize the text on your cards with gorgeous custom foil.',
    iconEnabled: FoilSilverIcon,
    hasDetails: true,
  },
  // Additional Stamped Foil color
  'bright-silver': {
    value: 'bright-silver',
    image: FoilSilver,
    label: 'Silver',
    price: 0,
    description: '',
    iconEnabled: FoilSilverIcon,
    hasDetails: true,
  },
  'rose-gold': {
    value: 'rose-gold',
    image: FoilRosegold,
    label: 'Rose Gold',
    price: 0,
    description: '',
    iconEnabled: FoilRosegoldIcon,
    hasDetails: true,
  },
  blue: {
    value: 'blue',
    image: '',
    label: 'Blue',
    price: 0,
    description: '',
    iconEnabled: FoilBlueIcon,
    hasDetails: true,
  },
  'glittery-gold': {
    value: 'glittery-gold',
    image: '',
    label: 'Gold Shimmer',
    price: 0,
    description: '',
    iconEnabled: FoilGlitteryGoldIcon,
    hasDetails: true,
  },
  'glittery-silver': {
    value: 'glittery-silver',
    image: '',
    label: 'Silver Shimmer',
    price: 0,
    description: '',
    iconEnabled: FoilGlitterySilverIcon,
    hasDetails: true,
  },
  bronze: {
    value: 'bronze',
    image: '',
    label: 'Bronze',
    price: 0,
    description: '',
    iconEnabled: FoilBronzeIcon,
    hasDetails: true,
  },
  black: {
    value: 'black',
    image: '',
    label: 'Black',
    price: 0,
    description: '',
    iconEnabled: FoilBlackIcon,
    hasDetails: true,
  },
  'ice-blue': {
    value: 'ice-blue',
    image: '',
    label: 'Frost Blue',
    price: 0,
    description: '',
    iconEnabled: FoilIceBlueIcon,
    hasDetails: true,
  },
};

export const MAGNET_DISABLED_MSG = {
  size: "These sizes aren't available for Magnets",
  silhouette: 'Magnets only come with rounded corners',
};

export const DISABLED_MSG = {
  type: 'This item is out of stock and cannot be shipped. We expect it to return in early 2022.',
  medium: "This medium isn't available for this card type",
  size: "This size isn't available for this card type",
  finish: "This finish isn't available for this card type",
  'paper-type': {
    pearlized: 'This paper type is out of stock. We expect it to return in early 2022.',
    default: "This paper type isn't available for this card type",
  },
  silhouette: `This shape isn't available for this card type`,
};

export const BASE_PAPER_SHAPES = ['square', 'rounded'];
export const ALL_PAPER_SHAPES = ['square', 'rounded', 'scalloped', 'arch', 'ticket', 'diamond'];

export const ALL_PAPER_TYPES = [
  'smooth',
  'eggshell',
  'recycled',
  'pearlized',
  'linen',
  'felt',
  'double-thick',
  'triple-thick',
];

export const BASE_PAPER_TYPES = ['smooth', 'eggshell'];

export const ALL_FOIL_COLORS = ['none', 'gold', 'copper', 'silver'];

export const ALL_STAMPED_FOIL_COLORS = [
  'gold',
  'glittery-gold',
  'rose-gold',
  'bronze',
  'black',
  'bright-silver',
  'glittery-silver',
  'ice-blue',
  'blue',
];

export const ALL_PAPER_TYPES_BY_KEY = [
  {
    key: 'smooth',
    label: 'smooth',
  },
  {
    key: 'eggshell',
    label: 'eggshell',
  },
  {
    key: 'recycled',
    label: 'recycled',
  },
  {
    key: 'pearlized',
    label: 'pearlescent',
  },
  {
    key: 'linen',
    label: 'linen',
  },
  {
    key: 'felt',
    label: 'natural',
  },
  {
    key: 'double-thick',
    label: 'double thick',
  },
  {
    key: 'triple-thick',
    label: 'triple thick',
  },
];

export const SIZE_OPTIONS_COPY = {
  magnet: '4.25 x 5.5 in.',
  petite: '4.25 x 5.5 in.',
  postcard: '4.25 x 5.5 in. (does not include envelope)',
  '5x7': '5.0 x 7.0 in.',
  'folded-petite': '4.25 x 5.5 in.',
  'folded-placecard-standard': '3.5 x 2 in.',
};
