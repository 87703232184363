const Modernizr = process.browser ? window.Modernizr || {} : {};
const SESSION_STORAGE_SUPPORT = Modernizr.sessionstorage;
const COOKIE_SUPPORT = true;
// set cookie_support to true to be able to test
// const COOKIE_SUPPORT = Modernizr.cookies;

export function setCookie(key, value, durationDays) {
  if (COOKIE_SUPPORT) {
    let cookieString = `${key}=${value}`;
    if (durationDays) {
      const d = new Date();
      d.setTime(d.getTime() + durationDays * 24 * 3600 * 1000);
      cookieString += `; expires=${d.toUTCString()}`;
    }
    document.cookie = `${cookieString}; path=/`;
  }
}

export function getCookie(key) {
  if (COOKIE_SUPPORT) {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }

  return '';
}

export function deleteCookie(key) {
  setCookie(key, '', -1);
}

export function getLocalStorage(key) {
  return window.localStorage.getItem(key);
}

export function setLocalStorage(key, value) {
  window.localStorage.setItem(key, value);
}

export function deleteLocalStorage(key) {
  window.localStorage.removeItem(key);
}

export function setSessionStorage(key, value) {
  if (SESSION_STORAGE_SUPPORT) {
    window.sessionStorage.setItem(key, value);
  } else {
    setCookie(key, value);
  }
}

export function getSessionStorage(key) {
  if (SESSION_STORAGE_SUPPORT) {
    return window.sessionStorage.getItem(key);
  }
  return getCookie(key);
}

export function deleteSessionStorage(key) {
  if (SESSION_STORAGE_SUPPORT) {
    window.sessionStorage.removeItem('name');
  } else {
    deleteCookie(key);
  }
}

export function getQueryParam(paramName, trimValue) {
  const queryString = window.location.search.trim();
  if (queryString.length > 1) {
    const queryStringParts = queryString.substring(1).split('&');
    for (let i = 0; i < queryStringParts.length; i += 1) {
      const partPair = queryStringParts[i].split('=');
      if (partPair[0] === paramName) {
        const paramValue = decodeURIComponent(partPair[1]);
        return trimValue ? paramValue.trim() : paramValue;
      }
    }
  }
  return '';
}

export function setAllStorage(key, value) {
  setCookie(key, value);
  setLocalStorage(key, value);
}

export function getAllStorage(key) {
  const cookieValue = getCookie(key);
  const localStorageValue = getLocalStorage(key);
  let value;

  if (cookieValue) {
    value = cookieValue;
  } else if (localStorageValue) {
    value = localStorageValue;
  } else {
    value = null;
  }

  return value;
}
