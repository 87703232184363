import { createSelector } from 'reselect';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _every from 'lodash/every';
import _sortBy from 'lodash/sortBy';
import _keys from 'lodash/keys';
import _some from 'lodash/some';

import { getCardSettings } from 'cards/util/cardSettingsUtils';
// Other selectors
import { getSelectedGuestIds } from './Customization';
import { getPricing } from '../reducers/pricing/selectors';
import { getCards } from './cardCatalogSelector';

// Constants
import { BACK_PRINTING_PRICE, DEFAULT_QUANTITY } from '../constants/Cards';
import {
  ORDERED_CUSTOMIZATION_TYPES,
  ENABLED_PROOF_ORDER_CARD_TYPES,
  CARD_TYPES,
} from '../constants/CardTypes';
import { SUMMARY_COPY } from '../constants/Copy';
import { STEPS, ADDRESSING_STEPS } from '../constants/Steps';

// Utils
import { pageName, customFoilColorLabel } from '../util';
import { isCustomizationActive } from '../util/isCustomizationActive';
import { getStepFromPathname, getTypeFromCustomization } from '../util/customization';
import {
  getOptionPricing,
  getMaxOptionPrice,
} from '../components/CardDetail/AllOptionsSection/utils';
import { getQuantityOptionsFromProjectMeta } from '../util/minQuantityHelper';
import { getQuantityRangeWithProof, getQuantityRange } from '../util/cardQuantityHelper';

export const getProjectState = state => state.cards.project;
export const getProjectError = state => state.cards.project.error;
export const getActiveField = state => state.cards.project.ui.activeField;
export const getBreadcrumbs = state => state.cards.project.ui.breadcrumbs;
export const getCustomizations = state => state.cards.project.entities.customizations;
export const getCustomizationTypeUUIDs = state => state.cards.project.customizationTypeToUUID;
export const getElementEntities = state => state.cards.project.entities.elements;
export const getFontsByCustomizationUUID = state => state.cards.project.fontsByCustomizationUUID;
export const getIsAddingToCart = state => state.cards.project.isAddingToCart;
export const getPageEntities = state => state.cards.project.entities.pages;
export const getProjectBusy = state => state.cards.project.isFetching;
export const getProjectType = state => state.cards.project.type;
export const getProjectUUID = state => state.cards.project.uuid;
export const getProjectMainColor = state => state.cards.project.optionValues.color;
export const getProjectShape = state => state.cards.project.optionValues.silhouette;
export const getProjectReversePrinting = state =>
  state.cards.project.optionValues['reverse-printing'];
export const getSize = state => state.cards.project.size;
export const getSplitOrderId = state => state.cards.project.splitOrderId;
export const getSteps = state => state.cards.project.ui.steps;
export const getOrderedSteps = state => state.cards.project.ui.orderedSteps;
export const getProjectMedium = state => state.cards.project.medium;
export const getAllProjectMeta = state => state.cards.project.metaByProjectUUID;
export const getProjectFoilStatus = state => state.cards.project.hasFoil;
export const getProjectProofModalStatus = state => state.cards.project.hasSeenProofModal;
export const getProjectIsTestProject = state => state.cards.project.isTestProject;
export const getSeededCardSuite = state => state.cards.project.seededCardSuiteUUID;
export const getProjectInitializedCustomFoilStatus = state =>
  state.cards.project.initializedCustomFoil;
export const getFoilHistory = state => state.cards.project.foilHistory;
export const getDeleteFoilHistory = state => state.cards.project.deletedFoilHistory;
export const getRSVPFoilHistory = state => state.cards.project.rsvpFoilHistory;
export const getRSVPDeleteFoilHistory = state => state.cards.project.rsvpDeletedFoilHistory;
export const getInitializedFoilColor = state => state.cards.project.initializedFoilColor;
export const getEarliestArrivalDate = state => state.cards.project.earliestArrivalDate;
export const getHolidaysShippingDetails = state => state.cards.project.holidaysShippingDetails;

export const getStepGroups = state => state.cards.project.ui.stepGroups;
export const getProjectAccountId = state => state.cards.project.accountId;
export const getProjectFamily = state => state.cards.project.family;
export const getProjectPdfRenderDetails = state => state.cards.project.pdfRenderDetails;
export const getProjectPdfForceReviewFlag = state => state.cards.project.forceReviewPdf;

// Store meta by projectUUID to avoid issues when switching between 2 projects
export const getProjectMeta = createSelector(
  getProjectUUID,
  getAllProjectMeta,
  (projectUUID, allMeta) => allMeta?.[projectUUID]
);

// This relies on ownProps.location to be available, so any component that calls it must be
// hooked up with react-router (i.e. withRouter HOC). Attempts to access the path through other
// router props failed as they are not updated at the same time as the actual URL (causing bugs).
// Directly using window.location.pathname also causes bugs because it changes BEFORE route components update/unmount.
const getPathname = (_, props) => (props && props.location && props.location.pathname) || '';

export const getCurrentStepObject = createSelector(
  getPathname,
  getOrderedSteps,
  (pathname, orderedSteps) => getStepFromPathname(pathname, orderedSteps)
);

export const getPreviousStepObject = createSelector(
  getBreadcrumbs,
  getCurrentStepObject,
  (breadcrumbs, currentStep) => {
    let previousStep;

    if (ADDRESSING_STEPS.includes(currentStep.id)) {
      // For addressing steps, return the step where the addressing will apply. It shares the same card type & page number.
      previousStep = breadcrumbs.find(step => {
        return step.cardType === currentStep.cardType && step.pageNumber === currentStep.pageNumber;
      });
    } else {
      // For non-addressing steps, simply return the step that preceeds it in the breadcrumbs.
      const currentIndex = breadcrumbs.findIndex(breadcrumb => breadcrumb.id === currentStep.id);

      if (currentIndex > 0) {
        previousStep = breadcrumbs[currentIndex - 1];
      }
    }

    return previousStep || null;
  }
);

export const getPreviousStepUrl = createSelector(
  getPreviousStepObject,
  previousStep => (previousStep && previousStep.url) || ''
);

export const getNextStepObject = createSelector(
  getOrderedSteps,
  getBreadcrumbs,
  getCurrentStepObject,
  getSelectedGuestIds,
  (orderedSteps, breadcrumbs, currentStep, selectedGuestIds) => {
    let nextStep;

    if (currentStep.id === STEPS.recipients && selectedGuestIds.length) {
      // If guests are selected on the 'recipients' step, next will be the 'addressing' step that shares the same card type & page number.
      nextStep = orderedSteps.find(({ id, cardType, pageNumber }) => {
        return (
          id === STEPS.addressing &&
          cardType === currentStep.cardType &&
          pageNumber === currentStep.pageNumber
        );
      });
    } else if (ADDRESSING_STEPS.includes(currentStep.id)) {
      // For addressing steps, return the step where the addressing will apply. It shares the same card type & page number. Same as previous step.
      nextStep = breadcrumbs.find(step => {
        return step.cardType === currentStep.cardType && step.pageNumber === currentStep.pageNumber;
      });
    } else {
      // For all other steps, return the next step in breadcrumbs
      const currentIndex = breadcrumbs.findIndex(breadcrumb => breadcrumb.key === currentStep.key);
      nextStep = currentIndex > -1 ? breadcrumbs[currentIndex + 1] : null;
    }

    return nextStep || null;
  }
);

export const getNextStepUrl = createSelector(
  getNextStepObject,
  nextStep => (nextStep && nextStep.url) || ''
);

export const getCardType = createSelector(
  getCurrentStepObject,
  currentStep => (currentStep && currentStep.cardType) || 'SAVE_THE_DATE'
);

export const getPageNumber = createSelector(
  getCurrentStepObject,
  currentStep => (currentStep && currentStep.pageNumber) || 0
);

export const getBreadcrumbGroups = createSelector(getBreadcrumbs, breadcrumbs =>
  breadcrumbs.reduce((groups, step) => {
    const existingGroup = groups.find(g => g.id === step.breadcrumbGroup);

    if (existingGroup) {
      existingGroup.items.push(step);
    } else {
      groups.push({
        id: step.breadcrumbGroup,
        items: [step],
      });
    }

    return groups;
  }, [])
);

export const getCustomizationUUID = createSelector(
  getCustomizationTypeUUIDs,
  getCardType,
  (customizationTypeToUUID, cardType) => customizationTypeToUUID[cardType]
);

export const getLeadCustomization = createSelector(
  getProjectType,
  getCustomizationTypeUUIDs,
  getCustomizations,
  (projectType, customizationTypeToUUIDs, customizations) => {
    const uuid = customizationTypeToUUIDs[projectType];
    const leadCustomization = customizations[uuid];
    return leadCustomization;
  }
);

export const getDigitalDetails = createSelector(
  getLeadCustomization,
  leadCustomization =>
    leadCustomization &&
    leadCustomization.digital_details &&
    leadCustomization.digital_details.details
);

export const getCustomizationByUUID = createSelector(
  getCustomizations,
  (_, customizationUUID) => customizationUUID,
  (customizations, customizationUUID) => customizations[customizationUUID]
);

export const getLeadVariationOptionValues = createSelector(getLeadCustomization, customization => {
  if (customization && customization.variation && customization.variation.option_values) {
    return customization.variation.option_values;
  }
  return null;
});

export const getLeadQuantity = createSelector(
  getLeadCustomization,
  customization => (customization && customization.quantity) || 0
);

export const getCustomization = createSelector(
  getCustomizations,
  getCustomizationUUID,
  (customizations, customizationUUID) => customizations[customizationUUID]
);

export const getIsSampleOrder = createSelector(
  getCustomization,
  customization => !!customization && customization.quantity === 1
);

export const getPage = createSelector(
  getCustomization,
  getPageEntities,
  getPageNumber,
  (customization, pageEntities, pageNumber) => {
    if (typeof pageNumber !== 'number' || !customization) return null;

    const pages = customization.pages.map(pageUUID => pageEntities[pageUUID]);
    const page = _find(pages, { page_number: pageNumber + 1 });
    return page;
  }
);

export const getPageBackgroundColor = createSelector(getPage, page => page?.background_color);
export const getPageOrientation = createSelector(getPage, page => page?.orientation);
export const getPageUUID = createSelector(getPage, page => page?.uuid);
export const getPageLayoutUUID = createSelector(
  getPage,
  page => page?.card_template_layout_content_uuid
);

export const getPageElementUUIDs = createSelector(getPage, page => (page ? page.elements : []));

export const getElements = createSelector(
  getPageElementUUIDs,
  getElementEntities,
  (elementUUIDs, elementEntities) => elementUUIDs.map(elementUUID => elementEntities[elementUUID])
);

export const getElementsByUUID = createSelector(
  getPageElementUUIDs,
  getElementEntities,
  (elementUUIDs, elementEntities) => {
    const elementsByUUID = {};
    elementUUIDs.forEach(elementUUID => {
      elementsByUUID[elementUUID] = elementEntities[elementUUID];
    });
    return elementsByUUID;
  }
);

export const getTextElements = createSelector(getElements, elements =>
  elements.filter(element => element.content_type === 'TEXT')
);

export const getFoiledTextElements = createSelector(getTextElements, textElements =>
  textElements.filter(
    element => element.printing_technique === 'CUSTOM_FOIL' && element.customizable
  )
);

export const getImageElements = createSelector(
  getElements,
  elements =>
    elements
      .filter(element => element.content_type === 'IMAGE')
      .sort((a, b) => (a.y - b.y === 0 ? a.x - b.x : a.y - b.y)) // Sort elements from top to bottom & left to right.
);

export const getBackgroundImageElement = createSelector(getImageElements, imageElements =>
  imageElements.find(element => element.tag === 'BACKGROUND')
);

export const getOverlayImageElements = createSelector(getImageElements, imageElements =>
  imageElements.filter(element => element.overlay)
);

export const getCustomizableImageElements = createSelector(getImageElements, imageElements =>
  imageElements.filter(element => element.customizable)
);

export const getCustomizationVariation = createSelector(
  getCustomization,
  customization => customization && customization.variation
);

export const getCustomizationNrfColor = createSelector(
  getCustomizationVariation,
  variation => variation.nrf_color_code_label
);

export const getCustomizationSize = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.size
);

export const getCustomizationSilhouette = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.silhouette
);

export const getCustomizationFoilColor = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.['foil-color'] || 'none'
);

export const getReversePrintingDisabled = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.['reverse-printing'] === 'false' || false
);

export const getCustomizationsWithValidOptionValues = createSelector(
  getCustomizations,
  customizationsByUUID => {
    const customizations = _keys(customizationsByUUID).map(uuid => customizationsByUUID[uuid]);
    return customizations.filter(customization => {
      if (customization && customization.variation) {
        const { medium, variation } = customization;
        const optionValueKeys = medium === 'PAPER' ? ['silhouette', 'paper-type'] : ['silhouette'];
        return _every(optionValueKeys, key => _has(variation.option_values, key));
      }
      return false;
    });
  }
);

const getRSVPCustomization = createSelector(
  getCustomizationTypeUUIDs,
  getCustomizations,
  (customizationTypeToUUID, customizations) => {
    const uuid = customizationTypeToUUID[CARD_TYPES.rsvp];
    return (uuid && customizations[uuid]) || null;
  }
);
export const getRSVPCustomizationDisabled = createSelector(
  getRSVPCustomization,
  rsvpCustomization => (rsvpCustomization && rsvpCustomization.inactive) || false
);

const getRecipientCustomization = createSelector(
  getSteps,
  getCustomizationTypeUUIDs,
  getCustomizations,
  (steps, customizationTypeToUUID, customizations) => {
    if (steps && steps.recipients && steps.recipients.cardType) {
      const uuid = customizationTypeToUUID[steps.recipients.cardType];
      return customizations[uuid];
    }
    return null;
  }
);
export const getIsRecipientAddressingEnabled = createSelector(
  getRecipientCustomization,
  recipientCustomization =>
    (recipientCustomization && recipientCustomization.recipient_addressing) || false
);

export const getIsCustomizationActive = createSelector(getCustomization, customization =>
  customization ? isCustomizationActive(customization) : null
);

export const getProjectOrderView = createSelector(getCustomizations, customizations =>
  _keys(customizations).reduce((result, uuid) => {
    const customization = customizations[uuid];

    if (customization && customization.variation) {
      const { variation } = customization;

      const cardType = getTypeFromCustomization(customization);
      const urlSlug = pageName(cardType);

      // make sure not to include any deleted customizations in order view
      // getProjectOrderView is used in subtotal selector so it's important to filter out deleted
      // customizations to avoid messing up subtotal
      if (variation && isCustomizationActive(customization)) {
        const orderView = {
          customizationUUID: customization.uuid,
          name: customization.name,
          type: customization.type,
          quantity: customization.quantity,
          recipientAddressing: customization.recipient_addressing,
          urlSlug,
          priceCents: variation.price_cents,
          imageUrls: variation.image_urls,
          color: variation.option_values.color,
          size: variation.option_values.size,
          liningColor: variation.option_values['envelope-lining'] || 'none',
        };
        result.push(orderView);
      }
    }
    return _sortBy(result, o => ORDERED_CUSTOMIZATION_TYPES.indexOf(o.type));
  }, [])
);

const backPrice = BACK_PRINTING_PRICE / 100;
const stepsWithCustomFoil = [STEPS.detailFront, STEPS.rsvp];
const getDefinedOptionValue = (values, key) =>
  values[key] && values[key] !== 'none' ? values[key] : null;
const findValidCustomization = (customizations, id) => {
  if (!id) return null;

  const customization = customizations[id];
  const isValid =
    customization &&
    isCustomizationActive(customization) &&
    !!customization.variation &&
    !!customization.variation.option_values;

  return isValid ? customization : null;
};

export const getCardDataByCustomizationUUID = createSelector(
  getCards,
  getCustomization,
  (cards, customization) => {
    return _find(cards, { customizationUUID: customization.uuid }) || {};
  }
);

export const getSubtotalSummary = createSelector(
  getCustomizations,
  getCustomizationTypeUUIDs,
  getStepGroups,
  getCurrentStepObject,
  getPricing,
  getProjectType,
  getSize,
  getProjectMedium,
  getProjectMeta,
  getLeadCustomization,
  getProjectState,
  getCards,
  (
    customizations,
    customizationTypeToUUIDs,
    stepGroups,
    currentStep,
    pricing,
    projectType,
    projectSize,
    projectMedium,
    projectMeta,
    leadCustomization,
    projectState,
    cards
  ) => {
    const thankYouMeta = projectMeta && projectMeta.thank_you;
    const insidePrice = thankYouMeta ? thankYouMeta.printed_extra_price_cents / 100 : 0;
    const insideQuantity = thankYouMeta ? thankYouMeta.printed : 0;
    const foilPricing = getOptionPricing(
      pricing,
      projectType,
      projectMedium.toLowerCase(),
      projectSize,
      'foil-color'
    );
    const isStampFoilCard = projectState.hasFoil;
    const cardCustomizations = projectState.customizationTypeToUUID.INVITATION_ENVELOPE;
    const cardVariations = _find(cards, { customizationUUID: cardCustomizations });
    const coloredEnvelopeWithoutLiner =
      cardVariations &&
      cardVariations.variations.filter(
        variation =>
          variation.option_values.color !== 'ffffff' &&
          variation.option_values['envelope-lining'] === 'none'
      );
    const whiteEnvelopeWithLiner =
      cardVariations &&
      cardVariations.variations.filter(
        variation =>
          variation.option_values.color === 'ffffff' &&
          variation.option_values['envelope-lining'] !== 'none'
      );
    const coloredEnvelopePrice = getMaxOptionPrice(coloredEnvelopeWithoutLiner);
    const coloredLinerPrice = getMaxOptionPrice(whiteEnvelopeWithLiner);

    const allGroups = stepGroups.map(stepGroup => {
      const { cardType, steps } = stepGroup;
      const items = [];
      const isLeadCustomization = cardType === leadCustomization.type;
      steps.forEach(step => {
        const stepCopy = SUMMARY_COPY[step.id];
        const customization = findValidCustomization(
          customizations,
          customizationTypeToUUIDs[step.cardType]
        );

        if (customization) {
          const { variation, custom_notes_enabled: customNotesEnabled } = customization;
          const options = variation.option_values;
          const isReversePrintingEnabled = options['reverse-printing'] === 'true';
          const isPostcard = options.size === 'postcard';
          const isChangeTheDate = cardType === 'CHANGE_THE_DATE';
          const customFoilColor = stepsWithCustomFoil.includes(step.id)
            ? getDefinedOptionValue(options, 'foil-color')
            : null;

          // Postcards and CTDs include free reverse printing. Other cards charge for it when enabled.
          const shouldChargeReversePrinting =
            isReversePrintingEnabled && !isPostcard && !isChangeTheDate;

          let price = variation.price_cents ? variation.price_cents / 100 : 0;
          let { description, label } = stepCopy;

          if (step.id === STEPS.detailFront && shouldChargeReversePrinting) {
            // When charging for reverse printing, we subtract its cost from the front's price.
            price -= backPrice;
          } else if (step.id === STEPS.detailBack) {
            price = shouldChargeReversePrinting ? backPrice : 0;
            description =
              (!isReversePrintingEnabled && 'No print on the back') ||
              (isPostcard && 'Print on the back (required)') ||
              'Print on the back';
          } else if (step.id === STEPS.detailInside) {
            // * Blanks require no aditional cost
            // * Custom notes cost is displayed on the following step
            price = 0;
            description = customNotesEnabled
              ? 'Mix blank + printed messages inside'
              : 'All blank inside';
          } else if (step.id === STEPS.detailWrite) {
            price = insidePrice;
            description = insideQuantity
              ? `<strong>${insideQuantity}</strong> printed message(s)`
              : 'Print on the inside';
          } else if (step.id === STEPS.recipients) {
            price = 0;

            // Holiday cards shouldn't mention "guests" like other cards
            if (projectType === 'HOLIDAY') {
              label = 'Recipients';
              description = 'Add addressing';
            }
          } else if (step.id === STEPS.detailAssign && projectType === CARD_TYPES.place) {
            price = 0;
          }

          items.push({
            customization,
            price,
            description,
            label,
            customNotesMeta: thankYouMeta || {},
            hasFoil: isStampFoilCard,
            hasLiner: !!getDefinedOptionValue(options, 'envelope-lining'),
            linerPrice: coloredLinerPrice,
            envelopePrice: coloredEnvelopePrice,
            hasLetterpress: options['printing-type'] === 'letterpress',
            customFoilLabel: (customFoilColor && customFoilColorLabel(customFoilColor)) || null,
            customFoilPrice:
              customFoilColor && foilPricing ? foilPricing[customFoilColor] / 100 : 0,
            step,
            active: currentStep?.id === step.id,
          });
        }
      });

      const groupLeadItem = items[0] || {};
      const hasInsidePrinting = items.some(item => item.step.id === STEPS.detailInside);

      // All group-specific quantity logic
      const groupQuantity = groupLeadItem.customization ? groupLeadItem.customization.quantity : 0;

      // All group-specific pricing logic
      const pricePerUnit = items.reduce((total, b) => total + b.price, 0);
      const groupSubtotal = hasInsidePrinting
        ? (pricePerUnit - insidePrice) * groupQuantity + insidePrice * insideQuantity
        : pricePerUnit * groupQuantity;

      return {
        cardType,
        hasInsidePrinting,
        items,
        isActive: items.some(item => item.active),
        label: getCardSettings(cardType).label,
        subtotal: groupSubtotal,
        defaultSubtotal: pricePerUnit * DEFAULT_QUANTITY,
        quantity: groupQuantity,
        defaultQuantity: DEFAULT_QUANTITY,
        isLeadCustomization,
      };
    }, []);

    const groups = allGroups.filter(group => group.items.length > 0); // Remove all groups with no items
    const subtotal = groups.reduce((total, group) => total + group.subtotal, 0); // Add together all group subtotals
    const defaultSubtotal = groups.reduce((total, group) => total + group.defaultSubtotal, 0);

    return { groups, subtotal, defaultSubtotal };
  }
);

export const getProjectQuantityData = createSelector(
  getSubtotalSummary,
  getProjectMeta,
  (summary, projectMeta) => {
    return summary.groups.reduce((acc, summaryGroup) => {
      const { cardType, items, quantity } = summaryGroup;

      const groupLeadItem = items[0] || {};
      const hasLetterpress = !!groupLeadItem.hasLetterpress;
      const hasFoil = !!groupLeadItem.hasFoil;
      const hasInsidePrinting = items.some(item => item.step.id === STEPS.detailInside);

      // For custom notes, quantity can be bumped up to next increment depending on qty vs. notes
      const updatedQuantity =
        groupLeadItem.hasInsidePrinting && !!projectMeta && projectMeta.minimum_quantity > quantity
          ? projectMeta.minimum_quantity
          : quantity;
      const quantityOptions =
        hasLetterpress || hasFoil
          ? getQuantityOptionsFromProjectMeta(cardType, projectMeta)
          : getQuantityRangeWithProof(cardType);
      // Always disable qty less than minimum returned
      const disabledOptions =
        hasInsidePrinting && !!projectMeta && !hasLetterpress
          ? getQuantityRange(cardType).filter(q => q < projectMeta.minimum_quantity)
          : [];

      return {
        ...acc,
        [cardType]: {
          cardType,
          quantity: updatedQuantity,
          defaultQuantity: DEFAULT_QUANTITY,
          quantityOptions,
          disabledOptions,
        },
      };
    }, {});
  }
);

export const getCurrentGroupQuantityData = createSelector(
  getSubtotalSummary,
  getProjectQuantityData,
  (summary, projectQuantityData) => {
    const currentGroup = summary.groups.find(group => group.isActive);
    return (currentGroup && projectQuantityData[currentGroup.cardType]) || null;
  }
);

export const getSubtotalSummaryItems = createSelector(getSubtotalSummary, summary => {
  return summary.groups.reduce((acc, summaryGroup) => [...acc, ...summaryGroup.items], []);
});

export const getCardStampedFoilsForCustomization = createSelector(
  getCardDataByCustomizationUUID,
  cardData =>
    (cardData && cardData.optionGroupsByKey && cardData.optionGroupsByKey['foil-color']) || {}
);

export const getCardColorsForCustomization = createSelector(
  getCardDataByCustomizationUUID,
  cardData => (cardData && cardData.optionGroupsByKey && cardData.optionGroupsByKey.color) || {}
);

export const getCardLinerColorsForCustomization = createSelector(
  getCardDataByCustomizationUUID,
  cardData =>
    (cardData && cardData.optionGroupsByKey && cardData.optionGroupsByKey['envelope-lining']) || {}
);

export const getEnvelopePriceForCustomization = createSelector(
  getCardDataByCustomizationUUID,
  cardData => {
    const colouredEnvelopeVariations =
      cardData.variations &&
      cardData.variations.filter(
        variation => variation.option_values['envelope-lining'] === 'none'
      );
    return getMaxOptionPrice(colouredEnvelopeVariations);
  }
);

export const getEnvelopeLinerPriceForCustomization = createSelector(
  getCardDataByCustomizationUUID,
  cardData => {
    const noColouredEnvelopeVariations =
      cardData.variations &&
      cardData.variations.length &&
      cardData.variations.filter(variation => variation.option_values.color === 'ffffff');
    return getMaxOptionPrice(noColouredEnvelopeVariations);
  }
);

/**
 * Hacky way of checking if user has a proof order on cart
 * check for same name of product and if it's single item
 * @param {*} state
 * @param {*} props
 */
const proofCheckoutState = (state, props) => {
  const { cart } = state;
  const { projectType, name, medium, hasFoil, hasCustomNotes } = props;
  const checkoutState = {
    cardTypeEnabledForProof:
      medium === 'MAGNET' || hasFoil || hasCustomNotes
        ? false
        : ENABLED_PROOF_ORDER_CARD_TYPES.includes(projectType),
    hasMultipleOfSameCard: false,
    hasOneOfSameCard: false,
  };
  if (cart) {
    const { items, busy, size } = cart;
    if (items && size && size > 0 && name && !busy) {
      Object.assign(checkoutState, {
        hasOneOfSameCard: _some(cart.items, { name, quantity: 1 }),
        hasMultipleOfSameCard: _some(cart.items, { name }),
      });
    }
  }
  return checkoutState;
};

export const makeGetproofCheckoutState = () => {
  const getProofCheckoutState = createSelector(
    [proofCheckoutState],
    checkoutState => checkoutState
  );
  return getProofCheckoutState;
};

export const getImageSizeRequirements = createSelector(
  getProjectState,
  projectState => projectState.imageSizeRequirements
);
