import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@zola/zola-ui/src/components/Link';

import { Free } from 'paper/components/Free';
import { CARD_TYPES } from 'cards/constants/CardTypes';
import star from '../../../../assets/images/invitations/pdp-hover/star.jpg';

import './DetailBox.less';

const DetailBox = ({ details, show, isMagnet, titleLink, isLinkHidden, titleLinkAction }) => {
  if (!(show && details && details.hasDetails)) {
    return null;
  }
  let descriptionSegments = [];

  if (details.description && details.replaceFree) {
    descriptionSegments = details.description.split('FREE');
  }

  return (
    <div className="detail-box">
      {details.image && (
        <div className="detail-box__img-wrapper">
          {details.imageLabel && (
            <div className="detail-box__img-label">
              <img width="14" height="14" src={star} alt="star" className="detail-box__star-img" />
              <div className="detail-box__img-text">{details.imageLabel}</div>
            </div>
          )}
          <img src={details.image} alt={details.label} className="detail-box__img" />
          {details.imageTag}
        </div>
      )}
      <div className="detail-box__title">
        {details.label}
        {details.subTitle ? (
          <span className="detail-box__sub-title">({details.subTitle})</span>
        ) : null}
      </div>
      {!isMagnet && !CARD_TYPES.menu && <div className="detail-box__price">{details.price}</div>}
      {details.description && details.replaceFree && (
        <div className="detail-box__description">
          <div>
            <span>{descriptionSegments[0]}</span>
            <Free />
            <span>{descriptionSegments[1]}</span>
          </div>
        </div>
      )}
      {details.description && !details.replaceFree && (
        <div className="detail-box__description">{details.description}</div>
      )}
      {titleLink && !isLinkHidden && (
        <span className="visible-sm">
          <Link arrow role="button" className="detail-box__link" onClick={titleLinkAction}>
            {titleLink}
          </Link>
        </span>
      )}
    </div>
  );
};

DetailBox.propTypes = {
  details: PropTypes.shape({
    image: PropTypes.string,
    label: PropTypes.string,
    subTitle: PropTypes.string,
    price: PropTypes.string,
    description: PropTypes.string,
    replaceFree: PropTypes.bool,
    imageLabel: PropTypes.string,
    imageTag: PropTypes.node,
  }),
  show: PropTypes.bool,
  isMagnet: PropTypes.bool,
  titleLink: PropTypes.string,
  isLinkHidden: PropTypes.bool,
  titleLinkAction: PropTypes.func,
};

DetailBox.defaultProps = {
  show: false,
};

export default DetailBox;
