import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import moment from 'moment';

// actions
import { setErrors, setWeddingDetails, setStepKey } from 'cards/reducers/samplesFlow/actions';

// selectors
import {
  getAllLPOrFoil,
  getDesignCustomizationsSet,
  getErrors,
  getHasLPOrFoil,
  getTemplatesSet,
  getWeddingDetails,
} from 'cards/selectors/samplesFlow';
import {
  getOwnerFirstName,
  getOwnerLastName,
  getPartnerFirstName,
  getPartnerLastName,
  getUserWeddingDate,
} from 'selectors/user/userSelectors';

// components
import SectionMessage from '@zola/zola-ui/src/components/SectionMessage';
import { Link } from '@zola/zola-ui/src/components/Link';

// utils
import { STEPS } from 'cards/components/common/Samples/SamplesFlow/constants';
import { WeddingDetails } from 'cards/reducers/samplesFlow/types';
import Form from '../../Form';
import { validateCoupleNames } from '../../utils';

import styles from './styles.module.less';

interface NamesStepProps {
  cardSuiteUUIDList: string[];
  exitFlowAndSaveDraft: () => void;
  isGuest: boolean;
  login: () => void;
}

const NamesStep = ({
  cardSuiteUUIDList,
  exitFlowAndSaveDraft,
  isGuest,
  login,
}: NamesStepProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const errors = useSelector(getErrors);

  const allLPOrFoil = useSelector(getAllLPOrFoil);
  const hasLPOrFoil = useSelector(getHasLPOrFoil);

  const weddingDetails = useSelector(getWeddingDetails);
  const firstName = useSelector(getOwnerFirstName);
  const lastName = useSelector(getOwnerLastName);
  const partnerFirstName = useSelector(getPartnerFirstName);
  const partnerLastName = useSelector(getPartnerLastName);
  const weddingDate = useSelector(getUserWeddingDate);

  const designCustomizationsSet = useSelector(getDesignCustomizationsSet);
  const templatesSet = useSelector(getTemplatesSet);

  const [localWeddingDetails, setLocalWEddingDetails] = useState<WeddingDetails>({
    ...weddingDetails,
  });
  const { fullName, partnerFullName, date } = localWeddingDetails;

  const setField = useCallback(
    (key, value) => setLocalWEddingDetails({ ...localWeddingDetails, [key]: value }),
    [localWeddingDetails, setLocalWEddingDetails]
  );

  const userInvalid = _get(errors, ['weddingDetails', 'user', 'isInvalid'])
    ? _get(errors, ['weddingDetails', 'user', 'errorMessage'])
    : null;
  const partnerInvalid = _get(errors, ['weddingDetails', 'partner', 'isInvalid'])
    ? _get(errors, ['weddingDetails', 'partner', 'errorMessage'])
    : null;
  const fullNameInvalid = _get(errors, ['weddingDetails', 'field', 'fullName']) || null;
  const partnerFullNameInvalid =
    _get(errors, ['weddingDetails', 'field', 'partnerFullName']) || null;
  const dateInvalid = _get(errors, ['weddingDetails', 'field', 'date']) || null;

  const setFullName = useCallback(e => setField('fullName', e.target.value), [setField]);
  const setPartnerName = useCallback(e => setField('partnerFullName', e.target.value), [setField]);
  const setDate = useCallback(value => setField('date', value), [setField]);

  if (fullName === null && firstName && lastName) {
    setField('fullName', `${firstName} ${lastName}`);
  }

  if (partnerFullName === null && partnerFirstName && partnerLastName) {
    setField('partnerFullName', `${partnerFirstName} ${partnerLastName}`);
  }

  useEffect(() => {
    if (date === null && weddingDate) {
      setField('date', weddingDate);
    }
  }, [weddingDate]);

  const nextBtnText = allLPOrFoil ? 'Next: Shipping Address' : 'Next: Customize Design';
  const nextStep = () => {
    const nameErrors = validateCoupleNames(fullName, partnerFullName);
    const dateProperlyFormatted =
      date === moment(date).format('YYYY-MM-DD') || date === null || date === '';

    if (dateProperlyFormatted && !nameErrors) {
      dispatch(
        setWeddingDetails({
          date: date === '' ? null : date,
          fullName,
          partnerFullName,
        })
      );

      dispatch(setStepKey(allLPOrFoil ? STEPS.SHIPPING : STEPS.PHOTO));
    }

    return dispatch(
      setErrors({
        weddingDetails: {
          field: {
            date: !dateProperlyFormatted && 'Oops! Please enter a valid date',
            fullName: nameErrors && nameErrors.fullName,
            partnerFullName: nameErrors && nameErrors.partnerFullName,
          },
        },
      })
    );
  };
  const steps = allLPOrFoil ? 2 : 4;

  if (Object.keys(designCustomizationsSet).length === 0 || Object.keys(templatesSet).length === 0) {
    return null;
  }

  const disableButton = !fullName || !partnerFullName;

  return (
    <>
      <Form.Header
        currentStep={1}
        description={
          <>
            If you have a date, great! If not, don&apos;t sweat it.
            {isGuest && (
              <>
                <br />
                Have an account? <Link onClick={login}>Log In</Link>
              </>
            )}
          </>
        }
        steps={steps}
        title="Add Your Details"
      />
      {hasLPOrFoil && (
        <SectionMessage
          className={styles.letterpressMessage}
          arrow={false}
          description="These samples feature pre-set colors and text—but you'll still get a feel for the card! Full orders can be customized."
          title="Heads up on letterpress and foil samples"
          type="Info"
        />
      )}
      <Form.TinyCardsPreview
        cardSuiteUUIDList={cardSuiteUUIDList}
        designCustomizationsSet={designCustomizationsSet}
        templatesSet={templatesSet}
      />
      <Form.Field
        errorMessage={userInvalid || fullNameInvalid}
        isRequired
        label="Your full name"
        name="fullName"
        onChange={setFullName}
        type="text"
        value={fullName || ''}
        width={335}
      />
      <Form.Field
        errorMessage={partnerInvalid || partnerFullNameInvalid}
        isRequired
        label="Your partner's full name"
        name="partnerFullName"
        onChange={setPartnerName}
        type="text"
        value={partnerFullName || ''}
        width={335}
      />
      <Form.DateField
        className={styles.sampleFlowDateField}
        errorMessage={dateInvalid}
        label={
          <>
            Wedding date <span className={styles.optional}>(optional)</span>
          </>
        }
        onChange={setDate}
        value={date}
      />
      <Form.Required />
      <Form.ButtonWrapper>
        <Form.BackButton handleClick={exitFlowAndSaveDraft} />
        <Form.Button btnText={nextBtnText} handleClick={nextStep} disabled={disableButton} />
      </Form.ButtonWrapper>
    </>
  );
};

export default NamesStep;
