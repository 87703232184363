import { getCountryFromCountryCode } from './CountryCodes';

export function getRecipientAddress(guestGroup) {
  const envelopeRecipient = guestGroup.envelope_recipient_override || guestGroup.envelope_recipient;
  const recipientAddress = {};

  recipientAddress.line1 = {
    body: envelopeRecipient.split('\n').join(' '), // make sure to strip out newlines
    image_id: null,
    key: 'mr-david-gonzalez-and-guest',
    // key: 'recipient-address-1',
  };
  recipientAddress.line2 = {
    body: `${guestGroup.address_1 ? `${guestGroup.address_1} ` : ''}${
      guestGroup.address_2 ? ` ${guestGroup.address_2}` : ''
    }`,
    image_id: null,
    key: '231-west-48th-street-6a',
    // key: 'recipient-address-2'
  };
  recipientAddress.line3 = {
    body: `${guestGroup.city ? `${guestGroup.city}, ` : ''}${
      guestGroup.state_province ? `${guestGroup.state_province} ` : ''
    } ${guestGroup.postal_code ? guestGroup.postal_code : ''}`,
    image_id: null,
    key: 'new-york-new-york',
    // key: 'recipient-address-3'
  };
  recipientAddress.line4 = {
    body: `${
      guestGroup.country_code && guestGroup.country_code.toUpperCase() !== 'US'
        ? getCountryFromCountryCode(guestGroup.country_code.toUpperCase())
        : ''
    }`,
    image_id: null,
    key: '10036',
  };

  return recipientAddress;
}

export default getRecipientAddress;
