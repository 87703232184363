/**
 * @prettier
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import DetailBox from '../DetailBox/DetailBox';
import FeatureFlags from '../../../util/featureFlags';

import './DetailColorPicker.less';

const type = 'color';

const DetailColorPicker = ({
  activeColor,
  activeOpacity = 100,
  colors,
  detailBoxOverrideClass,
  detailBoxTitleOverrideClass,
  getHoverTitle,
  getHoverImage,
  onSelect,
  hoverSocialBadge,
  hoverEnabled,
  opacityEnabled = false,
}) => {
  const [currentHover, setCurrentHover] = useState(null);

  if (!colors) {
    return null;
  }
  return (
    <>
      {FeatureFlags.get('albumsPDPHover') && hoverEnabled && (
        <div className="option-list-wrapper">
          <div className="option-list">
            <DetailBox
              detailBoxOverrideClass={detailBoxOverrideClass}
              detailBoxTitleOverrideClass={detailBoxTitleOverrideClass}
              img={getHoverImage(currentHover)}
              show={!!currentHover}
              title={getHoverTitle && getHoverTitle(currentHover)}
            />
            {hoverSocialBadge && currentHover === hoverSocialBadge.option && (
              <div className="option-list-recommended">
                <span
                  className="option-list-recommended-emoji"
                  role="img"
                  aria-label={hoverSocialBadge.text}
                >
                  {hoverSocialBadge.emoji}
                </span>{' '}
                {hoverSocialBadge.text}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="product-color-picker">
        {colors.map(({ label, value, uuid, opacity = 100 }) => (
          <span
            key={value}
            className={cx('product-color-picker__wrapper', {
              'product-color-picker__wrapper--active':
                activeColor === value && activeOpacity === opacity,
            })}
            onClick={() => {
              if (opacityEnabled) {
                onSelect({ type, label, value, uuid, opacity });
              } else {
                onSelect({ type, label, value, uuid });
              }
            }}
            onMouseEnter={hoverEnabled ? () => setCurrentHover(value) : () => {}}
            onMouseLeave={hoverEnabled ? () => setCurrentHover(null) : () => {}}
          >
            <span className="product-color-picker__circle-back" />
            <span
              className="product-color-picker__circle-front"
              style={{
                background: `#${value}`,
                opacity: parseFloat(opacity / 100),
              }}
            />
          </span>
        ))}
      </div>
    </>
  );
};

DetailColorPicker.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  activeColor: PropTypes.string,
  onSelect: PropTypes.func,
};

export default DetailColorPicker;
