/**
 * @prettier
 */

import { getTypeFromBackendType } from 'cards/util/cardTypes';
import _groupBy from 'lodash/groupBy';
import _minBy from 'lodash/minBy';

const initialState = {};

const allOptions = [
  'color',
  'size',
  'reverse-printing',
  'silhouette',
  'paper-type',
  'finish',
  'printing-type',
  'foil',
  'foil-color',
];

// Needs to be updated if the cheapest options change
const requiredBaseOptions = {
  paper: {
    'paper-type': 'smooth',
    'reverse-printing': 'false',
    silhouette: 'square',
  },
  magnet: {},
};

// Returns true if every OTHER option value is equal to the base variation
const isVariationOfOption = (option, variation, base) => {
  return allOptions.every(current => {
    const isOption = current === option;
    const isCustomFoilOption =
      option === 'foil-color' && current === 'foil' && variation.option_values.foil === 'custom';
    const isEqualToBaseOption = variation.option_values[current] === base.option_values[current];

    return isOption || isCustomFoilOption || isEqualToBaseOption;
  });
};

/*
An example of the generated pricing structure:

{
  SAVE_THE_DATE: {
    paper: {
      basePrice: 99,
      petite: {
        basePrice: 135,
        delta: 36,
        silhouette: {
          square: 0,
          rounded: 35,
        },
        paper-type: {
          smooth: 0,
          recycled: 50,
        },
      },
    },
  },
}
*/
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CARD_SUITE_FETCH_FULFILLED': {
      const suite = action.payload || {};
      const { type, mediums, name, uuid } = suite;
      const typePricing = {};
      const cardType = getTypeFromBackendType(type);

      // If any necessary info is missing, abort immediately
      if (!type || !mediums) {
        return state;
      }

      // Generate prices for each medium
      Object.keys(mediums).forEach(mediumKey => {
        const { variations } = mediums[mediumKey];

        if (!variations || !variations.length) {
          return;
        }

        const mediumBasePrice = _minBy(variations, 'price_cents').price_cents;
        const mediumPricing = { basePrice: mediumBasePrice };
        const variationsBySize = _groupBy(variations, 'option_values.size');

        // Generate prices for each of the medium's available sizes
        Object.keys(variationsBySize).forEach(size => {
          const sizeVariations = variationsBySize[size];

          // Needed because data issues can cause the wrong variation to be the cheapest.
          const baseOptions = {
            ...requiredBaseOptions[mediumKey],
            'reverse-printing': size === 'postcard' ? 'true' : 'false',
          };
          let potentialSizeBaseVariations = sizeVariations.filter(variation =>
            Object.keys(baseOptions).every(key => baseOptions[key] === variation.option_values[key])
          );
          // If no variations match the requirement, fallback on the cheapest non-matching variation
          if (potentialSizeBaseVariations.length === 0) {
            console.log(
              `⚠️ Couldn't find ${name} (${size}) base variation. Displayed ${size} prices may be incorrect.`,
              {
                suiteUUID: uuid,
                suiteName: name,
                size,
                requiredOptions: baseOptions,
              }
            );
            potentialSizeBaseVariations = sizeVariations;
          }

          const sizeBaseVariation = _minBy(potentialSizeBaseVariations, 'price_cents');

          if (sizeBaseVariation) {
            const sizeBasePrice = sizeBaseVariation.price_cents;
            const sizePricing = {
              basePrice: sizeBasePrice,
              delta: sizeBasePrice - mediumBasePrice,
            };

            // Based off the size's min price, calculate each option's cost difference
            sizeVariations.forEach(variation => {
              ['silhouette', 'paper-type', 'finish', 'foil-color'].forEach(optionKey => {
                if (isVariationOfOption(optionKey, variation, sizeBaseVariation)) {
                  const optionValue = variation.option_values[optionKey];
                  sizePricing[optionKey] = {
                    ...sizePricing[optionKey],
                    [optionValue]: variation.price_cents - sizeBasePrice,
                  };
                }
              });
            });
            mediumPricing[size] = sizePricing;
          }
        });

        typePricing[mediumKey] = mediumPricing;
      });

      return {
        ...state,
        [cardType]: typePricing,
      };
    }
    default:
      return state;
  }
};

export default reducer;
