import type {
  WHomeModuleView,
  WProductView,
  WProgressView,
  WSnapshotView,
  WStatusCenterView,
} from '@zola/svc-web-api-ts-client';
import * as ActionTypes from 'actions/types/DashboardActionTypes';
import * as RegistryOverviewActionTypes from 'actions/types/RegistryOverviewActionTypes';
import * as ProfileActionTypes from 'actions/types/ProfileActionTypes';

export type DashboardReducerStateType = {
  loading: boolean;
  snapshot_view: WSnapshotView;
  status_center_view: WStatusCenterView;
  progress_view: WProgressView;
  open_progress_tab_type: string;
  products: WProductView[];
  productsLoading: boolean;
  additionalProductsLoading: boolean;
  modules: Array<WHomeModuleView>;
};

const initialState: DashboardReducerStateType = {
  loading: false,
  snapshot_view: {},
  status_center_view: {},
  progress_view: {},
  open_progress_tab_type: 'OVERVIEW',
  products: [],
  productsLoading: false,
  additionalProductsLoading: false,
  modules: [],
};

const dashboardReducer = (
  state = initialState,
  action:
    | ActionTypes.DashboardActionTypes
    | RegistryOverviewActionTypes.RegistryOverviewActions
    | ProfileActionTypes.ProfileActionTypes
): DashboardReducerStateType => {
  switch (action.type) {
    case ActionTypes.REQUEST_DASHBOARD: {
      return Object.assign({}, state, { loading: true });
    }
    case ActionTypes.RECEIVE_DASHBOARD: {
      return Object.assign({}, state, action.payload, { loading: false });
    }
    case ActionTypes.RECEIVE_HOME: {
      return Object.assign({}, state, action.payload, { loading: false });
    }
    // TODO: revisit; awkward action/reducer structure
    case RegistryOverviewActionTypes.REQUEST_PRODUCTS: {
      return { ...state, productsLoading: true };
    }
    case RegistryOverviewActionTypes.RECEIVE_PRODUCTS: {
      return { ...state, productsLoading: false, products: action.data };
    }
    case RegistryOverviewActionTypes.REQUEST_ADDITIONAL_PRODUCTS: {
      return { ...state, additionalProductsLoading: true };
    }
    case RegistryOverviewActionTypes.RECEIVE_ADDITIONAL_PRODUCTS: {
      return { ...state, additionalProductsLoading: false, products: action.data };
    }
    case ProfileActionTypes.RECEIVE_PROFILE_IMAGE_UPDATE: {
      return {
        ...state,
        snapshot_view: { ...state.snapshot_view, profile_image_url: action.payload },
      };
    }
    default:
      return state;
  }
};

export default dashboardReducer;
