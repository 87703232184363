import * as ActionType from './types/ModalActionTypes';
import { ModalActionTypes } from './types/ModalActionTypes';

export const hideModal = (): ModalActionTypes => ({ type: ActionType.HIDE_MODAL });

export const showModal = (
  modalType: string,
  modalProps = {},
  modalOptions = {}
): ModalActionTypes => ({
  type: ActionType.SHOW_MODAL,
  payload: {
    modalType,
    modalProps,
    modalOptions,
  },
});

export const hideSecondaryModal = (): ModalActionTypes => ({
  type: ActionType.HIDE_SECONDARY_MODAL,
});

export const showSecondaryModal = (
  modalType: string,
  modalProps = {},
  modalOptions = {}
): ModalActionTypes => ({
  type: ActionType.SHOW_SECONDARY_MODAL,
  payload: {
    modalType,
    modalProps,
    modalOptions,
  },
});
