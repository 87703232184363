import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import { ActionTypes } from '../actions/cardCatalogActions';

// helper functions
const enrichThemeMatchedCards = cards =>
  Object.keys(cards).reduce(
    (acc, card) => ({
      ...acc,
      [card]: {
        ...cards[card],
        isThemeMatchedCard: true,
      },
    }),
    {}
  );

const createVariationsOptionsValues = cardVariations =>
  cardVariations.map(variation => ({
    uuid: variation.uuid,
    ...variation.option_values,
  }));

const mapSearchIndexToSuite = (suites, offset) => {
  return suites.map((suite, i) => ({
    ...suite,
    lead_card: {
      ...suite.lead_card,
      searchIndex: offset + i,
    },
  }));
};

const createSortedSuites = (state, suites) =>
  suites.reduce(
    (result, suite) => {
      if (!result.byUUID[suite.uuid]) {
        result.orderedUUIDs.push(suite.uuid);
      }
      result.byUUID[suite.uuid] = suite; // eslint-disable-line no-param-reassign
      return result;
    },
    {
      byUUID: { ...state.suites.byUUID },
      orderedUUIDs: [...state.suites.orderedUUIDs],
    }
  );

const prependSortedSuites = (state, suites) => {
  const reversed = suites.reverse();
  return reversed.reduce(
    (result, suite) => {
      if (!result.byUUID[suite.uuid]) {
        result.orderedUUIDs.unshift(suite.uuid);
      }
      result.byUUID[suite.uuid] = suite; // eslint-disable-line no-param-reassign
      return result;
    },
    {
      byUUID: { ...state.suites.byUUID },
      orderedUUIDs: [...state.suites.orderedUUIDs],
    }
  );
};

const splitCardSuitesIntoByUUIDAndOrderedUUIDs = suites => {
  const byUUID = {};
  suites.forEach(suite => {
    byUUID[suite.uuid] = suite;
  });
  const orderedUUIDs = suites.map(suite => suite.uuid);
  return {
    byUUID,
    orderedUUIDs,
  };
};

const mergeOptionGroupsByKey = (optionGroups, optionGroupsToMerge) => {
  const mergedOptionGroupsByKey = [];
  const finishMagnetOptions = _find(optionGroupsToMerge, { key: 'finish' });
  const finishLeadCardOptions = _find(optionGroups, { key: 'finish' });

  // * Lead card don't always have the finish option set by default (optionGroups)
  // * set here Magnet options values right away.
  if (!finishLeadCardOptions && finishMagnetOptions) {
    mergedOptionGroupsByKey.push(finishMagnetOptions);
  }

  optionGroups.forEach(optGroup => {
    let updatedOptions = optGroup.options;
    if (optGroup.key === 'size') {
      updatedOptions = [
        ...optGroup.options,
        ..._find(optionGroupsToMerge, { key: 'size' }).options,
      ];
    } else if (optGroup.key === 'finish' && finishMagnetOptions) {
      // * If the lead card options has a finish attribute, its option value is defaulted to "none".
      // * We override the default option with real magnet finish options values.
      updatedOptions = [..._find(optionGroupsToMerge, { key: 'finish' }).options];
    }
    mergedOptionGroupsByKey.push({
      ...optGroup,
      options: updatedOptions.reduce(
        (x, y) => (x.findIndex(e => e.value === y.value) < 0 ? [...x, y] : x),
        []
      ), // remove duplicates
    });
  });
  return mergedOptionGroupsByKey;
};

const sortSizes = opts => {
  /**
   * * When called, the assumption is that there is a size attribute, with options.
   * * For some reasons, Digital Cards are sometimes returned with missing data.
   * * Until data is fixed, this is a quick workaround
   */
  if (!opts.size) {
    return {
      ...opts,
    };
  }

  return {
    ...opts,
    size: {
      ...opts.size,
      options: opts.size && opts.size.options,
    },
  };
};

const createOptionGroups = optGroups =>
  optGroups.reduce(
    (result, optionGroup) => {
      result.orderedKeys.push(optionGroup.key);
      result[optionGroup.key] = { ...optionGroup }; // eslint-disable-line no-param-reassign
      return result;
    },
    { orderedKeys: [] }
  );

const createOptionGroupsByKey = optGroups => sortSizes(createOptionGroups(optGroups));

const createVariationOptions = (optionGroups, defaultVariationOptions) => {
  const varOptionsObj = {};
  optionGroups.forEach(optGroup => {
    let defaultOption;
    if (optGroup.options.length === 1) {
      const option = optGroup.options[0];
      defaultOption = option;
    } else if (defaultVariationOptions[optGroup.key]) {
      defaultOption =
        _find(optGroup.options, {
          value: defaultVariationOptions[optGroup.key],
        }) || optGroup.options[0];
    } else {
      defaultOption = optGroup.options && optGroup.options[0];
    }
    varOptionsObj[optGroup.key] = defaultOption.value;
  });
  return varOptionsObj;
};

// return non-paper, non-envelope for a given medium
const getMediumLeadCard = (medium, items) => {
  const matches = items.filter(
    item => !item.lead && item.card.medium === medium && item.card.type !== 'ENVELOPE'
  );
  return matches.length === 1 ? matches[0].card : null;
};

const initialState = {
  busy: false,
  initial: true,
  filters: {
    limit: 12, // integer filter, defaults to 12
    offset: 0, // integer filter, starts at zero, increments by limit
    color: null, // string filter, defaults to null,
    seo_colors: [], // string array filter, defaults to empty array
    motifs: [],
    silhouettes: [],
    lead_card_type: null, // string filter, defaults to null
    active_only: true, // boolean filter, defaults to true
    has_foil: false, // boolean filter, defaults to false
    has_custom_photo: false, // boolean filter, defaults to false
    has_no_custom_photo: false, // boolean filter, defaults to false
    has_digital: false, // boolean filter, defaults to false
    has_magnet: false, // boolean filter, defaults to false,
    is_letterpress: false, // boolean filter, defaults to false
    is_portrait: false,
    is_landscape: false,
    is_vow_renewal: false,
    featured_artist: false,
    multiColor: [],
    single_sample_available: false,
    sorts: null, // string filter, defaults to null
  },
  suites: {
    themeMatchedCards: {
      byUUID: {},
      orderedUUIDs: [],
    },
    byUUID: {},
    orderedUUIDs: [],
    count: 0,
    total: 0,
    displayableTotal: 0,
    loadedStartIndex: 0,
    loadedEndIndex: 0,
  },
  detailSuite: {
    uuid: null,
    name: null,
    description: null,
    cards: [],
    leadCard: null,
    leadOptionGroupsByKey: null, // INV-905
    variationOptions: {},
    selectedMedium: 'PAPER', // INV-905
    digitalAvailable: null, // INV-905
    digitalCard: null, // INV-905
    digitalOptionGroupsByKey: null, // INV-905
    hasFoil: false, // INV-784
  },
  cards: {},
  cardUUIDsByVariationUUID: {},
  collaborators: {},
  themeGroupsForCards: {},
  relatedCardsFromSuite: [],
  relatedCardsByDesigner: [],
  relatedCardsByMotif: [],
  isLoadingRelatedDraftsFromSuite: false,
  relatedDraftsFromSuite: [],
  initialVariationColor: null,
  isAdminView: false,
  relatedCardsFamilyName: null,
  motifs: [],
  silhouettes: [],
  multiSampleState: {
    enabled: false,
    selected: {},
    count: {},
  },
  recentlyViewed: [],
};

// TODO: refactor to use combineReducers to split up reducer into multiple slice reducers
// this is getting unwieldy and could probably be divided into search, suites, and cards slice reducers
const cardCatalogReducer = (state = initialState, action) => {
  switch (action.type) {
    // busy
    case ActionTypes.REQUEST_SEARCH_CARD_SUITES: {
      return { ...state, busy: true };
    }
    // suites, filters, busy, initial,
    case ActionTypes.RECEIVE_SEARCH_CARD_SUITES: {
      const { cardSuites } = action.payload;

      const { total, limit, offset, color, lead_card_type } = cardSuites;
      const { byUUID, orderedUUIDs } = createSortedSuites(state, cardSuites.suites);
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          count: orderedUUIDs.length,
          total,
        },
        filters: {
          ...state.filters,
          limit,
          offset,
          color,
          lead_card_type,
        },
      };
    }
    case ActionTypes.RECEIVE_SEARCH_CARD_SUITES_BY_UUID: {
      const { cardSuites } = action.payload;
      return {
        ...state,
        busy: false,
        recentlyViewed: cardSuites,
      };
    }
    case ActionTypes.RECEIVE_THEME_MATCHED_CARD_SUITES: {
      const { cardSuites } = action.payload;
      const { byUUID, orderedUUIDs } = splitCardSuitesIntoByUUIDAndOrderedUUIDs(cardSuites.suites);
      return {
        ...state,
        busy: false,
        suites: {
          ...state.suites,
          themeMatchedCards: { byUUID: enrichThemeMatchedCards(byUUID), orderedUUIDs },
          count: orderedUUIDs.length,
        },
      };
    }
    // detailSuite
    case ActionTypes.RECEIVE_CARD_SUITE: {
      const { cardSuite } = action.payload;
      const {
        uuid,
        name,
        description,
        items,
        family,
        default_variation_options: defaultVariationOptions,
        foil,
      } = cardSuite;
      const digital = cardSuite.digital ? cardSuite.digital : false; // INV-905
      const leadItem = _find(items, { lead: true });
      const leadCard = leadItem.card;
      const digitalCard = getMediumLeadCard('DIGITAL', items); // INV-905

      // Magnet medium as an size option for paper medium
      const magnetData = _find(
        items,
        i => i.card.medium === 'MAGNET' && !_isEmpty(i.card.variations)
      );
      const isMagnetAvailable =
        magnetData && ['SAVE_THE_DATE', 'HOLIDAY'].includes(magnetData.card.type);
      const consolidatedOptionGroups = isMagnetAvailable
        ? mergeOptionGroupsByKey(leadCard.option_groups, magnetData.card.option_groups)
        : null;
      const optionGroupsByKey = createOptionGroupsByKey(
        consolidatedOptionGroups || leadCard.option_groups
      );
      // add magnet variations to paper variations
      const variations = isMagnetAvailable
        ? [...leadCard.variations, ...magnetData.card.variations]
        : leadCard.variations;
      const variationsOptionValues = isMagnetAvailable
        ? createVariationsOptionsValues([...leadCard.variations, ...magnetData.card.variations])
        : createVariationsOptionsValues(leadCard.variations);

      if (state.initialVariationColor) {
        defaultVariationOptions.color = state.initialVariationColor;
      }
      if (leadCard.type === 'SAVE_THE_DATE') {
        defaultVariationOptions.size = 'petite';
      }

      const variationOptions = createVariationOptions(
        leadCard.option_groups,
        defaultVariationOptions
      );
      // default to current selectedMedium in state, which by default itself is 'PAPER'
      let { selectedMedium } = state.detailSuite;
      // TODO: clean up old logic regarding digital - digital is no longer received from the server
      const isDigitalCard = digital && digitalCard && state.filters.has_digital;
      selectedMedium = isDigitalCard ? 'DIGITAL' : 'PAPER';

      const digitalOptionGroupsByKey =
        digital && !_isEmpty(digitalCard.option_groups)
          ? createOptionGroupsByKey(digitalCard.option_groups)
          : null;

      return {
        ...state,
        detailSuite: {
          uuid,
          name,
          family,
          description,
          cards: items,
          leadCard: {
            ...leadCard,
            variations,
            variationsOptionValues,
          },
          leadOptionGroupsByKey: optionGroupsByKey,
          selectedMedium,
          variationOptions,
          digitalAvailable: digital,
          digitalCard: {
            ...digitalCard,
          },
          digitalOptionGroupsByKey,
          hasFoil: foil,
        },
      };
    }
    // collaboratorsByKey
    case ActionTypes.RECEIVE_COLLABORATORS: {
      const { collaborators } = action.payload;
      const collaboratorsByKey = {};
      collaborators.forEach(collaborator => {
        collaboratorsByKey[collaborator.key] = collaborator;
      });
      return {
        ...state,
        collaborators: collaboratorsByKey,
      };
    }
    // detailSuite
    case ActionTypes.UPDATE_VARIATION_OPTIONS: {
      const { variations, paperTypeOptionsAvailability } = action.payload;
      return {
        ...state,
        detailSuite: {
          ...state.detailSuite,
          variationOptions: {
            ...state.detailSuite.variationOptions,
            ...variations,
          },
          leadOptionGroupsByKey: {
            ...state.detailSuite.leadOptionGroupsByKey,
            'paper-type': {
              ...state.detailSuite.leadOptionGroupsByKey['paper-type'],
              options: paperTypeOptionsAvailability,
            },
          },
        },
      };
    }
    // cards
    case ActionTypes.RECEIVE_CARD: {
      const { card, variationUUID } = action.payload;
      const optionGroupsByKey = createOptionGroupsByKey(card.option_groups);
      const variationsOptionValues = createVariationsOptionsValues(card.variations);
      return {
        ...state,
        cards: {
          ...state.cards,
          [card.uuid]: {
            ...card,
            optionGroupsByKey,
            variationsOptionValues,
          },
        },
        cardUUIDsByVariationUUID: {
          ...state.cardUUIDsByVariationUUID,
          [variationUUID]: card.uuid,
        },
      };
    }
    // suites, filters, initial
    case ActionTypes.UPDATE_SEARCH_FILTERS: {
      const { key, value } = action.payload;
      let returnVal;
      switch (key) {
        case 'lead_card_type': {
          returnVal = {
            ...state,
            filters: {
              ...initialState.filters,
              ...state.filters,
              motifs: state.filters.motifs,
              lead_card_type: value,
            },
          };
          break;
        }
        case 'motifs': {
          if (_isEqual(state.filters[key], value)) {
            returnVal = {
              ...state,
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          } else {
            returnVal = {
              ...state,
              busy: true,
              initial: true,
              suites: {
                ...initialState.suites,
                themeMatchedCards: state.suites.themeMatchedCards,
              },
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          }
          break;
        }
        case 'seo_colors': {
          if (_isEqual(state.filters[key], value)) {
            returnVal = {
              ...state,
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          } else {
            returnVal = {
              ...state,
              busy: true,
              initial: true,
              suites: {
                ...initialState.suites,
                themeMatchedCards: state.suites.themeMatchedCards,
              },
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          }
          break;
        }
        case 'silhouettes': {
          if (_isEqual(state.filters[key], value)) {
            returnVal = {
              ...state,
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          } else {
            returnVal = {
              ...state,
              busy: true,
              initial: true,
              suites: {
                ...initialState.suites,
                themeMatchedCards: state.suites.themeMatchedCards,
              },
              filters: {
                ...state.filters,
                limit: initialState.filters.limit,
                offset: initialState.filters.offset,
                [key]: value,
              },
            };
          }
          break;
        }
        case 'collaborator': {
          returnVal = {
            ...state,
            busy: true,
            initial: true,
            suites: {
              ...initialState.suites,
              themeMatchedCards: state.suites.themeMatchedCards,
            },
            filters: {
              ...state.filters,
              collaborator: value,
            },
          };
          break;
        }
        default: {
          returnVal = {
            ...state,
            busy: true,
            initial: true,
            suites: {
              ...initialState.suites,
              themeMatchedCards: state.suites.themeMatchedCards,
            },
            filters: {
              ...state.filters,
              limit: initialState.filters.limit,
              offset: initialState.filters.offset,
              [key]: value,
            },
          };
        }
      }
      return returnVal;
    }
    case ActionTypes.UPDATE_MULTIPLE_SEARCH_FILTERS: {
      const newValues = action.payload;
      const filters = {
        ...state.filters,
        limit: initialState.filters.limit,
        offset: initialState.filters.offset,
        ...newValues,
      };
      return {
        ...state,
        busy: true,
        initial: true,
        suites: {
          ...initialState.suites,
          themeMatchedCards: state.suites.themeMatchedCards,
        },
        filters,
      };
    }
    case ActionTypes.OVERWRITE_SEARCH_FILTERS: {
      const newValues = action.payload;
      return {
        ...state,
        busy: true,
        initial: true,
        suites: {
          ...initialState.suites,
          themeMatchedCards: state.suites.themeMatchedCards,
        },
        filters: {
          ...initialState.filters,
          lead_card_type: state.filters.lead_card_type,
          active_only: state.filters.active_only,
          single_sample_available: state.filters.single_sample_available,
          ...newValues,
        },
      };
    }
    // suites, filters, initial
    case ActionTypes.RESET_SEARCH_FILTERS: {
      return {
        ...state,
        busy: true,
        initial: true,
        filters: {
          ...initialState.filters,
          lead_card_type: state.filters.lead_card_type,
          active_only: state.filters.active_only,
          collaborator: state.filters.collaborator,
          digital_suite: state.filters.digital_suite,
          motifs: [],
          silhouettes: [],
        },
        suites: {
          ...initialState.suites,
          themeMatchedCards: state.suites.themeMatchedCards,
        },
      };
    }
    // initialVariationColor
    case ActionTypes.SET_INITIAL_VARIATION_COLOR: {
      const { color } = action.payload;
      return {
        ...state,
        initialVariationColor: color,
      };
    }
    // relatedCardsFamilyName
    case ActionTypes.SET_RELATED_CARDS_FAMILY_NAME: {
      const { familyName } = action.payload;
      return {
        ...state,
        relatedCardsFamilyName: familyName,
      };
    }
    // detailSuite
    case ActionTypes.SET_SELECTED_MEDIUM: {
      const { selectedMedium } = action.payload;
      const { detailSuite } = state;
      return {
        ...state,
        detailSuite: {
          ...detailSuite,
          selectedMedium,
        },
      };
    }
    // relatedCardsFromSuite
    case ActionTypes.RESET_RELATED_CARDS_FAMILY_NAME: {
      return {
        ...state,
        relatedCardsFamilyName: initialState.relatedCardsFamilyName,
      };
    }
    // trigger, busy
    case ActionTypes.REQUEST_THEME_GROUPS_FOR_CARD: {
      return { ...state, busy: true };
    }
    // themeGroupsForCards
    case ActionTypes.RECEIVE_THEME_GROUPS_FOR_CARD: {
      const { cardUUID, themeGroups } = action.payload;
      return {
        ...state,
        busy: false,
        themeGroupsForCards: {
          ...state.themeGroupsForCards,
          [cardUUID]: themeGroups,
        },
      };
    }
    // trigger
    case ActionTypes.REQUEST_RELATED_CARDS_FROM_SUITE: {
      return state;
    }
    // relatedCardsFromSuite
    case ActionTypes.RECEIVE_RELATED_CARDS_FROM_SUITE: {
      const { relatedCards } = action.payload;
      return {
        ...state,
        relatedCardsFromSuite: relatedCards.suites,
      };
    }
    // relatedCardsFromSuite
    case ActionTypes.RESET_RELATED_CARDS_FROM_SUITE: {
      return {
        ...state,
        relatedCardsFromSuite: initialState.relatedCardsFromSuite,
      };
    }
    case ActionTypes.RECEIVE_RELATED_CARDS_BY_DESIGNER: {
      const { relatedCards } = action.payload;

      return {
        ...state,
        relatedCardsByDesigner: relatedCards.suites,
      };
    }
    case ActionTypes.RECEIVE_RELATED_CARDS_BY_MOTIF: {
      const { relatedCards } = action.payload;

      return {
        ...state,
        relatedCardsByMotif: relatedCards,
      };
    }
    case ActionTypes.RESET_RELATED_CARDS_BY_DESIGNER: {
      return {
        ...state,
        relatedCardsByDesigner: initialState.relatedCardsByDesigner,
      };
    }
    case ActionTypes.RESET_RELATED_CARDS_BY_MOTIF: {
      return {
        ...state,
        relatedCardsByMotif: initialState.relatedCardsByMotif,
      };
    }
    case ActionTypes.REQUEST_RELATED_DRAFTS_FOR_SUITE: {
      return {
        ...state,
        isLoadingRelatedDraftsFromSuite: true,
      };
    }
    case ActionTypes.RECEIVE_RELATED_DRAFTS_FOR_SUITE: {
      const { relatedDrafts } = action.payload;
      return {
        ...state,
        relatedDraftsFromSuite: relatedDrafts,
        isLoadingRelatedDraftsFromSuite: false,
      };
    }
    case ActionTypes.RESET_RELATED_DRAFTS_FOR_SUITE: {
      return {
        ...state,
        relatedDraftsFromSuite: initialState.relatedDraftsFromSuite,
        isLoadingRelatedDraftsFromSuite: initialState.isLoadingRelatedDraftsFromSuite,
      };
    }
    // isAdminView
    case ActionTypes.UPDATE_CATALOG_ADMIN_VIEW: {
      const { isAdminView } = action.payload;
      return {
        ...state,
        isAdminView,
      };
    }
    case ActionTypes.HYDRATE_MOTIFS: {
      return {
        ...state,
        motifs: action.payload.motif_views,
      };
    }
    case ActionTypes.UPDATE_SELECTED_MOTIFS: {
      const filters = {
        ...state.filters,
        selected_motifs: action.payload,
      };
      return {
        ...state,
        filters,
      };
    }
    case ActionTypes.UPDATE_SEARCH_FILTER_OFFSET:
      return {
        ...state,
        filters: { ...state.filters, offset: action.offset },
      };
    case ActionTypes.RECEIVE_PREVIOUS_SEARCH_RESULTS: {
      const { cardSuites } = action.payload;
      const { offset } = cardSuites;
      const mappedSuites = mapSearchIndexToSuite(cardSuites.suites, offset);
      const { byUUID, orderedUUIDs } = prependSortedSuites(state, mappedSuites);
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          loadedStartIndex: offset,
        },
      };
    }
    case ActionTypes.RECEIVE_ADDITIONAL_CARD_SUITES: {
      const { cardSuites } = action.payload;
      const { offset } = cardSuites;
      const count = cardSuites.suites.length;
      const mappedSuites = mapSearchIndexToSuite(cardSuites.suites, offset);
      const { byUUID, orderedUUIDs } = createSortedSuites(state, mappedSuites);
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          loadedEndIndex: offset + count - 1,
        },
      };
    }
    case ActionTypes.RECEIVE_INITIAL_CARD_SUITES: {
      const { cardSuites } = action.payload;
      const { offset, displayable_total: displayableTotal } = cardSuites;
      const mappedSuites = mapSearchIndexToSuite(cardSuites.suites, offset);
      const numNewSuites = cardSuites.suites.length;
      const { byUUID, orderedUUIDs } = createSortedSuites(state, mappedSuites);
      const loadedEndIndex = numNewSuites !== 0 ? offset + numNewSuites - 1 : offset;
      return {
        ...state,
        initial: false,
        busy: false,
        suites: {
          ...state.suites,
          byUUID,
          orderedUUIDs,
          count: orderedUUIDs.length,
          displayableTotal,
          loadedStartIndex: offset,
          loadedEndIndex,
        },
      };
    }
    case ActionTypes.RESET_THEME_MATCHED_CARDS:
      return {
        ...state,
        suites: {
          ...state.suites,
          themeMatchedCards: {
            ...initialState.suites.themeMatchedCards,
          },
        },
      };
    case ActionTypes.SET_MULTISAMPLE_ENABLED: {
      const { enabled, count } = action.payload;

      return {
        ...state,
        multiSampleState: {
          ...state.multiSampleState,
          enabled,
          count: {
            ...count,
            limit: count.maximumOrdersAllowed - count.ordersPlaced,
          },
          selected: enabled ? state.multiSampleState.selected : {},
        },
      };
    }
    case ActionTypes.SET_MULTISAMPLE_SELECTED: {
      const { card, selected, initialColor } = action.payload;

      if (Object.keys(selected).length >= state.multiSampleState.count.limit) {
        return state;
      }

      const nextState = {
        ...state,
        multiSampleState: {
          ...state.multiSampleState,
          selected: {
            ...state.multiSampleState.selected,
          },
        },
      };

      if (selected && card.suiteUUID) {
        nextState.multiSampleState.selected[card.suiteUUID] = { ...card, initialColor };
      } else if (nextState.multiSampleState.selected[card.suiteUUID]) {
        delete nextState.multiSampleState.selected[card.suiteUUID];
      }

      return nextState;
    }
    case ActionTypes.CLEAR_MULTISAMPLE_SELECTED: {
      const nextState = {
        ...state,
        multiSampleState: {
          ...state.multiSampleState,
          selected: {},
        },
      };
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default cardCatalogReducer;
