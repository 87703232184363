import { browserHistory } from 'react-router';
import { fetchDefaultTemplate } from 'cards/components/common/Samples/util/api/fetchTemplate';

// TODO: see if we need to move this to a more global scope
import ApiService from '../../../util/api';
import { getCardByVariationUUID } from '../../actions/cardCatalogActions';
import {
  receiveProject,
  setLandingPageVisibility,
  fetchProjectMeta,
} from '../../actions/projectActions';
import { getStepUrl } from '../../util/getStepUrl';

// TODO: better structure this file, seems confusing and convoluted
const cardSuiteIsFetching = () => ({
  type: 'CARD_SUITE_FETCH',
});

const digitalCardSuiteFulfilled = payload => ({
  type: 'DIGITAL_CARD_SUITE_FETCH_FULFILLED',
  payload,
});

export const cardSuiteFulfilled = payload => ({
  type: 'CARD_SUITE_FETCH_FULFILLED',
  payload,
});

const cardSuiteRejected = payload => ({
  type: 'CARD_SUITE_FETCH_REJECTED',
  payload,
});

export const setActiveSuite = uuid => ({
  type: 'SET_ACTIVE_SUITE',
  payload: uuid,
});

export const setPDPInitialVariationColor = payload => ({
  type: 'SET_PDP_INITIAL_VARIATION_COLOR',
  payload,
});

// TODO: Move all PDP local state into the component
// START of local state logic
export const setOptionValue = payload => ({
  type: 'SET_OPTION_VALUE',
  payload,
});

export const setQuantity = qty => dispatch => dispatch({ type: 'SET_QUANTITY', payload: qty });
// END of local state logic

export const fetchDigitalCardSuite = (uuid, isAdminView = false) => dispatch => {
  if (!uuid) {
    return Promise.resolve();
  }

  dispatch(cardSuiteIsFetching());

  return ApiService.post(`/web-api/v1/card-catalog/suite/details/${uuid}`, {
    show_unlisted: !!isAdminView,
  })
    .then(data => dispatch(digitalCardSuiteFulfilled(data)))
    .catch(err => dispatch(cardSuiteRejected(err)));
};

export const fetchCardSuite = (uuid, isAdminView = false) => dispatch => {
  if (!uuid) {
    return Promise.resolve();
  }

  dispatch(cardSuiteIsFetching());

  return ApiService.post(`/web-api/v1/card-catalog/suite/details/${uuid}`, {
    show_unlisted: !!isAdminView,
  })
    .then(data => dispatch(cardSuiteFulfilled(data)))
    .catch(err => dispatch(cardSuiteRejected(err)));
};

export const fetchCardSuiteByProjectId = projectUUID => dispatch => {
  if (!projectUUID) {
    return Promise.resolve();
  }

  dispatch(cardSuiteIsFetching());

  return ApiService.get(`/web-api/v3/card-project/${projectUUID}/suite-details`)
    .then(data => {
      dispatch(cardSuiteFulfilled({ ...data, ...{ projectUUID } }));
      return (data && data.uuid) || null;
    })
    .catch(err => dispatch(cardSuiteRejected(err)));
};

export const customizeCard = (
  activeUUID,
  activeVariationUUID,
  quantity,
  isAdminView = false,
  skipRedirect = false,
  pointOfOrigin,
  extraCustomizable,
  photoUuid = null
) => dispatch => {
  const withCustomPhoto = typeof photoUuid === 'string' ? { photo_uuid: photoUuid } : {};

  return ApiService.post('/web-api/v3/card-project', {
    suite_uuid: activeUUID,
    lead_variation_uuid: activeVariationUUID,
    quantity,
    from_admin_route: isAdminView,
    point_of_origin: pointOfOrigin,
    extra_customizable: extraCustomizable,
    ...withCustomPhoto,
  }).then(json => {
    dispatch(setLandingPageVisibility(true));
    const { customizations, uuid } = json;

    dispatch(fetchProjectMeta(uuid));

    // Fetch every customization variation
    customizations.forEach(customization => {
      const variationUUID = customization?.variation?.uuid;
      if (variationUUID) {
        dispatch(getCardByVariationUUID(variationUUID, customization.uuid, isAdminView));
      }
    });

    // Save the newly created project in the Redux store
    if (json) {
      dispatch(receiveProject(json, isAdminView));
    }

    if (!skipRedirect) {
      browserHistory.push(getStepUrl(uuid));
    }

    return json;
  });
};

export const fetchVariationTemplate = variationUUID => dispatch => {
  return fetchDefaultTemplate([variationUUID]).then(data => {
    dispatch({
      type: 'FETCH_VARIATION_TEMPLATE_SUCCESS',
      payload: {
        variationUUID,
        template: data[variationUUID],
      },
    });
  });
};
