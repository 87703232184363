import React, { Fragment } from 'react';
import { getTextColor } from 'cards/components/Customization/customizationUtils';
import { CARD_BLEED_PT } from 'cards/util';
import styles from './styles.module.less';

const TextElement = ({ element, foilColor = '', isPlaceCard = false }) => {
  if (!element || !element.body) {
    return null;
  }

  const lines = element.body.split('\n');

  const body = lines.map((s, i) => (
    <Fragment key={i}>
      {s}
      {i !== lines.length - 1 && <br />}
    </Fragment>
  ));

  const characterSpacing = element.font.character_spacing || element.character_spacing;
  const letterSpacing = characterSpacing ? characterSpacing * 0.001 : 0;
  const originalTextColor = element.font.font_color || element.font_color || 'black';
  const textColor = getTextColor(originalTextColor, element.printing_technique, foilColor);
  const verticalAliment = (
    element.font.vertical_alignment ||
    element.vertical_alignment ||
    'TOP'
  ).toLowerCase();

  const placeCardVerticalAligment =
    isPlaceCard && verticalAliment === 'bottom'
      ? {
          display: 'flex',
          justifyContent: 'startend',
          alignItems: 'end',
        }
      : {};

  /*
   * Because our templates dimensions includes the bleed value
   * it's deducted on the preview container context for Sample preview / Digital canvas preview.
   * Thus, elements positions needs to be adjusted.
   */
  const elementLeftPos = element.x - CARD_BLEED_PT;
  const elementTopPos = element.y - CARD_BLEED_PT;

  const style = {
    zIndex: element.layer,
    left: `${elementLeftPos}pt`,
    top: `${elementTopPos}pt`,
    width: `${element.width}pt`,
    height: `${element.height}pt`,
    color: `#${textColor}`,
    fontFamily: `'${element.font.font_family || element.font_family}'`,
    fontSize: `${element.font.font_size || element.font_size}pt`,
    fontStyle: element.font.font_style || element.font_style,
    fontWeight: `${element.font.font_weight || element.font_weight}`,
    textAlign: (
      element.font.horizontal_alignment ||
      element.horizontal_alignment ||
      'LEFT'
    ).toLowerCase(),
    verticalAlign: verticalAliment,
    letterSpacing: `${letterSpacing}em`,
    lineHeight: `${element.font.line_height || element.line_height}pt`,
    // @TODO COMMERCE-1398 Enable this when we want to support all_caps in FE
    // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1398
    // textTransform: element.all_caps ? 'uppercase' : 'none',
    ...placeCardVerticalAligment,
  };

  return (
    <div className={styles.textLayer} style={style}>
      {body}
    </div>
  );
};

export default TextElement;
