import _without from 'lodash/without';
import { ActionTypes } from '../actions/digitalOrdersActions';

const initialState = {
  busy: false,
  entities: {
    customizations: {},
    orders: {},
  },
  allIds: [],
  isOrderInCartWarning: false,
};

export default function digitalOrdersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.DIGITAL_REQUEST_ORDERS: {
      return { ...state, busy: true };
    }
    case ActionTypes.DIGITAL_RECEIVE_ORDERS: {
      const { normalizedOrders } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          ...normalizedOrders.entities,
        },
        allIds: normalizedOrders.result,
        busy: false,
      };
    }
    case ActionTypes.DIGITAL_RECEIVE_CANCELED_ORDER: {
      const { projectUUID } = action.payload;
      const allIds = _without(state.allIds, projectUUID);
      return {
        ...state,
        allIds,
      };
    }
    default:
      return state;
  }
}
