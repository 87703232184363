import { ActionTypes } from '../actions/UploadcareActions';

const initialState = {
  uploadcareFiles: {},
  zolaMetadata: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ZOLA_METADATA_FETCH_STARTED: {
      const { zolaUUID, fetchingPromise } = action.payload;
      return {
        ...state,
        zolaMetadata: {
          ...state.zolaMetadata,
          [zolaUUID]: {
            loading: true,
            fetchingPromise,
          },
        },
      };
    }
    case ActionTypes.ZOLA_METADATA_BATCH_FETCH_STARTED: {
      const { zolaUUIDs } = action.payload;
      const batchMetadata = {};
      zolaUUIDs.forEach(zolaUUID => {
        batchMetadata[zolaUUID] = { loading: true };
      });

      return {
        ...state,
        zolaMetadata: {
          ...state.zolaMetadata,
          ...batchMetadata,
        },
      };
    }
    case ActionTypes.UPLOADCARE_TRANSFER_STARTED: {
      const { uploadcareFile } = action.payload;
      return {
        ...state,
        uploadcareFiles: {
          ...state.uploadcareFiles,
          [uploadcareFile.cdnUrl]: uploadcareFile,
        },
      };
    }
    case ActionTypes.ZOLA_METADATA_FETCH_COMPLETE:
    case ActionTypes.UPLOADCARE_TRANSFER_COMPLETE: {
      const { metadata } = action.payload;

      // If metadata is null (i.e. API error occurred), don't save it
      if (!metadata) return state;

      return {
        ...state,
        zolaMetadata: {
          ...state.zolaMetadata,
          [metadata.uuid]: {
            ...metadata,
            loading: false,
          },
        },
      };
    }
    case ActionTypes.ZOLA_METADATA_BATCH_FETCH_COMPLETE: {
      const { metadataList } = action.payload;

      // If metadata is null (i.e. API error occurred), don't save it
      if (!metadataList) return state;

      const batchMetadata = {};
      metadataList.forEach(metadata => {
        batchMetadata[metadata.uuid] = { ...metadata, loading: false };
      });

      return {
        ...state,
        zolaMetadata: {
          ...state.zolaMetadata,
          ...batchMetadata,
        },
      };
    }
    case ActionTypes.UPLOADCARE_FILE_FETCH_STARTED: {
      const { uploadcareCdnUrl } = action.payload;
      return {
        ...state,
        uploadcareFiles: {
          ...state.uploadcareFiles,
          [uploadcareCdnUrl]: {},
        },
      };
    }
    case ActionTypes.UPLOADCARE_FILE_FETCH_COMPLETE: {
      const { uploadcareCdnUrl, uploadcareFile } = action.payload;
      return {
        ...state,
        uploadcareFiles: {
          ...state.uploadcareFiles,
          [uploadcareCdnUrl]: uploadcareFile,
        },
      };
    }
    case ActionTypes.CLEAR_UPLOADCARE_FILES: {
      return {
        ...state,
        uploadcareFiles: {},
      };
    }
    case ActionTypes.CLEAR_ZOLA_METADATA: {
      return {
        ...state,
        zolaMetadata: {},
      };
    }
    default:
      return state;
  }
};

export default reducer;
