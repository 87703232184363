import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FreeUnderLine from 'assets/images/invitations/free-underline-teal.svg';
import FreeUnderLinePink from 'assets/images/invitations/free-underline-pink.svg';
import styles from './styles.module.less';

const Free = ({
  freeStyle,
  isPink,
  isLowercase = false,
  customWord,
  customUnderLine,
  customUnderlineOverrideStyle,
}) => {
  let imageSource = isPink ? FreeUnderLinePink : FreeUnderLine;
  if (customUnderLine) {
    imageSource = customUnderLine;
  }
  return (
    <div className={styles.free}>
      {customWord ? (
        <span style={freeStyle}>{customWord}</span>
      ) : (
        <span style={freeStyle}>{isLowercase ? 'free' : 'FREE'}</span>
      )}
      <img
        src={imageSource}
        alt="free"
        className={cx('zolaicon', styles.freeImg, customUnderlineOverrideStyle)}
      />
    </div>
  );
};

Free.propTypes = {
  freeStyle: PropTypes.shape({}),
  isPink: PropTypes.bool,
  isLowercase: PropTypes.bool,
  customWord: PropTypes.string,
  customUnderLine: PropTypes.node,
};

export default Free;
