import { getCardSettings } from 'cards/util/cardSettingsUtils';
import { HIGHEST_ALLOWED_MIN_REQUIREMENT, MIN_DPI } from '../constants/Cards';
import {
  CARD_TYPES,
  ORDERED_CUSTOMIZATION_TYPES,
  PRIMARY_CUSTOMIZATION_TYPES,
} from '../constants/CardTypes';
import { STEPS_ALLOWING_MULTIPLES } from '../constants/Steps';
import {
  REGULAR_LISTS_OPTIONS,
  LETTERPRESS_LISTS_OPTIONS,
} from '../components/Customization/common/ColorPicker/constants';

// Need to filter out invitation and rsvp envelope card types,
// as they don't have card settings or a urlSlug value
const { invitationEnvelope, rsvpEnvelope, ...cardTypes } = CARD_TYPES;
const cardTypesBySlug = Object.values(cardTypes).reduce((acc, typeKey) => {
  const { urlSlug } = getCardSettings(typeKey);
  if (urlSlug) {
    acc[urlSlug] = typeKey;
  }
  return acc;
}, {});

export const getCardTypeFromSlug = slug => (slug && cardTypesBySlug[slug]) || null;

export function getStepKey(id, cardType) {
  const needsType = STEPS_ALLOWING_MULTIPLES.includes(id) && !!cardType;

  return needsType ? `${id}-${cardType}` : id;
}

export function getStepFromPathname(pathname, orderedSteps) {
  const paths = pathname.split('/');
  const slug = paths[paths.length - 2];
  const stepId = paths[paths.length - 1];
  const cardType = getCardTypeFromSlug(slug);

  let currentStep;
  if (cardType) {
    // Find the step with matching type and id
    const stepKey = getStepKey(stepId, cardType);
    currentStep = orderedSteps.find(step => step.key === stepKey);
  } else {
    // Otherwise, find the first step with matching id
    currentStep = orderedSteps.find(step => step.id === stepId);
  }

  return currentStep;
}

// IMPORTANT: This is a hack used during sku migration to get around multiple customizations with the "ENVELOPE" type.
export function getTypeFromCustomization(customization) {
  switch (customization.type) {
    case 'SAVE_THE_DATE':
      // TODO DSTD
      return customization.medium === 'DIGITAL' ? 'DIGITAL_SAVE_THE_DATE' : customization.type;
    case 'DETAILS':
      return CARD_TYPES.enclosure;
    case 'ENVELOPE':
      if (/rsvp/i.test(customization.name)) {
        return CARD_TYPES.rsvpEnvelope;
      }
      return CARD_TYPES.invitationEnvelope;
    default:
      return customization.type;
  }
}

export function findLeadCustomization(allCustomizations) {
  const customizationWithLeadFlag = allCustomizations.find(c => c.lead);
  const firstPrimaryCustomization = allCustomizations.find(
    c => !c.inactive && PRIMARY_CUSTOMIZATION_TYPES.includes(getTypeFromCustomization(c))
  );

  return customizationWithLeadFlag || firstPrimaryCustomization || {};
}

export function getLastDraftEditDate({ primary_customization: customization }) {
  return customization ? new Date(customization.edited_at || customization.updated_at) : new Date();
}

// get last edit date for drafts without a primary customization set
const getLastCustomizationsEditDate = ({ customizations }) =>
  new Date(customizations[0].edited_at || customizations[0].updated_at);

// sort customizations data for drafts
// only for payload coming from new drafts and orders endpoint
export const getMappedDraftsByCardType = draftsByCardType => {
  const mappedDrafts = {};

  Object.entries(draftsByCardType).forEach(drafts => {
    // sort card type drafts by edit date (from most to least recent)
    const sortedDrafts = drafts[1].sort(
      (a, b) => getLastCustomizationsEditDate(b) - getLastCustomizationsEditDate(a)
    );

    const mapped = [];
    sortedDrafts.forEach(draft => {
      // sort customizations by customization type
      const sortedCustomizations = draft.customizations.sort(
        (a, b) =>
          ORDERED_CUSTOMIZATION_TYPES.indexOf(a.type) - ORDERED_CUSTOMIZATION_TYPES.indexOf(b.type)
      );
      const primaryCustomization = findLeadCustomization(draft.customizations);
      mapped.push({
        ...draft,
        customizations: sortedCustomizations,
        primary_customization: primaryCustomization,
        version_history: [],
      });
    });

    // combine DETAILS and ENCLOSURE card types
    if (drafts[0] === 'DETAILS') {
      if (mappedDrafts.ENCLOSURE) {
        mappedDrafts.ENCLOSURE.push(mapped).sort(
          (a, b) => getLastDraftEditDate(b) - getLastDraftEditDate(a)
        );
      } else {
        mappedDrafts.ENCLOSURE = mapped;
      }

      return mappedDrafts;
    }

    mappedDrafts[drafts[0]] = mapped;
    return mappedDrafts;
  });

  return mappedDrafts;
};

// sort customizations data for orders
export const getMappedOrders = orders => {
  return orders.map(order => ({
    ...order,
    customizations: order.customizations.sort(
      (a, b) =>
        ORDERED_CUSTOMIZATION_TYPES.indexOf(a.type) - ORDERED_CUSTOMIZATION_TYPES.indexOf(b.type)
    ),
    primary_customization: findLeadCustomization(order.customizations),
  }));
};

export function getColorLabelFromCard(cardsData, customizationUUID) {
  const cardUUID = Object.keys(cardsData).find(
    card => cardsData[card].customizationUUID === customizationUUID
  );
  const { optionGroupsByKey: { color: { options = [] } = {} } = {} } = cardsData[cardUUID] || {};
  return options;
}

export function findColorLabel(cardsData, customizationUUID, colorHex) {
  const colorOptions = getColorLabelFromCard(cardsData, customizationUUID);
  const colorData = colorOptions.length && colorOptions.find(option => option.value === colorHex);
  return colorData && colorData.label;
}

export function findEnvelopeFullColorLabel(cardsData, customizationUUID, colorHex, hasLetterpress) {
  const colorLabel = findColorLabel(cardsData, customizationUUID, colorHex);
  const categoriesData = hasLetterpress ? LETTERPRESS_LISTS_OPTIONS : REGULAR_LISTS_OPTIONS;
  const category =
    colorHex.toLowerCase() === 'ffffff' ? categoriesData[0].title : categoriesData[1].title;

  return `${colorLabel ? `${colorLabel} envelope` : 'Envelope'} (${category})`;
}

export const getMinImageSize = (element, page, maxPageInches) => {
  const pageHeightPt = page.folded ? page.height / 2 : page.height;
  const pageWidthPt = page.width;
  const { height: elementHeightPt, width: elementWidthPt } = element;

  // We don't have precise physical dimensions for our cards, so base the page's size in inches
  // on an approximation of the card's largest dimension in inches. This ensures that the page
  // dimensions in points and inches have the same aspect ratio.
  const pageRatio = pageWidthPt / pageHeightPt;
  const pageHeightInches = pageHeightPt > pageWidthPt ? maxPageInches : maxPageInches / pageRatio;
  const pageWidthInches = pageWidthPt > pageHeightPt ? maxPageInches : maxPageInches * pageRatio;

  let minHeightPx = (elementHeightPt / pageHeightPt) * pageHeightInches * MIN_DPI;
  let minWidthPx = (elementWidthPt / pageWidthPt) * pageWidthInches * MIN_DPI;

  // We set a "highest allowed minimum dimension" (even if lower than the min DPI) because users don't
  // always have large images. Most images (except "full-card" images) will still meet the min DPI.
  if (minHeightPx >= minWidthPx && minHeightPx > HIGHEST_ALLOWED_MIN_REQUIREMENT) {
    minWidthPx = (elementWidthPt * HIGHEST_ALLOWED_MIN_REQUIREMENT) / elementHeightPt;
    minHeightPx = HIGHEST_ALLOWED_MIN_REQUIREMENT;
  } else if (minWidthPx > minHeightPx && minWidthPx > HIGHEST_ALLOWED_MIN_REQUIREMENT) {
    minHeightPx = (elementHeightPt * HIGHEST_ALLOWED_MIN_REQUIREMENT) / elementWidthPt;
    minWidthPx = HIGHEST_ALLOWED_MIN_REQUIREMENT;
  }

  return {
    height: Math.round(minHeightPx),
    width: Math.round(minWidthPx),
  };
};
