import type { WCardSuiteSearchView, WCardSuiteSearchRequest } from '@zola/svc-web-api-ts-client';
import ApiService from '../../../../../../util/api';

function fetchCardCatalogFacetedSearch(
  req: WCardSuiteSearchRequest
): Promise<WCardSuiteSearchView> {
  return ApiService.post('/web-api/v1/card-catalog/search/faceted', req)
    .then((data): WCardSuiteSearchView => data)
    .catch(e => {
      throw new Error(e);
    });
}

export default fetchCardCatalogFacetedSearch;
