import * as ActionTypes from '../actions/types/CacheActionTypes';

const initialState = {};

const cacheReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CACHE_RESPONSE: {
      const { url, json } = action.payload;
      return Object.assign({}, state, { [url]: Object.assign({}, json) });
    }
    default:
      return state;
  }
};

export default cacheReducer;
