import {
  getLastDraftEditDate,
  getMappedDraftsByCardType,
  getMappedOrders,
} from 'cards/util/customization';
import _uniqBy from 'lodash/uniqBy';
import { ORDERED_CUSTOMIZATION_TYPES } from 'cards/constants/CardTypes';
import type {
  WProjectListingView,
  WProjectListingViewByType,
  WProjectOrderView,
  WProjectSampleProofOrderView,
} from '@zola/svc-web-api-ts-client';
import {
  Medium,
  CardType,
  ProjectListingView,
  ProjectListingCustomizationView,
} from 'cards/pages/ProductDetail/ProducDetailTypes';
import { ActionTypes, DraftsAndOrdersAction } from '../actions/draftsAndOrdersActions';

interface ProjectsData extends WProjectListingViewByType {
  waitingOnPreviewSuites: boolean | undefined;
  entities: {
    customizations: ProjectListingCustomizationView[];
    projects: WProjectOrderView[];
  };
}

type DraftsMap = Record<CardType, ProjectListingView[]>;

interface draftsAndOrdersState {
  activeDraftsTab: string;
  activeSegment: string | null;
  activeAnchor: string | null;
  busy: boolean;
  waitingOnPreviewSuites: boolean;
  drafts: Record<Exclude<Medium, 'MAGNET'>, DraftsMap>;
  orders: WProjectOrderView[];
  published: any[];
  entities: {
    customizations: ProjectListingCustomizationView[];
    projects: WProjectOrderView[];
  };
  proofDrafts: DraftsMap;
  proofOrders: WProjectSampleProofOrderView[];
  samples: WProjectSampleProofOrderView[];
}

const init: draftsAndOrdersState = {
  activeDraftsTab: 'all',
  activeSegment: null,
  activeAnchor: null,
  busy: false,
  waitingOnPreviewSuites: false,
  drafts: {
    PAPER: {
      INVITATION: [],
      RSVP: [],
      SAVE_THE_DATE: [],
      CHANGE_THE_DATE: [],
      ENCLOSURE: [],
      MENU: [],
      PROGRAM: [],
      PLACE: [],
      THANK_YOU: [],
      ENVELOPE: [],
      INVITATION_ENVELOPE: [],
      RSVP_ENVELOPE: [],
      HOLIDAY: [],
    },
    DIGITAL: {
      INVITATION: [],
      RSVP: [],
      SAVE_THE_DATE: [],
      CHANGE_THE_DATE: [],
      ENCLOSURE: [],
      MENU: [],
      PROGRAM: [],
      PLACE: [],
      THANK_YOU: [],
      ENVELOPE: [],
      INVITATION_ENVELOPE: [],
      RSVP_ENVELOPE: [],
      HOLIDAY: [],
    },
  },
  orders: [],
  published: [],
  entities: {
    customizations: [],
    projects: [],
  },
  proofDrafts: {
    INVITATION: [],
    RSVP: [],
    SAVE_THE_DATE: [],
    CHANGE_THE_DATE: [],
    ENCLOSURE: [],
    MENU: [],
    PROGRAM: [],
    PLACE: [],
    THANK_YOU: [],
    ENVELOPE: [],
    INVITATION_ENVELOPE: [],
    RSVP_ENVELOPE: [],
    HOLIDAY: [],
  },
  proofOrders: [],
  samples: [],
};

const reducer = (
  state: draftsAndOrdersState = init,
  action: DraftsAndOrdersAction
): draftsAndOrdersState => {
  switch (action.type) {
    case ActionTypes.DELETE_DRAFT: {
      const { projectUUID, medium, cardType } = action.payload as {
        projectUUID: string;
        medium: string;
        cardType: CardType;
      };
      const { drafts } = state;
      const localMedium = medium === 'PAPER' || medium === 'MAGNET' ? 'PAPER' : 'DIGITAL';

      let newDrafts;

      const draftToDelete = drafts[localMedium][cardType].find(
        ({ project_uuid }) => project_uuid === projectUUID
      );

      if (draftToDelete?.version_history && draftToDelete.version_history.length > 0) {
        const { version_history: versionHistory } = draftToDelete;
        const newCardTypeDrafts = drafts[localMedium][cardType]
          .concat(versionHistory)
          .filter(({ project_uuid }) => project_uuid !== projectUUID)
          // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must...
          .sort((a, b) => getLastDraftEditDate(b) - getLastDraftEditDate(a));

        // @ts-expect-error ts-migrate(7034) FIXME: Variable 'mappedNewCardTypeDrafts' implicitly has ...
        const mappedNewCardTypeDrafts = [];

        newCardTypeDrafts.forEach(draft => {
          // @ts-expect-error ts-migrate(7005) FIXME: Variable 'mappedNewCardTypeDrafts' implicitly has ...
          const existingDraft = mappedNewCardTypeDrafts.find(
            ({ project_name }: { project_name: string }) => project_name === draft.project_name
          );

          if (!existingDraft) {
            mappedNewCardTypeDrafts.push(draft);
          } else {
            existingDraft.version_history.push(draft);
          }
        });

        // @ts-expect-error ts-migrate(7005) FIXME: Variable 'mappedNewCardTypeDrafts' implicitly has ...
        newDrafts = mappedNewCardTypeDrafts;
      } else {
        newDrafts = drafts[localMedium][cardType].filter(
          ({ project_uuid }) => project_uuid !== projectUUID
        );
      }

      return {
        ...state,
        drafts: {
          ...state.drafts,
          [localMedium]: {
            ...state.drafts[localMedium],
            [cardType]: newDrafts,
          },
        },
      };
    }
    case ActionTypes.DELETE_PUBLISHED: {
      const { projectUUID } = action.payload;
      const { published } = state;

      const updatedPublished = published.filter(({ project_uuid }) => project_uuid !== projectUUID);

      return {
        ...state,
        published: updatedPublished,
      };
    }
    case ActionTypes.MOVE_PUBLISHED_TO_DRAFTS: {
      const { projectUUID } = action.payload;
      const { drafts, published } = state;

      const project = published.find(({ project_uuid }) => project_uuid === projectUUID);
      const updatedPublished = published.filter(({ project_uuid }) => project_uuid !== projectUUID);
      const updatedDrafts = [...drafts.DIGITAL.SAVE_THE_DATE, project];

      return {
        ...state,
        drafts: {
          ...state.drafts,
          ...updatedDrafts,
        },
        published: updatedPublished,
      };
    }
    case ActionTypes.REQUEST_PROJECTS:
      return {
        ...state,
        busy: true,
        waitingOnPreviewSuites: true,
      };
    case ActionTypes.RECEIVE_PROJECTS: {
      const {
        waitingOnPreviewSuites,
        drafts,
        orders,
        published,
        entities,
        proof_drafts: proofDrafts,
        proof_orders: proofOrders = [],
        samples = [],
      } = action.payload.projects as ProjectsData;
      const { PAPER = {}, MAGNET = {}, DIGITAL = {} } = drafts || {};

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      const allPhysicalKeys = _uniqBy([...Object.keys(PAPER), ...Object.keys(MAGNET)]);

      const allPhysicalDrafts = allPhysicalKeys.reduce((result, currentValue) => {
        let newContent: WProjectListingView[] = [];
        if (PAPER[currentValue] && Array.isArray(PAPER[currentValue])) {
          newContent = [...PAPER[currentValue]];
        }
        if (MAGNET[currentValue] && Array.isArray(MAGNET[currentValue])) {
          newContent = [...newContent, ...MAGNET[currentValue]];
        }
        // eslint-disable-next-line no-param-reassign
        result[currentValue] = newContent;
        return result;
      }, {} as Record<string, WProjectListingView[]>);

      const mappedPaperDrafts = PAPER && getMappedDraftsByCardType(allPhysicalDrafts);
      const mappedDigitalDrafts = DIGITAL && getMappedDraftsByCardType(DIGITAL);
      const mappedOrders =
        orders?.sort(
          (a, b) =>
            new Date(b.created_at as Date).getTime() - new Date(a.created_at as Date).getTime()
        ) || [];
      const mappedPublished = getMappedOrders(published);

      return {
        ...state,
        busy: false,
        waitingOnPreviewSuites: Boolean(waitingOnPreviewSuites),
        drafts: {
          ...state.drafts,
          PAPER: {
            ...state.drafts.PAPER,
            ...mappedPaperDrafts,
          },
          DIGITAL: {
            ...state.drafts.DIGITAL,
            ...mappedDigitalDrafts,
          },
        },
        orders: mappedOrders,
        published: mappedPublished,
        entities,
        proofDrafts: proofDrafts?.PAPER as DraftsMap,
        proofOrders,
        samples,
      };
    }
    case ActionTypes.RECEIVE_CANCELLED_ORDER: {
      const { cancelledOrder } = action.payload;
      const { orders } = state;

      const updatedOrders = orders.map(order =>
        cancelledOrder.split_order_id === order.split_order_id ? cancelledOrder : order
      );

      return {
        ...state,
        orders: updatedOrders,
      };
    }
    case ActionTypes.SET_ACTIVE_DRAFTS_TAB: {
      const { key } = action.payload;

      return {
        ...state,
        activeDraftsTab: ORDERED_CUSTOMIZATION_TYPES.includes(key) ? key : 'all',
      };
    }
    case ActionTypes.SET_ACTIVE_SEGMENT: {
      const { key } = action.payload;

      return {
        ...state,
        activeSegment: key,
      };
    }
    case ActionTypes.SET_ACTIVE_ANCHOR: {
      const { anchor } = action.payload;

      return {
        ...state,
        activeAnchor: anchor,
      };
    }
    case ActionTypes.TOGGLE_WAITING_ON_PREVIEW_SUITES: {
      const { waitingOnPreviewSuites } = action.payload;

      return {
        ...state,
        waitingOnPreviewSuites,
      };
    }
    default:
      return state;
  }
};

export default reducer;
