export const ActionTypes = {
  PUSH_CHANGE: 'zola/cards/form_history/PUSH_CHANGE',
  POP_CHANGE: 'zola/cards/form_history/POP_CHANGE',
  CLEAR_HISTORY: 'zola/cards/form_history/CLEAR_HISTORY',
  UPDATE_HISTORY_ELEMENT: 'zola/card/card_project/UPDATE_FORM_HISTORY_ELEMENT',
};

export const pushChange = changeSet => ({
  type: ActionTypes.PUSH_CHANGE,
  payload: changeSet,
});

const popChange = () => ({
  type: ActionTypes.POP_CHANGE,
});

const updateHistory = (oldElementUUID, newElementUUID) => ({
  type: ActionTypes.UPDATE_HISTORY_ELEMENT,
  payload: {
    oldElementUUID,
    newElementUUID,
  },
});

// return a promise to avoid race condition when
// skipping undos on a deleted element
export const popChangeAsync = () => dispatch =>
  new Promise(resolve => {
    dispatch(popChange());
    resolve();
  });

export const clearHistory = () => ({
  type: ActionTypes.CLEAR_HISTORY,
});

export const updateFormHistory = (oldElementUUID, newElementUUID) => dispatch =>
  new Promise(resolve => {
    dispatch(updateHistory(oldElementUUID, newElementUUID));
    resolve();
  });
