import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from '@zola/zola-ui/src/components/Link';
import { useDispatch } from 'react-redux';
import { openModal } from 'cards/actions/modalActions';
import DetailColorPicker from 'paper/components/DetailColorPicker/DetailColorPicker';
import { InfoIcon } from '@zola/zola-ui/src/components/SvgIcons/Info';
import OptionList from '../OptionList/OptionList';
import { CARD_TYPES } from '../../../constants/CardTypes';
import featureFlags from '../../../../util/featureFlags';

import styles from './styles.module.less';

const STAMPED_FOIL_TOOLTIP =
  'Foil stamping uses a heated dye to transfer foil onto paper so it permanently adheres to it.';
const CUSTOM_FOIL_TOOLTIP = 'Customize the text on your cards with gorgeous custom foil.';

const OptionSection = ({
  title,
  options,
  selection,
  setOptionValue,
  currentLabel,
  currentDimension,
  sizePricing,
  styleOverrides,
  cardType,
  optionType,
  isMagnet,
  isLinkHidden,
  showLinkOnMobile,
  titleLink,
  titleLinkAction,
  hoverDisabled,
  tooltipMessage,
  isStampFoilCard = false,
  condensed = false,
}) => {
  const dispatch = useDispatch();
  const [isUnderExtraCustomizationTest] = useState(featureFlags.get('cardsExtraCustomization'));
  const isMenuCard = cardType === CARD_TYPES.menu;
  const priceDelta = useMemo(() => {
    let result;
    if (sizePricing && optionType && selection.value) {
      if (optionType === 'size') {
        result = sizePricing.delta;
      } else {
        const optionPricing = sizePricing[optionType];
        result = optionPricing && optionPricing[selection.value];
      }
    }
    return result || 0;
  }, [sizePricing, optionType, selection.value]);

  let buttonVariation;
  if (condensed) {
    buttonVariation =
      (optionType === 'silhouette' && 'iconOnly') ||
      (['foil-color', 'color'].includes(optionType) && 'iconOnlyRound') ||
      'pill';
  }

  return (
    <div className={cx({ [styles.condensed]: condensed })}>
      <div
        className={cx(
          styles.optionSectionTitleArea,
          styleOverrides && styleOverrides.optionSectionTitleArea
        )}
      >
        <span>
          <span
            className={cx(
              styles.optionSectionTitle,
              styleOverrides && styleOverrides.optionSectionTitle
            )}
          >
            {title}:
          </span>
          <span className={styles.optionSectionSubtitle}>{currentLabel}</span>
          {optionType === 'foil-color' && (
            <span
              className={styles.informationTooltipFoilCoilors}
              data-tooltip={isStampFoilCard ? STAMPED_FOIL_TOOLTIP : CUSTOM_FOIL_TOOLTIP}
            >
              <InfoIcon title="" />
            </span>
          )}
          {!isMenuCard && currentDimension && optionType !== 'type' ? (
            <span className={styles.optionSectionDimensions}>({currentDimension})</span>
          ) : null}
          {priceDelta && !isMagnet ? (
            <span className={styles.optionSectionPrice}>
              + ${(priceDelta / 100).toFixed(2).toString()} Ea.
            </span>
          ) : null}
        </span>
        {optionType === 'paper-type' && !isLinkHidden && (
          <span className="hidden-xs">
            <Link
              arrow
              role="button"
              className={styles.optionSectionLink}
              onClick={titleLinkAction}
            >
              {titleLink}
            </Link>
          </span>
        )}
      </div>
      <div
        className={cx(
          styles.optionSectionToggleContainer,
          styleOverrides && styleOverrides.optionSectionToggleContainer
        )}
      >
        {optionType === 'color' && !condensed ? (
          <DetailColorPicker
            colors={options}
            activeColor={selection.value}
            onSelect={setOptionValue}
          />
        ) : (
          <OptionList
            options={options}
            currentSelection={selection}
            onToggle={setOptionValue}
            optionType={optionType}
            isMagnet={isMagnet}
            isLinkHidden={isLinkHidden}
            styleOverrides={styleOverrides}
            titleLink={titleLink}
            titleLinkAction={titleLinkAction}
            cardType={cardType}
            hoverDisabled={hoverDisabled}
            tooltipMessage={tooltipMessage}
            variation={buttonVariation}
          />
        )}
        {optionType === 'paper-type' && showLinkOnMobile && (
          <div className={styles.mobileLink}>
            <Link
              arrow
              role="button"
              className={styles.optionSectionLink}
              onClick={titleLinkAction}
            >
              {titleLink}
            </Link>
          </div>
        )}
        {isUnderExtraCustomizationTest &&
          optionType === 'color' &&
          selection.metadata?.['extra-customizable'] && (
            <div className={styles.extraCustomizationDescription}>
              <span aria-hidden>🎨</span>
              <span>&nbsp;This design features extra customizable colors and fonts</span>
              <Link
                arrow
                role="button"
                className={styles.learnMore}
                onClick={() => dispatch(openModal('EXTRA_CUSTOMIZATION_COLORS'))}
              >
                Learn More
              </Link>
            </div>
          )}
      </div>
    </div>
  );
};

OptionSection.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      subTitle: PropTypes.string,
    })
  ),
  selection: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  setOptionValue: PropTypes.func.isRequired,
  currentLabel: PropTypes.string,
  currentDimension: PropTypes.string,
  sizePricing: PropTypes.shape({}),
  optionType: PropTypes.string,
  isMagnet: PropTypes.bool,
  isLinkHidden: PropTypes.bool,
  titleLink: PropTypes.string,
  titleLinkAction: PropTypes.func,
  tooltipMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  condensed: PropTypes.bool,
};

export default OptionSection;
