import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getUserHasWebsite } from 'selectors/user/userSelectors';
import { CARD_TYPES } from 'cards/constants/CardTypes';
import type {
  WCardSuiteSearchView,
  WCardSuiteSummaryView,
  WCardSuiteSearchRequest,
} from '@zola/svc-web-api-ts-client';
import fetchCardCatalogFacetedSearch from 'cards/components/common/Samples/util/api/fetchCardCatalogFacetedSearch';
import { usePrevious } from '@zola/zola-ui/src/hooks/usePrevious';
import { CardSuiteInfoSummary } from '../types';
import { mapCardSuiteToCardInfoSummary } from '../mappers';

const useMostPopularSamplesFromFamily = (
  familyName?: string,
  sampleCardType?: string,
  cardSuiteFilterFn?: (suites: WCardSuiteSummaryView[]) => WCardSuiteSummaryView,
  fetchNextMostPopularIfNone?: boolean
): { cardInfo: CardSuiteInfoSummary | undefined; fetchedNextMostPopular: boolean } => {
  const [cardInfo, setCardInfo] = useState<CardSuiteInfoSummary | undefined>();
  const [fetchedNextMostPopular, setFetchedNextMostPopular] = useState<boolean>(false);
  const previousFamilyName = usePrevious(familyName);
  const hasWebsite = useSelector(getUserHasWebsite);

  const fetchAndSetCardInfo = useCallback(
    request => {
      fetchCardCatalogFacetedSearch(request)
        .then((res: WCardSuiteSearchView) => {
          const { suites } = res;
          // Default chosen is first/highest ranked
          let chosenSuite: WCardSuiteSummaryView = suites?.[0] || {};
          if (suites && suites.length > 0 && cardSuiteFilterFn) {
            chosenSuite = cardSuiteFilterFn(suites);
          }
          const newCardInfo = mapCardSuiteToCardInfoSummary(chosenSuite);

          // Fetch next popular if there is no matching card from the first fetch, we haven't already done so & hook requires it
          const fetchNextPopular =
            !newCardInfo?.uuid && !fetchedNextMostPopular && fetchNextMostPopularIfNone;
          if (fetchNextPopular) {
            const nextPopularReq = {
              ...request,
              family: '',
            };
            setFetchedNextMostPopular(true);
            fetchAndSetCardInfo(nextPopularReq);
            return;
          }

          setCardInfo(newCardInfo);
        })
        .catch(e => {
          throw new Error(e);
        });
    },
    [cardSuiteFilterFn, fetchNextMostPopularIfNone, fetchedNextMostPopular]
  );

  useEffect(() => {
    if (previousFamilyName !== familyName) {
      // Fetch most popular if user hasn't started website account (since Abbey is auto-assigned) & hook requires us to fetch next
      const fetchMostPopular = !hasWebsite && !!fetchNextMostPopularIfNone;
      const isDigital = sampleCardType === CARD_TYPES.dstd;
      const isSingleSampleAvailable =
        sampleCardType === CARD_TYPES.invitation || sampleCardType === CARD_TYPES.std;

      const req: WCardSuiteSearchRequest = {
        offset: 0,
        limit: 12,
        active_only: true,
        has_custom_photo: undefined,
        family: fetchMostPopular ? '' : familyName,
        lead_card_type: (isDigital
          ? CARD_TYPES.std
          : (sampleCardType as unknown)) as WCardSuiteSearchRequest.LeadCardTypeEnum,
        single_sample_available: isSingleSampleAvailable,
        digital_suite: isDigital, // DSTD requires lead_card_type to be STD and digital_suite to be true
      };

      setFetchedNextMostPopular(fetchMostPopular);
      fetchAndSetCardInfo(req);
    }
  }, [
    cardInfo,
    cardSuiteFilterFn,
    familyName,
    fetchAndSetCardInfo,
    fetchNextMostPopularIfNone,
    fetchedNextMostPopular,
    hasWebsite,
    previousFamilyName,
    sampleCardType,
  ]);

  return { cardInfo, fetchedNextMostPopular };
};

export default useMostPopularSamplesFromFamily;
