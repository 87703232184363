import _some from 'lodash/some';
import _find from 'lodash/find';
import _assign from 'lodash/assign';
import _isEqual from 'lodash/isEqual';
import { ActionTypes as TrackingActionTypes } from '../actions/tracking';

const initialState = {
  customization: [],
};

// Small helper to map steps on a given event
const stepMetaMapper = (properties, eventMeta) => {
  const { step_name: stepName } = properties;
  if (stepName) {
    const { steps } = eventMeta;
    if (steps && !steps.includes(stepName)) {
      steps.push(stepName);
    } else if (!steps) {
      _assign(eventMeta, { steps: [stepName] });
    }
  }
  return eventMeta;
};

const trackedEvents = (state = initialState, action) => {
  switch (action.type) {
    case TrackingActionTypes.TRACK_CARD_EVENT: {
      const { name, properties } = action.payload;
      const { card_project_customization_id: projectUUID } = properties;
      const { customization } = state;
      let eventProps = [];
      let eventMeta = { name };

      /**
       * This checks for events already tracked for a specific project
       * if the project has tracked events it checks if the event can be added
       * To be added, an event has to be absent from the events array.
       * If the event has been triggered, we check if the new payload has a step_name property
       * if it does, we add it to the tracked steps array if is not in the array
       */
      if (customization[projectUUID]) {
        const events = customization[projectUUID];

        if (events.length > 0) {
          const storedEvent = _find(events, { name });

          // if it was fired we check it's properties
          if (storedEvent) {
            eventMeta = stepMetaMapper(properties, storedEvent);
            if (!_isEqual(storedEvent, eventMeta)) {
              eventProps.push(_assign(storedEvent, eventMeta));
            } else {
              eventProps = events;
            }
          } else {
            events.push(stepMetaMapper(properties, eventMeta));
            eventProps = events;
          }
        }
      } else if (!customization[projectUUID]) {
        const { name: metaName } = eventMeta;
        if (properties.step_name) {
          _assign(eventMeta, { steps: [properties.step_name] });
        }
        if (!_some(eventProps, { name: metaName })) {
          eventProps.push(eventMeta);
        }
      }

      return {
        ...state,
        customization: {
          ...state.customization,
          [projectUUID]: eventProps,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default trackedEvents;
