export const SHOW_MODAL = 'zola/modal/SHOW_MODAL';
export const HIDE_MODAL = 'zola/modal/HIDE_MODAL';
export const FLIP_FORMAT_FLAG = 'zola/modal/FLIP_FORMAT_FLAG';
export const IS_BUSY = 'zola/modal/IS_BUSY';
export const SHOW_SECONDARY_MODAL = 'zola/modal/SHOW_SECONDARY_MODAL';
export const HIDE_SECONDARY_MODAL = 'zola/modal/HIDE_SECONDARY_MODAL';

type ModalPayloadType = {
  modalType: string;
  modalProps?: {
    authState?: string;
    disableClose?: boolean;
    formIdentifier?: string;
    hideClose?: boolean;
    onHideModal?: () => void;
  };
  modalOptions?: {
    size?: string;
    styleOverride?: React.CSSProperties;
  };
};

type ShowModalType = {
  type: typeof SHOW_MODAL;
  payload: ModalPayloadType;
};

type HideModalType = {
  type: typeof HIDE_MODAL;
};

type ShowSecondaryModalType = {
  type: typeof SHOW_SECONDARY_MODAL;
  payload: ModalPayloadType;
};

type HideSecondaryModalType = {
  type: typeof HIDE_SECONDARY_MODAL;
};

export type ModalActionTypes =
  | ShowModalType
  | HideModalType
  | ShowSecondaryModalType
  | HideSecondaryModalType;
