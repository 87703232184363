import { normalize } from 'normalizr';
import moment from 'moment';
import { ORDERED_CUSTOMIZATION_TYPES } from 'cards/constants/CardTypes';
import ApiService from '../../util/api';

import { orderSchema, orderListSchema } from '../schemas';

export const ActionTypes = {
  REQUEST_ORDERS: 'zola/card/card_project/REQUEST_ORDERS',
  RECEIVE_ORDERS: 'zola/card/card_project/RECEIVE_ORDERS',
  RECEIVE_CANCELED_ORDER: 'zola/card/card_project/RECEIVE_CANCELED_ORDER',
};

function requestOrders() {
  return {
    type: ActionTypes.REQUEST_ORDERS,
  };
}

function receiveOrders(normalizedOrders) {
  return {
    type: ActionTypes.RECEIVE_ORDERS,
    payload: {
      normalizedOrders,
    },
  };
}

function receiveCanceledOrder(canceledOrder) {
  return {
    type: ActionTypes.RECEIVE_CANCELED_ORDER,
    payload: {
      normalizedOrder: canceledOrder,
    },
  };
}

export function fetchOrders() {
  return dispatch => {
    dispatch(requestOrders());
    return ApiService.get('/web-api/v2/card-project/projects/orders').then(json => {
      const mappedOrders = json
        .map(order => ({
          ...order,
          projects: order.projects.map(project => ({
            ...project,
            customizations: project.customizations.sort(
              (a, b) =>
                ORDERED_CUSTOMIZATION_TYPES.indexOf(a.type) -
                ORDERED_CUSTOMIZATION_TYPES.indexOf(b.type)
            ),
          })),
          // if digital order, use project_uuid (also the key used to cancel the order)
          split_order_id:
            order.split_order_id !== null ? order.split_order_id : order.projects[0].project_uuid,
          has_split_order_id: order.split_order_id !== null,
        }))
        .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf());
      const normalizedOrders = normalize(mappedOrders, orderListSchema);
      return dispatch(receiveOrders(normalizedOrders));
    });
  };
}

export function cancelOrder(orderUUID) {
  return dispatch =>
    ApiService.post(`/web-api/v2/card-project/orders/${orderUUID}/cancel`).then(json => {
      const normalizedOrder = normalize(json, orderSchema);
      return dispatch(receiveCanceledOrder(normalizedOrder));
    });
}
