import { createSelector } from 'reselect';

const getUploadcareState = state => state.uploadcare;

export const getCachedUploadcareFiles = createSelector(
  getUploadcareState,
  uploadcareState => uploadcareState.uploadcareFiles
);

export const getCachedMetadata = createSelector(
  getUploadcareState,
  uploadcareState => uploadcareState.zolaMetadata
);
