import _last from 'lodash/last';
import * as ActionTypes from '../actions/types/FeedActionTypes';

const initialState = {
  busy: false,
  results: [],
  unread: 0,
  unseen: 0,
  lastActivityId: null,
  hasMore: true,
};

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_FEED: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_FEED: {
      const { results, unread, unseen, hasMore } = action.payload;

      const updatedResults = state.results.concat(results);

      const lastActivity = _last(results);
      const oldestActivityId = (lastActivity && lastActivity.id) || null;

      return Object.assign(
        {},
        state,
        { results: updatedResults },
        unread,
        unseen,
        { hasMore: hasMore && true },
        { lastActivityId: oldestActivityId },
        { busy: false }
      );
    }
    case ActionTypes.RESET_FEED: {
      return initialState;
    }
    default:
      return state;
  }
};

export default feedReducer;
