import UploadClient from '@uploadcare/upload-client';
import _get from 'lodash/get';
import ApiService from '../../../../util/api';
import { uploadcareKey } from '../constants';

const uploadcareClient = new UploadClient({ publicKey: uploadcareKey });

const FORMAT_TO_MIME_TYPE = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  GIF: 'image/gif',
  SVG: 'image/svg+xml',
  TIFF: 'image/tiff',
  WEBP: 'image/webp',
  BMP: 'image/bmp',
};
const getMimeTypeFromFormat = uploadcareFile =>
  FORMAT_TO_MIME_TYPE[_get(uploadcareFile, 'imageInfo.format')];

const MAX_COPY_ATTEMPTS = 2;

// TODO: The retry here is a temporary fix until svc-image implements it. Remove it here after.
export const copyUploadcareFileToZola = (uploadcareFile, preserveInUC, attemptNb = 0) => {
  return ApiService.post('/web-api/v1/image/uploadcare-save', {
    url: uploadcareFile.cdnUrl,
    contentType: uploadcareFile.mimeType || getMimeTypeFromFormat(uploadcareFile),
    preserveInUC,
  }).then(json => {
    const data = json && json.data;

    if (!data && attemptNb < MAX_COPY_ATTEMPTS) {
      return copyUploadcareFileToZola(uploadcareFile, preserveInUC, attemptNb + 1);
    }

    return data;
  });
};

export const copyUploadcareFileToZolaLite = (uploadcareUuids, preserveInUC = true) => {
  return ApiService.post('/web-api/v1/image/uploadcare-save-lite', {
    uploadcareUuids,
    preserveInUC,
  })
    .then(json => {
      const data = json && json.data;
      return data;
    })
    .catch(() => {});
};

export const fetchJobForCopyUploadcareFileToZolaLite = ({ jobUuid }) => {
  return ApiService.get(`/web-api/v1/image/uploadcare-save-lite/job/${jobUuid}`)
    .then(json => {
      const data = json && json.data;
      return data;
    })
    .catch(() => {});
};

export const copyUploadcareFileGroupToZola = (fileGroupId, preserveInUC = true) => {
  return ApiService.post('/web-api/v1/image/uploadcare-group-save', {
    groupId: fileGroupId,
    preserveInUC,
  }).then(json => json.data);
};

export const fetchImageMetadata = zolaImageId => {
  return ApiService.get(`/web-api/v1/image/metadata/${zolaImageId}`).then(json => json.data);
};

export const fetchBatchImageMetadata = imageUUIDs => {
  return ApiService.post(`/web-api/v1/image/metadata/list`, { imageUUIDs }).then(json => json.data);
};

export const fetchOriginalMetadata = uploadcareImageId => {
  return ApiService.get(`/web-api/v1/image/uploadcare-original/${uploadcareImageId}`)
    .then(json => json.data)
    .catch(() => ({})); // Return empty data if there is an error fetching the original
};

// Uploadcare allows us to fetch the original file data, but doesn't return modifier info,
// We must therefore manually add modiifiers to the returned uploadcare file.
export const fetchUploadcareFile = cdnUrl => {
  const urlMatches = cdnUrl.match(/ucarecdn\.com\/([^/]+)\/(-.+)?/);
  const cropMatches = cdnUrl.match(/-\/crop\/(\d+)x(\d+)\/(\d+),(\d+)/);

  const id = urlMatches[1];
  const cdnUrlModifiers = urlMatches[2];
  const crop = cropMatches
    ? {
        width: cropMatches[1],
        height: cropMatches[2],
        x: cropMatches[3],
        y: cropMatches[4],
      }
    : null;

  // Doesn't actually reupload: Simply returns existing upload's data.
  return uploadcareClient.uploadFile(id).then(uploadcareFile => {
    return {
      ...uploadcareFile,
      cdnUrl,
      cdnUrlModifiers,
      crop,
      originalImageInfo: uploadcareFile.imageInfo,
    };
  });
};
