export const REQUEST_GUEST = 'zola/guest/request_guest';
export const RECEIVE_GUEST = 'zola/guest/receive_guest';
export const REQUEST_GUESTS = 'zola/guest/request_guests';
export const RECEIVE_GUESTS = 'zola/guest/receive_guests';
export const CREATE_GUEST = 'zola/guest/create_guest';
export const GUEST_CREATED = 'zola/guest/guest_created';
export const UPDATE_GUEST_GROUP = 'zola/guest/group/update_guest_group';
export const TOGGLE_INVITED = 'zola/guest/group/toggle_invited';
export const TOGGLE_INVITATION_SENT = 'zola/guest/group/toggle_invitation_sent';
export const TOGGLE_SAVE_THE_DATE_SENT = 'zola/guest/group/toggle_save_the_date_sent';
export const TOGGLE_FACET_VALUE = 'zola/guest/group/toggle_facet_value';
export const SEND_MESSAGE_CUSTOM = 'zola/guest/group/SEND_MESSAGE_CUSTOM';
export const SEND_MESSAGE_COLLECT_ADDRESS = 'zola/guest/group/SEND_MESSAGE_COLLECT_ADDRESS';
export const SELECT_GUESTS = 'zola/guest/group/select_guests';
export const UNSELECT_GUESTS = 'zola/guest/group/unselect_guests';
export const CHANGE_SEATED_GUESTS = 'zola/guest/group/seat_guests';
export const EXPAND_GUEST_GROUP = 'zola/guest/group/expand_guest_group';
export const COLLAPSE_GUEST_GROUP = 'zola/guest/group/expand_guest_group';
export const TOGGLE_SORT_BY_NAME_ASC = 'zola/guest/group/TOGGLE_SORT_BY_NAME_ASC';
export const RESET_FACET_VALUES = 'zola/guest/group/RESET_FACET_VALUES';
export const REINITIALIZE = 'zola/guest/group/REINITIALIZE';
export const EVENT_TAB_SELECTED = 'zola/guest/group/event_tab_selected';
export const UPDATE_ALT_ACTIVE = 'zola/guest/group/UPDATE_ALT_ACTIVE';
export const UPDATE_SORTING = 'zola/guest/group/UPDATE_SORTING';

// CSV upload actions: (guestList/import.js)
export const RECEIVE_CSV_FIELDS = 'zola/guest_import/receive_csv_fields';
export const RECEIVE_UPLOAD_ERRORS = 'zola/guest_import/receive_upload_errors';
export const CLEAR_UPLOAD_ERRORS = 'zola/guest_import/clear_upload_errors';
export const SEND_FIELD_MAP = 'zola/guest_import/send_field_map';
export const RECEIVE_MAPPING_ERRORS = 'zola/guest_import/receive_mapping_errors';
export const CLEAR_MAPPING_ERRORS = 'zola/guest_import/clear_mapping_errors';
export const SET_BUSY = 'zola/guest_import/set_busy';
export const UPLOAD_COMPLETE = 'zola/guest_import/upload_complete';
export const MAPPING_STARTED = 'zola/guest_import/mapping_started';
export const MAP_COLUMNS = 'zola/guest_import/map_columns';
export const UNMAP_COLUMNS = 'zola/guest_import/unmap_columns';
export const ADD_EXTRA_FIELD = 'zola/guest_import/add_extra_field';
export const MAPPING_COMPLETE = 'zola/guest_import/mapping_complete';
export const RESET_MAPPING = 'zola/guest_import/reset_mapping';
