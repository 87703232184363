/**
 * @prettier
 */

import { createSelector } from 'reselect';

export const getPricing = state => state.cards.pricing;
const getCardType = (state, props) => props.cardType;

export const getPricingForCardType = createSelector(
  getPricing,
  getCardType,
  (pricing, cardType) => pricing[cardType]
);
