import type { ProjectCustomizationElementViewResponse } from '@zola/svc-web-api-ts-client';
import type { QRCodeState, PageQRCodeData } from 'cards/reducers/qrCode';
import type { AppThunk } from 'reducers/index';
import { getProjectUUID } from 'cards/selectors/cardProjectSelector';
import { getQRCodeState } from 'cards/selectors/qrCode';
import { fetchProject } from 'cards/actions/projectActions';
import ApiService from '../../util/api';

export const UPDATE_PAGE_QR_CODE = 'UPDATE_PAGE_QR_CODE';
export interface UpdatePageQRCodeAction {
  type: typeof UPDATE_PAGE_QR_CODE;
  payload: {
    pageUUID: string;
    data: Partial<PageQRCodeData>;
  };
}

export const UPDATE_QR_CODE_STATE = 'UPDATE_QR_CODE_STATE';
export interface UpdateQRCodeStateAction {
  type: typeof UPDATE_QR_CODE_STATE;
  payload: Partial<QRCodeState>;
}

const WATCHED_KEYS: (keyof PageQRCodeData)[] = ['enabled', 'type', 'qrCodeURL', 'color'];

export function updatePageQRCode(
  pageUUID: string,
  payload: Partial<PageQRCodeData>
): UpdatePageQRCodeAction {
  const watchedKeyChanged = Object.keys(payload).some(k =>
    WATCHED_KEYS.includes(k as keyof PageQRCodeData)
  );

  return {
    type: UPDATE_PAGE_QR_CODE,
    payload: {
      pageUUID,
      data: {
        ...(watchedKeyChanged ? { generated: false, reviewed: false } : {}),
        ...payload,
      },
    },
  };
}

export function updateQRCodeState(payload: Partial<QRCodeState>): UpdateQRCodeStateAction {
  return {
    type: UPDATE_QR_CODE_STATE,
    payload,
  };
}

export function generatePageQRCode(
  pageUUID: string
): AppThunk<Promise<ProjectCustomizationElementViewResponse | null>> {
  return (dispatch, getState) => {
    const projectUUID = getProjectUUID(getState());
    const qrCodeState = getQRCodeState(getState());
    if (!projectUUID || !pageUUID || !qrCodeState) return Promise.resolve(null);

    const pageQRData = qrCodeState.byPageUUID[pageUUID];
    const isInvalidCustomURL =
      pageQRData.type === 'CUSTOM' && (!pageQRData.verified || !pageQRData.qrCodeURL);
    if (qrCodeState.loading || isInvalidCustomURL) return Promise.resolve(null);

    dispatch(updateQRCodeState({ loading: true }));
    return ApiService.put(`/web-api/v2/card-project/customization/page/${pageUUID}/qrCode`, {
      enabled: pageQRData.enabled,
      dimension: 'MEDIUM',
      url_type: pageQRData.type,
      color: pageQRData.color,
      ...(pageQRData.type === 'CUSTOM' ? { url: pageQRData.qrCodeURL } : {}),
    })
      .then(result => {
        return dispatch(fetchProject(projectUUID)).then(() => result);
      })
      .then(result => {
        dispatch(updateQRCodeState({ loading: false }));
        return result?.data || null;
      });
  };
}

export function togglePageQRCode(
  enabled: boolean,
  pageUUID: string
): AppThunk<Promise<ProjectCustomizationElementViewResponse | null>> {
  return dispatch => {
    if (!pageUUID) return Promise.resolve(null);
    dispatch(updatePageQRCode(pageUUID, { enabled }));

    if (!enabled) {
      return dispatch(generatePageQRCode(pageUUID));
    }

    return Promise.resolve(null);
  };
}

export function disableQRCodeIfEmpty(pageUUID: string): AppThunk<void> {
  return (dispatch, getState) => {
    const qrCodeState = getQRCodeState(getState());
    const pageQRData = qrCodeState?.byPageUUID[pageUUID];
    const shouldDisable = !!pageQRData?.enabled && pageQRData?.qrElement === null;

    if (shouldDisable) {
      dispatch(updatePageQRCode(pageUUID, { enabled: false }));
    }
  };
}
