import { createSelector } from 'reselect';
import type { RootState } from 'reducers/index';
import type { QRCodeState } from 'cards/reducers/qrCode';
import { getPageUUID } from 'cards/selectors/cardProjectSelector';

export const getQRCodeState = (state: RootState): QRCodeState | null =>
  state?.cards?.qrCode || null;

// Requires the presence of router props in the component calling the selector
export const getPageQRCodeData = createSelector(
  getPageUUID,
  getQRCodeState,
  (pageUUID, qrCodeState) => qrCodeState?.byPageUUID[pageUUID] || null
);
