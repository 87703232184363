import { CARD_TYPES } from 'cards/components/CardListing/types';
import type { FilterCardType } from 'favorites/types';

export const DEFAULT_PARTNER = {
  firstName: 'Someone',
  lastName: 'Special',
};

export const ACCOUNT_ERROR_MESSAGES = {
  invalidEmail: 'Please enter a correct email address',
  inUseEmail: 'The provided email is in use.',
  invalidPassword: 'Your password must be at least 8 characters long',
  emptyWeddingDetails: 'Please enter your first and last name',
  emptyPartnerWeddingDetails: "Please enter your partner's first and last name",
  invalidWeddingDate: 'Please make sure your wedding date is valid',
  invalidWeddingDetails: 'Please make sure your wedding details are valid.',
};

type Filter = {
  type: FilterCardType;
  label: string;
};

export const FILTER_TYPE_ALL = 'all';

export const CARD_TYPE_FILTERS: Array<Filter> = [
  { type: FILTER_TYPE_ALL, label: 'All' },
  { type: CARD_TYPES.std, label: 'Save the Dates' },
  { type: CARD_TYPES.dstd, label: 'Digital Save the Dates' },
  { type: CARD_TYPES.invitation, label: 'Invitations' },
  { type: CARD_TYPES.ctd, label: 'Change the Dates' },
  { type: CARD_TYPES.enclosure, label: 'Enclosure Cards' },
  { type: CARD_TYPES.menu, label: 'Menus' },
  { type: CARD_TYPES.program, label: 'Programs' },
  { type: CARD_TYPES.place, label: 'Place Cards' },
  { type: CARD_TYPES.thankyou, label: 'Thank You Cards' },
  { type: CARD_TYPES.holiday, label: 'Holiday Cards' },
];

export interface Data {
  name: string;
  url: string;
}

export const CARD_TYPE_DATA: { [key: string]: Data } = {
  [FILTER_TYPE_ALL]: { name: 'Invites & Paper', url: '/wedding-planning/save-the-date/shop' },
  [CARD_TYPES.std]: { name: 'Save the Dates', url: '/wedding-planning/save-the-date/shop' },
  DIGITAL_SAVE_THE_DATE: {
    name: 'Digital Save the Dates',
    url: '/wedding-planning/digital/save-the-date/shop',
  },
  [CARD_TYPES.invitation]: { name: 'Invitations', url: '/wedding-planning/invitations/shop' },
  [CARD_TYPES.ctd]: { name: 'Change the Dates', url: '/wedding-planning/change-the-date/shop' },
  [CARD_TYPES.enclosure]: { name: 'Enclosure Cards', url: '/wedding-planning/enclosures/shop' },
  [CARD_TYPES.menu]: { name: 'Menus', url: '/wedding-planning/menus/shop' },
  [CARD_TYPES.program]: { name: 'Programs', url: '/wedding-planning/programs/shop' },
  [CARD_TYPES.holiday]: { name: 'Holiday Cards', url: '/wedding-planning/holiday-cards/shop' },
  [CARD_TYPES.thankyou]: {
    name: 'Thanks You Cards',
    url: '/wedding-planning/thank-you-cards/shop',
  },
  [CARD_TYPES.place]: { name: 'Place Cards', url: '/wedding-planning/place-cards/shop' },
};
