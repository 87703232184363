import { normalize } from 'normalizr';
import _find from 'lodash/find';
import _keys from 'lodash/keys';
import _trimEnd from 'lodash/trimEnd';
import {
  trackOnboardingCompleted,
  onboardTrackingConstants,
} from '@zola-helpers/client/dist/es/tracking/onboard/onboardEvents';

import { CARD_TYPES } from 'cards/constants/CardTypes';
import { isCustomizationActive } from 'cards/util/isCustomizationActive';
import { fetchWeddingAccount } from 'actions/WeddingActions';
import { getAvailableSamplesCount } from 'cards/components/common/Samples/util/api/index';
import { RECEIVE_PLACECARD_GUEST_LIST } from 'cards/reducers/placecardGuestList/types';
import { getFormValues } from 'redux-form';
import { getGuestGroupIds } from 'selectors/guestList';
import ApiService from '../../util/api';
import featureFlags from '../../util/featureFlags';

import { submitOnboarding } from '../../actions/OnboardActions';
import { getCardByVariationUUID } from './cardCatalogActions';

// Selectors
import {
  getProjectUUID,
  getCustomizationTypeUUIDs,
  getCustomizations,
  getLeadCustomization,
  getCustomizationByUUID,
  getStepGroups,
  getSize,
  getLeadQuantity,
  getPageEntities,
  getProjectIsTestProject,
  getFontsByCustomizationUUID,
  getAllProjectMeta,
  getProjectPdfRenderDetails,
} from '../selectors/cardProjectSelector';

import { customizationObject } from '../schemas/projectSchema';
import { getSelectedGuestIds } from '../selectors/Customization';
import { getMinImageSize, getTypeFromCustomization } from '../util/customization';

import { MAX_CARD_DIMENSION_INCHES, CARD_SIZES } from '../constants/Cards';

export const ActionTypes = {
  RECEIVE_CUSTOMIZATION: 'zola/card/project/RECEIVE_CUSTOMIZATION',
  RECEIVE_ADD_TEXT_ELEMENT: 'zola/card/project/RECEIVE_ADD_TEXT_ELEMENT',
  RECEIVE_RESTORED_ELEMENT: 'zola/card/project/RECEIVE_RESTORED_ELEMENT',
  RECEIVE_DELETE_ELEMENT: 'zola/card/project/RECEIVE_DELETE_ELEMENT',
  RECEIVE_DELETE_ELEMENT_IMAGE: 'zola/card/project/RECEIVE_DELETE_ELEMENT_IMAGE',
  RECEIVE_PROJECT_AUTHENTICATION: 'zola/card/project/RECEIVE_PROJECT_AUTHENTICATION',
  RECEIVE_PROJECT_GUEST_IDS: 'zola/card/project/guests/RECEIVE_PROJECT_GUEST_IDS',
  RECEIVE_PROJECT_GUESTS: 'zola/card/project/guests/RECEIVE_PROJECT_GUESTS',
  RECEIVE_PROJECT: 'zola/cards/project/RECEIVE_PROJECT',
  CLEAR_PROJECT_ERROR: 'zola/cards/project/CLEAR_PROJECT_ERROR',
  REQUEST_CUSTOMIZATION: 'zola/cards/project/REQUEST_CUSTOMIZATION',
  REQUEST_PROJECT_AUTHENTICATION: 'zola/cards/project/REQUEST_PROJECT_AUTHENTICATION',
  REQUEST_PROJECT_GUEST_IDS: 'zola/cards/project/guests/REQUEST_PROJECT_GUEST_IDS',
  REQUEST_PROJECT: 'zola/cards/project/REQUEST_PROJECT',
  SET_ACTIVE_FIELD: 'zola/cards/project/SET_ACTIVE_FIELD',
  GET_CUSTOMIZATION_FONTS_REQUEST: 'zola/cards/project/GET_CUSTOMIZATION_FONTS_REQUEST',
  GET_CUSTOMIZATION_FONTS_SUCCESS: 'zola/cards/project/GET_CUSTOMIZATION_FONTS_SUCCESS',
  GET_CUSTOMIZATION_FONTS_FAILURE: 'zola/cards/project/GET_CUSTOMIZATION_FONTS_FAILURE',
  REQUEST_ADD_PROJECT_TO_CART: 'zola/cards/project/REQUEST_ADD_PROJECT_TO_CART',
  RECEIVE_ADD_PROJECT_TO_CART: 'zola/cards/project/RECEIVE_ADD_PROJECT_TO_CART',
  REQUEST_PROJECT_META: 'zola/cards/project/REQUEST_PROJECT_META',
  RECEIVE_PROJECT_META: 'zola/cards/project/RECEIVE_PROJECT_META',
  COMPLETE_FONT_RESIZE: 'zola/cards/project/COMPLETE_FONT_RESIZE',
  OVERRIDE_FONT_SIZES: 'zola/cards/project/OVERRIDE_FONT_SIZES',
  ADD_TO_FOIL_HISTORY: 'zola/cards/project/ADD_TO_FOIL_HISTORY',
  REMOVE_ONE_FROM_FOIL_HISTORY: 'zola/cards/project/REMOVE_ONE_FROM_FOIL_HISTORY',
  ADD_TO_DELETE_HISTORY: 'zola/cards/project/ADD_TO_DELETE_HISTORY',
  REMOVE_ONE_FROM_DELETE_FOIL_HISTORY: 'zola/cards/project/REMOVE_ONE_FROM_DELETE_FOIL_HISTORY',
  SET_LANDING_PAGE_VISIBILITY: 'zola/cards/project/SET_LANDING_PAGE_VISIBILITY',
  UPDATE_IMAGE_SIZE_REQUIREMENTS: 'zola/cards/project/UPDATE_IMAGE_SIZE_REQUIREMENTS',
  UPDATE_PLACE_CARD_CUSTOMIZATION: 'zola/cards/project/UPDATE_PLACE_CARD_CUSTOMIZATION',
  CHECK_FREE_SAMPLE_AVAILABILITY: 'zola/cards/project/CHECK_FREE_SAMPLE_AVAILABILITY',
  UPDATE_DIGITAL_DETAILS: 'zola/cards/project/UPDATE_DIGITAL_DETAILS',
  SET_PROJECT_STEPS: 'zola/card/project/SET_PROJECT_STEPS',
  RECEIVE_PDF_REQUEST_DETAILS: 'zola/card/project/RECEIVE_PDF_REQUEST_DETAILS',
  REQUEST_PDF_REQUEST_DETAILS: 'zola/card/project/REQUEST_PDF_REQUEST_DETAILS',
};

const findNextVariation = (getState, customizationUUID, nextOptionValues) => {
  const state = getState();
  const customizations = getCustomizations(state);
  const cardType = getLeadCustomization(state)?.type;

  // get customization using customziationUUID argument
  const customization = customizations[customizationUUID];
  // grab variation from customization - this will only work for new sku model
  // variation.option_values needed to merge nextOptionValues with
  const {
    variation: { option_values: currentOptionValues },
  } = customization;
  // mergedOptionValues is what we use to find a matching variation from the associated
  // card
  const mergedOptionValues = {
    ...currentOptionValues,
    ...nextOptionValues,
    ...(cardType === 'PLACE' ? { 'per-guest-customizable': 'false' } : {}),
  };

  // * If applicable, Ignore envelope-type in the finding process for now (envelope color and envelope-type are 1:1)
  delete mergedOptionValues['envelope-type'];

  // find card associated with current customization, each card has a customizationUUID for this purpose
  const customizationCard = _find(getState().cards.cardCatalog.cards, {
    customizationUUID,
  });

  // grab next variation - this might need some error handling in the case that a variation is not found, however
  // that should be rare
  const nextVariation = _find(customizationCard.variations, {
    option_values: mergedOptionValues,
  });

  return nextVariation;
};

function requestProjectMeta() {
  return {
    type: ActionTypes.REQUEST_PROJECT_META,
  };
}

function receiveProjectMeta(projectMeta, projectUUID) {
  return {
    type: ActionTypes.RECEIVE_PROJECT_META,
    payload: {
      projectMeta,
      projectUUID,
    },
  };
}

function requestProject() {
  return {
    type: ActionTypes.REQUEST_PROJECT,
  };
}

export function clearProjectError() {
  return {
    type: ActionTypes.CLEAR_PROJECT_ERROR,
  };
}

export function receiveProject(project, isTestProject) {
  return {
    type: ActionTypes.RECEIVE_PROJECT,
    payload: {
      project,
      isTestProject,
    },
  };
}

function requestCustomization() {
  return {
    type: ActionTypes.REQUEST_CUSTOMIZATION,
  };
}

function getCustomizationFontsRequest() {
  return {
    type: ActionTypes.GET_CUSTOMIZATION_FONTS_REQUEST,
  };
}

function getCustomizationFontsSuccess(fonts, customizationUUID) {
  return {
    type: ActionTypes.GET_CUSTOMIZATION_FONTS_SUCCESS,
    payload: {
      fonts,
      customizationUUID,
    },
  };
}

function getCustomizationFontsFailure(error, customizationUUID) {
  return {
    type: ActionTypes.GET_CUSTOMIZATION_FONTS_FAILURE,
    payload: {
      error,
      customizationUUID,
    },
  };
}

export function receiveCustomization(normalizedCustomization, rawCustomization) {
  return {
    type: ActionTypes.RECEIVE_CUSTOMIZATION,
    payload: {
      customization: normalizedCustomization,
      rawCustomization,
    },
  };
}

function receiveAddTextElement(customizationUUID, pageUUID, element) {
  return {
    type: ActionTypes.RECEIVE_ADD_TEXT_ELEMENT,
    payload: {
      customizationUUID,
      pageUUID,
      element,
    },
  };
}

function receiveRestoredElement(customizationUUID, pageUUID, elements, lastChanges) {
  return {
    type: ActionTypes.RECEIVE_RESTORED_ELEMENT,
    payload: {
      customizationUUID,
      pageUUID,
      elements,
      lastChanges,
    },
  };
}

function receiveDeleteElement(pageUUID, elementUUIDs) {
  return {
    type: ActionTypes.RECEIVE_DELETE_ELEMENT,
    payload: {
      pageUUID,
      elementUUIDs,
    },
  };
}

function receiveDeleteElementImage(elementUUID, element) {
  return {
    type: ActionTypes.RECEIVE_DELETE_ELEMENT_IMAGE,
    payload: {
      elementUUID,
      element,
    },
  };
}

function requestProjectGuestIds() {
  return {
    type: ActionTypes.REQUEST_PROJECT_GUEST_IDS,
  };
}

function receiveProjectGuestIds(json) {
  return {
    type: ActionTypes.RECEIVE_PROJECT_GUEST_IDS,
    payload: {
      projectGuestIds: json
        .map(({ guest_group }) => guest_group?.guests?.map(guest => guest.id))
        .flat(),
    },
  };
}

function receiveProjectGuests(projectGuests) {
  return {
    type: ActionTypes.RECEIVE_PROJECT_GUESTS,
    payload: { projectGuests },
  };
}

function overrideFontSizes(fontSizeOverrides) {
  return {
    type: ActionTypes.OVERRIDE_FONT_SIZES,
    payload: { fontSizeOverrides },
  };
}

function completeFontResize() {
  return {
    type: ActionTypes.COMPLETE_FONT_RESIZE,
  };
}

function requestProjectAuthentication() {
  return {
    type: ActionTypes.REQUEST_PROJECT_AUTHENTICATION,
  };
}

function requestAddProjectToCart() {
  return {
    type: ActionTypes.REQUEST_ADD_PROJECT_TO_CART,
  };
}

function receiveAddProjectToCart() {
  return {
    type: ActionTypes.RECEIVE_ADD_PROJECT_TO_CART,
  };
}

export function setActiveField(activeField) {
  return {
    type: ActionTypes.SET_ACTIVE_FIELD,
    payload: {
      activeField,
    },
  };
}

// Customization TYC Generator Inside #INVITES-1094
export function setLandingPageVisibility(status) {
  return {
    type: ActionTypes.SET_LANDING_PAGE_VISIBILITY,
    payload: {
      status,
    },
  };
}

export function setProjectSteps(payload) {
  return {
    type: ActionTypes.SET_PROJECT_STEPS,
    payload,
  };
}

export function fetchProjectMeta(projectUUID, forceFetch = false) {
  return (dispatch, getState) => {
    if (!forceFetch) {
      // If the project metadata is already available, don't fetch it again.
      const allMeta = getAllProjectMeta(getState());
      const existingProjectMeta = allMeta[projectUUID];
      if (existingProjectMeta) return Promise.resolve(existingProjectMeta);
    }

    dispatch(requestProjectMeta());
    return ApiService.get(`/web-api/v2/card-project/${projectUUID}/meta`).then(json => {
      dispatch(receiveProjectMeta(json, projectUUID));
      return json;
    });
  };
}

export function createProject(
  suiteUUID,
  activeVariationUUID,
  quantity,
  isAdminView = false,
  photoUuid = null
) {
  return dispatch => {
    const withCustomPhoto = typeof photoUuid === 'string' ? { photo_uuid: photoUuid } : {};

    const requestBody = {
      suite_uuid: suiteUUID,
      lead_variation_uuid: activeVariationUUID,
      quantity,
      from_admin_route: isAdminView,
      ...withCustomPhoto,
    };

    return ApiService.post('/web-api/v3/card-project', requestBody).then(json => {
      dispatch(setLandingPageVisibility(true));
      dispatch(fetchProjectMeta(json.uuid));
      json.customizations.forEach(customization => {
        if (customization.variation && customization.variation.uuid) {
          dispatch(
            getCardByVariationUUID(customization.variation.uuid, customization.uuid, isAdminView)
          );
        }
      });
      dispatch(receiveProject(json, isAdminView));
      return json;
    });
  };
}

export function fetchProject(projectUUID, skip = false) {
  return dispatch => {
    dispatch(setLandingPageVisibility(false));
    dispatch(requestProject());
    return ApiService.get(`/web-api/v2/card-project/${projectUUID}`).then(json => {
      const isTestProject = !!json.test_project;
      /**
       * The getProjectMeta and the findCardByVariationUuid calls are heavy on BE.
       * So in some case we don't need to call those two APIs, just fetch the project details and skip them.
       */
      if (!skip) {
        dispatch(fetchProjectMeta(projectUUID));
        json.customizations.forEach(customization => {
          if (customization.variation && customization.variation.uuid) {
            dispatch(
              getCardByVariationUUID(
                customization.variation.uuid,
                customization.uuid,
                isTestProject
              )
            );
          }
        });
      }
      dispatch(receiveProject(json, isTestProject));
      return json;
    });
  };
}

// gets called for outside (bigger? outter?) updates like changing the quantity of cards
export function updateProject(
  projectUUID,
  customizations,
  isProof = false,
  hasSeenProofModal = false
) {
  return dispatch => {
    let requestBody = {
      proof: isProof,
      customizations,
    };

    if (hasSeenProofModal) {
      requestBody = { ...requestBody, proof_modal_viewed: hasSeenProofModal };
    }
    return ApiService.put(`/web-api/v2/card-project/${projectUUID}`, requestBody).then(json => {
      const isTestProject = !!json.test_project;
      if (json.customizations) {
        json.customizations.forEach(customization => {
          if (customization.variation && customization.variation.uuid) {
            dispatch(
              getCardByVariationUUID(
                customization.variation.uuid,
                customization.uuid,
                isTestProject
              )
            );
          }
        });
      }
      dispatch(receiveProject(json, isTestProject));
      return json;
    });
  };
}

export function cloneProject(projectUUID) {
  return dispatch =>
    ApiService.post(`/web-api/v2/card-project/${projectUUID}/clone`).then(json => {
      const { uuid } = json;
      return dispatch(fetchProject(uuid));
    });
}

export function cloneProjectToMedium(projectUUID, medium) {
  return dispatch => {
    const requestBody = {
      card_project_uuid: projectUUID,
      medium,
    };
    return ApiService.post(
      `/web-api/v2/card-project/${projectUUID}/clone/medium`,
      requestBody
    ).then(json => {
      const { uuid } = json;
      return dispatch(fetchProject(uuid));
    });
  };
}

export function cloneSampleProject(projectUUID, variationUUID, quantity) {
  return dispatch => {
    const requestBody = {
      single_sample_project_uuid: projectUUID,
      variation_uuid: variationUUID,
      quantity,
    };
    return ApiService.post(
      `/web-api/v1/card-single-sample/create-project-from-sample`,
      requestBody
    ).then(json => dispatch(fetchProject(json.uuid)));
  };
}

export function fetchCustomization(uuid, isAdminView = false) {
  return dispatch => {
    dispatch(requestCustomization());
    return ApiService.get(`/web-api/v2/card-project/customization/${uuid}`).then(json => {
      if (json.variation && json.variation.uuid) {
        dispatch(getCardByVariationUUID(json.variation.uuid, json.uuid, isAdminView));
      }
      const normalizedCustomization = normalize(json, customizationObject);
      dispatch(receiveCustomization(normalizedCustomization, json));
      return json;
    });
  };
}

const updatesByCustomizationUUID = {};

function runNextUpdateQueueItem(updates) {
  const item = updates[0];

  if (!item) return;

  const { customizationUUID, customizationData, resolve, reject } = item;
  ApiService.put(`/web-api/v2/card-project/customization/${customizationUUID}`, customizationData)
    .then(json => {
      // If the update is successful, resolve the item's promise
      resolve(json);
    })
    .catch(err => {
      // If the update fails, reject the item's promise
      reject(err);
    })
    .then(() => {
      // In both cases, remove the item from the queue and move on to the next item
      updates.shift();
      runNextUpdateQueueItem(updates);
    });
}

function queueCustomizationUpdate(customizationUUID, customizationData) {
  // Initialize the array of updates if necessary
  if (!updatesByCustomizationUUID[customizationUUID]) {
    updatesByCustomizationUUID[customizationUUID] = [];
  }

  return new Promise((resolve, reject) => {
    const currentUpdate = { customizationUUID, customizationData, resolve, reject };
    const updates = updatesByCustomizationUUID[customizationUUID];
    updates.push(currentUpdate);

    // If this is the only item in the queue, start running it now
    if (updates.length === 1) {
      runNextUpdateQueueItem(updates);
    }
  });
}

export function updateCustomization(customizationUUID, customizationData, isTestProject = false) {
  return dispatch => {
    return queueCustomizationUpdate(customizationUUID, customizationData).then(json => {
      if (json.variation && json.variation.uuid) {
        dispatch(getCardByVariationUUID(json.variation.uuid, json.uuid, isTestProject || false));
      }
      const normalizedCustomization = normalize(json, customizationObject);
      dispatch(receiveCustomization(normalizedCustomization, json));
      return json;
    });
  };
}

export function saveCustomizationChanges(customizationUUID, pageNumber) {
  return (dispatch, getState) => {
    if (!customizationUUID || typeof pageNumber !== 'number') return;

    // Get the current card customization
    const state = getState();
    const customizations = getCustomizations(state);
    const customization = customizations[customizationUUID];
    const customizationDeleted =
      typeof customization?.deleted_at === 'string' || customization?.deleted_at === true;
    if (!customization || customizationDeleted) return;

    // Get the current page
    const pageEntities = getPageEntities(state);
    const pageUUID = customization.pages.find(
      uuid => pageEntities?.[uuid]?.page_number === pageNumber + 1
    );
    const page = pageEntities[pageUUID];
    if (!page) return;

    // Get all form data
    const formKey = `${customizationUUID}_${pageNumber}`;
    const formValues = getFormValues(formKey)(state);
    if (!formValues || Object.keys(formValues).length < 1) return;

    const elementUUIDs = page.elements || [];
    const elements = [];
    elementUUIDs?.forEach(elementUUID => {
      if (formValues[elementUUID]) {
        const typedValuesSelection = formValues[elementUUID];
        const element = { ...typedValuesSelection };
        const elementBody = element.body;
        if (typeof elementBody === 'string') {
          // remove any trailing newlines, they have the potential to break pdf render
          const trimmedElementBody = _trimEnd(elementBody, '\n');
          element.body = trimmedElementBody;
        }
        elements.push(element);
      }
    });

    // Prepare the API request
    const formattedPage = { ...page, elements };
    const updateRequest = { pages: [formattedPage] };
    const isTestProject = getProjectIsTestProject(state);
    dispatch(updateCustomization(customizationUUID, updateRequest, isTestProject || false));
  };
}

export function addTextElement(customizationUUID, pageUUID, customizationData) {
  return dispatch =>
    ApiService.post(
      `/web-api/v2/card-project/customization/page/${pageUUID}/element`,
      customizationData
    ).then(json => {
      dispatch(receiveAddTextElement(customizationUUID, pageUUID, json));
      return json;
    });
}

export function restoreElement(customizationUUID, pageUUID, elementUUID, lastChanges) {
  return dispatch =>
    ApiService.post(`/web-api/v2/card-project/element/${elementUUID}/restore`).then(json => {
      dispatch(receiveRestoredElement(customizationUUID, pageUUID, json, lastChanges));
      return json;
    });
}

// TODO: remove customizationUUID from parameters when the signature no longer
// needs to match signature of legacy cardProjectActions.deleteElement
export function deleteElement(pageUUID, elementUUIDs) {
  return dispatch =>
    ApiService.delete(
      `/web-api/v2/card-project/element/${elementUUIDs[0]}`,
      {},
      {},
      {},
      'text'
    ).then(json => {
      dispatch(receiveDeleteElement(pageUUID, elementUUIDs));
      return json;
    });
}

export function deleteElementImage(elementUUID) {
  return dispatch =>
    ApiService.delete(`/web-api/v2/card-project/element/${elementUUID}/image`).then(json => {
      dispatch(receiveDeleteElementImage(elementUUID, json));
      return json;
    });
}

export function toggleRecipientAddressing(
  customizationUUID,
  enableRecipientAddressing,
  isExpeditorFilled,
  isTestProject
) {
  return (dispatch, getState) => {
    let requestBody = {
      recipient_addressing: enableRecipientAddressing,
    };
    const projectSize = getSize(getState());
    if (!isExpeditorFilled && projectSize !== CARD_SIZES.postcard) {
      const nextVariation = findNextVariation(getState, customizationUUID, {
        'envelope-printing': enableRecipientAddressing ? 'front' : 'none',
      });
      requestBody = {
        ...requestBody,
        variation_uuid: nextVariation.uuid,
      };
    }
    return dispatch(updateCustomization(customizationUUID, requestBody, isTestProject)).then(
      json => {
        const { userContext } = getState().user;
        // if the user does not have a wedding account we need to create one so that they can
        // add guests to guest list. This logic used to be handled in components but probably fits
        // better here because a wedding account must be created if recipient addressing is toggled on
        // for a user without a wedding account
        if (enableRecipientAddressing && !userContext.has_wedding_account) {
          const onboardingData = {
            existing_account: true,
            business_unit: 'WEDDING_GUEST_LIST',
            answers: [],
          };
          return dispatch(submitOnboarding(onboardingData)).then(() => {
            trackOnboardingCompleted(
              onboardTrackingConstants.BUSINESS_UNIT.WEDDINGS,
              onboardTrackingConstants.COMPONENT.WEDDINGS,
              { isExistingUser: true }
            );
            // * Fetch and store in state the freshly created data regarding wedding account
            dispatch(fetchWeddingAccount());
            return json;
          });
        }
        return json;
      }
    );
  };
}

export function getMediaFileUrl(mediaFileUUID) {
  return () =>
    ApiService.get(
      `/web-api/v1/card-project/media_file/${mediaFileUUID}/presigned_url`,
      {},
      {},
      'text'
    );
}

export function getProjectGuestIds(projectUUID) {
  return dispatch => {
    dispatch(requestProjectGuestIds());

    return ApiService.get(`/web-api/v2/card-project/${projectUUID}/guests`).then(json =>
      dispatch(receiveProjectGuests(json))
    );
  };
}

export function updateProjectFontSizeOverrides(fontSizeOverrides = {}) {
  return (dispatch, getState) => {
    dispatch(overrideFontSizes(fontSizeOverrides)); // Save overrides immediately
    const projectUUID = getProjectUUID(getState());
    const requestBody = Object.keys(fontSizeOverrides).map(guestId => {
      const overrides = fontSizeOverrides[guestId];
      return {
        guest_group_id: guestId,
        enabled: true,
        guest_name_font_size_override: overrides.name || 0,
        address_font_size_override: overrides.address || 0,
      };
    });
    return ApiService.put(
      `/web-api/v2/card-project/${projectUUID}/guests`,
      requestBody
    ).then(json => dispatch(receiveProjectGuests(json))); // Set overrides again with API response
  };
}

function updateNextQueueItem(updates) {
  const item = updates[0];

  if (!item) return;

  const { projectUUID, requestBody, resolve, reject } = item;
  ApiService.put(`/web-api/v2/card-project/${projectUUID}/guests`, requestBody)
    .then(json => {
      // If the update is successful, resolve the item's promise
      resolve(json);
    })
    .catch(err => {
      // If the update fails, reject the item's promise
      reject(err);
    })
    .then(() => {
      // In both cases, remove the item from the queue and move on to the next item
      updates.shift();
      updateNextQueueItem(updates);
    });
}

const projectGuestAddressingUpdates = [];

export function updateProjectGuestAddressing(projectGuestIds) {
  return (dispatch, getState) => {
    const projectUUID = getProjectUUID(getState());
    const requestBody = Object.keys(projectGuestIds).map(guestId => ({
      guest_group_id: guestId,
      enabled: projectGuestIds[guestId],
    }));

    return new Promise((resolve, reject) => {
      const currentUpdate = { projectUUID, requestBody, resolve, reject };
      projectGuestAddressingUpdates.push(currentUpdate);

      if (projectGuestAddressingUpdates.length === 1) {
        updateNextQueueItem(projectGuestAddressingUpdates);
      }
    }).then(json => dispatch(receiveProjectGuests(json)));
  };
}

export function updateProjectSelectedGuestIds(projectGuestIds) {
  return (dispatch, getState) => {
    const guestGroupIds = getGuestGroupIds(getState());
    const selectedGuestIds = getSelectedGuestIds(getState());
    const updatedGuestIds = projectGuestIds.some(id => selectedGuestIds.includes(id))
      ? selectedGuestIds.filter(id => !projectGuestIds.includes(id))
      : selectedGuestIds.concat(projectGuestIds);
    const selectedGuestIdsMap = guestGroupIds.reduce(
      (acc, guestGroupId) => ({
        ...acc,
        [guestGroupId]: updatedGuestIds.includes(guestGroupId),
      }),
      {}
    );

    return dispatch(updateProjectGuestAddressing(selectedGuestIdsMap));
  };
}

/**
 * @deprecated
 */
export function updateProjectGuestIds(projectGuestIds) {
  return (dispatch, getState) => {
    const projectUUID = getProjectUUID(getState());
    const requestBody = Object.keys(projectGuestIds).map(guestId => ({
      guest_group_id: guestId,
      enabled: projectGuestIds[guestId],
    }));
    return ApiService.put(
      `/web-api/v2/card-project/${projectUUID}/guests`,
      requestBody
    ).then(json => dispatch(receiveProjectGuestIds(json)));
  };
}

/**
 * @deprecated
 */
export function updateProjectGuestId(guestId) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedGuestIds = getSelectedGuestIds(state);
    const projectUUID = getProjectUUID(state);
    const isCurrentlySelected = selectedGuestIds.indexOf(guestId) > -1;
    const requestBody = [
      {
        guest_group_id: guestId,
        enabled: !isCurrentlySelected,
      },
    ];
    return ApiService.put(
      `/web-api/v2/card-project/${projectUUID}/guests`,
      requestBody
    ).then(json => dispatch(receiveProjectGuestIds(json)));
  };
}

export function getCustomizationFonts(customizationUUID) {
  return (dispatch, getState) => {
    // If the desired fonts have already been fetched, no need to get them again.
    const fontsByCustomizationUUID = getFontsByCustomizationUUID(getState());
    if (fontsByCustomizationUUID[customizationUUID]) {
      return Promise.resolve();
    }

    dispatch(getCustomizationFontsRequest());
    return (
      ApiService.get(`/web-api/v2/card-project/customization/${customizationUUID}/fonts`)
        .then(data => dispatch(getCustomizationFontsSuccess(data, customizationUUID)))
        // TODO: This can be move to handleErrors and pass as param the action to fire on failure
        // But we'll keep it specific to this method for now until we handle errors gobally
        .catch(err => dispatch(getCustomizationFontsFailure(err, customizationUUID)))
    );
  };
}

// NEW ACTIONS FOR SKU MIGRATION

export function getProjectAuthenticated(projectUUID) {
  return dispatch => {
    dispatch(requestProjectAuthentication());
    return ApiService.post(`/web-api/v3/card-project/${projectUUID}/is_authenticated`).then(
      auth => auth
    );
  };
}

// generic function to update variation_uuid of a customization
export function updateCustomizationVariation(
  customizationUUID,
  nextOptionValues,
  isTestProject = false
) {
  return (dispatch, getState) => {
    const nextVariation = findNextVariation(getState, customizationUUID, nextOptionValues);
    if (!nextVariation) return null;

    const requestBody = { variation_uuid: nextVariation?.uuid };
    return dispatch(updateCustomization(customizationUUID, requestBody, isTestProject));
  };
}

// function that handles using v1 endpoint for projects with old sku model or v3 endpoint
// for projects with new sku model - with this action conditional logic doesnt have to be
// implemented in components
export function addProjectToCart() {
  return (dispatch, getState) => {
    const state = getState();
    const projectUUID = getProjectUUID(state);
    const requestBody = {
      projectUUID,
    };
    dispatch(requestAddProjectToCart());
    return ApiService.post('/web-api/v3/card-project/cart', requestBody, {}, {}, 'text')
      .then(() => dispatch(receiveAddProjectToCart()))
      .then(() => {
        window.location = `/wedding-planning/cart/${projectUUID}/confirmation`;
      });
  };
}

export function updateProjectProofModalViewed(proofModalViewed = false, isProof = false) {
  return (dispatch, getState) => {
    const state = getState();
    const projectUUID = getProjectUUID(state);

    return dispatch(updateProject(projectUUID, {}, isProof, proofModalViewed));
  };
}

export function updateAllProjectQuantities(quantity, isProof = false) {
  return (dispatch, getState) => {
    const state = getState();
    const projectUUID = getProjectUUID(state);
    const customizations = getCustomizations(state);
    const formattedCustomizations = _keys(customizations).reduce((result, uuid) => {
      result[uuid] = { quantity }; // eslint-disable-line no-param-reassign
      return result;
    }, {});
    return dispatch(updateProject(projectUUID, formattedCustomizations, isProof));
  };
}

const currentGroupCustomizations = (
  allCustomizations,
  selectedItemType,
  stepGroups,
  groupCardTypes,
  cardType
) => {
  const updateGroupCardTypes = [
    ...new Set([].concat(...stepGroups.map(group => group.steps.map(s => s.cardType)))),
  ];
  const selectedItemTypeValue = selectedItemType === 'DETAILS' ? 'ENCLOSURE' : selectedItemType;
  const isSelectedItemType = featureFlags.get('reviewQuantityUpdate') && selectedItemType;
  const isReviewQuantityUpdate =
    isSelectedItemType &&
    (cardType === 'INVITATION' || cardType === 'MENU' || cardType === 'PROGRAM');
  const typeArrayFromCustomization = [selectedItemTypeValue, `${selectedItemTypeValue}_ENVELOPE`];
  return allCustomizations.filter(c => {
    return isReviewQuantityUpdate
      ? updateGroupCardTypes.includes(getTypeFromCustomization(c)) &&
          typeArrayFromCustomization.includes(getTypeFromCustomization(c))
      : groupCardTypes.includes(getTypeFromCustomization(c));
  });
};

// uses updateProject action to update inactive field of both rsvp and rsvp envelope cards

export function updateStepGroupQuantities(
  cardType,
  newQuantity,
  isFoldedThankYouCard = false,
  selectedItemType = null
) {
  return (dispatch, getState) => {
    const state = getState();
    const projectUUID = getProjectUUID(state);
    const customizationTypeToUUID = getCustomizationTypeUUIDs(state);
    const currentLeadQuantity = getLeadQuantity(state);

    const stepGroups = getStepGroups(state);
    const currentGroup = stepGroups.find(group => group.cardType === cardType);
    if (!currentGroup) return;
    const groupCardTypes = currentGroup.steps.map(s => s.cardType);
    const allCustomizations = Object.values(getCustomizations(state));

    const updateCustomizationQuantities = (customizations, quantity, isProofSelected) =>
      customizations.reduce((result, { uuid }) => {
        if (customizationTypeToUUID.THANK_YOU === uuid && isFoldedThankYouCard && isProofSelected) {
          return {
            ...result,
            [uuid]: {
              quantity,
              custom_notes_enabled: false,
            },
          };
        }
        return {
          ...result,
          [uuid]: {
            quantity,
          },
        };
      }, {});

    const isProofSelected = newQuantity === 1;
    const isIncresingFromProof = currentLeadQuantity === 1 && newQuantity !== 1;
    const customizationsToUpdate =
      isProofSelected || isIncresingFromProof
        ? allCustomizations
        : currentGroupCustomizations(
            allCustomizations,
            selectedItemType,
            stepGroups,
            groupCardTypes,
            cardType
          );
    const formattedCustomizations = updateCustomizationQuantities(
      customizationsToUpdate,
      newQuantity,
      isProofSelected
    );

    // eslint-disable-next-line consistent-return
    return dispatch(updateProject(projectUUID, formattedCustomizations));
  };
}

// Toggles a customization on or off (used to enable secondary card types)
export function toggleCustomization(customizationUUID) {
  return (dispatch, getState) => {
    const state = getState();
    const projectUUID = getProjectUUID(state);
    const customizations = getCustomizations(state);
    const customization = customizations[customizationUUID];
    const inactive = !!isCustomizationActive(customization); // Determine NEW inactive status

    const updatedCustomizations = { [customizationUUID]: { inactive } };

    // If the user is toggling RSVPs, toggle RSVP envelopes too
    if (customization.type === CARD_TYPES.rsvp) {
      const customizationTypeToUUID = getCustomizationTypeUUIDs(state);
      const rsvpEnvelopeUUID = customizationTypeToUUID.RSVP_ENVELOPE;
      updatedCustomizations[rsvpEnvelopeUUID] = { inactive };
    }

    dispatch(setActiveField(null));
    return dispatch(updateProject(projectUUID, updatedCustomizations));
  };
}

export function updateProjectMeta() {
  return (dispatch, getState) => {
    const state = getState();
    const projectUUID = getProjectUUID(state);
    return ApiService.put(`/web-api/v2/card-project/${projectUUID}/meta`)
      .then(json => {
        dispatch(receiveProjectMeta(json, projectUUID));
        return json;
      })
      .catch(err => {
        console.log('err: ', err);
      });
  };
}

function addToFoilHistory(newFoil, customizationCardType) {
  return {
    type: ActionTypes.ADD_TO_FOIL_HISTORY,
    payload: {
      newFoil,
      customizationCardType,
    },
  };
}

function removeOneFoilHistory(customizationCardType) {
  return {
    type: ActionTypes.REMOVE_ONE_FROM_FOIL_HISTORY,
    payload: {
      customizationCardType,
    },
  };
}

function addToDeleteFoilHistory(newFoil, customizationCardType) {
  return {
    type: ActionTypes.ADD_TO_DELETE_HISTORY,
    payload: {
      newFoil,
      customizationCardType,
    },
  };
}

function removeOneDeleteFoilHistory(customizationCardType) {
  return {
    type: ActionTypes.REMOVE_ONE_FROM_DELETE_FOIL_HISTORY,
    payload: {
      customizationCardType,
    },
  };
}

function updateImageSizeRequirements(customizableImageElements, page, customizationSize) {
  const maxCardDimensionInches = MAX_CARD_DIMENSION_INCHES[customizationSize];
  const allMinWidths = [];
  const allMinHeights = [];
  const minCrops = {};

  customizableImageElements.forEach(element => {
    const minImageSize = getMinImageSize(element, page, maxCardDimensionInches);

    allMinWidths.push(minImageSize.width);
    allMinHeights.push(minImageSize.height);
    minCrops[element.uuid] = minImageSize;
  });

  const imageSizeRequirements = {
    minWidth: allMinWidths.length ? Math.max(...allMinWidths) : 0,
    minHeight: allMinHeights.length ? Math.max(...allMinHeights) : 0,
    minCrops,
  };

  return {
    type: ActionTypes.UPDATE_IMAGE_SIZE_REQUIREMENTS,
    payload: {
      imageSizeRequirements,
    },
  };
}

const receivePlacecardGuestList = payload => ({
  type: RECEIVE_PLACECARD_GUEST_LIST,
  payload,
});

export const updatePlaceCardDetails = (projectUUID, customizationUUID) => (dispatch, getState) => {
  const state = getState();
  const customization = getCustomizationByUUID(state, customizationUUID);

  const { should_print_guest_names, should_print_table_names } = customization;

  const requestBody = {
    should_print_guest_names,
    should_print_table_names,
  };

  return ApiService.put(`/web-api/v2/card-project/${projectUUID}/place-card/details`, requestBody)
    .then(guestList => dispatch(receivePlacecardGuestList(guestList)))
    .catch(() => {
      /**
       * There is a error when fire this updatePlaceCardDetail in component will unmont
       */
    });
};

const requestPdfRenderDetails = () => {
  return {
    type: ActionTypes.REQUEST_PDF_REQUEST_DETAILS,
  };
};

const receivePdfRequestDetails = payload => ({
  type: ActionTypes.RECEIVE_PDF_REQUEST_DETAILS,
  payload,
});

export const requestPdfRender = projectUUID => dispatch => {
  return ApiService.put(`/web-api/v3/card-project/${projectUUID}/render-project`)
    .then(json => dispatch(receivePdfRequestDetails(json)))
    .catch(error => {
      throw new Error(error?.message || 'Unable to generate project PDF');
    });
};

export const getPdfRenderStatus = requestUUID => (dispatch, getState) => {
  const state = getState();
  const { isBusy } = getProjectPdfRenderDetails(state);
  if (isBusy) {
    return Promise.resolve();
  }

  dispatch(requestPdfRenderDetails());
  return ApiService.get(`/web-api/v3/card-project/render/${requestUUID}/status`)
    .then(json => dispatch(receivePdfRequestDetails({ ...json, isBusy: false })))
    .catch(error => {
      throw new Error(error?.message || 'Unable to get project PDF details');
    });
};

export function checkFreeSampleAvailability(cardType) {
  return dispatch => {
    return getAvailableSamplesCount(cardType).then(data => {
      const freeSamplesAvailable = data.order_placed < data.maximum_orders_allowed;
      dispatch({
        type: ActionTypes.CHECK_FREE_SAMPLE_AVAILABILITY,
        payload: {
          freeSamplesAvailable,
        },
      });
      return data;
    });
  };
}

export const updatePlaceCardCustomization = (
  customizationUUID,
  shouldPrintGuestNames,
  shouldPrintTableNames
) => ({
  type: ActionTypes.UPDATE_PLACE_CARD_CUSTOMIZATION,
  payload: {
    customizationUUID,
    shouldPrintGuestNames,
    shouldPrintTableNames,
  },
});

export const updateDigitalDetails = (customizationUUID, digitalDetails) => {
  return {
    type: ActionTypes.UPDATE_DIGITAL_DETAILS,
    payload: {
      customizationUUID,
      digitalDetails,
    },
  };
};

export const actions = {
  setActiveField,
  createProject,
  fetchProject,
  updateProject,
  cloneProject,
  cloneProjectToMedium,
  cloneSampleProject,
  fetchCustomization,
  updateCustomization,
  addTextElement,
  restoreElement,
  deleteElement,
  deleteElementImage,
  toggleRecipientAddressing,
  getMediaFileUrl,
  getProjectGuestIds,
  updateProjectFontSizeOverrides,
  completeFontResize,
  updateProjectGuestIds,
  getCustomizationFonts,
  getProjectAuthenticated,
  updateCustomizationVariation,
  toggleCustomization,
  addProjectToCart,
  updateAllProjectQuantities,
  updateStepGroupQuantities,
  updateProjectGuestId,
  setLandingPageVisibility,
  setProjectSteps,
  updateProjectMeta,
  fetchProjectMeta,
  addToFoilHistory,
  removeOneFoilHistory,
  addToDeleteFoilHistory,
  removeOneDeleteFoilHistory,
  updateImageSizeRequirements,
  updatePlaceCardCustomization,
  updatePlaceCardDetails,
  clearProjectError,
  checkFreeSampleAvailability,
  updateProjectGuestAddressing,
};
