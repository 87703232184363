import { UploadCareTabTypes } from '@zola-helpers/client/dist/es/uploadcare/constants';
import Confirmation from './Steps/Confirmation';
import CustomizeStep from './Steps/Customize';
import Warning from './Steps/Warning';
import NamesStep from './Steps/Names';
import PhotoStep from './Steps/Photo';
import ProcessingOrder from './Steps/ProcessingOrder';
import ShippingStep from './Steps/Shipping';

export const CARD_UPLOAD_TABS = [
  UploadCareTabTypes.FILE,
  UploadCareTabTypes.DROPBOX,
  UploadCareTabTypes.GOOGLE_PHOTOS,
  UploadCareTabTypes.ONE_DRIVE,
  UploadCareTabTypes.FACEBOOK,
  UploadCareTabTypes.INSTAGRAM,
];

export const STEPS = {
  CONFIRMATION: 'FLOW_COMPLETION',
  CUSTOMIZE: 'CUSTOMIZATION',
  WARNING: 'WARNING',
  NAMES: 'WEDDING_DETAILS_FORM',
  PHOTO: 'FILE_UPLOAD',
  PROCESSING_ORDER: 'PROCESSING_ORDER',
  SHIPPING: 'SHIPPING_DETAILS_FORM',
};

export const COMPONENTS = {
  [STEPS.CONFIRMATION]: Confirmation,
  [STEPS.CUSTOMIZE]: CustomizeStep,
  [STEPS.NAMES]: NamesStep,
  [STEPS.PHOTO]: PhotoStep,
  [STEPS.PROCESSING_ORDER]: ProcessingOrder,
  [STEPS.SHIPPING]: ShippingStep,
  [STEPS.WARNING]: Warning,
};
