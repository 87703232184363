import { STEPS } from 'cards/components/common/Samples/SamplesFlow/constants';
import { autocropUploadcareFile } from 'components/common/UploadcareWidget/utils/crop';
import { actionTypes } from './actions';
import { Actions, DesignCustomizationsSet, SamplesFlowState } from './types';

const init = {
  accountDetails: {
    email: null,
    password: null,
  },
  allLPOrFoil: true,
  cardSuiteDetailsSet: {},
  cardType: null,
  designCustomizationsSet: {},
  errors: {},
  hasLPOrFoil: false,
  orderNumber: null,
  paperCustomizations: {
    activeSelections: {
      'foil-color': null,
      'paper-type': null,
      'reverse-printing': 'false',
      silhouette: null,
      size: null,
    },
    options: {},
  },
  shippingDetails: {
    address: null,
    apt: null,
    city: null,
    state: null,
    zip: null,
    fetched: false,
  },
  samplesAvailable: null,
  stepKey: STEPS.NAMES,
  templatesSet: {},
  weddingDetails: {
    date: null,
    fullName: null,
    partnerFullName: null,
  },
};

const reducer = (
  state: SamplesFlowState = init,
  action: Actions.SingleSampleFlowAction
): SamplesFlowState => {
  switch (action.type) {
    case actionTypes.DELETE_UPLOADCARE_IMAGE: {
      const { cardSuiteUUID } = action.payload;

      return {
        ...state,
        designCustomizationsSet: {
          ...state.designCustomizationsSet,
          [cardSuiteUUID]: {
            ...state.designCustomizationsSet[cardSuiteUUID],
            uploadcareFile: null,
          },
        },
      };
    }
    case actionTypes.RESET: {
      return init;
    }
    case actionTypes.SET_ACCOUNT_DETAILS: {
      const { accountDetails } = action.payload;

      return {
        ...state,
        accountDetails,
      };
    }
    case actionTypes.SET_ALL_LP_OR_FOIL: {
      return {
        ...state,
        allLPOrFoil: false,
      };
    }
    case actionTypes.SET_CARD_SUITE_DETAILS: {
      const { suiteDetails, activePDPVariation } = action.payload;

      const nonLPOrFoilSuite: any = Object.values(suiteDetails).find(
        (suite: any) => !suite.letterpress && !suite.foil
      );
      const paperData = nonLPOrFoilSuite
        ? nonLPOrFoilSuite.mediums.paper
        : suiteDetails[0].mediums.paper;
      const {
        available_options: availableOptions,
        default_variation_options: defaultOptions,
      } = paperData;
      const customizeOptions = {
        size: availableOptions.size,
        'foil-color': availableOptions['foil-color'],
        'paper-type': availableOptions['paper-type'],
        silhouette: availableOptions.silhouette,
      };
      const size = activePDPVariation ? activePDPVariation.size : defaultOptions.size;
      const foilColor = activePDPVariation
        ? activePDPVariation['foil-color']
        : defaultOptions['foil-color'];
      const paperType = activePDPVariation
        ? activePDPVariation['paper-type']
        : defaultOptions['paper-type'];
      const silhouette = activePDPVariation
        ? activePDPVariation.silhouette
        : defaultOptions.silhouette;

      const activeSelections = {
        'foil-color': foilColor,
        'paper-type': paperType,
        'reverse-printing': (size === 'postcard').toString(),
        silhouette,
        size,
      };

      const suiteDetailsByUUID: { [key: string]: any } = {};

      suiteDetails.forEach((suite: any) => {
        suiteDetailsByUUID[suite.uuid] = suite;
      });

      return {
        ...state,
        cardType: suiteDetails[0].type,
        paperCustomizations: {
          ...state.paperCustomizations,
          options: {
            ...state.paperCustomizations.options,
            ...customizeOptions,
          },
          activeSelections: {
            ...state.paperCustomizations.activeSelections,
            ...activeSelections,
          },
        },
        cardSuiteDetailsSet: {
          ...state.cardSuiteDetailsSet,
          ...suiteDetailsByUUID,
        },
      };
    }
    case actionTypes.SET_DEFAULT_TEMPLATES: {
      const { templates } = action.payload;

      return {
        ...state,
        templatesSet: {
          ...state.templatesSet,
          ...templates,
        },
      };
    }
    case actionTypes.SET_DESIGN_CUSTOMIZATIONS: {
      const { designCustomizationsSet } = action.payload;

      return {
        ...state,
        designCustomizationsSet: {
          ...state.designCustomizationsSet,
          ...designCustomizationsSet,
        },
      };
    }
    case actionTypes.SET_ERRORS: {
      const { errors } = action.payload;

      return {
        ...state,
        errors: {
          ...state.errors,
          ...errors,
        },
      };
    }
    case actionTypes.SET_HAS_LP_OR_FOIL: {
      return {
        ...state,
        hasLPOrFoil: true,
      };
    }
    case actionTypes.SET_PAPER_CUSTOMIZATIONS: {
      const { newSelections } = action.payload;

      return {
        ...state,
        paperCustomizations: {
          ...state.paperCustomizations,
          activeSelections: {
            ...state.paperCustomizations.activeSelections,
            ...newSelections,
          },
        },
      };
    }
    case actionTypes.SET_WEDDING_DETAILS: {
      const { weddingDetails } = action.payload;

      return {
        ...state,
        weddingDetails: {
          ...state.weddingDetails,
          ...weddingDetails,
        },
      };
    }
    case actionTypes.SET_ORDER_NUMBER: {
      const { orderNumber } = action.payload;

      return {
        ...state,
        orderNumber,
      };
    }
    case actionTypes.SET_SHIPPING_DETAILS: {
      const { shippingDetails } = action.payload;

      return {
        ...state,
        shippingDetails: {
          ...state.shippingDetails,
          ...shippingDetails,
        },
      };
    }
    case actionTypes.SET_SINGLE_SAMPLE_AVAILABLE: {
      const { samplesAvailable } = action.payload;

      return {
        ...state,
        samplesAvailable,
      };
    }
    case actionTypes.SET_STEP_KEY: {
      const { stepKey } = action.payload;

      return {
        ...state,
        stepKey,
      };
    }
    case actionTypes.SET_UPLOADCARE_IMAGE: {
      const { activePreviewUUID, cardSuiteUUIDList, uploadcareFile } = action.payload;
      const { designCustomizationsSet, templatesSet } = state;
      const newCustomizationsSet = JSON.parse(JSON.stringify(designCustomizationsSet));

      const addImageToCardSuite = (uuid: string) => {
        const firstPage = templatesSet[uuid].pages[0];
        const customizableImageSlot =
          firstPage &&
          firstPage.elements.find(
            (element: any) => element.content_type === 'IMAGE' && element.customizable
          );

        if (customizableImageSlot) {
          newCustomizationsSet[uuid].uploadcareFile = autocropUploadcareFile(
            uploadcareFile,
            customizableImageSlot
          );
        }
      };

      if (
        !Object.values(designCustomizationsSet).find(
          (customizations: any) => customizations.uploadcareFile !== null
        )
      ) {
        cardSuiteUUIDList.forEach(addImageToCardSuite);
      } else {
        addImageToCardSuite(activePreviewUUID);
      }

      return {
        ...state,
        designCustomizationsSet: {
          ...state.designCustomizationsSet,
          ...newCustomizationsSet,
        },
      };
    }
    case actionTypes.SET_PREVIEW_PHOTO: {
      const { cdnUrl, mimeType } = action.payload;
      const newDesignCustomizationsSet = Object.keys(state.designCustomizationsSet).reduce<
        DesignCustomizationsSet
      >(
        (prev, key) => ({
          ...prev,
          [key]: {
            ...prev[key],
            uploadcareFile: { cdnUrl, mimeType },
          },
        }),
        state.designCustomizationsSet
      );

      return {
        ...state,
        designCustomizationsSet: newDesignCustomizationsSet,
      };
    }
    case actionTypes.SET_SHIPPING_DETAILS_FETCHED: {
      return {
        ...state,
        shippingDetails: {
          ...state.shippingDetails,
          fetched: true,
        },
      };
    }
    case actionTypes.UPDATE_DESIGN_CUSTOMIZATIONS: {
      const { cardSuiteUUID, customizations } = action.payload;

      return {
        ...state,
        designCustomizationsSet: {
          ...state.designCustomizationsSet,
          [cardSuiteUUID]: {
            ...state.designCustomizationsSet[cardSuiteUUID],
            ...customizations,
          },
        },
      };
    }
    case actionTypes.UPDATE_TEMPLATE: {
      const { cardSuiteUUID, template } = action.payload;

      return {
        ...state,
        templatesSet: {
          ...state.templatesSet,
          [cardSuiteUUID]: {
            ...state.templatesSet[cardSuiteUUID],
            ...template,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
