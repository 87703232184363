import { useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';
import { isMobile } from '@zola-helpers/client/dist/es/util/responsive';

export const useIsMobile = () => {
  const [isMobileSize, setIsMobileSize] = useState(false);

  useEffect(() => {
    setIsMobileSize(isMobile());
    const handleResize = _debounce(() => {
      setIsMobileSize(isMobile());
    }, 1000);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileSize;
};
