import fetch from 'isomorphic-fetch';
import moment from 'moment';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { setGTMLoggedIn } from '@zola-helpers/client/dist/es/tracking/googleTagManager';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import { trackUserCreated } from '@zola-helpers/client/dist/es/tracking/trackingHelper';

import { getCookie, setAllStorage } from '../util/storage';
import * as ActionType from './types/OnboardActionTypes';

import { identifyUserSailthru, getUserContext } from './UserActions';

function handleErrors(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
  return response;
}

export function checkingSlug() {
  return {
    type: ActionType.CHECK_SLUG_AVAILABLE,
  };
}

export function requestUpdateSlug() {
  return {
    type: ActionType.REQUEST_UPDATE_SLUG,
  };
}

export function receiveSlugAvailability(response) {
  return {
    type: ActionType.SLUG_AVAILABILITY_RECEIVED,
    payload: response.data,
  };
}

export function receiveSlugSuggestions(response) {
  return {
    type: ActionType.SLUG_SUGGESTIONS_RECEIVED,
    payload: response.data,
  };
}

export function receiveUpdatedSlug(response) {
  return {
    type: ActionType.RECEIVE_UPDATED_SLUG,
    payload: response.data,
  };
}

export function setDeferSelectProduct(bool) {
  return {
    type: ActionType.SET_DEFER_SELECT_PRODUCT,
    payload: bool,
  };
}

export function setSubmitDelayActive(bool) {
  return {
    type: ActionType.UPDATE_SUBMIT_DELAY_ACTIVE,
    payload: bool,
  };
}

export function checkSlugAvailability(slug) {
  return dispatch => {
    dispatch(checkingSlug());
    return fetch(`/web-api/v1/wedding/slug/${slug}/available`, {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveSlugAvailability(json)))
      .then(action => action);
  };
}

export function getSlugSuggestions(suggestionData) {
  const {
    primaryFirstName,
    partnerFirstName,
    primaryLastName,
    partnerLastName,
    eventDate,
  } = suggestionData;
  return dispatch => {
    dispatch(checkingSlug());
    const epoch = moment(eventDate).valueOf() || '';
    const slugData = {
      owner_first_name: primaryFirstName,
      owner_last_name: primaryLastName,
      partner_first_name: partnerFirstName,
      partner_last_name: partnerLastName,
      event_date: epoch,
    };
    return fetch('/web-api/v1/wedding/slug/suggestions', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(slugData),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveSlugSuggestions(json)));
  };
}

// TODO: maybe move slug actions into another file becuase not really related to onboarding anymore;
export function updateSlug(request) {
  return dispatch => {
    dispatch(requestUpdateSlug());
    return fetch('/web-api/v1/wedding/slug', {
      method: 'PUT',
      credentials: 'same-origin',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(handleErrors)
      .then(response => response.json())
      .then(json => {
        dispatch(receiveUpdatedSlug(json));
        return json;
      });
  };
}

// Onboard Actions related to new (Perfect) Onboard flow.

function requestSignup() {
  return {
    type: ActionType.REQUEST_SIGNUP,
  };
}

function onboardingCompleted(json) {
  return {
    type: ActionType.ONBOARDING_COMPLETED,
    payload: json,
  };
}

export function updateQuestionNumber(questionNumber) {
  return {
    type: ActionType.UPDATE_QUESTION_NUMBER,
    payload: questionNumber,
  };
}

export function updateWeddingDate(date) {
  return {
    type: ActionType.UPDATE_WEDDING_DATE,
    payload: {
      date,
    },
  };
}

export function setBusinessUnitComponent(component) {
  return {
    type: ActionType.SET_BUSINESS_UNIT_COMPONENT,
    payload: component,
  };
}

export function submitOnboarding(data) {
  return (dispatch, getState) => {
    const { userContext } = getState().user; // Do this up here so you do not have a modify userContext after dispatching any account creation events.
    const isGuest = userContext && userContext.is_guest;
    dispatch(requestSignup());

    const onboardEndpoint = isGuest
      ? '/web-api/v1/onboard/submit'
      : '/web-api/v1/onboard/submit/existing';

    return ApiService.post(onboardEndpoint, data).then(json => {
      if (json.error) {
        throw new Error(json.error.message);
      }

      setAllStorage('zolaUserCreatedAt', new Date().toISOString());
      setGTMLoggedIn();
      dispatch(onboardingCompleted(json));
      return dispatch(getUserContext()).then(() => {
        const { user } = getState();
        const latestUserContext = user.userContext || {};

        if (isGuest) {
          // track user created
          let cookie = getCookie('pkey');
          if (cookie === '') {
            cookie = null;
          }
          trackUserCreated(latestUserContext.email, cookie);
        }
        return identifyUserSailthru(latestUserContext.email, isGuest ? data.user : undefined);
      });
    });
  };
}

export function setOnboardCityState(cityStateString) {
  return {
    type: ActionType.SET_CITY_STATE,
    payload: cityStateString,
  };
}

export function setOnboardWeddingYear(year) {
  return {
    type: ActionType.SET_WEDDING_YEAR,
    payload: year,
  };
}

export function setOnboardWeddingMonth(month) {
  return {
    type: ActionType.SET_WEDDING_MONTH,
    payload: month,
  };
}

function requestQuestions() {
  return {
    type: ActionType.REQUEST_QUESTIONS,
  };
}

function receiveQuestions(json) {
  return {
    type: ActionType.RECEIVE_QUESTIONS,
    payload: json,
  };
}

export function fetchQuestions(flowName) {
  return dispatch => {
    dispatch(requestQuestions());
    return ApiService.get(`/web-api/v1/onboard/get?flow=${flowName}`)
      .then(json => {
        dispatch(receiveQuestions(json));
        return json;
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({ headline: 'Error in getting questions for onboarding.' })
        );
      });
  };
}

export function fetchQuestionnaire(version, businessUnit) {
  return dispatch => {
    dispatch(requestQuestions());
    return ApiService.get(`/web-api/v1/onboard/get?version=${version}&businessUnit=${businessUnit}`)
      .then(json => {
        dispatch(receiveQuestions(json));
        return json;
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({ headline: 'Error in getting questions for onboarding.' })
        );
      });
  };
}

export function submitQuestionnaireAnswers(questionKey, answers) {
  return {
    type: ActionType.SUBMIT_QUESTIONNAIRE_ANSWERS,
    payload: { questionKey, values: answers },
  };
}

export function clearQuestionnaireAnswer(questionKey) {
  return {
    type: ActionType.CLEAR_QUESTIONNAIRE_ANSWER,
    payload: { questionKey },
  };
}
