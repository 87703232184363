import type { WChecklistStatsView, WProductView } from '@zola/svc-web-api-ts-client';

export const REQUEST_PRODUCTS = 'zola/overview/REQUEST_PRODUCTS';
export const REQUEST_ADDITIONAL_PRODUCTS = 'zola/overview/REQUEST_ADDITIONAL_PRODUCTS';
export const RECEIVE_PRODUCTS = 'zola/overview/RECEIVE_PRODUCTS';
export const RECEIVE_ADDITIONAL_PRODUCTS = 'zola/overview/RECEIVE_ADDITIONAL_PRODUCTS';

export const REQUEST_CHECKLIST = 'zola/checklist/REQUEST_CHECKLIST';
export const RECEIVE_CHECKLIST = 'zola/checklist/RECEIVE_CHECKLIST';

type RequestProductsActionType = {
  type: typeof REQUEST_PRODUCTS;
};

type RequestAdditionalProductsActionType = {
  type: typeof REQUEST_ADDITIONAL_PRODUCTS;
};

type ReceiveProductsActionType = {
  type: typeof RECEIVE_PRODUCTS;
  data: WProductView[];
};

type ReceiveAdditionalProductsActionType = {
  type: typeof RECEIVE_ADDITIONAL_PRODUCTS;
  data: WProductView[];
};

type RequestRegistryChecklistActionType = {
  type: typeof REQUEST_CHECKLIST;
};

type ReceiveRegistryChecklistActionType = {
  type: typeof RECEIVE_CHECKLIST;
  payload: WChecklistStatsView;
};

export type RegistryOverviewActions =
  | RequestProductsActionType
  | RequestAdditionalProductsActionType
  | ReceiveProductsActionType
  | ReceiveAdditionalProductsActionType
  | RequestRegistryChecklistActionType
  | ReceiveRegistryChecklistActionType;
