import { getTypeFromBackendType } from 'cards/util/cardTypes';
import { DISABLED_FOLDED_PAPER, DISABLED_POSTCARD_PAPER } from 'cards/constants/Cards';
import { Just, Maybe } from 'pratica';
import { AnyAction } from 'redux';
import {
  ActiveSelection,
  PageData,
  ImageData,
  Cost,
} from 'cards/pages/ProductDetail/ProducDetailTypes';
import featureFlags from '../../../util/featureFlags';

export const ZOLA_PDP_INITIAL_COLOR_KEY = 'ZOLA_PDP_INITIAL_COLOR_KEY';

const orderedEnabledMediums = ['paper', 'magnet', 'digital'];

const isVariationSelected = (
  selection: Record<string, any> & { type: string },
  variation: Record<string, any>,
  isStampFoil: boolean | undefined
) => {
  const variationKeys = Object.keys(variation);
  return (
    variationKeys.filter(key => {
      // Do not check printing-type
      if (key === 'printing-type') {
        return true;
      }
      // * For Stamp Foil designs, default variation option foil value is set to foil type by BE
      // * By changing size, FE doesn't update the foil type value as it's unknown
      // * For now, every stamp foil value is unique to each listed size variation, so it's safe to be ignored
      if (isStampFoil && key === 'foil') {
        return true;
      }
      // Exception for paper-type magnet
      if (key === 'paper-type' && variation[key] === 'magnet' && selection.type === 'magnet') {
        return true;
      }
      return (
        selection[key] === variation[key] ||
        (variation[key] === 'none' && typeof selection[key] !== 'string')
      );
    }).length === variationKeys.length
  );
};

const getActiveVariation = (
  variations: Record<string, any>,
  selection: Record<string, any> & { type: string },
  isStampFoil: boolean | undefined
) => {
  const values = Object.keys(variations).map(key => variations[key]);
  return (
    values.filter(variation => {
      return isVariationSelected(selection, variation.option_values, isStampFoil);
    })[0] || {}
  );
};

const getPriceFromActiveVariation = (
  activeVariation: Record<string, any>,
  def: number | undefined
) => {
  return activeVariation.price_cents || def;
};

export type PdpData = PageData;

interface PdpReducerState {
  busy: boolean;
  error: null;
  data: PdpData | null;
  mediums: Record<string, any>;
  activeUUID: string;
  activeVariation: Record<string, any>;
  activeMediumKey: string;
  activeMediumData: Record<string, any>;
  activeMediumSelection: ActiveSelection;
  images: Record<string, ImageData>;
  cost: Cost;
  quantity: number;
  initialVariationColor: string | null;
  variationTemplates: Record<string, any>;
  projectUUID: null;
}

const init: PdpReducerState = {
  busy: true,
  error: null,
  data: null,
  mediums: {},
  activeUUID: '',
  activeVariation: {},
  activeMediumKey: 'paper',
  activeMediumData: {},
  activeMediumSelection: {} as ActiveSelection,
  images: {},
  cost: {
    base: 0,
    extras: 0,
    total: 0,
  },
  quantity: 100,
  initialVariationColor: null,
  variationTemplates: {},
  projectUUID: null,
};

const reducer = (state: PdpReducerState = init, action: AnyAction): PdpReducerState => {
  switch (action.type) {
    case 'SET_ACTIVE_SUITE':
      return {
        ...state,
        activeUUID: action.payload,
      };
    case 'CARD_SUITE_FETCH':
      return {
        ...state,
        busy: true,
        error: null,
      };
    case 'DIGITAL_CARD_SUITE_FETCH_FULFILLED':
      return Maybe(action.payload)
        .map(
          ({
            collaborator,
            description,
            family,
            orientation,
            images_urls: images,
            mediums,
            name,
            paper_info: paperInfo,
            foil: isStampFoilCard,
            theme,
            type,
            single_sample_sku: singleSampleSku,
          }) => {
            // Get first define value per order + enabled
            const activeMediumKey = 'digital';
            const activeMediumData = mediums[activeMediumKey];
            const { default_variation_options: defaultOptions, variations } = activeMediumData;
            const updatedDefaultOptions = {
              ...defaultOptions,
              ...(state?.data?.cardType === 'PLACE' ? { 'per-guest-customizable': 'false' } : {}),
              color: state.initialVariationColor || defaultOptions.color,
            };
            const activeVariation = getActiveVariation(
              variations,
              updatedDefaultOptions,
              isStampFoilCard
            );

            return {
              ...state,
              busy: false,
              mediums,
              activeMediumKey,
              activeMediumData,
              images,
              data: {
                collaborator,
                description,
                family,
                orientation,
                name,
                paperInfo,
                cardType: getTypeFromBackendType(type), // Mapping back-end value to front-end value
                websiteTheme: theme,
                isStampFoilCard,
                isCustomFoilAvailable: false,
                singleSampleSku,
                motifs: [],
                hasCustomPhoto: false,
              },
              activeMediumSelection: { ...updatedDefaultOptions, type: activeMediumKey },
              quantity: paperInfo.qty_default,
              cost: {
                ...state.cost,
                base: 0,
                extras: 0,
                total: 0,
              },
              activeVariation,
            };
          }
        )
        .cata({
          Just: updatedState => updatedState,
          Nothing: () => state,
        });
    case 'CARD_SUITE_FETCH_FULFILLED':
      return Maybe(action.payload)
        .map(
          ({
            collaborator,
            description,
            family,
            has_custom_photo: hasCustomPhoto,
            orientation,
            images_urls: images,
            mediums,
            motifs,
            name,
            paper_info: paperInfo,
            foil: isStampFoilCard,
            theme,
            type,
            single_sample_sku: singleSampleSku,
            single_sample_available: singleSampleAvailable,
            projectUUID,
            uuid,
          }) => {
            // Choose first available medium (per order in orderedEnabledMediums)
            const activeMediumKey = orderedEnabledMediums.find(key =>
              Object.keys(mediums).includes(key)
            ) as string;
            const activeMediumData = mediums[activeMediumKey];
            const {
              available_options: availableOptions,
              default_variation_options: defaultOptions,
              variations,
            } = activeMediumData;
            const updatedDefaultOptions = {
              ...defaultOptions,
              ...(state?.data?.cardType === 'PLACE' ? { 'per-guest-customizable': 'false' } : {}),
              color: state.initialVariationColor || defaultOptions.color,
            };
            const activeVariation = getActiveVariation(
              variations,
              updatedDefaultOptions,
              isStampFoilCard
            );
            const priceFromSelection = getPriceFromActiveVariation(
              activeVariation,
              paperInfo.price_cents_min
            );

            const foilOptions =
              (availableOptions && availableOptions.foil && availableOptions.foil.options) || [];
            const isCustomFoilAvailable = !!foilOptions.find(
              (o: { value: string; enabled: boolean }) => o.value === 'custom' && o.enabled
            );

            // Until the `cardsExtraCustomization` experiment wins, manually change the "Color" label to "Theme".
            // If it wins, update the label in the BE instead (and remove this block)
            if (featureFlags.get('cardsExtraCustomization') && availableOptions.color) {
              availableOptions.color.label = 'Theme';
            }

            return {
              ...state,
              busy: false,
              mediums,
              activeMediumKey,
              activeMediumData,
              images,
              projectUUID,
              data: {
                collaborator,
                description,
                family,
                hasCustomPhoto,
                motifs,
                orientation,
                name,
                paperInfo,
                cardType: getTypeFromBackendType(type), // Mapping back-end value to front-end value
                websiteTheme: theme,
                isStampFoilCard,
                isCustomFoilAvailable,
                singleSampleSku,
                singleSampleAvailable,
                uuid,
              },
              activeMediumSelection: { ...updatedDefaultOptions, type: activeMediumKey },
              quantity: paperInfo.qty_default,
              cost: {
                ...state.cost,
                base: paperInfo.price_cents_min / 100,
                extras: priceFromSelection / 100,
                total: (paperInfo.qty_default * priceFromSelection) / 100,
              },
              activeVariation,
            };
          }
        )
        .cata({
          Just: updatedState => updatedState,
          Nothing: () => state,
        });
    case 'CARD_SUITE_FETCH_REJECTED':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_PDP_INITIAL_VARIATION_COLOR':
      return {
        ...state,
        initialVariationColor: action.payload,
      };
    case 'SET_OPTION_VALUE':
      return Maybe(action.payload)
        .map(v => {
          const { type: key, value } = v;
          let isPostcard =
            (key === 'size' ? value : state.activeMediumSelection.size) === 'postcard';
          const cardType = state?.data?.cardType;
          const isChangeTheDate = cardType === 'CHANGE_THE_DATE';
          const reversePrinting = isPostcard ? isPostcard.toString() : isChangeTheDate.toString();

          // Reselecting the same type: don't change anything.
          if (key === 'type' && value === state.activeMediumKey) {
            return state;
          }

          // Selecting another type: reset to new type's defaults.
          if (key === 'type' && ['paper', 'magnet'].includes(value)) {
            const { default_variation_options: defaultOptions } = state.mediums[value];
            const newSize = value === 'magnet' ? 'magnet' : defaultOptions.size;

            isPostcard = newSize === 'postcard';

            return {
              ...state,
              activeMediumKey: value,
              activeMediumData: state.mediums[value],
              activeMediumSelection: {
                ...defaultOptions,
                type: value,
                color: state.activeMediumSelection.color,
                size: newSize,
                'reverse-printing': isPostcard ? isPostcard.toString() : isChangeTheDate.toString(),
                ...(cardType === 'PLACE' ? { 'per-guest-customizable': 'false' } : {}),
              },
            };
          }

          // Selecting postcard or folded: reset unsupported options.
          if (key === 'size' && ['postcard', 'folded-petite'].includes(value)) {
            const paperType = state.activeMediumSelection['paper-type'];
            const isUnsupportedPaperType =
              (value === 'postcard' && DISABLED_POSTCARD_PAPER.includes(paperType)) ||
              (value === 'folded-petite' && DISABLED_FOLDED_PAPER.includes(paperType));
            const resetFoilColor = isPostcard ? 'none' : state.activeMediumSelection['foil-color'];
            const resetFoil = isPostcard ? 'none' : state.activeMediumSelection.foil;

            return {
              ...state,
              activeMediumSelection: {
                ...state.activeMediumSelection,
                silhouette: 'square',
                size: value,
                'paper-type': isUnsupportedPaperType ? 'smooth' : paperType,
                'reverse-printing': reversePrinting,
                'foil-color': resetFoilColor,
                foil: resetFoil,
              },
            };
          }

          // Selecting new custom foil color option: update foil option as well.
          if (key === 'foil-color' && state.data?.isCustomFoilAvailable) {
            return {
              ...state,
              activeMediumSelection: {
                ...state.activeMediumSelection,
                'foil-color': value,
                foil: value === 'none' ? 'none' : 'custom',
              },
            };
          }

          return {
            ...state,
            activeMediumSelection: {
              ...state.activeMediumSelection,
              [key]: value,
              'reverse-printing': reversePrinting,
            },
          };
        })
        .cata({
          // This takes the freshly updated state and recalculates cost base on selection
          Just: updatedState =>
            Just(updatedState.activeMediumSelection)
              .map(options => {
                const { activeMediumData, data } = updatedState;
                const activeVariation = getActiveVariation(
                  activeMediumData.variations,
                  options,
                  data?.isStampFoilCard
                );
                const priceFromSelection = getPriceFromActiveVariation(
                  activeVariation,
                  updatedState.data?.paperInfo.price_cents_min
                );
                return {
                  ...updatedState,
                  cost: {
                    base: (data?.paperInfo.price_cents_min as number) / 100,
                    extras: priceFromSelection / 100,
                    total: (priceFromSelection * updatedState.quantity) / 100,
                  },
                  activeVariation,
                };
              })
              .cata({
                Just: newState => newState,
                Nothing: () => updatedState,
              }),
          Nothing: () => state,
        });
    case 'SET_QUANTITY':
      return {
        ...state,
        quantity: action.payload,
        cost: {
          ...state.cost,
          total: action.payload * state.cost.extras,
        },
      };
    case 'FETCH_VARIATION_TEMPLATE_SUCCESS': {
      const { variationUUID, template } = action.payload;
      return {
        ...state,
        variationTemplates: {
          ...state.variationTemplates,
          [variationUUID]: template || null,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
