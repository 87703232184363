import {
  CARD_TYPES,
  CARD_TYPES_WITH_INFO_BANNER,
  CARD_TYPES_WITH_PROOF,
} from 'cards/constants/CardTypes';
import { CARD_SIZES } from 'cards/constants/Cards';
import { ORIENTATION } from 'cards/types';

import { PRODUCT_OPTION_DETAILS, TYPE_OPTION_DETAILS } from 'cards/constants/CardLevelOptions';

export const matchOptionDetails = (option, optionType, orientation) => {
  const finalOption =
    option === CARD_SIZES.folded && orientation === ORIENTATION.portrait
      ? `${option}-portrait`
      : option;
  return optionType === 'type' ? TYPE_OPTION_DETAILS[option] : PRODUCT_OPTION_DETAILS[finalOption];
};

const SINGLE_OPTION = { label: '1', value: 1 };
const SINGLE_OPTION_TAG = { label: '1', value: 1, tagText: 'Proof' }; // tagText will populate the zola-ui tag component on desktop
const SINGLE_OPTION_TAG_MOBILE = { label: '1 (Proof)', value: 1 }; // mobile can't display the html tag

export const enrichQtyOptions = (
  min,
  max,
  increment = 1,
  addProofOption = false,
  addProofTag = false,
  isMobile = false
) => {
  const options = [];
  if (min && max) {
    for (let i = min; i <= max; i += increment) {
      options.push({ label: i.toString(), value: i });
    }
  }

  if (addProofOption) {
    if (addProofTag) {
      options.unshift(isMobile ? SINGLE_OPTION_TAG_MOBILE : SINGLE_OPTION_TAG);
    } else {
      options.unshift(SINGLE_OPTION);
    }
  }

  return options;
};

export const hasSingleSizeOption = availableOptions => {
  return (
    availableOptions &&
    availableOptions.size.options.filter(each => {
      return each.enabled;
    }).length === 1
  );
};

export const getInformationBannerVisibility = cardType =>
  CARD_TYPES_WITH_INFO_BANNER.includes(cardType);

export const cardHasProof = cardType => CARD_TYPES_WITH_PROOF.includes(cardType);

export const getEnabledSizeOptions = (optionList, cardType, foil) => {
  return optionList.filter(option => {
    // Some day, this should be done by the BE
    if (foil && [CARD_TYPES.std].includes(cardType) && option.value === 'postcard') {
      return false;
    }
    return option.enabled;
  });
};
