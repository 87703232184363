import type {
  WRegistryCollectionSearchView,
  WProductView,
  WRegistryCollectionItemView,
} from '@zola/svc-web-api-ts-client';

export const SELECT_COLLECTION = 'zola/registryCollection/SELECT_COLLECTION';
export const SELECT_COLLECTION_BY_ID = 'zola/registryCollection/SELECT_COLLECTION_BY_ID';
export const TOGGLE_FACET_VALUE = 'zola/registryCollection/TOGGLE_FACET_VALUE';
export const REPLACE_FACET_VALUES = 'zola/registryCollection/REPLACE_FACET_VALUES';
export const RESET_FACET_VALUES = 'zola/registryCollection/RESET_FACET_VALUES';
export const UPDATE_SORT = 'zola/registryCollection/UPDATE_SORT';
export const RECEIVE_SEARCH = 'zola/registryCollection/RECEIVE_SEARCH';
export const REQUEST_SEARCH = 'zola/registryCollection/REQUEST_SEARCH';
export const RESET = 'zola/registryCollection/RESET';
export const REQUEST_DEFAULT_GIFT_CARD = 'zola/registryCollection/REQUEST_DEFAULT_GIFT_CARD';
export const RECEIVE_DEFAULT_GIFT_CARD = 'zola/registryCollection/RECEIVE_DEFAULT_GIFT_CARD';
export const REQUEST_ADD_TO_REGISTRY = 'zola/registryCollection/REQUEST_ADD_TO_REGISTRY';
export const RECEIVE_ADD_TO_REGISTRY = 'zola/registryCollection/RECEIVE_ADD_TO_REGISTRY';
export const REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID =
  'zola/registryCollection/REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID';
export const RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID =
  'zola/registryCollection/RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID';

type SelectCollectionActionType = {
  type: typeof SELECT_COLLECTION;
  payload: {
    collectionObject: { value: string; label: string };
  };
};
type SelectCollectionByIdActionType = {
  type: typeof SELECT_COLLECTION_BY_ID;
  payload: {
    collectionObjectId: string;
  };
};
type ToggleFacetValueActionType = {
  type: typeof TOGGLE_FACET_VALUE;
  payload: {
    facetKey: string;
    valueKey: string;
  };
};
type ReplaceFacetValuesActionType = {
  type: typeof REPLACE_FACET_VALUES;
  payload: {
    facetKey: string;
    facetValues: string[];
  };
};
type ResetFacetValuesActionType = {
  type: typeof RESET_FACET_VALUES;
  payload: {
    facetKey?: string;
  };
};
type UpdateSortActionType = {
  type: typeof UPDATE_SORT;
  payload: {
    sort: string;
  };
};
type ReceiveSearchActionType = {
  type: typeof RECEIVE_SEARCH;
  payload: WRegistryCollectionSearchView;
};
type RequestSearchActionType = {
  type: typeof REQUEST_SEARCH;
};
type ResetActionType = {
  type: typeof RESET;
};
type RequestDefaultGiftCardActionType = {
  type: typeof REQUEST_DEFAULT_GIFT_CARD;
};
type ReceiveDefaultGiftCardActionType = {
  type: typeof RECEIVE_DEFAULT_GIFT_CARD;
  payload: WProductView | undefined;
};
type RequestAddToRegistryActionType = {
  type: typeof REQUEST_ADD_TO_REGISTRY;
};
type ReceiveAddToRegistryActionType = {
  type: typeof RECEIVE_ADD_TO_REGISTRY;
  payload: WRegistryCollectionItemView;
};
type RequestRegistryItemByCollectionItemIdActionType = {
  type: typeof REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID;
};
type ReceivedRegistryItemByCollectionItemIdActionType = {
  type: typeof RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID;
  payload: WRegistryCollectionItemView;
};

export type RegistryCollectionActionTypes =
  | SelectCollectionActionType
  | SelectCollectionByIdActionType
  | ToggleFacetValueActionType
  | ReplaceFacetValuesActionType
  | ResetFacetValuesActionType
  | UpdateSortActionType
  | ReceiveSearchActionType
  | RequestSearchActionType
  | ResetActionType
  | RequestDefaultGiftCardActionType
  | ReceiveDefaultGiftCardActionType
  | RequestAddToRegistryActionType
  | ReceiveAddToRegistryActionType
  | RequestRegistryItemByCollectionItemIdActionType
  | ReceivedRegistryItemByCollectionItemIdActionType;
