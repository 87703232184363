import { createSelector } from 'reselect';

export const getDrafts = state => state.cards.drafts;
export const getDraftEntities = state => state.cards.drafts.entities;
export const getDraftInCartWarningStatus = state => state.cards.drafts.isDraftInCartWarning;

export const getDraftsBusy = createSelector(getDrafts, drafts => drafts.busy);

export const getDraftIds = createSelector(getDrafts, drafts => drafts.allIds);

export const getDraftsById = createSelector(getDrafts, drafts => drafts.entities.drafts);

export const getAllDrafts = createSelector(getDraftIds, getDraftsById, (draftIds, draftsById) =>
  draftIds.map(id => draftsById[id])
);

export const getDraftProject = (state, props) => {
  return state.cards.drafts.entities.drafts[props.projectUUID];
};

// wrap getPrimaryCustomization in makeGetPrimaryCustomization factory because
// it is shared across multiple component instances, each instance needs to get
// its own private copy of the selector in order to memoize correctly
export const makeGetPrimaryCustomization = () => {
  const getPrimaryCustomization = createSelector(
    getDraftEntities,
    getDraftProject,
    (entities, project) => {
      const { customizations } = entities;
      return customizations[project.primary_customization];
    }
  );
  return getPrimaryCustomization;
};

// wrap getProjectCustomizations in makeGetProjectCustomizations factory because
// it is shared across multiple component instances, each instance needs to get
// its own private copy of the selector in order to memoize correctly
export const makeGetProjectCustomizations = () => {
  const getProjectCustomizations = createSelector(
    getDraftEntities,
    getDraftProject,
    (entities, project) => project.customizations.map(uuid => entities.customizations[uuid])
  );
  return getProjectCustomizations;
};

// pre-Digital, pre-Magnet selector
export const getGroupedDrafts = createSelector(
  getDraftIds,
  getDraftsById,
  (draftIds, draftsById) => {
    return draftIds.reduce(
      (acc, draftId) => {
        acc.count += 1;
        const draft = draftsById[draftId];
        if (acc[draft.project_name]) {
          acc[draft.project_name] = [...acc[draft.project_name], draftId];
        } else {
          acc.orderedNames.push(draft.project_name);
          acc[draft.project_name] = [draftId];
        }
        return acc;
      },
      { orderedNames: [], count: 0 }
    );
  }
);

// INV-905
// organize draft data considering mediums:
export const getSortedDrafts = createSelector(
  getDraftIds,
  getDraftsById,
  getDraftEntities,
  (draftsIds, draftsById, entities) =>
    draftsIds.reduce(
      (acc, draftId) => {
        acc.count += 1;
        const { customizations } = entities;
        const draft = draftsById[draftId];
        const name = draft.project_name;
        const primaryCustomization = draft.primary_customization;
        // some bad stage data may mean primaryCustomization === undefined, so fallback to 'PAPER'
        const medium = customizations[primaryCustomization]
          ? customizations[primaryCustomization].medium
          : 'PAPER';
        const type =
          customizations[primaryCustomization] && customizations[primaryCustomization].type;
        if (acc[name] === undefined) {
          // different name = initialize a group
          acc.orderedNames.push(name);
          acc[name] = {
            projectUUID: draftId,
            primaryMedium: medium,
            versions: [],
            otherMediums: {},
            type,
          };
        } else if (acc[name] && acc[name].primaryMedium === medium) {
          // same name + same medium = different version
          acc[name].versions = acc[name].versions.concat([draftId]);
        } else if (acc[name].otherMediums[medium]) {
          // same name + different medium + add to otherMedium entry
          acc[name].otherMediums[medium] = acc[name].otherMediums[medium].concat([draftId]);
        } else if (acc[name].otherMediums[medium] === undefined) {
          // same name + different medium + create otherMedium entry
          acc[name].otherMediums[medium] = [draftId];
        }
        return acc;
      },
      { orderedNames: [], count: 0 }
    )
);

export const getAllDraftsWithPrimaryCustomizations = createSelector(
  getAllDrafts,
  getDraftEntities,
  (drafts, entities) =>
    drafts.map(draft => ({
      ...draft,
      primary_customization: entities.customizations[draft.primary_customization],
    }))
);
