import { ExternalOrderPlacedReturnType, ExternalReducerStateType } from 'reducers/external';

export const UPDATE_EXTERNAL = 'zola/external/UPDATE_EXTERNAL';
export const PLACED_ORDER = 'zola/external/PLACED_ORDER';
export const CONFIRMED_ORDER = 'zola/external/CONFIRMED_ORDER';
export const CANCELLED_ORDER = 'zola/external/CANCELLED_ORDER';
export const RESET = 'zola/external/RESET';

// TODO: MISMATCH
type UpdateExternalActionType = {
  type: typeof UPDATE_EXTERNAL;
  payload: ExternalReducerStateType;
};

type PlacedOrderActionType = {
  type: typeof PLACED_ORDER;
  payload: ExternalOrderPlacedReturnType;
};

// OTOD: MISMATCH
type ConfirmedOrderActionType = {
  type: typeof CONFIRMED_ORDER;
};

type CancelledOrderActionType = {
  type: typeof CANCELLED_ORDER;
};

type ResetActionType = {
  type: typeof RESET;
};

export type ExternalActionType =
  | UpdateExternalActionType
  | PlacedOrderActionType
  | ConfirmedOrderActionType
  | CancelledOrderActionType
  | ResetActionType;
