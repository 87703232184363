export const ActionTypes = {
  CUSTOMIZATION_CLEANUP: 'zola/card/card_customization/CUSTOMIZATION_CLEANUP',
  SET_CUSTOMIZATION_ERROR_LIST: 'zola/card/card_customization/SET_CUSTOMIZATION_ERROR_LIST',
  CLEAR_CUSTOMIZATION_ERROR_LIST: 'zola/card/card_customization/CLEAR_CUSTOMIZATION_ERROR_LIST',
};

export function customizationCleanUp() {
  return {
    type: ActionTypes.CUSTOMIZATION_CLEANUP,
  };
}

export function setCustomizationErrorList(errorsByElementUUID) {
  return {
    type: ActionTypes.SET_CUSTOMIZATION_ERROR_LIST,
    payload: errorsByElementUUID,
  };
}

export function clearCustomizationErrorList() {
  return {
    type: ActionTypes.CLEAR_CUSTOMIZATION_ERROR_LIST,
  };
}
