import { normalize } from 'normalizr';
import _isEmpty from 'lodash/isEmpty';
import { ORDERED_CUSTOMIZATION_TYPES } from 'cards/constants/CardTypes';
import { findLeadCustomization } from '../util/customization';
import ApiService from '../../util/api';

import { draftListSchema } from '../schemas';
import { getAllDrafts } from '../selectors/draftsSelector';
import { getInvitesAccountId } from '../../selectors/user/userSelectors';

export const ActionTypes = {
  DIGITAL_REQUEST_DRAFTS: 'zola/card/card_project/DIGITAL_REQUEST_DRAFTS',
  DIGITAL_RECEIVE_DRAFTS: 'zola/card/card_project/DIGITAL_RECEIVE_DRAFTS',
  DIGITAL_RECEIVE_DELETE_DRAFT: 'zola/cards/card_project/DIGITAL_RECEIVE_DELETE_DRAFT',
  DIGITAL_ADD_REMOVED_ORDER: 'zola/cards/card_project/DIGITAL_ADD_REMOVED_ORDER',
};

function requestDigitalDrafts() {
  return {
    type: ActionTypes.DIGITAL_REQUEST_DRAFTS,
  };
}

function receiveDigitalDrafts(drafts) {
  return {
    type: ActionTypes.DIGITAL_RECEIVE_DRAFTS,
    payload: {
      drafts,
    },
  };
}

function updateDigitalDrafts(drafts) {
  return {
    type: ActionTypes.DIGITAL_ADD_REMOVED_ORDER,
    payload: {
      drafts,
    },
  };
}

function receiveDeleteDraft(projectUUID) {
  return {
    type: ActionTypes.DIGITAL_RECEIVE_DELETE_DRAFT,
    payload: {
      projectUUID,
    },
  };
}

function getLastDraftEditDate({ primary_customization: customization }) {
  return customization ? new Date(customization.edited_at || customization.updated_at) : new Date();
}

function sortDraft(json) {
  const mappedDigitalDrafts = json
    .map(draft => ({
      ...draft,
      customizations: draft.customizations.sort(
        (a, b) =>
          ORDERED_CUSTOMIZATION_TYPES.indexOf(a.type) - ORDERED_CUSTOMIZATION_TYPES.indexOf(b.type)
      ),
      primary_customization: findLeadCustomization(draft.customizations),
    }))
    .sort((a, b) => getLastDraftEditDate(b) - getLastDraftEditDate(a)); // sort drafts from most recent edit to oldest
  const normalizedDigitalDrafts = normalize(mappedDigitalDrafts, draftListSchema);
  return normalizedDigitalDrafts;
}

export function fetchDigitalDrafts() {
  return dispatch => {
    dispatch(requestDigitalDrafts());
    return ApiService.get(`/web-api/v2/card-project/digital/drafts/account`).then(json => {
      return dispatch(receiveDigitalDrafts(sortDraft(json)));
    });
  };
}

export function updateDrafts() {
  return dispatch => {
    return ApiService.get(`/web-api/v2/card-project/digital/drafts/account`).then(json => {
      return dispatch(updateDigitalDrafts(sortDraft(json)));
    });
  };
}

export function deleteDraft(projectUUID) {
  return dispatch => {
    const requestBody = {
      projectUUID,
    };
    return ApiService.delete(`/web-api/v2/card-project/${projectUUID}`, requestBody).then(() =>
      dispatch(receiveDeleteDraft(projectUUID))
    );
  };
}

export const maybeFetchDigitalDrafts = () => (dispatch, getState) => {
  const state = getState();
  const drafts = getAllDrafts(state);
  const hasInvitesAccount = getInvitesAccountId(state);

  if (hasInvitesAccount && _isEmpty(drafts)) {
    return dispatch(fetchDigitalDrafts());
  }

  return null;
};
