import ApiService from '../../util/api';

export const GET_PRODUCTS = 'zola/cards/product/GET_PRODUCTS';
export const LOADING_PRODUCTS = 'zola/cards/product/LOADING_PRODUCTS';
export const PRODUCTS_LOADED = 'zola/cards/product/PRODUCTS_LOADED';
export const PRODUCTS_ERROR = 'zola/cards/product/PRODUCTS_ERROR';
export const RESET_PRODUCTS = 'zola/cards/product/RESET_PRODUCTS';

/**
 * @param {string[]} productSkuIds - Product SKU Identifiers
 */
export const findProducts = productSkuIds => dispatch => {
  dispatch({ type: GET_PRODUCTS });
  dispatch({ type: LOADING_PRODUCTS });
  ApiService.post('/web-api/v1/products/find', {
    sku_ids: productSkuIds,
  })
    .then(json => dispatch({ type: PRODUCTS_LOADED, payload: json }))
    .catch(error => dispatch({ type: PRODUCTS_ERROR, payload: error }));
};

export const resetProducts = () => dispatch => {
  dispatch({ type: RESET_PRODUCTS });
};
