export const uploadcareKey =
  typeof window !== 'undefined' && window.zola && window.zola.env === 'production'
    ? 'bed481ea2bef6baf44de'
    : 'debf625b3ed8063e511f';

export const isUploadcareEnabled = !!uploadcareKey;

export const DEFAULT_FILE_TYPES = ['image/jpeg', 'image/png'];

export const DEFAULT_MAX_FILE_SIZE_MB = 10;

export const DEFAULT_TABS = 'file dropbox gphotos';
