export const REQUEST_PAGES = 'zola/website/page/REQUEST_PAGES';
export const RECEIVE_PAGES = 'zola/website/page/RECEIVE_PAGES';
export const TOGGLE_PAGE = 'zola/website/page/TOGGLE_PAGE';
export const MOVE_PAGE = 'zola/website/page/MOVE_PAGE';
export const REQUEST_PAGE = 'zola/website/page/REQUEST_PAGE';
export const RECEIVE_PAGE = 'zola/website/page/RECEIVE_PAGE';
export const ACTIVATE_PAGE = 'zola/website/page/ACTIVATE_PAGE';
export const RECEIVE_HIDDEN_PAGE_SUGGESTIONS = 'zola/website/page/RECEIVE_HIDDEN_PAGE_SUGGESTIONS';
export const REQUEST_PROFILE_IMAGE = 'zola/website/page/REQUEST_PROFILE_IMAGE';
export const RECEIVE_PROFILE_IMAGE = 'zola/website/page/RECEIVE_PROFILE_IMAGE';

export type PageActionTypes =
  | 'zola/website/page/REQUEST_PAGES'
  | 'zola/website/page/RECEIVE_PAGES'
  | 'zola/website/page/TOGGLE_PAGE'
  | 'zola/website/page/MOVE_PAGE'
  | 'zola/website/page/REQUEST_PAGE'
  | 'zola/website/page/RECEIVE_PAGE'
  | 'zola/website/page/ACTIVATE_PAGE'
  | 'zola/website/page/RECEIVE_HIDDEN_PAGE_SUGGESTIONS'
  | 'zola/website/page/REQUEST_PROFILE_IMAGE'
  | 'zola/website/page/RECEIVE_PROFILE_IMAGE';
