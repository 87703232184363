import _every from 'lodash/every';
import moment from 'moment';

export function extractWeddingNames(weddingDetails) {
  if (
    !(weddingDetails.fullName && weddingDetails.fullName.split(' ').length > 1) ||
    (!weddingDetails.hasNoPartner &&
      !(weddingDetails.partnerFullName && weddingDetails.partnerFullName.split(' ').length > 1))
  ) {
    return null;
  }

  const [ownerUserFirstName, ...ownerUserLastNames] = weddingDetails.fullName.split(' ');
  const [partnerUserFirstName, ...partnerUserLastNames] = weddingDetails.partnerFullName
    ? weddingDetails.partnerFullName.split(' ')
    : ['Someone', 'Special'];

  return {
    owner_first_name: ownerUserFirstName,
    owner_last_name: ownerUserLastNames.join(' '),
    partner_first_name: partnerUserFirstName,
    partner_last_name: partnerUserLastNames.join(' '),
  };
}

export function extractUserWeddingDetails(userContext) {
  return (
    userContext &&
    userContext.user_role_account_weddings &&
    userContext.user_role_account_weddings[0]
  );
}

export function lookupSelectedVariation(selected, cardSuiteDetails, isMagnet = false) {
  const mediumInfo = isMagnet ? cardSuiteDetails.mediums.magnet : cardSuiteDetails.mediums.paper;

  const variation = mediumInfo.variations.find(v => {
    return _every(
      Object.keys(selected).map(k => selected[k] && v.option_values[k] === selected[k])
    );
  });

  return variation;
}

export function lookupDefaultVariation(cardSuiteDetails, isMagnet = false) {
  if (!cardSuiteDetails) {
    return null;
  }

  let defaultOptions =
    cardSuiteDetails.mediums[isMagnet ? 'magnet' : 'paper'].default_variation_options;

  defaultOptions = {
    ...defaultOptions,
    size: isMagnet ? 'magnet' : defaultOptions.size,
  };

  const defaultVariation = lookupSelectedVariation(defaultOptions, cardSuiteDetails, isMagnet);

  return defaultVariation;
}

export function matchColorToCardVariation(cardSuiteDetails, color, isMagnet = false) {
  const mediumInfo = isMagnet ? cardSuiteDetails.mediums.magnet : cardSuiteDetails.mediums.paper;
  const defaultOptions = mediumInfo.default_variation_options;

  const match = {
    ...defaultOptions,
    color: color || defaultOptions.color,
    size: isMagnet ? 'magnet' : defaultOptions.size,
  };

  const variation = lookupSelectedVariation(match, cardSuiteDetails, isMagnet);

  return variation;
}

export function parseWeddingDate(date) {
  if (!date) {
    return null;
  }

  if (date.length !== 10) {
    return null;
  }

  const parsedDate = moment(
    `${date.substring(0, 4)}-${date.substring(5, 7)}-${date.substring(8, 10)}`
  );

  if (!parsedDate.isValid()) {
    return null;
  }

  return parsedDate;
}

export function createSubstitutionsFromObjects(weddingNames, weddingDate) {
  const substitutions = {};
  const parsedDate = parseWeddingDate(weddingDate);

  if (weddingNames) {
    if (weddingNames.owner_first_name.length > 0) {
      substitutions.PrimaryFirstName = weddingNames.owner_first_name;
      substitutions.PrimaryFirstNameInitial = weddingNames.owner_first_name[0].toUpperCase();
    }

    if (weddingNames.owner_last_name.length > 0) {
      substitutions.PrimaryLastName = weddingNames.owner_last_name;
      substitutions.PrimaryLastNameInitial = weddingNames.owner_last_name[0].toUpperCase();
    }

    if (weddingNames.partner_first_name.length > 0) {
      substitutions.PartnerFirstName = weddingNames.partner_first_name;
      substitutions.PartnerFirstNameInitial = weddingNames.partner_first_name[0].toUpperCase();
    }

    if (weddingNames.partner_last_name.length > 0) {
      substitutions.PartnerLastName = weddingNames.partner_last_name;
      substitutions.PartnerLastNameInitial = weddingNames.partner_last_name[0].toUpperCase();
    }
  }

  if (parsedDate) {
    substitutions.Month = parsedDate.format('MMMM');
    substitutions.MonthNumber = parsedDate.format('M');
    substitutions.MonthNumber2Digits = parsedDate.format('MM');
    substitutions.Day = parsedDate.format('D');
    substitutions.DayNumber = parsedDate.format('D');
    substitutions.DayNumber2Digits = parsedDate.format('DD');
    substitutions.Year = parsedDate.format('YYYY');
    substitutions.Year2Digits = parsedDate.format('YY');
  }

  return substitutions;
}

export function createSubstitutions(weddingDetails) {
  let substitutions = {};

  if (weddingDetails) {
    const names = extractWeddingNames(weddingDetails);
    const { date } = weddingDetails;

    substitutions = createSubstitutionsFromObjects(names, date);
  }

  return substitutions;
}

export function getIsSampleCustomizable(cardSuiteDetails, isMagnet = false) {
  if (!cardSuiteDetails) {
    return false;
  }

  return !(cardSuiteDetails.letterpress || cardSuiteDetails.foil || isMagnet);
}
