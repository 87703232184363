import { createSelector } from 'reselect';

export const getSubtotal = state => state.cards.customization.review.total;
export const getPendingChanges = state => state.cards.customization.hasPendingChanges;

// formHistory selectors
export const getFormHistory = state => state.cards.formHistory;

// customizationBar selectors
export const getCustomizationErrorsByElementUUID = state =>
  state.cards.cardCustomizationBar.errorsByElementUUID;

export const getCustomizationHasErrors = createSelector(
  getCustomizationErrorsByElementUUID,
  errorsByElementUUID => !!errorsByElementUUID && errorsByElementUUID.length > 0
);

// recipients selectors
export const getGuestGroups = state => state?.guestList?.guests?.byId;
export const getRecipientsIsBusy = state => state.cards.recipients.busy;
export const getSelectedGuestIds = state => state.cards.recipients.selectedGuestIds;
export const getFontSizeOverrides = state => state.cards.recipients.fontSizeOverrides;
export const getIsFirstFontResizeComplete = state =>
  state.cards.recipients.isFirstFontResizeComplete;
export const getInvitedGuestsCount = state => state.guestList.guests.orderedIds.length || 0;

export const getGuestGroupsMissingEmailIds = createSelector(
  getGuestGroups,
  getSelectedGuestIds,
  (guestGroups, selectedGuestIds) =>
    selectedGuestIds.filter(
      id =>
        guestGroups[id] &&
        (guestGroups[id].email_address === null || guestGroups[id].email_address === '')
    )
);

export const getIsGuestListMissingEmail = createSelector(
  getGuestGroupsMissingEmailIds,
  missingEmailIds => missingEmailIds.length > 0
);
