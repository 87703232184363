import { normalize } from 'normalizr';
import { updateDrafts } from 'cards/actions/digitalDraftsActions';
import ApiService from '../../util/api';

import { digitalOrderListSchema } from '../schemas';
import { ORDERED_CUSTOMIZATION_TYPES } from '../constants/CardTypes';
import { findLeadCustomization } from '../util/customization';

export const ActionTypes = {
  DIGITAL_REQUEST_ORDERS: 'zola/card/card_project/DIGITAL_REQUEST_ORDERS',
  DIGITAL_RECEIVE_ORDERS: 'zola/card/card_project/DIGITAL_RECEIVE_ORDERS',
  DIGITAL_RECEIVE_CANCELED_ORDER: 'zola/card/card_project/DIGITAL_RECEIVE_CANCELED_ORDER',
};

function requestDigitalOrders() {
  return {
    type: ActionTypes.DIGITAL_REQUEST_ORDERS,
  };
}

function receiveDigitalOrders(normalizedOrders) {
  return {
    type: ActionTypes.DIGITAL_RECEIVE_ORDERS,
    payload: {
      normalizedOrders,
    },
  };
}

function receiveDeleteProject(projectUUID) {
  return {
    type: ActionTypes.DIGITAL_RECEIVE_CANCELED_ORDER,
    payload: {
      projectUUID,
    },
  };
}

function getLastOrderEditDate({ primary_customization: customization }) {
  return customization ? new Date(customization.edited_at || customization.updated_at) : new Date();
}

export const fetchDigitalOrders = () => {
  return dispatch => {
    dispatch(requestDigitalOrders());
    return ApiService.get(`/web-api/v2/card-project/digital/orders/account`).then(json => {
      const mappedOrders = json
        .map(order => ({
          ...order,
          customizations: order.customizations.sort(
            (a, b) =>
              ORDERED_CUSTOMIZATION_TYPES.indexOf(a.type) -
              ORDERED_CUSTOMIZATION_TYPES.indexOf(b.type)
          ),
          primary_customization: findLeadCustomization(order.customizations),
        }))
        .sort((a, b) => getLastOrderEditDate(b) - getLastOrderEditDate(a)); // sort drafts from most recent edit to oldest
      const normalizedDigitalDrafts = normalize(mappedOrders, digitalOrderListSchema);
      return dispatch(receiveDigitalOrders(normalizedDigitalDrafts));
    });
  };
};

export function moveToDrafts(projectUUID) {
  return dispatch =>
    ApiService.post(`/web-api/v2/card-project/${projectUUID}/digital/unpublish`).then(() => {
      dispatch(updateDrafts());
      dispatch(receiveDeleteProject(projectUUID));
    });
}

export function deleteOrder(projectUUID) {
  return dispatch => {
    const requestBody = {
      projectUUID,
    };
    return ApiService.delete(`/web-api/v2/card-project/${projectUUID}`, requestBody).then(() =>
      dispatch(receiveDeleteProject(projectUUID))
    );
  };
}
