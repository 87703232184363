import { schema } from 'normalizr';

export const customizationSchema = new schema.Entity(
  'customizations',
  {},
  {
    idAttribute: 'project_customization_uuid',
  }
);

export const draftSchema = new schema.Entity(
  'drafts',
  {
    customizations: [customizationSchema],
    primary_customization: customizationSchema,
  },
  {
    idAttribute: 'project_uuid',
  }
);

export const digitalOrderSchema = new schema.Entity(
  'orders',
  {
    customizations: [customizationSchema],
    primary_customization: customizationSchema,
  },
  {
    idAttribute: 'project_uuid',
  }
);

export const digitalOrderListSchema = new schema.Array(digitalOrderSchema);

export const draftListSchema = new schema.Array(draftSchema);

export const projectSchema = new schema.Entity(
  'projects',
  {
    customizations: [customizationSchema],
  },
  {
    idAttribute: 'project_uuid',
  }
);

export const orderSchema = new schema.Entity(
  'orders',
  {
    projects: [projectSchema],
  },
  {
    idAttribute: 'split_order_id',
  }
);

export const orderListSchema = new schema.Array(orderSchema);
