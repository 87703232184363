import _values from 'lodash/values';

import {
  createAccount as createPaperAccount,
  getUserContext,
  createInvitationsAccount,
} from 'paper/utils/account';
import { copyUploadcareFileToZola } from 'components/common/UploadcareWidget/utils/api';

import ApiService from '../../../../../../util/api';
import {
  extractWeddingNames,
  extractUserWeddingDetails,
  matchColorToCardVariation,
  createSubstitutions,
} from '../helpers';
import fetchTemplate from './fetchTemplate';

export function createAccount(accountDetails, weddingDetails) {
  return getUserContext().then(user =>
    createPaperAccount({
      accountDetails,
      weddingDetails,
      shouldTrackOnboarding: false,
      isGuest: user.is_guest,
    })
  );
}

export function getCardSuiteDetails(cardSuiteUUID, isAdminView = false) {
  return ApiService.post(`/web-api/v1/card-catalog/suite/details/${cardSuiteUUID}`, {
    show_unlisted: !!isAdminView,
  });
}

export function uploadPhoto(photo, crop) {
  if (!photo.valid) {
    return Promise.resolve();
  }

  const formData = new FormData();

  formData.append('attachment', photo.file);
  formData.append('size', Math.max(photo.size.width, photo.size.height));

  return fetch('/web-api/v1/image/temp-upload', {
    credentials: 'same-origin',
    method: 'POST',
    body: formData,
  })
    .then(response => response.json())
    .then(data => {
      return fetch('/web-api/v1/image/crop-save', {
        credentials: 'same-origin',
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          bucketName: data.s3_bucket,
          s3key: data.s3_key,
          crop,
          contentType: data.content_type,
        }),
      });
    })
    .then(response => response.json())
    .then(json => json && json.data.uuid);
}

export function isSingleSampleLimitExhausted(cardType) {
  if (!cardType) {
    return Promise.resolve(false);
  }

  return ApiService.get(`/web-api/v1/card-single-sample/available/${cardType}`).then(
    available => !available
  );
}

export function getAvailableSamplesCount(cardType) {
  if (!cardType) {
    return Promise.resolve(null);
  }

  return ApiService.get(`/web-api/v1/card-single-sample/availability/${cardType}`);
}

export function saveSingleSampleFormAsDraft(
  cardSuiteDetails,
  form,
  userContext,
  photoUploadHandler,
  overrideActiveVariationUUID,
  isMagnet
) {
  const { variation, variationSet } = form.customizations;
  // * For MULTI, only the first card of the selected card list will be created as a draft
  // * In that very case, cardSuiteDetails.uuid being the last displayed card suite in the previous preview,
  // * The variation uuid returned for draft creation and suite uuid (cardSuiteDetails.uuid) can be out of synch
  // * Find the right suite UUID for the variation picked from the variationSet object (properly ordered by suite UUID).
  const suiteUUID =
    Object.keys(variationSet).find(suite => variationSet[suite].uuid === variation.uuid) ||
    cardSuiteDetails.uuid;

  const activeVariationUUID =
    (variation && variation.uuid) ||
    overrideActiveVariationUUID ||
    matchColorToCardVariation(cardSuiteDetails, form.customizations.color, isMagnet).uuid;

  let accountPromise;
  if (!userContext.is_guest && !userContext.has_invitation_account) {
    // We manually create an invitations account since it's required to load the drafts & orders pages.
    accountPromise = createInvitationsAccount().then(() => ({}));
  } else {
    accountPromise = Promise.resolve({});
  }

  return accountPromise
    .then(result => {
      if (result.errors) {
        return result;
      }

      const { photo, pixelCrop, uploadcareFile } = form.customizations || {};

      // Uploadcare uploader: copy file to Zola
      if (uploadcareFile) {
        return copyUploadcareFileToZola(uploadcareFile, true).then(zolaFile => ({
          ...result,
          photoUUID: zolaFile.uuid,
        }));
      }

      // Deprecated Zola uploader
      if (photo && photo.valid && pixelCrop) {
        return photoUploadHandler(form.customizations.photo, form.customizations.pixelCrop).then(
          photoUUID => ({
            ...result,
            photoUUID,
          })
        );
      }

      return result;
    })
    .then(result => {
      if (result.errors) {
        return result;
      }

      const weddingNames = extractWeddingNames(form.weddingDetails);

      if (!weddingNames) {
        return {
          errors: {
            saveDraft: {
              field: {
                fullName: 'Please make sure you enter your full name',
                partnerFullName: "Please make sure you enter your partner's full name",
              },
            },
          },
        };
      }

      const substitutions = createSubstitutions(form && form.weddingDetails);

      const cardInfo = {
        variation_uuid: activeVariationUUID,
        suite_uuid: suiteUUID,
        wedding_details: {
          ...weddingNames,
          event_date: !form.weddingDetails.stillDeciding && form.weddingDetails.date,
          ...substitutions,
        },
      };

      const body = {
        ...cardInfo,
        photo_uuid: result.photoUUID,
      };

      const cannotSaveDraft = 'Your single sample draft could not be saved.';

      return ApiService.post('/web-api/v1/card-single-sample/draft', body).then(saveDraftResult => {
        if (saveDraftResult.status === 'error' || saveDraftResult.errors) {
          return { errors: { saveDraft: { form: saveDraftResult.message || cannotSaveDraft } } };
        }

        return {};
      });
    });
}

export function getTemplate(
  cardSuiteDetails,
  variation,
  color,
  weddingDetails,
  sampleNotCustomizable,
  isMagnet
) {
  if (!cardSuiteDetails || !color) {
    return Promise.resolve();
  }

  const matched = variation || matchColorToCardVariation(cardSuiteDetails, color, isMagnet);

  const substitutions = sampleNotCustomizable ? {} : createSubstitutions(weddingDetails);
  return fetchTemplate([matched.uuid], substitutions).then(data => {
    return _values(data)[0];
  });
}

export function getLocationData(userContext) {
  const weddingDetails = extractUserWeddingDetails(userContext);
  const slug = weddingDetails && weddingDetails.slug;

  if (!slug) {
    return Promise.resolve(null);
  }

  return ApiService.get(`/web-api/v1/card-single-sample/prefill-content/${slug}`);
}

export function getSingleSampleOrders(cardType) {
  return ApiService.get(`/web-api/v1/card-single-sample/orders/${cardType}`);
}

if (typeof window !== 'undefined') {
  window.apicall = getSingleSampleOrders;
}
