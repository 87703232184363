export const ActionTypes = {
  OPEN_MODAL: 'zola/cards/modal/OPEN_MODAL',
  HIDE_MODAL: 'zola/cards/modal/HIDE_MODAL',
  UPDATE_MODAL_OPTIONS: 'zola/modal/UPDATE_MODAL_OPTIONS',
  FLIP_FORMAT_FLAG: 'zola/cards/modal/FLIP_FORMAT_FLAG',
  IS_BUSY: 'zola/cards/modal/IS_BUSY',
};

export const hideModal = () => ({ type: ActionTypes.HIDE_MODAL });

export const openModal = (modalType, modalProps = {}, modalOptions = {}) => ({
  type: ActionTypes.OPEN_MODAL,
  payload: {
    modalType,
    modalProps,
    modalOptions,
  },
});

export const updateModalOptions = modalOptions => ({
  type: ActionTypes.UPDATE_MODAL_OPTIONS,
  payload: modalOptions,
});
