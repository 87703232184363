import { AppThunk } from 'reducers/index';
import { getCardTemplateLayoutUuid } from 'cards/selectors/reversePrintingLayouts';
import { getBackendTypeFromType } from 'cards/util/cardTypes';
import { CARD_TYPES } from 'cards/components/CardListing/types';
import { ReversePrintingLayoutsState } from 'cards/reducers/reversePrintingLayouts';
import ApiService from '../../util/api';

// Actions
export const UPDATE_LAYOUTS_STATE = 'cards/actions/reversePrintingLayouts/UPDATE_LAYOUTS';

interface UpdateLayoutsState {
  type: typeof UPDATE_LAYOUTS_STATE;
  payload: Partial<ReversePrintingLayoutsState>;
}

const updateLayoutsState = (
  payload: Partial<ReversePrintingLayoutsState>
): ReversePrintingLayoutsAction => ({
  type: UPDATE_LAYOUTS_STATE,
  payload,
});

export const fetchLayouts = (
  cardType: string,
  size: string,
  variationUuid: string
): AppThunk<any> => dispatch => {
  if (!cardType || !size || !variationUuid) return Promise.resolve(null);
  const backendType = getBackendTypeFromType(cardType as CARD_TYPES);

  dispatch(updateLayoutsState({ loading: true }));

  return ApiService.get(
    `/web-api/v2/card-template/card-template-layout/${backendType}/${size}/${variationUuid}`
  ).then(result => {
    return dispatch(
      updateLayoutsState({ layouts: result.layouts, loading: false, uuid: result.uuid })
    );
  });
};

export const updateSelectedLayout = (
  cardTemplateLayoutContentUuid: string,
  customizationUuid: string,
  pageUuid: string
): AppThunk<Promise<void>> => (dispatch, getState) => {
  const cardTemplateLayoutUuid = getCardTemplateLayoutUuid(getState());

  const requestBody = {
    card_template_layout_uuid: cardTemplateLayoutUuid,
    card_template_layout_content_uuid: cardTemplateLayoutContentUuid,
  };

  return ApiService.put(
    `/web-api/v2/card-project/customization/${customizationUuid}/page/${pageUuid}/update-customization-page-template-layout`,
    requestBody
  );
};

export const setIsRecroppingInProgress = (newStatus: boolean): AppThunk<void> => dispatch => {
  dispatch(updateLayoutsState({ isRecroppingInProgress: !!newStatus }));
};

export type ReversePrintingLayoutsAction = UpdateLayoutsState;
