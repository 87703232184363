import React from 'react';
import moment from 'moment';
import cx from 'classnames';

// components
import { ButtonV2 } from '@zola/zola-ui/src/components/Button';
import { DatePickerInput } from '@zola/zola-ui/src/components/Form/DatePickerInput';
import { DropdownV2 } from '@zola/zola-ui/src/components/Form/Dropdown';
import { InputFieldV2 } from '@zola/zola-ui/src/components/Form/InputFieldV2';
import Preview from '../Steps/Photo/Preview';

import styles from './styles.module.less';

interface ButtonProps {
  btnText: string;
  disabled?: boolean;
  handleClick: () => void;
  variant?: string;
}

const Button = ({
  btnText,
  disabled,
  handleClick,
  variant = 'primary',
}: ButtonProps): JSX.Element => (
  <ButtonV2
    className={cx(styles.btn, styles.mobileBtn)}
    disabled={disabled}
    onClick={handleClick}
    variant={variant}
  >
    {btnText}
  </ButtonV2>
);

interface BackButtonProps {
  handleClick: () => void;
}

const BackButton = ({ handleClick }: BackButtonProps): JSX.Element => (
  <Button btnText="Back" variant="secondary" handleClick={handleClick} />
);

interface ButtonWrapperProps {
  children: React.ReactNode;
}

const ButtonWrapper = ({ children }: ButtonWrapperProps): JSX.Element => (
  <div className={styles.btnWrapper}>{children}</div>
);

const formatDate = (date?: Date): string => {
  return moment.max(moment(date), moment()).format('YYYY-MM-DD');
};

const parseDate = (dateString?: string): Date => {
  // Return today's date if the input is empty or invalid
  if (!dateString || dateString.length === 0 || !moment(dateString).isValid()) {
    return moment().toDate();
  }

  // Fallback on today's date if selected date is in the past
  return moment.max(moment(dateString), moment()).toDate();
};

interface DateFieldProps {
  className: string;
  errorMessage: string;
  label: string | JSX.Element;
  onChange: (value: string) => void;
  value: string | null;
}

const DateField = ({
  className,
  errorMessage,
  label,
  onChange,
  value,
}: DateFieldProps): JSX.Element => (
  <DatePickerInput
    className={cx(styles.field, className)}
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; label: string | Element...
    label={label}
    formatDate={formatDate}
    parseDate={parseDate}
    isFormField
    inputOnChange={e => onChange(e.target.value)}
    value={value || ''}
    inputFieldProps={{
      name: 'date',
      errorMessage,
    }}
    placeholder="MM/DD/YYYY"
  />
);

interface StateOptions {
  label: string;
  value: string;
}

interface DropdownProps {
  errorMessage: string;
  label: string;
  onSelect: (option: string) => void;
  options: StateOptions[];
  selected: string | null;
  width: number;
}

const Dropdown = ({
  errorMessage,
  label,
  onSelect,
  options,
  selected,
  width,
}: DropdownProps): JSX.Element => (
  <div className={styles.field} style={{ width }}>
    <DropdownV2
      label={label}
      isRequired
      onSelect={onSelect}
      options={options}
      value={selected}
      id="state"
      className={styles.defensiveDropdownFix}
      errorMessage={errorMessage}
    />
  </div>
);

interface FieldProps {
  errorMessage?: string;
  isRequired?: boolean;
  label: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  value: string;
  width?: number;
}

const Field = ({ width, ...props }: FieldProps): JSX.Element => (
  <div className={styles.field} style={{ width }}>
    <InputFieldV2 {...props} />
  </div>
);

interface GroupProps {
  children?: React.ReactNode;
}

const Group = ({ children }: GroupProps): JSX.Element => (
  <div className={styles.group}>{children}</div>
);

interface HeaderProps {
  currentStep?: number;
  description: string | JSX.Element;
  steps?: number;
  title: string;
}

const Header = ({ currentStep, description, steps, title }: HeaderProps): JSX.Element => (
  <>
    {currentStep && steps && (
      <div className={styles.step}>
        Step {currentStep} of {steps}
      </div>
    )}
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
  </>
);

const Required = (): JSX.Element => (
  <div className={styles.required}>
    <span className={styles.red}>*</span> Required
  </div>
);

const Spacer = (): JSX.Element => <div className={styles.spacer} />;

interface TinyCardsPreviewProps {
  cardSuiteUUIDList: string[];
  designCustomizationsSet: any;
  templatesSet: any;
}

const TinyCardsPreview = ({
  cardSuiteUUIDList,
  designCustomizationsSet,
  templatesSet,
}: TinyCardsPreviewProps): JSX.Element => (
  <div className={styles.tinyCardsWrapper}>
    {cardSuiteUUIDList.map(cardSuiteUUID => {
      const customizations = designCustomizationsSet[cardSuiteUUID];
      const page = templatesSet[cardSuiteUUID].pages[0];

      return (
        <div className={styles.tinyCard} key={`tiny-preview-${cardSuiteUUID}`}>
          <Preview customizations={customizations} page={page} width={52} />
        </div>
      );
    })}
  </div>
);

export default {
  Button,
  BackButton,
  ButtonWrapper,
  DateField,
  Dropdown,
  Field,
  Group,
  Header,
  Required,
  Spacer,
  TinyCardsPreview,
};
