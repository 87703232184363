import fetch from 'isomorphic-fetch';
import Cookies from 'universal-cookie';
import { browserHistory } from 'react-router';
import { toastsActions, ApiService as SharedApiService } from '@zola-helpers/client';

import _find from 'lodash/find';
import ApiService from '../util/api';
import * as ActionType from './types/PublicWebsiteActionTypes';
import { createGuest } from './GuestActions';
import { hideModal } from './ModalActions';
import { trackGuestRsvp } from '../util/trackingHelper';

export const entityNames = {
  wedding_party: 'wedding_party_members',
  home: 'homepage_sections',
  event: 'events',
  travel: 'travels',
  photos: 'photos',
  registry: 'wedding_registries',
  faq: 'faqs',
  things_to_do: 'pois',
  rsvp: 'rsvp_questions',
  videos: 'videos',
  video: 'video', // homepage is singular video  ¯\_(ツ)_/¯
};

function handleErrors(response) {
  if (response.error === 'forbidden') {
    browserHistory.push('/wedding/manage/guests/all');
  }
  return response;
}

function requestWedding() {
  return {
    type: ActionType.REQUEST_WEDDING,
  };
}

function requestPage(pageSlug) {
  return {
    type: ActionType.REQUEST_PAGE,
    pageSlug,
  };
}

function receivePage(pageSlug, data) {
  const entityName = entityNames[pageSlug.toLowerCase()];
  const baseEntities = data[entityName];
  let videoEntities = [];
  if (entityName === entityNames.home && data.video) videoEntities = [data.video];
  if (entityName === entityNames.photos && data.videos) videoEntities = data.videos;
  const entities = [...baseEntities, ...videoEntities];

  return {
    type: ActionType.RECEIVE_PAGE,
    entityName,
    payload: data,
    entities,
  };
}

export function receiveWedding(data) {
  return {
    type: ActionType.RECEIVE_WEDDING,
    payload: data,
  };
}

export function weddingNotFound(err) {
  return {
    type: ActionType.NOT_FOUND,
    error: err,
  };
}

function updateGuestSearchCookies() {
  const cookies = new Cookies();
  const date = new Date();
  const cookieMinutesToExpire = 30;
  const searchesNotActedOnLimit = 5;
  const guestSearchesNotActedOn = parseInt(cookies.get('guestSearchesNotActedOn'), 10) || 0;
  // disable search lockout for development - removing staging for react-16 testing
  if (window.zola.env === 'development') {
    return;
  }
  date.setTime(date.getTime() + 1000 * 60 * cookieMinutesToExpire);
  cookies.set('guestSearchesNotActedOn', guestSearchesNotActedOn + 1, { expires: date });

  if (guestSearchesNotActedOn + 1 >= searchesNotActedOnLimit) {
    cookies.set('rsvpSearchDisabled', date.getTime(), { expires: date });
  }
}

export function receiveGuestSearch(json) {
  updateGuestSearchCookies();
  return {
    type: ActionType.RECEIVE_GUEST_SEARCH,
    payload: json,
  };
}

export function revealAccessForm() {
  return {
    type: ActionType.REVEAL_ACCESS_FORM,
    payload: true,
  };
}

export function hideAccessForm() {
  return {
    type: ActionType.HIDE_ACCESS_FORM,
    payload: false,
  };
}

export function resetGuestSearch() {
  return {
    type: ActionType.RESET_GUEST_SEARCH,
  };
}

export function requestGuestRsvp() {
  return {
    type: ActionType.REQUEST_GUEST_RSVP,
  };
}

export function receiveGuestRsvp(json) {
  return {
    type: ActionType.RECEIVE_GUEST_RSVP,
    payload: json,
  };
}

export function resetGuestRsvp() {
  return {
    type: ActionType.RESET_GUEST_RSVP,
  };
}

function receiveWebsiteVisibility(visible) {
  return {
    type: ActionType.RECEIVE_WEBSITE_VISIBILITY,
    payload: visible,
  };
}

export function receiveGuestRsvpSuccess(json) {
  return {
    type: ActionType.RECEIVE_GUEST_RSVP_SUCCESS,
    payload: json,
  };
}

export function resetGuestRsvpSuccess() {
  return {
    type: ActionType.RECEIVE_GUEST_RSVP_SUCCESS,
    payload: {
      success: false,
    },
  };
}
export function storeMobileToken(token) {
  return {
    type: ActionType.STORE_MOBILE_TOKEN,
    payload: {
      token,
    },
  };
}

export function receiveVirtualEventPage(eventData) {
  return {
    type: ActionType.RECEIVE_VIRTUAL_EVENT_PAGE,
    payload: eventData,
  };
}

export function getWeddingBySlug(slug) {
  return dispatch => {
    dispatch(requestWedding());
    return fetch(`/web-api/v1/publicwedding/slug/${slug}`, {
      credentials: 'same-origin',
    })
      .then(ApiService.handleErrors)
      .then(response => response.json())
      .then(json => dispatch(receiveWedding(json)));
  };
}

export function getWeddingPasscodeViewBySlug(slug) {
  return dispatch => {
    dispatch(requestWedding());
    return fetch(`/web-api/v1/publicwedding/slug/${slug}/passcode`, {
      credentials: 'same-origin',
    })
      .then(ApiService.handleErrors)
      .then(response => response.json())
      .then(json => dispatch(receiveWedding(json)));
  };
}

export function submitPasscode(request) {
  return () =>
    fetch('/web-api/v1/publicwedding/passcode', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    }).then(ApiService.handleErrors);
}

export function getWeddingPageByType(pageSlug) {
  return (dispatch, getState) => {
    dispatch(requestPage(pageSlug));
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    const { slug } = getState().publicWebsite.wedding;
    return fetch(
      `/web-api/v1/publicwedding/page/slug/${pageSlug}/wedding_account/id/${weddingAccountId}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receivePage(pageSlug, json)))
      .catch(error => {
        dispatch(weddingNotFound(error));
        browserHistory.push(`/wedding/${slug}`);
      });
  };
}

// v2 for mobile snapshot hidden pages support
export function getWeddingPageByTypeV2(pageSlug) {
  return (dispatch, getState) => {
    dispatch(requestPage(pageSlug));
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    const viewHiddenPage = getState().routing.locationBeforeTransitions.query.view_hidden_page
      ? getState().routing.locationBeforeTransitions.query.view_hidden_page
      : false;
    const { slug } = getState().publicWebsite.wedding;
    const token = getState().publicWebsite.token.token
      ? getState().publicWebsite.token.token
      : false;
    return fetch(
      `/web-api/v1/publicwedding/page/slug/${pageSlug}/wedding_account/id/${weddingAccountId}?view_hidden_page=${viewHiddenPage}&token=${token}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receivePage(pageSlug, json)))
      .catch(error => {
        dispatch(weddingNotFound(error));
        browserHistory.push(`/wedding/${slug}`);
      });
  };
}

export function getPublicRsvpPage() {
  return (dispatch, getState) => {
    dispatch(requestPage('rsvp'));
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    const { slug } = getState().publicWebsite.wedding;
    return fetch(
      `/web-api/v2/publicwedding/page/slug/rsvp/wedding_account/id/${weddingAccountId}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receivePage('rsvp', json)))
      .catch(error => {
        dispatch(weddingNotFound(error));
        browserHistory.push(`/wedding/${slug}`);
      });
  };
}

export function getPublicRsvpPageV2() {
  return (dispatch, getState) => {
    dispatch(requestPage('rsvp'));
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    const viewHiddenPage = getState().routing.locationBeforeTransitions.query.view_hidden_page;
    const { slug } = getState().publicWebsite.wedding;
    const { token } = getState().publicWebsite.token;
    return fetch(
      `/web-api/v2/publicwedding/page/slug/rsvp/wedding_account/id/${weddingAccountId}?view_hidden_page=${viewHiddenPage}&token=${token}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receivePage('rsvp', json)))
      .catch(error => {
        dispatch(weddingNotFound(error));
        browserHistory.push(`/wedding/${slug}`);
      });
  };
}

export function guestSearch(guestName) {
  return (dispatch, getState) => {
    dispatch({ type: 'GUEST_SEARCH' });
    const uuid = getState().publicWebsite.wedding.wedding_account_uuid;
    const url = `/web-api/v1/publicwedding/rsvp/guest/wedding_account/uuid/${uuid}/search_groups`;
    return fetch(url, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ guest_name: guestName }),
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveGuestSearch(json));
        return json;
      });
  };
}

export function getGuestRsvpByUuid(guestId) {
  return (dispatch, getState) => {
    dispatch(requestGuestRsvp());
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return fetch(
      `/web-api/v1/publicwedding/rsvp/guest/uuid/${guestId}/wedding_account/id/${weddingAccountId}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receiveGuestRsvp(json)));
  };
}

export function getGuestGroupRsvpByUuid(guestGroupUUID) {
  return (dispatch, getState) => {
    dispatch(requestGuestRsvp());
    const weddingAccountId = getState().wedding.account.id;
    return fetch(
      `/web-api/v1/publicwedding/rsvp/guest/uuid/${guestGroupUUID}/wedding_account/id/${weddingAccountId}`,
      {
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receiveGuestRsvp(json)));
  };
}

function normalizeRsvpInput(guest) {
  if (guest.name_unknown) {
    return Object.assign({}, guest, {
      prefix: undefined,
      first_name: undefined,
      family_name: undefined,
      suffix: undefined,
    });
  }
  if (guest.rsvp === 'DECLINED') {
    return Object.assign({}, guest, { meal_option_id: null });
  }
  return Object.assign({}, guest);
}

function normalizeRsvpInputV2(guest) {
  // const eventInvitations = guest.event_invitations.map(id => guest.event_invitations[id]);
  if (guest.name_unknown) {
    return Object.assign({}, guest, {
      prefix: undefined,
      first_name: undefined,
      family_name: undefined,
      suffix: undefined,
    });
  }
  const { firstName, familyName } = guest.name; // assign name: { firstName, lastName } to first_name and last_name
  const normalizedRsvp = Object.assign({}, guest, {
    first_name: firstName,
    family_name: familyName,
  });
  return normalizedRsvp;
}

export function updateGuestRsvp(guestRsvpRequest) {
  const guests = guestRsvpRequest.guests.map(guest => normalizeRsvpInput(guest));
  const formattedRsvpRequest = Object.assign({}, guestRsvpRequest, { guests });
  return (dispatch, getState) => {
    dispatch(requestGuestRsvp());
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return fetch(`/web-api/v1/publicwedding/rsvp/wedding_account/id/${weddingAccountId}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedRsvpRequest),
    })
      .then(response => response.json())
      .then(json => {
        trackGuestRsvp();
        return dispatch(receiveGuestRsvp(json));
      });
  };
}

export function updateWebsiteVisibility(enable) {
  return dispatch =>
    fetch('/web-api/v1/weddingAccount/publish', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ enable }),
    }).then(() => dispatch(receiveWebsiteVisibility(!enable)));
}

export function getRsvpByGuestUuid(guestGroupUuid) {
  return (dispatch, getState) => {
    dispatch(requestGuestRsvp());
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return fetch(
      `/web-api/v2/publicwedding/rsvp/guest/uuid/${guestGroupUuid}/wedding_account/id/${weddingAccountId}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receiveGuestRsvp(json)));
  };
}

export function getRsvpByGuestGroupId(guestGroupId) {
  return (dispatch, getState) => {
    dispatch(requestGuestRsvp());
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return fetch(
      `/web-api/v2/publicwedding/rsvp/guest/id/${guestGroupId}/wedding_account/id/${weddingAccountId}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receiveGuestRsvp(json)));
  };
}

export function getRsvpByGuestGroupUuid(guestGroupUuid) {
  return (dispatch, getState) => {
    dispatch(requestGuestRsvp());
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return fetch(
      `/web-api/v2/publicwedding/rsvp/guest_group/uuid/${guestGroupUuid}/wedding_account/id/${weddingAccountId}`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(receiveGuestRsvp(json)));
  };
}

export function updateGuestRsvpV2(guestRsvpRequest) {
  const guests = guestRsvpRequest.guests.map(guest => normalizeRsvpInputV2(guest));
  const answers = guestRsvpRequest.rsvp_answers.allIds.map(id => {
    const rsvpAnswer = guestRsvpRequest.rsvp_answers[id];
    const { answer } = rsvpAnswer;
    if (answer === '') {
      return { ...rsvpAnswer, answer: null };
    }
    return rsvpAnswer;
  });
  const formattedRsvpRequest = Object.assign({}, guestRsvpRequest, {
    guests,
    rsvp_answers: answers,
  });

  return (dispatch, getState) => {
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return fetch(`/web-api/v2/publicwedding/rsvp/wedding_account/id/${weddingAccountId}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formattedRsvpRequest),
    })
      .then(response => response.json())
      .then(json => {
        trackGuestRsvp();
        return dispatch(receiveGuestRsvpSuccess(json));
      });
  };
}

export function rsvpConfirmation(rsvpConfirmationRequest) {
  return () => {
    return fetch('/web-api/v2/publicwedding/rsvp/confirmation', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rsvpConfirmationRequest),
    });
  };
}

// manage the guest request request response and any necessary rerouting
export function manageGuestRequests(notificationObject) {
  if (notificationObject.length === 0) {
    browserHistory.push('/wedding/manage/guests/all');
    return {
      type: ActionType.GET_GUEST_REQUESTS,
      payload: notificationObject,
    };
  }
  return {
    type: ActionType.GET_GUEST_REQUESTS,
    payload: notificationObject,
  };
}

export function manageGuestRequestCount(json) {
  return {
    type: ActionType.RECEIVE_GUEST_COUNT,
    payload: json,
  };
}

// get all guest requests .. request
export function getGuestRequests() {
  return (dispatch, getState) => {
    const weddingAccountId = getState().wedding.account.id;
    return fetch(
      `/web-api/v2/publicwedding/rsvp/request/wedding_account/id/${weddingAccountId}/list`,
      {
        credentials: 'same-origin',
      }
    )
      .then(response => response.json())
      .then(json => dispatch(manageGuestRequests(json)));
  };
}

export function getGuestRequestCount() {
  return dispatch => {
    return fetch('/website-nav/web-api/v1/nav/wedding_account/id/count', {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(json => dispatch(manageGuestRequestCount(json)));
  };
}

// triggers off success state for submitted rsvp request
function handleRequestSubmit() {
  return dispatch => {
    dispatch({
      type: ActionType.RSVP_REQUEST_SUBMITTED,
      payload: true,
    });
  };
}

// receive guest request by uuid

export function receiveGuestByUuid(json) {
  return {
    type: ActionType.RECEIVE_GUEST_REQUEST_BY_UUID,
    payload: json,
  };
}

export function triggerAddGuestPage() {
  return {
    type: ActionType.REVEAL_GUEST_FORM,
    payload: true,
  };
}

// get guest request by uuid

export function grabGuestByUuid(guestUuid) {
  return (dispatch, getState) => {
    const weddingAccountId = getState().wedding.account.id;
    const requestBody = JSON.stringify({ uuid: guestUuid, wedding_account_id: weddingAccountId });
    return fetch('/web-api/v2/publicwedding/rsvp/request/uuid', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: requestBody,
    })
      .then(response => response.json())
      .then(json => handleErrors(json))
      .then(json => dispatch(receiveGuestByUuid(json)));
  };
}

// create request for rsvp by guest not in the guest list
export function createRsvpRequest(payload) {
  return (dispatch, getState) => {
    const publicWeddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    const requestObject = {
      wedding_account_id: publicWeddingAccountId,
      first_name: payload.first_name,
      family_name: payload.family_name,
      email_address: payload.email_address,
      message: payload.message,
    };
    return fetch('/web-api/v2/publicwedding/rsvp/request', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestObject),
    })
      .then(response => response.json())
      .then(() => dispatch(handleRequestSubmit()));
  };
}

// create request for rsvp by guest not in the guest list
export function createRsvpRequestV2(payload) {
  return (dispatch, getState) => {
    const publicWeddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return SharedApiService.post('/web-api/v3/publicwedding/rsvp/request', {
      wedding_account_id: publicWeddingAccountId,
      ...payload,
    }).then(() => dispatch(handleRequestSubmit()));
  };
}

export function guestSubmitted() {
  return {
    type: ActionType.GUEST_SUCCESSFULLY_SUBMITTED,
  };
}

// decline guest request to be added :smirk:

export function processDecline(notificationObject) {
  const firstName = notificationObject.first_name;
  const familyName = notificationObject.family_name;
  const guestUuid = notificationObject.uuid;
  const headline = `${firstName} ${familyName}'s RSVP request has been declined.`;

  return (dispatch, getState) => {
    const weddingAccountId = getState().wedding.account.id;
    const requestBody = JSON.stringify({ uuid: guestUuid, wedding_account_id: weddingAccountId });
    return fetch('/web-api/v2/publicwedding/rsvp/request/decline', {
      credentials: 'same-origin',
      method: 'PUT',
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(() => dispatch(hideModal()))
      .then(() => dispatch(guestSubmitted()))
      .then(() => dispatch(getGuestRequestCount()))
      .then(json => {
        dispatch(toastsActions.positive({ headline }));
        return json;
      })
      .then(() => browserHistory.push('/wedding/manage/guests/rsvp-notifications'));
  };
}

export function submitGuestGroupRequestFromNotification(createGuestGroupRequest, guestUuid) {
  const firstName = createGuestGroupRequest.guests[0].first_name;
  const familyName = createGuestGroupRequest.guests[0].family_name;
  const headline = `${firstName} ${familyName} RSVP request has been accepted.`;
  return dispatch => {
    return fetch(`/web-api/v2/publicwedding/rsvp/request/uuid/${guestUuid}/accept`, {
      credentials: 'same-origin',
      method: 'PUT',
      body: JSON.stringify(createGuestGroupRequest),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(() => dispatch(guestSubmitted()))
      .then(() => dispatch(getGuestRequestCount()))
      .then(() => dispatch(getGuestRequests()))
      .then(json => {
        dispatch(toastsActions.positive({ headline }));
        return json;
      })
      .then(() => browserHistory.push('/wedding/manage/guests/rsvp-notifications'));
  };
}

function normalizeGuestInputs(guest) {
  const mealOptionId = guest.meal_option_id ? parseInt(guest.meal_option_id, 10) : undefined;
  const prefix = guest.prefix || undefined;
  const suffix = guest.suffix || undefined;

  if (guest.name_unknown) {
    return Object.assign({}, guest, {
      prefix: undefined,
      first_name: undefined,
      family_name: undefined,
      suffix: undefined,
      meal_option_id: mealOptionId,
    });
  }
  return Object.assign({}, guest, { prefix, suffix, meal_option_id: mealOptionId });
}

// Maybe this should just be on web-api side...
export function formatGuestGroupRequest(guestGroupRequest) {
  const formattedRequest = Object.assign({}, guestGroupRequest, {
    invited: guestGroupRequest.invited === 'true' || guestGroupRequest.invited === true,
    save_the_date_sent:
      guestGroupRequest.save_the_date_sent === 'true' ||
      guestGroupRequest.save_the_date_sent === true,
    invitation_sent:
      guestGroupRequest.invitation_sent === 'true' || guestGroupRequest.invitation_sent === true,
  });

  // Make sure relationship_type is set and in an acceptable configuration and add common fields to primary only
  const guests = guestGroupRequest.guests.map((guest, index) => {
    const inviteObject = formattedRequest.event_invitations;
    const filteredInvites = [];
    inviteObject.forEach(invite => {
      if (invite.checked === true) {
        filteredInvites.push(invite);
      }
    });

    switch (index) {
      case 0:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'PRIMARY',
          event_invitations: filteredInvites,
        });
      case 1: {
        const relationshipType =
          guest.relationship_type === 'PRIMARY' ? 'PARTNER' : guest.relationship_type;
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: relationshipType,
          event_invitations: filteredInvites,
        });
      }
      default:
        return Object.assign({}, normalizeGuestInputs(guest), {
          relationship_type: 'CHILD',
          event_invitations: filteredInvites,
        });
    }
  });

  // Return the final request object
  return Object.assign({}, formattedRequest, { guests });
}

// add guest from request notification
export function addGuestFromRequestNotification(createGuestGroupRequest) {
  // handle notification messaging
  const guestGroupRequest = formatGuestGroupRequest(createGuestGroupRequest);
  return (dispatch, getState) => {
    const guestUuid = getState().publicWebsite.guestRequestByUuid.uuid;
    dispatch(createGuest());
    return dispatch(submitGuestGroupRequestFromNotification(guestGroupRequest, guestUuid));
  };
}

export function showWebsiteRsvpSummary(value) {
  return {
    type: ActionType.SHOW_RSVP_SUMMARY,
    payload: value,
  };
}

export function handleModalAction(action) {
  return {
    type: ActionType.HANDLE_RSVP_MODAL_ACTION,
    payload: action,
  };
}

export const getEventEncodedPage = hash => {
  return (dispatch, getState) => {
    const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return SharedApiService.get(
      `/web-api/v1/publicwedding/page/event/encoded/${hash}/wedding_account/id/${weddingAccountId}`
    ).then(json => {
      dispatch(receiveVirtualEventPage(json));
      return json;
    });
  };
};

export const getPassThroughZoomMeetingData = hash => {
  return dispatch => {
    // const weddingAccountId = getState().publicWebsite.wedding.wedding_account_id;
    return SharedApiService.get(
      `/web-api/v1/publicwedding/page/event/encoded/${hash}/read-through`
    ).then(json => {
      dispatch(receiveVirtualEventPage(json));
      return json;
    });
  };
};

export function hideTopNavigation() {
  return {
    type: ActionType.HIDE_TOP_NAVIGATION,
  };
}

function receiveWeddingTheme(data) {
  return {
    type: ActionType.RECEIVE_WEDDING_THEME,
    payload: data,
  };
}

export function fetchThemeOverride(themeKey) {
  return dispatch => {
    return fetch(`/web-api/v2/theme/${themeKey}`, {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => {
        const { themes } = json;
        if (themes) {
          const match = _find(themes, o => o.key === themeKey);
          if (match) {
            const pickedValues = {
              font_name: match.font_name,
              map_pin: match.map_pin,
              map_style: match.map_style,
              key: match.key,
              background_color: match.background_color,
            };
            dispatch(receiveWeddingTheme(pickedValues));
          }
        }
      });
  };
}

export function togglePoiMaps() {
  return {
    type: ActionType.TOGGLE_POI_MAPS,
  };
}
