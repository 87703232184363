import fetch from 'isomorphic-fetch';
import { openModal, hideModal } from './modalActions';

export const ActionTypes = {
  FILE_UPLOADING: 'zola/cards/file/FILE_UPLOADING',
  FILE_UPLOADED: 'zola/cards/file/FILE_UPLOADED',
  STORE_REFERENCE_KEY: 'zola/cards/file/STORE_REFERENCE_KEY',
  UPLOAD_INTERRUPTED: 'zola/cards/file/UPLOAD_INTERRUPTED',
  IS_BUSY: 'zola/cards/modal/IS_BUSY', // this should not be here, kept to maintain compatability with handleFailedSubmit action below
};

const fileUploading = contentKey => ({
  type: ActionTypes.FILE_UPLOADING,
  payload: { contentKey },
});

export const fileUploaded = (fileData, contentKey) => ({
  type: ActionTypes.FILE_UPLOADED,
  payload: { contentKey, fileData },
});

const uploadInterrupted = contentKey => ({
  type: ActionTypes.UPLOAD_INTERRUPTED,
  payload: { contentKey },
});

const checkImage = path =>
  new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve({ path, status: 'ok', width: img.width, height: img.height });
    img.onerror = () => resolve({ path, status: 'error' });
    img.src = path;
  });

export const handleFailedSubmit = referenceKey => dispatch => {
  dispatch({
    type: ActionTypes.STORE_REFERENCE_KEY,
    payload: referenceKey,
  });
  dispatch({
    type: ActionTypes.IS_BUSY,
    payload: false,
  });
};

const saveCrop = (fileData, crop, contentKey) => dispatch => {
  const { s3_bucket: bucketName, s3_key: s3key, content_type: contentType } = fileData;

  dispatch(fileUploading(contentKey));

  const requestBody = JSON.stringify({ bucketName, s3key, crop, contentType });
  return fetch('/web-api/v1/image/crop-save', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: requestBody,
  })
    .then(response => response.json())
    .then(json => json.data)
    .then(croppedFileData => {
      dispatch(fileUploaded(croppedFileData, contentKey));
      return croppedFileData;
    });
};

export const uploadToZolaAndCrop = (
  file,
  contentKey,
  onUploadComplete,
  aspectRatio = 1,
  minWidth = 1200,
  minHeight = 1200
) => dispatch => {
  const URL = window.URL || window.webkitURL;
  const path = URL.createObjectURL(file.files[0]);

  dispatch(fileUploading(contentKey));

  // Used to render an ImageUpload component in error modals
  const uploadProps = {
    aspectRatio,
    contentKey,
    minWidth,
    minHeight,
    onUploadComplete,
  };

  return checkImage(path).then(img => {
    // Check image dimensions and bail if too small
    if (img.width < minWidth || img.height < minHeight) {
      dispatch(uploadInterrupted(contentKey));
      dispatch(
        openModal(
          'REJECT_SMALL_PHOTO_MODAL',
          { w: img.width, h: img.height, minWidth, minHeight, uploadProps },
          { size: 'md' }
        )
      );
      return true;
    }

    // Check file size and bail if too big
    const fileBytes = file.files[0].size / 1024 / 1000;
    if (fileBytes > 10) {
      dispatch(uploadInterrupted(contentKey));
      dispatch(openModal('REJECT_BIG_PHOTO_MODAL', { bytes: fileBytes, uploadProps }));
      return true;
    }

    // Upload file to temporary s3 bucket
    const formData = new FormData();
    formData.append('attachment', file.files[0], file.name);
    formData.append('size', Math.max(img.width, img.height));
    return fetch('/web-api/v1/image/temp-upload', {
      credentials: 'same-origin',
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(fileData => {
        dispatch(fileUploaded(fileData, contentKey));

        // When upload succeeds, open the crop modal
        const onCropSelect = crop => {
          if (crop && crop.width && crop.height) {
            if (crop.width < minWidth || crop.height < minHeight) {
              dispatch(
                openModal(
                  'REJECT_SMALL_PHOTO_MODAL',
                  { w: crop.width, h: crop.height, minWidth, minHeight, uploadProps },
                  { size: 'md' }
                )
              );
            } else {
              // When user finishes cropping, save cropped image to S3
              dispatch(saveCrop(fileData, crop, contentKey))
                .then(croppedFileData => onUploadComplete({ [contentKey]: croppedFileData.uuid }))
                .then(() => dispatch(hideModal()));
            }
          } else {
            // TODO: dispatch action to handle error state where crop, crop.width, or crop.height is not defined
          }
        };

        dispatch(
          openModal(
            'CROP_MODAL',
            { aspectRatio, imageUrl: fileData.url, img, onCropSelect },
            { size: 'md' }
          )
        );
      });
  });
};
