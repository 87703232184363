import * as ActionTypes from '../../actions/types/GuestActionTypes';
// import { mockImportState as initialState } from '../util/mockStates';
const initialState = {
  busy: false,
  isMapping: false,
  mappingErrors: [],
  mappingSummaries: [],
  readyToMap: false,
  token: null,
  uploadErrors: [],
  userFields: null,
  zolaFields: null,
  extraFields: [],
  requiredFields: [],
};

const guestImportReducer = (state = initialState, action) => {
  switch (action.type) {
    // busy
    case ActionTypes.SET_BUSY: {
      const { busy } = action.payload;
      return { ...state, busy };
    }
    // busy, isMapping, extraFields, userFields, zolaFields, requiredFields
    case ActionTypes.RECEIVE_CSV_FIELDS: {
      const {
        requiredFields,
        token,
        userFields,
        zolaDisplayFields,
        zolaExtraFields,
      } = action.payload;
      const zolaFieldsObj = zolaDisplayFields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: { name: field, assignment: null },
        }),
        {}
      );
      const userFieldsObj = userFields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: { name: field, target: null },
        }),
        {}
      );
      return {
        ...state,
        busy: false,
        readyToMap: true,
        requiredFields,
        token,
        userFields: userFieldsObj,
        zolaFields: zolaFieldsObj,
        extraFields: zolaExtraFields,
      };
    }
    // busy, uploadErrors
    case ActionTypes.RECEIVE_UPLOAD_ERRORS: {
      const { uploadErrors } = action.payload;
      return { ...state, busy: false, uploadErrors };
    }
    // uploadErrors
    case ActionTypes.CLEAR_UPLOAD_ERRORS: {
      return { ...state, uploadErrors: [] };
    }
    // isMapping, readyToMap
    case ActionTypes.MAPPING_STARTED: {
      return { ...state, isMapping: true, readyToMap: false };
    }
    // userFields, zolaFields
    case ActionTypes.MAP_COLUMNS: {
      const { userCol, zolaCol } = action.payload;
      const userFields = {
        ...state.userFields,
        [userCol]: { name: userCol, target: zolaCol },
      };
      const zolaFields = {
        ...state.zolaFields,
        [zolaCol]: { name: zolaCol, assignment: userCol },
      };

      return { ...state, userFields, zolaFields };
    }
    // userFields, zolaFields
    case ActionTypes.UNMAP_COLUMNS: {
      const { userCol, zolaCol } = action.payload;
      const userFields = {
        ...state.userFields,
        [userCol]: { name: userCol, target: null },
      };
      const zolaFields = {
        ...state.zolaFields,
        [zolaCol]: { name: zolaCol, assignment: null },
      };

      return { ...state, userFields, zolaFields };
    }
    // extraFields, zolaFields
    case ActionTypes.ADD_EXTRA_FIELD: {
      const { fieldName } = action.payload;
      const extraFields = state.extraFields.filter(field => field !== fieldName);
      const zolaFields = {
        ...state.zolaFields,
        [fieldName]: { name: fieldName, assignment: null },
      };
      return { ...state, extraFields, zolaFields };
    }
    // busy
    case ActionTypes.SEND_FIELD_MAP: {
      return { ...state, busy: true };
    }
    // mappingErrors, mappingSummary, busy
    case ActionTypes.RECEIVE_MAPPING_ERRORS: {
      const { summaries, errors } = action.payload;
      return {
        ...state,
        mappingErrors: errors,
        mappingSummaries: summaries,
        busy: false,
      };
    }
    // mappingErrors, mappingSummaries
    case ActionTypes.CLEAR_MAPPING_ERRORS: {
      return { ...state, mappingErrors: [], mappingSummaries: [] };
    }
    // isMapping
    case ActionTypes.MAPPING_COMPLETE: {
      return { ...state, isMapping: false, busy: false };
    }
    // reset
    case ActionTypes.RESET_MAPPING: {
      return initialState;
    }

    default:
      return state;
  }
};

export default guestImportReducer;
