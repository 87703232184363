import fetch from 'isomorphic-fetch';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import _hasIn from 'lodash/hasIn';

import type {
  SetWeddingAccountFlagRequest,
  UpdateSearchSettings,
  UpdateWeddingAccountRequest,
  UpdateWeddingRequest,
  WThemeGroupView,
  WThemeView,
  WWeddingAccountView,
  WWeddingView,
  UpdateWebsiteDateRequest,
  WWeddingThemeView,
  WThemeGroupDetailView,
} from '@zola/svc-web-api-ts-client';
import * as ActionType from './types/WeddingActionTypes';
import type { WeddingActionTypes } from './types/WeddingActionTypes';
import {
  getWeddingTheme,
  getWeddingThemeKey,
  getWeddingThemeGroupTitle,
  getWeddingThemeName,
} from '../selectors/website/websiteSelectors';
import type { AppThunk } from '../reducers';
import { togglePoiMaps } from './PublicWebsiteActions';

function requestWedding(): WeddingActionTypes {
  return {
    type: ActionType.REQUEST_WEDDING,
  };
}

export function receiveWeddingAccount(json: WWeddingAccountView): WeddingActionTypes {
  return {
    type: ActionType.RECEIVE_WEDDING_ACCOUNT,
    payload: {
      account: json,
    },
  };
}

function receiveWedding(json: WWeddingView): WeddingActionTypes {
  return {
    type: ActionType.RECEIVE_WEDDING,
    payload: {
      wedding: json,
    },
  };
}

function requestTheme(): WeddingActionTypes {
  return {
    type: ActionType.THEME_REQUESTED,
  };
}

// V1 - WThemeView | V2 - WWeddingThemeView
export function receiveTheme(
  json: WThemeView | WWeddingThemeView
): ActionType.ReceiveThemeActionType {
  return {
    type: ActionType.THEME_RECEIVED,
    payload: {
      theme: json,
    },
  };
}

function requestThemeGroup(): WeddingActionTypes {
  return {
    type: ActionType.THEMEGROUP_REQUESTED,
  };
}

// V1 - WThemeGroupView | V2 - WThemeGroupDetailView
export function receiveThemeGroup(
  json: WThemeGroupView | WThemeGroupDetailView
): WeddingActionTypes {
  return {
    type: ActionType.THEMEGROUP_RECEIVED,
    payload: {
      theme: json,
    },
  };
}

// This may not be needed but i need to get something to prod
export function disableWebsiteOnboarding(): WeddingActionTypes {
  return {
    type: ActionType.DISABLE_WEBSITE_ONBOARDING,
  };
}

export function enableGuestListOnboarding(): WeddingActionTypes {
  return {
    type: ActionType.ENABLE_GUEST_LIST_ONBOARDING,
  };
}

export function enableGuestListTour(): WeddingActionTypes {
  return {
    type: ActionType.ENABLE_GUEST_LIST_TOUR,
  };
}

export function fetchWedding(): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestWedding());
    return fetch('/web-api/v1/wedding', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveWedding(json)));
  };
}

export function fetchWeddingAccount(): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestWedding());
    return fetch('/web-api/v1/weddingAccount', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveWeddingAccount(json)));
  };
}

export function fetchWeddingThemeGroup(key?: string): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestThemeGroup());
    return fetch(`/web-api/v2/theme/${key}`, {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveThemeGroup(json)));
  };
}

export function fetchWeddingTheme(): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestTheme());
    return fetch('/web-api/v1/wedding/theme', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveTheme(json));
        return dispatch(fetchWeddingThemeGroup(json.key));
      });
  };
}

export function fetchWeddingThemeV2(themeKey: string): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestTheme());
    return fetch(`/web-api/v2/theme/${themeKey}`, {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveTheme(json)));
  };
}

export function updateWeddingTheme(
  themeId: number,
  toastHeadlineType: 'group' | 'individual' = 'group'
): AppThunk<Promise<void>> {
  return (dispatch, getState): Promise<void> => {
    return fetch('/web-api/v1/wedding/theme', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        theme_id: themeId,
      }),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveTheme(json)))
      .then(() => dispatch(fetchWeddingThemeGroup(getWeddingThemeKey(getState()))))
      .then(() => {
        const customizePath = '/wedding/manage/website/customize';
        const isCustomizable = _hasIn(getWeddingTheme(getState()), 'customization_default');
        const customizableLink =
          isCustomizable && window.location.pathname !== customizePath
            ? {
                linkText: 'Customize Fonts & Colors',
                linkDestination: customizePath,
              }
            : {};
        dispatch(
          toastsActions.positive({
            headline: `${
              toastHeadlineType === 'group'
                ? getWeddingThemeGroupTitle(getState())
                : getWeddingThemeName(getState())
            } Design Selected`,
            ...customizableLink,
          })
        );
      });
  };
}

export function updateWebsitePublish(enable: boolean): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    fetch('/web-api/v1/weddingAccount/publish', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ enable }),
    }).then(() => dispatch(fetchWeddingAccount()));
}

export type UpdateSearchSettingsClientType = Omit<UpdateSearchSettings, 'account_id'>;

export function updateSearchSettings(
  request: UpdateSearchSettingsClientType
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    fetch('/web-api/v1/wedding/searchSettings', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(request),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveWedding(json)));
}

// TODO: remove and replace all instances with updateWebsitePublish
export const toggleWebsite = (value: boolean): AppThunk<Promise<WeddingActionTypes>> => (
  dispatch
): Promise<WeddingActionTypes> => {
  return dispatch(updateWebsitePublish(value));
};

export type UpdateWeddingAccountRequestClientType = Omit<
  UpdateWeddingAccountRequest,
  'wedding_account_id' | 'account_id'
>;
export function updateWeddingAccount(
  request: UpdateWeddingAccountRequestClientType
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    fetch('/web-api/v1/weddingAccount/update', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(request),
    })
      .then(response => response.json())
      .then(json => dispatch(receiveWeddingAccount(json)));
}

export type UpdateWeddingRequestClientType = Omit<UpdateWeddingRequest, 'account_id'>;

export const postUpdateWedding = (body: UpdateWeddingRequestClientType): Promise<WWeddingView> => {
  return ApiService.post<WWeddingView>('/web-api/v1/wedding/update', body);
};

export function updateWedding(
  request: UpdateWeddingRequestClientType
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    postUpdateWedding(request).then(json => dispatch(receiveWedding(json)));
}

export function updateShowPoiMap(hidden: boolean): AppThunk<Promise<void>> {
  return (dispatch, getState): Promise<void> => {
    const weddingAccount = getState().wedding.account;
    weddingAccount.enable_poi_maps = hidden;
    return dispatch(updateWeddingAccount(weddingAccount as UpdateWeddingAccountRequest)).then(
      () => {
        dispatch(togglePoiMaps());
      }
    );
  };
}

export function updateGuestListOnboarding(enable: boolean): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    fetch('/web-api/v1/weddingAccount/guest_list_onboarding', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ enable }),
    }).then(() => dispatch(fetchWeddingAccount()));
}

export function updateFeatureFlag(
  uuid: string,
  flags: SetWeddingAccountFlagRequest
): AppThunk<Promise<WeddingActionTypes>> {
  return (dispatch): Promise<WeddingActionTypes> =>
    fetch('/web-api/v1/weddingAccount/features', {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ uuid, flags }),
    }).then(() => dispatch(fetchWeddingAccount()));
}

export function updateCustomDomain(customDomain: string): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return ApiService.put<WWeddingView>('/web-api/v2/wedding/custom_domain', {
      custom_domain: customDomain,
    }).then((response: WWeddingView) => {
      dispatch(receiveWedding(response));
    });
  };
}

export const postUpdateWeddingDate = (
  body: UpdateWebsiteDateRequest
): Promise<WWeddingAccountView> => {
  return ApiService.post<WWeddingAccountView>('/web-api/v1/weddingAccount/update-date', body);
};

export const submitWeddingAndWebsiteDate = (
  weddingRequest: UpdateWeddingRequestClientType,
  websiteDateRequest: UpdateWebsiteDateRequest,
  disableToast?: boolean,
  cb?: () => void
): AppThunk<Promise<[WWeddingView, WWeddingAccountView]>> => {
  return (dispatch): Promise<[WWeddingView, WWeddingAccountView]> => {
    return postUpdateWedding(weddingRequest).then((weddingRes: WWeddingView) => {
      return postUpdateWeddingDate(websiteDateRequest).then(
        (weddingAccountRes: WWeddingAccountView) => {
          dispatch(receiveWeddingAccount(weddingAccountRes));
          dispatch(receiveWedding(weddingRes));
          if (!disableToast) {
            dispatch(toastsActions.positive({ headline: 'Wedding Details Updated' }));
          }
          if (cb) {
            cb();
          }
          return [weddingRes, weddingAccountRes];
        }
      );
    });
  };
};

export const fetchWeddingThemeGroupV2 = (key?: string): AppThunk<Promise<WeddingActionTypes>> => {
  return (dispatch): Promise<WeddingActionTypes> => {
    dispatch(requestThemeGroup());
    return ApiService.get<WThemeGroupDetailView>(`/web-api/v3/theme/key/${key}/group`)
      .then(data => dispatch(receiveTheme(data)))
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Unable to fetch wedding theme group');
      });
  };
};

export const updateWeddingWebsiteThemeV2 = (
  themeKey: string,
  themeLayout?: string
): AppThunk<Promise<void>> => {
  const payload: {
    themeKey: string;
    themeLayout?: string;
  } = { themeKey };
  if (themeLayout) {
    payload.themeLayout = themeLayout;
  }
  return (dispatch, getState): Promise<void> => {
    return ApiService.put<WWeddingThemeView>('/web-api/v2/weddingTheme/update', payload)
      .then(data => dispatch(receiveTheme(data)))
      .then(() => dispatch(fetchWeddingThemeGroupV2(themeKey)))
      .then(() => {
        dispatch(
          toastsActions.positive({
            headline: `${getWeddingThemeGroupTitle(getState())} Design Selected`,
          })
        );
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Unable to update wedding theme');
      });
  };
};
