import _findIndex from 'lodash/findIndex';
import type {
  WCardSuiteSummaryView,
  WWeddingView,
  WCardVariationColorSummaryView,
  WCardTemplateView,
  WCardSummaryView,
} from '@zola/svc-web-api-ts-client';
import moment from 'moment';
import { notEmpty } from '../../../../../util/typeUtil';
import type {
  WeddingDetailsSubstitutions,
  CardSuiteInfoSummary,
  CustomCardSuiteInfo,
  CustomTemplateSummary,
} from './types';

export const mapCardSuiteToCardInfoSummary = (
  suite: WCardSuiteSummaryView
): CardSuiteInfoSummary => {
  const cardInfo: CardSuiteInfoSummary = {};
  cardInfo.uuid = suite.uuid;
  cardInfo.cardType = suite.lead_card?.type;
  cardInfo.singleSampleSku = suite.single_sample_sku;
  cardInfo.singleSampleAvailable = suite.single_sample_available;
  cardInfo.isMagnet =
    suite.lead_card?.medium === (('MAGNET' as unknown) as WCardSummaryView.MediumEnum);
  cardInfo.isLetterpress = suite.lead_card?.letterpress;
  cardInfo.sampleCard = suite;

  return cardInfo;
};

export const mapCardInfoSummaryToCardSuite = (
  summary: CardSuiteInfoSummary
): WCardSuiteSummaryView => {
  return summary.sampleCard || {};
};

// Pass initialColor so that variation of initial color is first in returned array
export const mapCardSuiteToVariations = (
  suite: WCardSuiteSummaryView,
  initialColor?: string
): string[] => {
  if (!suite.lead_card) return [];

  const { colors = [] } = suite.lead_card;
  const orderedColors = colors; // Edit suite in memory, so that colors rendered in this order as well
  if (initialColor) {
    const indexOfInitialColor = _findIndex(colors, c => {
      return c.value === initialColor.toLowerCase();
    });
    if (indexOfInitialColor > -1) {
      const initialVariation = orderedColors.splice(indexOfInitialColor, 1)[0];
      orderedColors.unshift(initialVariation);
    }
  }
  return orderedColors.map(c => c.default_variation_uuid).filter(notEmpty);
};

const mapColorSummaryToCustomTemplateSummary = (
  c: WCardVariationColorSummaryView,
  templateRes: Record<string, WCardTemplateView> | null
): CustomTemplateSummary => {
  const { default_variation_uuid: variationUuid } = c;
  const previewTemplate = templateRes && variationUuid ? templateRes[variationUuid] : undefined;
  const colorPreviewTemplate = {
    ...previewTemplate,
    ...c,
  };
  return colorPreviewTemplate;
};

export const mapCardSuiteSummaryToPreviewTemplates = (
  s: WCardSuiteSummaryView,
  templateRes: Record<string, WCardTemplateView> | null
): CustomTemplateSummary[] | undefined => {
  const colorSummaries = s.lead_card?.colors;
  return colorSummaries?.map(c => mapColorSummaryToCustomTemplateSummary(c, templateRes));
};

export const mapCardSuiteSummaryToCustomCardSuiteInfo = (
  s: WCardSuiteSummaryView,
  templateRes: Record<string, WCardTemplateView> | null
): CustomCardSuiteInfo => {
  const customPreviewTemplates = mapCardSuiteSummaryToPreviewTemplates(s, templateRes);
  const cardSuiteInfo = mapCardSuiteToCardInfoSummary(s);

  return {
    cardSuiteInfo,
    customPreviewTemplates,
  };
};

const getNameInitial = (name: string): string => {
  return name[0].toUpperCase();
};

export const mapWeddingDetailsToSubstitutions = (
  userWeddingDetails?: WWeddingView
): WeddingDetailsSubstitutions => {
  const substitutions: WeddingDetailsSubstitutions = {};
  if (!userWeddingDetails) return substitutions;
  const {
    owner_first_name: ownerFirstName,
    owner_last_name: ownerLastName,
    partner_first_name: partnerFirstName,
    partner_last_name: partnerLastName,
    wedding_date: weddingDate,
  } = userWeddingDetails;

  if (ownerFirstName) {
    substitutions.PrimaryFirstName = ownerFirstName;
    substitutions.PrimaryFirstNameInitial = getNameInitial(ownerFirstName);
  }

  if (ownerLastName) {
    substitutions.PrimaryLastName = ownerLastName;
    substitutions.PrimaryLastNameInitial = getNameInitial(ownerLastName);
  }

  if (partnerFirstName) {
    substitutions.PartnerFirstName = partnerFirstName;
    substitutions.PartnerFirstNameInitial = getNameInitial(partnerFirstName);
  } else {
    substitutions.PartnerFirstName = 'Someone';
    substitutions.PartnerFirstNameInitial = 'S';
  }

  if (partnerLastName) {
    substitutions.PartnerLastName = partnerLastName;
    substitutions.PartnerLastNameInitial = getNameInitial(partnerLastName);
  } else {
    substitutions.PartnerLastName = 'Special';
    substitutions.PartnerLastNameInitial = 'S';
  }

  if (weddingDate) {
    const date = moment(weddingDate);
    substitutions.Month = date.format('MMMM');
    substitutions.MonthNumber = date.format('M');
    substitutions.MonthNumber2Digits = date.format('MM');
    substitutions.Day = date.format('D');
    substitutions.DayNumber = date.format('D');
    substitutions.DayNumber2Digits = date.format('DD');
    substitutions.Year = date.format('YYYY');
    substitutions.Year2Digits = date.format('YY');
  }

  return substitutions;
};
