export const REQUEST_SIZE = 'zola/cart/REQUEST_SIZE';
export const RECEIVE_SIZE = 'zola/cart/RECEIVE_SIZE';
export const REQUEST_CART = 'zola/cart/REQUEST_CART';
export const RECEIVE_CART = 'zola/cart/RECEIVE_CART';
export const ADD_ITEM = 'zola/cart/ADD_ITEM';
export const ADDED_ITEM = 'zola/cart/ADDED_ITEM';
export const DELETE_ITEM = 'zola/cart/DELETE_ITEM';
export const DELETED_ITEM = 'zola/cart/DELETE_ITEM';
export const UPDATE_ITEM_QUANTITY = 'zola/cart/UPDATE_ITEM_QUANTITY';
export const RESET_CART = 'zola/cart/RESET_CART';
export const UNLOAD_CART = 'zola/cart/UNLOAD_CART';
