import * as ActionTypes from '../actions/types/ExternalActionTypes';

// https://github.com/NewAmsterdamLabs/web-zola/blob/development/app/controllers/api/v0/ExternalOrderApiController.scala#L36
export type ExternalOrderConfirmData = {
  quantity: number;
  giftGiverName: string;
  purchasedInStore: boolean;
  externalStoreName?: string;
  orderNumber?: string | null;
};

// https://github.com/NewAmsterdamLabs/web-zola/blob/development/app/controllers/api/v0/ExternalOrderApiController.scala#L44
export type ExternalOrderPlaceData = {
  collectionItemId: string;
  quantity: number;
  giftGiverName?: string | null;
  giftGiverEmail: string;
  isConfirmed: boolean;
  purchasedInStore: boolean;
  orderNumber?: string | null;
  externalStoreName?: string;
  itemOrigin?: string;
};

export type ExternalOrderPlacedReturnType = {
  data: {
    splitOrderId: string;
    token: string;
  };
};

export type ExternalReducerStateType = Partial<ExternalOrderPlaceData> &
  Partial<ExternalOrderPlacedReturnType['data']> & {
    noOrderNum?: boolean;
    collectionItemId?: string;
  };

const initialState: ExternalReducerStateType = {
  giftGiverName: null,
  purchasedInStore: false,
  orderNumber: null,
  isConfirmed: false,
  noOrderNum: false,
};

const externalReducer = (state = initialState, action: ActionTypes.ExternalActionType) => {
  switch (action.type) {
    case ActionTypes.UPDATE_EXTERNAL: {
      return Object.assign({}, state, action.payload);
    }
    case ActionTypes.PLACED_ORDER: {
      const { splitOrderId, token } = action.payload.data;
      return Object.assign({}, state, { splitOrderId, token });
    }
    case ActionTypes.CANCELLED_ORDER: {
      return initialState;
    }
    case ActionTypes.CONFIRMED_ORDER: {
      return initialState;
    }
    case ActionTypes.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default externalReducer;
