import moment from 'moment';
import { AppDispatch } from 'reducers/index';
import { normalize } from 'normalizr';
import { ORDERED_CUSTOMIZATION_TYPES } from 'cards/constants/CardTypes';
import { projectMapper } from 'cards/reducers/projectReducer';
import projectSchema from 'cards/schemas/projectSchema';
import { findLeadCustomization } from 'cards/util/customization';
import _get from 'lodash/get';
import { getCustomizationNavData } from 'cards/util/customizationNavigation';
import type { WProjectListingViewByType, WProjectOrderView } from '@zola/svc-web-api-ts-client';
import { cloneProject } from './projectActions';
import { orderListSchema } from '../schemas';
import ApiService from '../../util/api';

// Action Types
export const ActionTypes = {
  RECEIVE_CANCELLED_ORDER: 'zola/cards/draftsAndOrders/RECEIVE_CANCELLED_ORDER',
  MOVE_PUBLISHED_TO_DRAFTS: 'zola/cards/draftsAndOrders/MOVE_PUBLISHED_TO_DRAFTS',
  REQUEST_PROJECTS: 'zola/cards/draftsAndOrders/REQUEST_PROJECTS',
  RECEIVE_PROJECTS: 'zola/cards/draftsAndOrders/RECEIVE_PROJECTS',
  DELETE_DRAFT: 'zola/cards/draftsAndOrders/DELETE_DRAFTS',
  DELETE_PUBLISHED: 'zola/cards/draftsAndOrders/DELETE_PUBLISHED',
  SET_ACTIVE_DRAFTS_TAB: 'zola/cards/draftsAndOrders/SET_ACTIVE_DRAFTS_KEY',
  SET_ACTIVE_SEGMENT: 'zola/cards/draftsAndOrders/SET_ACTIVE_SEGMENT_KEY',
  SET_ACTIVE_ANCHOR: 'zola/cards/draftsAndOrders/SET_ACTIVE_ANCHOR',
  TOGGLE_WAITING_ON_PREVIEW_SUITES: 'zola/card/draftsAndOrders/TOGGLE_WAITING_ON_PREVIEW_SUITES',
};

// Types
interface DeleteDraft {
  type: typeof ActionTypes.DELETE_DRAFT;
  payload: { projectUUID: string; medium: string; cardType: string };
}

interface DeletePublished {
  type: typeof ActionTypes.DELETE_PUBLISHED;
  payload: { projectUUID: string };
}

interface MovePublishedToDrafts {
  type: typeof ActionTypes.MOVE_PUBLISHED_TO_DRAFTS;
  payload: { projectUUID: string };
}

interface RequestProjects {
  type: typeof ActionTypes.RECEIVE_PROJECTS;
  payload: any;
}

interface ProjectsData extends WProjectListingViewByType {
  waitingOnPreviewSuites: boolean | undefined;
  entities: {
    customizations: WProjectOrderView[];
    projects: WProjectOrderView[];
  };
}

interface ReceiveProjects {
  type: typeof ActionTypes.RECEIVE_PROJECTS;
  payload: { projects: ProjectsData };
}

interface ReceiveCancelledOrder {
  type: typeof ActionTypes.RECEIVE_CANCELLED_ORDER;
  payload: { cancelledOrder: AnalyserNode };
}

interface SetActiveDraftsTab {
  type: typeof ActionTypes.SET_ACTIVE_DRAFTS_TAB;
  payload: { key: string };
}

interface SetActiveSegment {
  type: typeof ActionTypes.SET_ACTIVE_SEGMENT;
  payload: { key: string };
}

interface SetActiveAnchor {
  type: typeof ActionTypes.SET_ACTIVE_ANCHOR;
  payload: { anchor: string | null };
}

interface ToggleWaitingOnPreviewSuites {
  type: typeof ActionTypes.TOGGLE_WAITING_ON_PREVIEW_SUITES;
  payload: { waitingOnPreviewSuites: boolean };
}

export type DraftsAndOrdersAction =
  | DeleteDraft
  | DeletePublished
  | MovePublishedToDrafts
  | RequestProjects
  | ReceiveProjects
  | ReceiveCancelledOrder
  | ToggleWaitingOnPreviewSuites;

// Actions
export const deleteDraft = (
  projectUUID: string,
  medium: string,
  cardType: string
): DeleteDraft => ({
  type: ActionTypes.DELETE_DRAFT,
  payload: { projectUUID, medium, cardType },
});

export const deletePublished = (projectUUID: string): DeletePublished => ({
  type: ActionTypes.DELETE_PUBLISHED,
  payload: { projectUUID },
});

export const movePublishedToDrafts = (projectUUID: string): MovePublishedToDrafts => ({
  type: ActionTypes.MOVE_PUBLISHED_TO_DRAFTS,
  payload: { projectUUID },
});

const requestProjects = (): RequestProjects => ({
  type: ActionTypes.REQUEST_PROJECTS,
  payload: {},
});

const receiveProjects = (projects: ProjectsData): ReceiveProjects => ({
  type: ActionTypes.RECEIVE_PROJECTS,
  payload: { projects },
});

export const receiveCancelledOrder = (cancelledOrder: any): ReceiveCancelledOrder => ({
  type: ActionTypes.RECEIVE_CANCELLED_ORDER,
  payload: { cancelledOrder },
});

export const setActiveDraftsTab = (key: string): SetActiveDraftsTab => ({
  type: ActionTypes.SET_ACTIVE_DRAFTS_TAB,
  payload: { key },
});

export const setActiveSegment = (key: string): SetActiveSegment => ({
  type: ActionTypes.SET_ACTIVE_SEGMENT,
  payload: { key },
});

export const setActiveAnchor = (anchor: string | null): SetActiveAnchor => ({
  type: ActionTypes.SET_ACTIVE_ANCHOR,
  payload: { anchor },
});

export const toggleWaitingOnPreviewSuites = (
  waitingOnPreviewSuites: boolean
): ToggleWaitingOnPreviewSuites => ({
  type: ActionTypes.TOGGLE_WAITING_ON_PREVIEW_SUITES,
  payload: { waitingOnPreviewSuites },
});

export const fetchProjects = (isWithFirstPage = false) => (dispatch: AppDispatch) => {
  dispatch(requestProjects());
  return ApiService.get<WProjectListingViewByType>(
    `/web-api/v2/card-project/projects/account?with_first_page=${isWithFirstPage}`
  ).then(json => {
    const { orders } = json;
    const mappedOrders = orders
      ?.map(order => ({
        ...order,
        projects: order.projects?.map(project => ({
          ...project,
          customizations: project.customizations?.sort(
            (a, b) =>
              ORDERED_CUSTOMIZATION_TYPES.indexOf(a.type!) -
              ORDERED_CUSTOMIZATION_TYPES.indexOf(b.type!)
          ),
        })),
        // if digital order, use project_uuid (also the key used to cancel the order)
        split_order_id:
          order.split_order_id !== null ? order.split_order_id : order.projects?.[0].project_uuid,
        has_split_order_id: order.split_order_id !== null,
      }))
      .sort((a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf());
    const normalizedOrders = normalize<
      typeof mappedOrders,
      { customizations: WProjectOrderView[]; projects: WProjectOrderView[] }
    >(mappedOrders, orderListSchema);

    const projectsData = {
      ...json,
      waitingOnPreviewSuites: orders && orders.length > 0,
      entities: normalizedOrders.entities,
    };

    dispatch(receiveProjects(projectsData));
  });
};

export const cancelOrder = (orderUUID: string) => (dispatch: AppDispatch) => {
  return ApiService.post(`/web-api/v2/card-project/orders/${orderUUID}/cancel`).then(json =>
    dispatch(receiveCancelledOrder(json))
  );
};

export const orderMore = (projectUUID: string) => (dispatch: AppDispatch) => {
  return dispatch(cloneProject(projectUUID)).then(clonedProject => {
    const rawProject = projectMapper(clonedProject);
    const { result: project, entities }: { result: any; entities: any } = normalize(
      rawProject,
      projectSchema
    );
    const allCustomizations = Object.values(entities.customizations);
    const leadCustomization = findLeadCustomization(allCustomizations);
    const optionValues = _get(leadCustomization, ['variation', 'option_values'], {});
    const { size } = optionValues;

    const { steps } = getCustomizationNavData(
      project.uuid,
      entities,
      rawProject.customizationTypeToUUID,
      leadCustomization,
      size,
      []
    );

    return { steps, clonedProject };
  });
};
