// TODO: Use zola-helpers tracking functions
import type { AnalyticsProduct, WClientUserAccountView } from '@zola/svc-web-api-ts-client';
import { getAllStorage, setCookie, getCookie, deleteCookie } from './storage';

export const customEventNames = {
  REGISTRY_ITEM_ADDED: 'Registry Item Added',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  PRODUCT_LIST_FILTERED: 'Product List Filtered',
};

type PageContext = {
  path: string;
  search: string;
  referrer: string;
  url: string;
  title: string;
};
export function getPageContext(): PageContext {
  return {
    path: window.location.pathname,
    search: window.location.search,
    referrer: (window && window.zola && (window.zola.referrer as string)) || document.referrer,
    url: window.location.href,
    title: document.title,
  };
}

type TrackEventOptions = {
  page?: PageContext;
  integrations?: {
    [key: string]: boolean;
  };
};

type Payload = Record<string, unknown>;

export function trackEvent(
  eventName: string,
  payload: Payload,
  options: TrackEventOptions = {}
): void {
  const optionsFinal = options || {};
  optionsFinal.page = getPageContext();
  if (window.analytics && window.analytics.track) {
    window.analytics.track(eventName, payload, optionsFinal);
  }
}

export function trackIdentifyUser(
  userId: string,
  traits: Payload,
  options?: TrackEventOptions
): void {
  const optionsFinal = options || {};
  optionsFinal.page = getPageContext();
  if (window && window.analytics) {
    window.analytics.identify(userId, traits, optionsFinal);
  }
}

export function trackGoogleAnalyticsEvent(eventName: string, payload: Payload): void {
  const options = {
    integrations: {
      All: false,
      'Google Analytics': true,
    },
  };
  trackEvent(eventName, payload, options);
}

function checkGTMBrand(eventData: Record<string, unknown>): void {
  // if the brand is not defined - clear it on the GTM data layer because we check for it to determine if the product added is a cash gift.
  if (!eventData.brand && window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({
      brand: undefined,
    });
  }
}

// Tracking  Utility Functions - These function use data from the Analytics cache.
// https://github.com/NewAmsterdamLabs/data-infrastructure/blob/development/segment2redis/README.md

export function hasOnboardedForInvitationsProduct(
  userContext: WClientUserAccountView,
  invitesProjectType: string
): boolean {
  const productsOnboarded =
    userContext && userContext.analytics && userContext.analytics.products_onboarded;
  if (!productsOnboarded || !invitesProjectType) {
    return false;
  }
  return (
    productsOnboarded.filter(
      (product: AnalyticsProduct) => product.component === invitesProjectType
    ).length > 0
  );
}

type Draft = { primary_customization: { type: string } };

export function hasPreviousDraftsForProjectType(
  drafts: Draft[],
  invitesProjectType: string
): boolean {
  if (drafts.length > 0) {
    return (
      drafts.filter(
        (draft: Draft) =>
          draft.primary_customization && draft.primary_customization.type === invitesProjectType
      ).length > 0
    );
  }
  return false;
}

export function userCreatedAfter(testBeganAtStr: string | number | Date): boolean {
  const testBeganAt = new Date(testBeganAtStr).getTime();
  const userCreatedAtValue = getAllStorage('zolaUserCreatedAt');
  const userCreatedAfterTestBegan = userCreatedAtValue
    ? new Date(userCreatedAtValue).getTime() > testBeganAt
    : false;
  return userCreatedAfterTestBegan;
}

export function hasOnboardedForProduct(
  userContext: WClientUserAccountView,
  businessUnitComponent: string
): boolean {
  const productsOnboarded =
    userContext && userContext.analytics && userContext.analytics.products_onboarded;
  if (!productsOnboarded) {
    return false;
  }
  return (
    productsOnboarded.filter(
      (product: AnalyticsProduct) => product.component === businessUnitComponent
    ).length > 0
  );
}

type CartItem = {
  id: string;
  skuId: string;
  productName: string;
  brandName: string;
  productLookId: string;
  subtotal: string;
  contribution: { quantity: number };
  itemLink: string;
  imageLink: string;
  childItems: CartItem[];
};

type CartViewedSegmentEvent = {
  product_id: string;
  sku: string;
  name: string;
  brand: string;
  variant: string;
  price: string;
  quantity: number;
  position: number;
  url: string;
  image_url: string;
};

/**
 * Takes an item from the cart API and reformats it to fit
 * the expectations of the CartViewed Segment Event
 * @param {Object} i an item as returned from the cart API
 * @param {Integer} idx the index of the item
 */
const normalizeItem = (i: CartItem, idx: number): CartViewedSegmentEvent => ({
  product_id: i.id,
  sku: i.skuId,
  name: i.productName,
  brand: i.brandName,
  variant: i.productLookId,
  price: i.subtotal,
  quantity: i.contribution.quantity,
  position: idx,
  url: i.itemLink,
  image_url: i.imageLink,
});

export function trackCartViewed(registryId: string, cartId: string, items: CartItem[]): void {
  const itemsPayload: CartViewedSegmentEvent[] = [];

  // loop over all items and add them to the event payload
  items.forEach((i: CartItem, idx: number) => {
    // for normal products, push directly onto array
    if (i.contribution) {
      itemsPayload.push(normalizeItem(i, idx));
    }
    // for parent products with children, push children onto array with same index as parent
    if (i.childItems) {
      i.childItems.forEach((c: CartItem) => {
        itemsPayload.push(normalizeItem(c, idx));
      });
    }
  });
  const payload = {
    registry_id: registryId,
    cart_id: cartId,
    products: itemsPayload,
  };
  trackEvent('Cart Viewed', payload);
}

export function trackGuestRsvp(payload: Record<string, unknown>, options: TrackEventOptions): void {
  // Will be replaced with "Guest Sent Rsvp" once event properties are decided upon.
  // Payload should be undefined, until we decide to add properties to this event in the tracking plan.
  trackEvent('guest_rsvpd', payload, options);
}

/*
  'Product Added': https://docs.google.com/spreadsheets/d/1He7KAgK_ryUca8TJgRAnowKXeFWxqq1csWZWZdyR3dc/edit#gid=164175519
*/
export function trackProductAdded(eventData: Record<string, unknown>): void {
  if (!eventData) return;
  // if the brand is not defined - clear it on the GTM data layer becuase we check for it to determine if the product added is a cash gift.
  if (!eventData.brand && window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({
      brand: undefined,
    });
  }
  trackEvent('Product Added', eventData);
}

export type TrackCTARecommendationData = {
  recommendation_key?: string;
  recommendation_name?: string;
  recommendation_copy?: string;
  location?: string;
  section?: string;
  variationKey?: string;
};

export type TrackCtaRecommendationActions = 'Clicked' | 'Viewed';

export function trackRecommendation(
  action: TrackCtaRecommendationActions,
  recommendationData: TrackCTARecommendationData
): void {
  trackEvent(`CTA Recommendation ${action}`, recommendationData);
}

export function trackProductViewed(eventData: Record<string, unknown>): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_VIEWED, eventData);
}

export function trackRegistryItemAdded(eventData: Record<string, unknown>): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.REGISTRY_ITEM_ADDED, eventData);
}

export function trackProductListViewed(eventData: Record<string, unknown>): void {
  if (!eventData) return;
  checkGTMBrand(eventData);
  trackEvent(customEventNames.PRODUCT_LIST_VIEWED, eventData);
}

export function trackGLOnboardSatrted(): void {
  trackEvent('Onboarding Started', {
    business_unit: 'WEDDINGS',
    component: 'WEDDING_GUEST_LIST',
  });
}

export function trackGLOnboardCompleted(): void {
  trackEvent('Onboarding Completed', {
    business_unit: 'WEDDINGS',
    component: 'WEDDING_GUEST_LIST',
  });
}

export function trackGLOnboardStepCompleted(eventData: Record<string, unknown>): void {
  trackEvent('Onboarding Step Completed', {
    business_unit: 'WEDDINGS',
    component: 'WEDDING_GUEST_LIST',
    ...eventData,
  });
}

export function trackUploadSpreadsheetStarted(flowRunId: string): void {
  if (flowRunId) {
    trackEvent('Flow Started', {
      flow_name: 'Upload Spreadsheet Flow',
      flow_version: 1,
      flow_run_id: flowRunId,
      flow_step_count: 5,
    });
  }
}

export function trackUploadSpreadsheetCompleted(
  flowRunId: string,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateFlowRunId = (): void => {}
): void {
  if (flowRunId) {
    trackEvent('Flow Completed', {
      flow_name: 'Upload Spreadsheet Flow',
      flow_version: 1,
      flow_run_id: flowRunId,
      flow_step_count: 5,
    });

    updateFlowRunId();
  }
}

export function trackUploadSpreadsheetStepCompleted(
  eventData: Record<string, unknown>,
  flowRunId: string
): void {
  if (flowRunId) {
    trackEvent('Flow Step Completed', {
      flow_name: 'Upload Spreadsheet Flow',
      flow_version: 1,
      flow_run_id: flowRunId,
      step_value: null,
      ...eventData,
    });
  }
}

export const trackUpdateGuestListStep = (stepValueType: string, flowRunId: string): void => {
  if (flowRunId) {
    trackUploadSpreadsheetStepCompleted(
      {
        step_name: 'Update Guest List',
        step_number: 3,
        step_value_type: stepValueType,
        step_value: null,
      },
      flowRunId
    );
  }
};

export function trackLoadMorePLP(): void {
  trackEvent('CTA Clicked', {
    business_unit: 'INVITATIONS',
    cta_type: 'SHOW_MORE',
  });
}

const ZOOM_FLOW_VERSION = 1;

export const trackZoomSetupFlow = (eventName: string, payload: Payload): void => {
  const flowRunId = getCookie('zoomSetupFlow');
  if (eventName === 'Flow Started') setCookie('zoomSetupFlow', `zoom_${Date.now().toString()}`, 1);
  if (eventName === 'Flow Completed') deleteCookie('zoomSetupFlow');
  if (flowRunId || eventName === 'Flow Started') {
    trackEvent(eventName, {
      ...payload,
      flow_version: ZOOM_FLOW_VERSION,
      flow_name: 'Zoom Setup Flow',
      ...(flowRunId && { flow_run_id: flowRunId }),
    });
  }
};

export const trackZoomCtaClick = (payload: Payload): void =>
  trackEvent('CTA Recommendation Clicked', {
    ...payload,
    recommendation_key: 'virtual_hub_cta',
  });

export const trackRegistrySortFilter = (
  ctaType: string,
  ctaGroup: string,
  ctaOption: string
): void => {
  const eventData = {
    business_unit: 'REGISTRY',
    cta_type: ctaType,
    group_id: ctaGroup,
    cta_id: ctaOption,
    position: 0,
    location: 'REGISTRY_OVERVIEW', // TODO: confirm value
    section: null, // TODO: confirm value
    cta: null,
  };
  trackEvent('CTA Clicked', eventData);
};

export const trackProductListFiltered = (payload: Payload): void => {
  const eventData = {
    category: 'Registry',
    filters: [],
    sorts: [],
  };
  trackEvent(customEventNames.PRODUCT_LIST_FILTERED, {
    ...eventData,
    ...payload,
  });
};
