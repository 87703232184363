import React from 'react';
import { useSelector } from 'react-redux';

// selectors
import { getOrderNumber } from 'cards/selectors/samplesFlow';
import { getUserEmail } from 'selectors/user/userSelectors';

// components
import { ButtonV2 } from '@zola/zola-ui/src/components/Button';

// icons
import ConfirmationIcon from 'assets/images/invitations/single-sample-modal/confirmation-icon.svg';

import styles from './styles.module.less';

interface ConfirmationProps {
  exitFlow: () => void;
}

const Confirmation = ({ exitFlow }: ConfirmationProps): JSX.Element | null => {
  const orderNumber = useSelector(getOrderNumber);
  const email = useSelector(getUserEmail);

  if (!orderNumber || !email) {
    return null;
  }

  return (
    <div className={styles.confirmationWrapper}>
      <div className={styles.confirmationIcon}>
        <img src={ConfirmationIcon} alt="Confirmation icon" />
      </div>
      <div className={styles.confirmationMessage}>We&apos;re on it!</div>
      We&apos;ll send a confirmation for order <b>{orderNumber}</b> to <b>{email}</b>.
      <div className={styles.buttonsWrapper}>
        <ButtonV2
          onClick={() => {
            exitFlow();
            window.location.href = `${window.location.origin}/wedding-planning/projects/drafts`;
          }}
          variant="secondary"
        >
          View Drafts
        </ButtonV2>
        <ButtonV2 onClick={exitFlow}>Keep Shopping</ButtonV2>
      </div>
    </div>
  );
};

export default Confirmation;
