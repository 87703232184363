import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import ReCAPTCHA from 'react-google-recaptcha';
import { getCookie } from '@zola-helpers/client/dist/es/util/storage';

// actions
import {
  setAccountDetails,
  setShippingDetails,
  setStepKey,
  submitSamplesFlowForm,
} from 'cards/reducers/samplesFlow/actions';

// selectors
import {
  getAllLPOrFoil,
  getDesignCustomizationsSet,
  getErrors,
  getShippingDetails,
  getTemplatesSet,
} from 'cards/selectors/samplesFlow';
import { getUserContext } from 'selectors/user/userSelectors';

// components
import { Link } from '@zola/zola-ui/src/components/Link';

// utils
import { useIsMobile } from 'paper/hooks/isMobile';
import Form from '../../Form';
import { stateDropdownOptions } from '../../utils';
import { STEPS } from '../../constants';

import styles from './styles.module.less';

interface ShippingStepProps {
  cardSuiteUUIDList: string[];
  isGuest: boolean;
  login: () => void;
  trackingParams: Record<string, unknown>;
}

const ShippingStep = ({
  cardSuiteUUIDList,
  isGuest,
  login,
  trackingParams,
}: ShippingStepProps): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const errors = useSelector(getErrors);
  const userContext = useSelector(getUserContext);

  const allLPOrFoil = useSelector(getAllLPOrFoil);
  const shippingDetails = useSelector(getShippingDetails);
  const designCustomizationsSet = useSelector(getDesignCustomizationsSet);
  const templatesSet = useSelector(getTemplatesSet);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const isAutomatedTest = getCookie('automatedTest') === 'true';
  const showRecaptcha = !isAutomatedTest;

  const [address, setAddress] = useState<string | null>(shippingDetails.address);
  const [apt, setApt] = useState<string | null>(shippingDetails.apt);
  const [city, setCity] = useState<string | null>(shippingDetails.city);
  const [state, setState] = useState<string | null>(shippingDetails.state);
  const [zip, setZip] = useState<string | null>(shippingDetails.zip);

  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  const emailInUse = _get(errors, ['account', 'emailInUse', 'isInvalid'])
    ? _get(errors, ['account', 'emailInUse', 'errorMessage'])
    : null;
  const emailInvalid = _get(errors, ['account', 'email', 'isInvalid'])
    ? _get(errors, ['account', 'email', 'errorMessage'])
    : null;
  const passwordInvalid = _get(errors, ['account', 'password', 'isInvalid'])
    ? _get(errors, ['account', 'password', 'errorMessage'])
    : null;
  const addressInvalid = _get(errors, ['shipping', 'field', 'address']) || null;
  const cityInvalid = _get(errors, ['shipping', 'field', 'city']) || null;
  const stateInvalid = _get(errors, ['shipping', 'field', 'state']) || null;
  const zipInvalid = _get(errors, ['shipping', 'field', 'zipCode']) || null;

  const nextBtnText = 'Place Order (Free)';
  const nextStep = async () => {
    dispatch(
      setShippingDetails({
        address,
        apt,
        city,
        state,
        zip,
      })
    );

    if (isGuest) {
      dispatch(setAccountDetails({ email, password }));
    }

    let captchaToken;
    if (recaptchaRef?.current) {
      captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
    }

    dispatch(
      submitSamplesFlowForm({
        cardSuiteUUIDList,
        isGuest,
        userContext,
        trackingParams,
        captchaToken: captchaToken || undefined,
      })
    );
    dispatch(setStepKey(STEPS.PROCESSING_ORDER));
  };
  const prevStep = allLPOrFoil
    ? () => dispatch(setStepKey(STEPS.NAMES))
    : () => dispatch(setStepKey(STEPS.CUSTOMIZE));
  const steps = allLPOrFoil ? 2 : 4;

  const disableButton = isGuest
    ? !address || !city || !state || !zip || !email || !password
    : !address || !city || !state || !zip;

  return (
    <>
      <Form.Header
        currentStep={steps}
        description={
          <>
            Free samples <i>and</i> free shipping? Can&apos;t lose.
          </>
        }
        steps={steps}
        title="Where can we send your samples?"
      />
      <Form.TinyCardsPreview
        cardSuiteUUIDList={cardSuiteUUIDList}
        designCustomizationsSet={designCustomizationsSet}
        templatesSet={templatesSet}
      />
      <Form.Group>
        <Form.Field
          errorMessage={addressInvalid}
          isRequired
          label="Street Address"
          name="address"
          onChange={e => setAddress(e.target.value)}
          type="text"
          value={address || ''}
          width={isMobile ? 227 : 380}
        />
        <Form.Spacer />
        <Form.Field
          label="Apt/Floor"
          name="apt"
          onChange={e => setApt(e.target.value)}
          type="text"
          value={apt || ''}
          width={92}
        />
      </Form.Group>
      {isMobile && (
        <Form.Field
          errorMessage={cityInvalid}
          isRequired
          label="City"
          name="city"
          onChange={e => setCity(e.target.value)}
          type="text"
          value={city || ''}
        />
      )}
      <Form.Group>
        {!isMobile && (
          <>
            <Form.Field
              errorMessage={cityInvalid}
              isRequired
              label="City"
              name="city"
              onChange={e => setCity(e.target.value)}
              type="text"
              value={city || ''}
              width={218}
            />
            <Form.Spacer />
          </>
        )}
        <Form.Dropdown
          errorMessage={stateInvalid}
          label="State"
          options={stateDropdownOptions}
          onSelect={option => setState(option)}
          selected={state}
          width={isMobile ? 231 : 150}
        />
        <Form.Spacer />
        <Form.Field
          errorMessage={zipInvalid}
          isRequired
          label="Zip Code"
          name="zip"
          onChange={e => setZip(e.target.value)}
          type="text"
          value={zip || ''}
          width={92}
        />
      </Form.Group>
      {isGuest && (
        <>
          Create a free account, so you don&apos;t lose the customizations you made.
          {isMobile && (
            <>
              {' '}
              <b>Already have an account?</b> <Link onClick={login}>Log In</Link>
            </>
          )}
          <div className={styles.account}>
            <div className={styles.createAccount}>
              <Form.Field
                errorMessage={emailInUse || emailInvalid}
                isRequired
                label="Email"
                name="email"
                onChange={e => setEmail(e.target.value)}
                type="text"
                value={email || ''}
                width={!isMobile ? 281 : undefined}
              />
              <Form.Field
                errorMessage={passwordInvalid}
                isRequired
                label="Password"
                name="password"
                onChange={e => setPassword(e.target.value)}
                type="password"
                value={password || ''}
                width={!isMobile ? 281 : undefined}
              />
              {showRecaptcha && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lf91DcfAAAAACNVXd1VdLe-Ty6IWlGHvHZ7jRmk"
                  size="invisible"
                  className={styles.grecaptchaBadge}
                  badge="inline"
                />
              )}
            </div>
            {!isMobile && (
              <div className={styles.login}>
                Already have an account?
                <Link onClick={login}>Log In</Link>
              </div>
            )}
          </div>
        </>
      )}
      <Form.Required />
      <Form.ButtonWrapper>
        <Form.BackButton handleClick={prevStep} />
        <Form.Button btnText={nextBtnText} handleClick={nextStep} disabled={disableButton} />
      </Form.ButtonWrapper>
    </>
  );
};

export default ShippingStep;
