import _findIndex from 'lodash/findIndex';
import _includes from 'lodash/includes';
import type { WCmsPageEntityView } from '@zola/svc-web-api-ts-client';

import * as ActionTypes from 'actions/types/website/EntityActionTypes';
import * as FileActionTypes from 'actions/types/website/FileUploadActionTypes';
import type {
  FileUploadActionTypes,
  FilePreviewType,
} from 'actions/types/website/FileUploadActionTypes';
import type { EntityActionTypes, GenericEntityType } from 'actions/types/website/EntityActionTypes';
import type { UploadedImage } from 'api/svc-image';

export type EntityReducerState = {
  busy: boolean;
  byId: Record<number, GenericEntityType>;
  orderedIds: Array<number>;
  selectedEntityId?: number;
  filePreview?: UploadedImage | Record<string, unknown>; // FilePreviewType;
  address: unknown;
  referenceKey: string;
};

const initialState: EntityReducerState = {
  busy: false,
  byId: {},
  orderedIds: [],
  selectedEntityId: undefined,
  filePreview: {},
  address: {},
  referenceKey: '',
};

const entitiesReducer = (
  state = initialState,
  action: EntityActionTypes | FileUploadActionTypes
): EntityReducerState => {
  switch (action.type) {
    case ActionTypes.ENTITY_ADD: {
      return Object.assign({}, state, {
        address: {},
        selectedEntityId: undefined,
        filePreview: {},
      });
    }
    case ActionTypes.ENTITY_REQUESTED: {
      return Object.assign({}, state, { busy: true }, { address: {} });
    }
    case ActionTypes.ENTITY_RECEIVED: {
      const byId: Record<number, GenericEntityType> = { ...state.byId };
      const { entity } = action.payload;
      if (entity.id) {
        byId[entity.id] = entity;
      }
      return Object.assign({}, state, { byId }, { busy: false }, { selectedEntityId: undefined });
    }
    case ActionTypes.ENTITY_CREATED: {
      const byId: Record<number, GenericEntityType> = { ...state.byId };
      const orderedIds: number[] = [...state.orderedIds];
      const { entity } = action.payload;
      const { id: entityId } = entity;
      if (entityId) {
        byId[entityId] = { ...entity, created: true };
        orderedIds.push(entityId);
      }
      return Object.assign(
        {},
        state,
        { byId, orderedIds },
        { busy: false },
        { address: {} },
        { selectedEntityId: undefined }
      );
    }
    case ActionTypes.ENTITIES_REQUESTED: {
      return Object.assign({}, state, { busy: true }, { address: {} });
    }
    case ActionTypes.ENTITIES_RECEIVED: {
      const byId: Record<number, GenericEntityType> = {};
      action.payload.entities.forEach((entity: GenericEntityType) => {
        if (entity.id) {
          byId[entity.id] = entity;
        }
      });
      const orderedIds = action.payload.entities.map((entity: GenericEntityType) => entity.id);
      return Object.assign({}, state, { byId, orderedIds }, { busy: false }, { filePreview: {} });
    }

    case ActionTypes.ENTITY_MOVE: {
      const { orderedIds } = action.payload;
      orderedIds.splice(
        action.payload.newIndex,
        0,
        orderedIds.splice(action.payload.oldIndex, 1)[0]
      );
      const orderedIdsInState = Object.assign([], state.orderedIds);
      const stateOrderedIds = orderedIdsInState.filter(i => !_includes(orderedIds, i));
      return Object.assign({}, state, { orderedIds: [...stateOrderedIds, ...orderedIds] });
    }
    case ActionTypes.ENTITY_MOVED: {
      const orderedIds = action.payload.entities.map(
        (entity: WCmsPageEntityView) => entity.entity_id
      );
      return Object.assign({}, state, { orderedIds });
    }
    case ActionTypes.ENTITY_SELECTED: {
      return Object.assign(
        {},
        state,
        { selectedEntityId: action.payload.id },
        { address: {}, filePreview: {} }
      );
    }
    case ActionTypes.ENTITY_DELETED: {
      const byId: Record<number, GenericEntityType> = { ...state.byId };
      delete byId[action.payload.id];

      const orderedIds = [...state.orderedIds];
      const index = _findIndex(orderedIds, i => i === action.payload.id);
      orderedIds.splice(index, 1);

      let { selectedEntityId } = state;
      if (state.selectedEntityId && state.selectedEntityId === action.payload.id) {
        selectedEntityId = undefined;
      }

      return Object.assign(
        {},
        state,
        { byId, orderedIds, selectedEntityId },
        { busy: false },
        { selectedEntityId }
      );
    }
    case ActionTypes.ENTITIES_RESET: {
      return Object.assign({}, initialState);
    }
    case ActionTypes.SUGGESTIONS_RECEIVED: {
      const suggestions = action.payload;
      return Object.assign({}, state, { suggestions });
    }
    case ActionTypes.REQUEST_ADDRESS: {
      const address = { busy: true };
      return Object.assign({}, state, { address });
    }
    case ActionTypes.RECEIVE_ADDRESS: {
      const result = action.payload;
      const address = result;
      if (action.overrideName) {
        address[action.overrideName] = result.name;
        delete address.name;
      }
      if (!address.url) {
        address.url = address.google_url;
      }
      address.google_map_url = address.google_url;
      address.busy = false;
      return Object.assign({}, state, { address });
    }
    case ActionTypes.RESET_ADDRESS: {
      return { ...state, address: {} };
    }
    case FileActionTypes.FILE_UPLOADING: {
      const newFilePreview: FilePreviewType = {};
      newFilePreview[action.payload] = { busy: true };
      const filePreview = Object.assign(state.filePreview || {}, newFilePreview);
      return Object.assign({}, state, { filePreview });
    }
    case FileActionTypes.FILE_UPLOADED: {
      const filePreview = Object.assign(state.filePreview || {}, action.payload);
      return Object.assign({}, state, { filePreview });
    }
    case FileActionTypes.STORE_REFERENCE_KEY: {
      return {
        ...state,
        referenceKey: action.payload,
      };
    }
    case FileActionTypes.FILE_TEMP_UPLOADED: {
      const filePreview = Object.assign(state.filePreview || {}, action.payload);
      return Object.assign({}, state, { filePreview });
    }
    case FileActionTypes.FILE_CROPPED: {
      const filePreview = Object.assign(state.filePreview || {}, action.payload);
      return Object.assign({}, state, { filePreview });
    }
    case FileActionTypes.RESET_FILE_PREVIEW: {
      return Object.assign({}, state, { filePreview: {} });
    }
    default:
      return state;
  }
};

export default entitiesReducer;
