import type { TemplatePreviewRequest, WCardTemplateView } from '@zola/svc-web-api-ts-client';
import ApiService from '../../../../../../util/api';
import { WeddingDetailsSubstitutions } from '../types';

function fetchTemplate(
  variations?: string[],
  substitutions?: WeddingDetailsSubstitutions
): Promise<Record<string, WCardTemplateView> | null> {
  if (!variations || variations.length === 0) return Promise.resolve(null);

  const req: TemplatePreviewRequest = {
    variation_uuids: variations,
    substitutions,
  };

  return ApiService.post('/web-api/v2/card-template/preview/by-variations', req).then(
    (data): Record<string, WCardTemplateView> => data
  );
}

export function fetchDefaultTemplate(
  variations?: string[]
): Promise<Record<string, WCardTemplateView> | null> {
  if (!variations || variations.length === 0) return Promise.resolve(null);

  const req: TemplatePreviewRequest = {
    variation_uuids: variations,
  };

  return ApiService.post('/web-api/v2/card-template/preview/by-variations-default', req).then(
    (data): Record<string, WCardTemplateView> => data
  );
}

export default fetchTemplate;
