import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { getBackendTypeFromType } from 'cards/util/cardTypes';
import ApiService from '../../util/api';
import { fetchWeddingTheme } from '../../actions/WeddingActions';
import {
  getSearchFilters,
  getDisplayableSearchTotal,
  getCardUUIDsByVariationUUID,
  getCards,
} from '../selectors/cardCatalogSelector';

// From old type to new type
// Use before sending to faceted api
export function facetedFilterMapperOldToNew(oldFiltersObj) {
  const newFilters = {
    ...oldFiltersObj,
    colors:
      [...(oldFiltersObj.multiColor || []), ...(oldFiltersObj.seo_colors || [])] ||
      (oldFiltersObj.color ? [oldFiltersObj.color] : []),
  };

  // remove multiColor, color, and seo_colors -> not part of new API
  return _omit(newFilters, ['multiColor', 'color', 'seo_colors']);
}

export const ActionTypes = {
  REQUEST_SEARCH_CARD_SUITES: 'zola/cards/card_catalog/REQUEST_SEARCH_CARD_SUITES',
  RECEIVE_SEARCH_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_SEARCH_CARD_SUITES',
  RECEIVE_SEARCH_CARD_SUITES_BY_UUID: 'zola/cards/card_catalog/RECEIVE_SEARCH_CARD_SUITES_BY_UUID',
  // Card suites that are matched to the user based on the theme they selected for their wedding website
  RECEIVE_THEME_MATCHED_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_THEME_MATCHED_CARD_SUITES',
  REQUEST_CARD_SUITE: 'zola/cards/card_catalog/REQUEST_CARD_SUITE',
  RECEIVE_CARD_SUITE: 'zola/cards/card_catalog/RECEIVE_CARD_SUITE',
  REQUEST_CARD: 'zola/cards/card_catalog/REQUEST_CARD',
  RECEIVE_CARD: 'zola/cards/card_catalog/RECEIVE_CARD',
  UPDATE_SEARCH_FILTERS: 'zola/cards/card_catalog/UPDATE_SEARCH_FILTER',
  UPDATE_MULTIPLE_SEARCH_FILTERS: 'zola/cards/card_catalog/UPDATE_MULTIPLE_SEARCH_FILTERS',
  OVERWRITE_SEARCH_FILTERS: 'zola/cards/card_catalog/OVERWRITE_SEARCH_FILTERS',
  RESET_SEARCH_FILTERS: 'zola/cards/card_catalog/RESET_SEARCH_FILTERS',
  SET_INITIAL_VARIATION_COLOR: 'zola/cards/card_catalog/SET_INITIAL_VARIATION_COLOR',
  SET_RELATED_CARDS_FAMILY_NAME: 'zola/cards/card_catalog/SET_RELATED_CARDS_FAMILY_NAME',
  RESET_RELATED_CARDS_FAMILY_NAME: 'zola/cards/card_catalog/RESET_RELATED_CARDS_FAMILY_NAME',
  UPDATE_VARIATION_OPTIONS: 'zola/cards/card_catalog/UPDATE_VARIATION_OPTIONS',
  REQUEST_THEME_GROUPS_FOR_CARD: 'zola/cards/card_catalog/REQUEST_THEME_GROUPS_FOR_CARD',
  REQUEST_RELATED_DRAFTS_FOR_SUITE: 'zola/cards/card_catalog/REQUEST_RELATED_DRAFTS_FOR_SUITE',
  RECEIVE_RELATED_DRAFTS_FOR_SUITE: 'zola/cards/card_catalog/RECEIVE_RELATED_DRAFTS_FOR_SUITE',
  RESET_RELATED_DRAFTS_FOR_SUITE: 'zola/cards/card_catalog/RESET_RELATED_DRAFTS_FOR_SUITE',
  RECEIVE_THEME_GROUPS_FOR_CARD: 'zola/cards/card_catalog/RECEIVE_THEME_GROUPS_FOR_CARD',
  UPDATE_CATALOG_ADMIN_VIEW: 'zola/cards/card_catalog/UPDATE_CATALOG_ADMIN_VIEW',
  SET_SELECTED_MEDIUM: 'zola/cards/card_catalog/SET_SELECTED_MEDIUM',
  REQUEST_RELATED_CARDS_FROM_SUITE: 'zola/cards/card_catalog/REQUEST_RELATED_CARDS_FROM_SUITE',
  RECEIVE_RELATED_CARDS_FROM_SUITE: 'zola/cards/card_catalog/RECEIVE_RELATED_CARDS_FROM_SUITE',
  RESET_RELATED_CARDS_FROM_SUITE: 'zola/cards/card_catalog/RESET_RELATED_CARDS_FROM_SUITE',
  RECEIVE_RELATED_CARDS_BY_DESIGNER: 'zola/cards/card_catalog/RECEIVE_RELATED_CARDS_BY_DESIGNER',
  RECEIVE_RELATED_CARDS_BY_MOTIF: 'zola/cards/card_catalog/RECEIVE_RELATED_CARDS_BY_MOTIF',
  RESET_RELATED_CARDS_BY_DESIGNER: 'zola/cards/card_catalog/RESET_RELATED_CARDS_BY_DESIGNER',
  RESET_RELATED_CARDS_BY_MOTIF: 'zola/cards/card_catalog/RESET_RELATED_CARDS_BY_MOTIF',
  RECEIVE_COLLABORATORS: 'zola/cards/card_catalog/RECEIVE_COLLABORATORS',
  HYDRATE_MOTIFS: 'zola/cards/card_catalogs/HYDRATE_MOTIFS',
  UPDATE_SELECTED_MOTIFS: 'zola/cards/card_catalog/UPDATE_SELECTED_MOTIFS',
  RECEIVE_PREVIOUS_SEARCH_RESULTS: 'zola/cards/card_catalog/RECEIVE_PREVIOUS_SEARCH_RESULTS',
  RECEIVE_ADDITIONAL_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_ADDITIONAL_CARD_SUITES',
  RECEIVE_INITIAL_CARD_SUITES: 'zola/cards/card_catalog/RECEIVE_INITIAL_CARD_SUITES',
  RESET_THEME_MATCHED_CARDS: 'zola/cards/card_catalog/RESET_THEME_MATCHED_CARDS',
  SET_MULTISAMPLE_SELECTED: 'zola/card/project/SET_MULTISAMPLE_SELECTED',
  CLEAR_MULTISAMPLE_SELECTED: 'zola/card/project/CLEAER_MULTISAMPLE_SELECTED',
  SET_MULTISAMPLE_ENABLED: 'zola/card/project/SET_MULTISAMPLE_ENABLED',
};

function requestSearchCardSuites() {
  return {
    type: ActionTypes.REQUEST_SEARCH_CARD_SUITES,
  };
}

function receiveSearchCardSuites(json) {
  return {
    type: ActionTypes.RECEIVE_SEARCH_CARD_SUITES,
    payload: {
      cardSuites: json,
    },
  };
}

const receiveSearchCardSuitesByUUID = json => {
  return {
    type: ActionTypes.RECEIVE_SEARCH_CARD_SUITES_BY_UUID,
    payload: {
      cardSuites: json,
    },
  };
};

function receiveThemeMatchedCardSuites(json) {
  return {
    type: ActionTypes.RECEIVE_THEME_MATCHED_CARD_SUITES,
    payload: {
      cardSuites: json,
    },
  };
}

export function resetThemeMatchedCards() {
  return {
    type: ActionTypes.RESET_THEME_MATCHED_CARDS,
  };
}

function requestCardSuite() {
  return {
    type: ActionTypes.REQUEST_CARD_SUITE,
  };
}

function receiveCardSuite(json) {
  return {
    type: ActionTypes.RECEIVE_CARD_SUITE,
    payload: {
      cardSuite: json,
    },
  };
}

function requestCard() {
  return {
    type: ActionTypes.REQUEST_CARD,
  };
}

function receiveCard(card, variationUUID) {
  return {
    type: ActionTypes.RECEIVE_CARD,
    payload: {
      card,
      variationUUID,
    },
  };
}

function requestRelatedDraftsForSuite() {
  return {
    type: ActionTypes.REQUEST_RELATED_DRAFTS_FOR_SUITE,
  };
}

function requestThemeGroupsForCard() {
  return {
    type: ActionTypes.REQUEST_THEME_GROUPS_FOR_CARD,
  };
}

function requestRelatedCardsFromSuite() {
  return {
    type: ActionTypes.REQUEST_RELATED_CARDS_FROM_SUITE,
  };
}

function receiveRelatedDraftsForSuite(suiteUUID, json) {
  return {
    type: ActionTypes.RECEIVE_RELATED_DRAFTS_FOR_SUITE,
    payload: {
      suiteUUID,
      relatedDrafts: json,
    },
  };
}

function receiveThemeGroupsForCard(cardUUID, json) {
  return {
    type: ActionTypes.RECEIVE_THEME_GROUPS_FOR_CARD,
    payload: {
      cardUUID,
      themeGroups: json,
    },
  };
}

function receiveRelatedCardsFromSuite(relatedCards) {
  return {
    type: ActionTypes.RECEIVE_RELATED_CARDS_FROM_SUITE,
    payload: { relatedCards },
  };
}

const receiveRelatedCardsByDesigner = json => ({
  type: ActionTypes.RECEIVE_RELATED_CARDS_BY_DESIGNER,
  payload: {
    relatedCards: json,
  },
});

const receiveRelatedCardsByMotif = relatedCards => ({
  type: ActionTypes.RECEIVE_RELATED_CARDS_BY_MOTIF,
  payload: { relatedCards },
});

function receiveCollaborators(collaborators) {
  return {
    type: ActionTypes.RECEIVE_COLLABORATORS,
    payload: {
      collaborators,
    },
  };
}

export function unMountRelatedCards() {
  return {
    type: ActionTypes.RESET_RELATED_CARDS_FROM_SUITE,
  };
}

export const resetRelatedCardsByDesigner = () => ({
  type: ActionTypes.RESET_RELATED_CARDS_BY_DESIGNER,
});

export const resetRelatedCardsByMotif = () => ({
  type: ActionTypes.RESET_RELATED_CARDS_BY_MOTIF,
});

export function updateSearchFilters(key, value) {
  return {
    type: ActionTypes.UPDATE_SEARCH_FILTERS,
    payload: { key, value },
  };
}

export function updateMultipleSearchFilters(newValues) {
  return {
    type: ActionTypes.UPDATE_MULTIPLE_SEARCH_FILTERS,
    payload: newValues,
  };
}

export function overwriteSearchFilters(newValues) {
  return {
    type: ActionTypes.OVERWRITE_SEARCH_FILTERS,
    payload: newValues,
  };
}

export function resetSearchFilters() {
  return {
    type: ActionTypes.RESET_SEARCH_FILTERS,
  };
}

export function resetRelatedCardsFamilyName() {
  return {
    type: ActionTypes.RESET_RELATED_CARDS_FAMILY_NAME,
  };
}

export function setInitialVariationColor(color) {
  return {
    type: ActionTypes.SET_INITIAL_VARIATION_COLOR,
    payload: { color },
  };
}

export function setRelatedCardsFamilyName(familyName) {
  return {
    type: ActionTypes.SET_RELATED_CARDS_FAMILY_NAME,
    payload: { familyName },
  };
}

export function updateVariationOptions(variations, paperTypeOptionsAvailability) {
  return {
    type: ActionTypes.UPDATE_VARIATION_OPTIONS,
    payload: { variations, paperTypeOptionsAvailability },
  };
}

export function updateCatalogAdminView(isAdminView) {
  return {
    type: ActionTypes.UPDATE_CATALOG_ADMIN_VIEW,
    payload: { isAdminView },
  };
}

export function setSelectedMedium(selectedMedium) {
  return {
    type: ActionTypes.SET_SELECTED_MEDIUM,
    payload: { selectedMedium },
  };
}

export function hydrateMotifs(motif) {
  return {
    type: ActionTypes.HYDRATE_MOTIFS,
    payload: motif,
  };
}

export function getMotifs(categories) {
  return dispatch => {
    ApiService.get(`/web-api/v1/card-catalog/motifs/${categories}`).then(json => {
      dispatch(hydrateMotifs(json));
    });
  };
}

export function updateSelectedMotifs(selected) {
  return dispatch => {
    dispatch({
      type: ActionTypes.UPDATE_SELECTED_MOTIFS,
      payload: selected,
    });
  };
}

const getOrientation = filters => {
  let orientation = null;
  if (filters.is_landscape) orientation = 'landscape';
  if (filters.is_portrait) orientation = 'portrait';
  if (filters.is_landscape && filters.is_portrait) orientation = null;
  return orientation;
};

export function searchCardSuites() {
  return (dispatch, getState) => {
    const { filters, initial } = getState().cards.cardCatalog;
    const { limit, offset } = filters;

    const orientation = getOrientation(filters);

    const requestBody = {
      offset: initial ? 0 : offset + limit,
      limit,
      active_only: filters.active_only,
      lead_card_type: filters.lead_card_type,
      color: filters.color,
      seo_colors: filters.seo_colors,
      has_foil: filters.has_foil,
      has_custom_photo: filters.has_custom_photo,
      has_magnet: filters.has_magnet,
      is_letterpress: filters.is_letterpress,
      collaborator: filters.collaborator,
      motifs: filters.motifs,
      silhouettes: filters.silhouettes,
      has_digital: filters.has_digital,
      orientation,
      third_party: filters.featured_artist,
      single_sample_available: filters.single_sample_available,
      digital_suite: filters.digital_suite,
    };
    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => dispatch(receiveSearchCardSuites(json)));
  };
}

export const searchCardSuitesByUUID = UUIDs => {
  return (dispatch, getState) => {
    const state = getState();
    const requestBody = {
      card_suite_uuids: UUIDs,
      offset: 0,
      limit: UUIDs.length,
      active_only: true,
      digital_suite: _get(state, 'cards.cardCatalog.filters.digital_suite'),
    };

    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(({ suites }) => {
      const orderedSuites = UUIDs.map(uuid => suites.find(suite => suite.uuid === uuid));
      return dispatch(receiveSearchCardSuitesByUUID(orderedSuites));
    });
  };
};

const body = state => ({
  active_only: true,
  lead_card_type: _get(state, 'cards.cardCatalog.filters.lead_card_type'),
  family: _get(state, 'wedding.themeGroupTitle'),
  offset: 0,
  limit: 1, // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1723
  color: null,
  seo_colors: [],
  has_foil: false,
  has_custom_photo: false,
  has_magnet: false,
  motifs: [],
  silhouettes: [],
  has_digital: false,
  orientation: null,
  third_party: null,
  single_sample_available: _get(state, 'cards.cardCatalog.filters.single_sample_available'),
  digital_suite: _get(state, 'cards.cardCatalog.filters.digital_suite'),
});

export function initialLoadMatchingThemeCardsForUser() {
  return (dispatch, getState) => {
    const state = getState();

    const isCollaboratorFilterActive = _get(state, 'cards.cardCatalog.filters.collaborator');
    const userHasIdentified = _get(state, 'user.hasIdentified');
    const userHasWeddingAccount = _get(state, 'user.userContext.has_wedding_account');

    // Only fetch matching theme cards if user is logged-in, has a wedding account, and isn't searching for cards from collaborators
    if (isCollaboratorFilterActive || !userHasIdentified || !userHasWeddingAccount)
      return Promise.resolve();

    dispatch(requestSearchCardSuites()); // Set busy state to true

    return dispatch(fetchWeddingTheme())
      .then(() =>
        ApiService.post(
          '/web-api/v1/card-catalog/search/faceted',
          facetedFilterMapperOldToNew(body(getState()))
        )
      )
      .then(json => dispatch(receiveThemeMatchedCardSuites(json)));
  };
}

export function getCollaborators() {
  return dispatch =>
    ApiService.get('/web-api/v1/card-catalog/collaborators').then(json => {
      dispatch(receiveCollaborators(json));
    });
}

export function getCardSuite(suiteUUID, showUnlisted = false) {
  return dispatch => {
    dispatch(requestCardSuite());
    const requestBody = {
      show_unlisted: showUnlisted,
    };
    return ApiService.post(`/web-api/v1/card-catalog/suites/${suiteUUID}`, requestBody).then(json =>
      dispatch(receiveCardSuite(json))
    );
  };
}

export function getMatchingStd(themeKey) {
  return () => {
    const requestBody = {
      theme_key: themeKey,
      card_type: 'SAVE_THE_DATE',
    };
    return ApiService.post('/web-api/v1/card-catalog/matching-suites', requestBody).then(
      response => response
    );
  };
}

export function getCardByVariationUUID(variationUUID, customizationUUID, showUnlisted = false) {
  return (dispatch, getState) => {
    // Since the data won't change, check if the variation's card is available before trying to fetch it.
    const cardUUIDsByVariationUUID = getCardUUIDsByVariationUUID(getState());
    const cardUUID = cardUUIDsByVariationUUID[variationUUID];
    if (cardUUID) {
      const allCardData = getCards(getState());
      return Promise.resolve(allCardData[cardUUID]);
    }

    dispatch(requestCard());
    const requestBody = {
      show_unlisted: showUnlisted,
    };
    return ApiService.post(
      `/web-api/v1/card-catalog/variations/${variationUUID}/card`,
      requestBody
    ).then(json => {
      // TODO: think about possible ways to improve this without needing to inject customizationUUID
      // into response object, perhaps set cardUUID on customization objects in cardProject.customizations instead
      const card = { ...json, customizationUUID };
      dispatch(receiveCard(card, variationUUID));
      return card;
    });
  };
}

export function getThemeGroupsForCard(cardUUID) {
  return dispatch => {
    dispatch(requestThemeGroupsForCard());
    return (
      ApiService.get(`/web-api/v1/card-catalog/cards/${cardUUID}/themes`)
        .then(json => dispatch(receiveThemeGroupsForCard(cardUUID, json)))
        // eslint-disable-next-line no-console
        .catch(() => console.log(`could not fetch theme groups for card ${cardUUID}`))
    );
  };
}

export function getRelatedDraftsForSuite(suiteUUID) {
  return dispatch => {
    dispatch(requestRelatedDraftsForSuite());
    return ApiService.get(`/web-api/v2/card-catalog/suites/${suiteUUID}/drafts`).then(json =>
      dispatch(receiveRelatedDraftsForSuite(suiteUUID, json))
    );
  };
}

export function resetRelatedDrafts() {
  return {
    type: ActionTypes.RESET_RELATED_DRAFTS_FOR_SUITE,
  };
}

export function getRelatedCardsFromSuite(familyName, color, seo_colors, cardType, limit = 12) {
  return dispatch => {
    const requestBody = {
      offset: 0,
      limit,
      active_only: true,
      lead_card_type: familyName ? null : cardType,
      color,
      seo_colors,
      has_foil: null,
      has_custom_photo: null,
      has_magnet: null,
      family: familyName,
      orientation: null,
      third_party: null,
    };
    dispatch(requestRelatedCardsFromSuite());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => dispatch(receiveRelatedCardsFromSuite(json)));
  };
}

export const getRelatedCardsByDesigner = (cardType, collaborator, limit = 12) => {
  return dispatch => {
    const requestBody = {
      offset: 0,
      limit,
      active_only: true,
      lead_card_type: cardType,
      color: null,
      seo_colors: [],
      has_foil: null,
      has_custom_photo: null,
      family: null,
      orientation: null,
      third_party: null,
      collaborator,
    };

    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => dispatch(receiveRelatedCardsByDesigner(json)));
  };
};

export const getRelatedCardsByMotif = (
  cardType,
  hasCustomPhoto,
  motifs,
  limit = 12,
  excludedSuiteUUIDs = []
) => {
  return dispatch => {
    const requestBody = {
      offset: 0,
      limit,
      active_only: true,
      lead_card_type: getBackendTypeFromType(cardType),
      color: null,
      seo_colors: [],
      has_foil: null,
      has_custom_photo: hasCustomPhoto || null,
      has_no_custom_photo: !hasCustomPhoto || null,
      family: null,
      orientation: null,
      third_party: null,
      motifs,
      silhouettes: [],
    };

    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => {
      const relatedCards = json.suites.filter(suite => !excludedSuiteUUIDs.includes(suite.uuid));
      return dispatch(receiveRelatedCardsByMotif(relatedCards));
    });
  };
};

function receiveAdditionalCardSuites(cardSuites) {
  return {
    type: ActionTypes.RECEIVE_ADDITIONAL_CARD_SUITES,
    payload: { cardSuites },
  };
}

export function requestAdditionalCardSuites(offset, limit) {
  return (dispatch, getState) => {
    const state = getState();
    const displayableSearchTotal = getDisplayableSearchTotal(state);
    const currentFilters = getSearchFilters(state);

    const noMore = offset + 1 >= displayableSearchTotal;
    if (noMore) return Promise.resolve({ total: displayableSearchTotal, count: 0, offset });

    const orientation = getOrientation(currentFilters);

    const overTotal = offset + limit >= displayableSearchTotal;
    const searchRequest = {
      ...currentFilters,
      limit: overTotal ? displayableSearchTotal - offset : limit,
      offset,
      orientation,
    };

    dispatch(requestSearchCardSuites());
    // TODO: If search returns 0 items - search again at offset 0.
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    ).then(json => dispatch(receiveAdditionalCardSuites(json)));
  };
}

export const receiveInitialCardSuites = cardSuites => ({
  type: ActionTypes.RECEIVE_INITIAL_CARD_SUITES,
  payload: {
    cardSuites,
  },
});

export function requestInitialCardSuites(offset, limit) {
  return (dispatch, getState) => {
    const { filters } = getState().cards.cardCatalog;
    const orientation = getOrientation(filters);
    const searchRequest = {
      ...filters,
      limit,
      offset,
      orientation,
      third_party: filters.featured_artist,
    };

    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    ).then(json => {
      dispatch(receiveInitialCardSuites(json));
      return Promise.resolve();
    });
  };
}

export function requestSSRInitialCardSuites(offset, limit, filterUpdates) {
  return (dispatch, getState) => {
    const { filters } = getState().cards.cardCatalog;
    const orientation = getOrientation(filters);
    const searchRequest = {
      ...filters,
      ...filterUpdates,
      limit,
      offset,
      orientation,
      third_party: filters.featured_artist,
    };

    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    )
      .then(json => {
        dispatch(receiveInitialCardSuites(json));
        return Promise.resolve();
      })
      .catch(e => {
        throw new Error(e);
      });
  };
}

function receiveSearchPreviousCardSuites(cardSuites) {
  return {
    type: ActionTypes.RECEIVE_PREVIOUS_SEARCH_RESULTS,
    payload: {
      cardSuites,
    },
  };
}

export function requestPreviousCardSuites(offset, limit) {
  return (dispatch, getState) => {
    const state = getState();
    const currentFilters = getSearchFilters(state);
    const orientation = getOrientation(currentFilters);
    const searchRequest = { ...currentFilters, limit, offset, orientation };
    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    ).then(json => dispatch(receiveSearchPreviousCardSuites(json)));
  };
}
