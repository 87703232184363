const createEnvironmentFlags = (development = false, staging = false, production = false) => ({
  development,
  staging,
  production,
});

const flags = {
  fastFacets: createEnvironmentFlags(true, true, false),
  seatingChartView2: createEnvironmentFlags(false, false, false), // - this is on hold
  fontRenderFix: createEnvironmentFlags(true, true, false), // INVITES-1054
  newPlpCardTile: createEnvironmentFlags(false, false, false), // INV-501
  confirmExitModalFlag: createEnvironmentFlags(true, true, false), // WEDDINGS-1785
  invitesSinglesampleSaveToDraftsOnExit: createEnvironmentFlags(false, false, false), // INV-1265
  expeditedShippingModal: createEnvironmentFlags(false, false, false), // INV-1439
  freeCustomFoilEvent: createEnvironmentFlags(false, false, false), // INV-1562 // INV-1789
  hideAds: createEnvironmentFlags(false, false, false), // INV-1524
  singleSampleEnvelopes: createEnvironmentFlags(true, true, false), // INV-1588
  albumsFTERedesign: createEnvironmentFlags(true, true, true), // COMMERCE-503
  albumsGenerateWithoutPayload: createEnvironmentFlags(true, true, true), // COMMERCE-621
  albumsPDPHover: createEnvironmentFlags(true, true, true), // COMMERCE-162
  envelopeLinerA2: createEnvironmentFlags(true, true, true), // COMMERCE-703
  seoColorSeasonFilters: createEnvironmentFlags(true, true, true), // COMMERCE-461
  albumsPricingBreakdown: createEnvironmentFlags(true, true, true), // COMMERCE-482
  albumsPricingMatrix: createEnvironmentFlags(true, true, true), // COMMERCE-480
  datadog: createEnvironmentFlags(false, true, true), // Toggle datadog monitoring
  cardsOutOfStock: createEnvironmentFlags(true, true, true), // COMMERCE-588
  cardsSortBy: createEnvironmentFlags(true, true, true), // COMMERCE-652
  cardsFavoriting: createEnvironmentFlags(true, true, true), // COMMERCE-639
  cardsPLPFilterBubbles: createEnvironmentFlags(false, false, false),
  showHolidayShippingReminder: createEnvironmentFlags(false, false, false), // COMMERCE-922
  cardsSampleFlow: createEnvironmentFlags(true, true, false), // COMMERCE-1014
  websiteToUploadcare: createEnvironmentFlags(true, true, true), // CE-1539
  cardsDisableMagnets: createEnvironmentFlags(false, false, false),
  dstdWebsiteEntry: createEnvironmentFlags(true, true, true), // COMMERCE-1072
  dstdUnavailable: createEnvironmentFlags(true, true, true), // COMMERCE-1103
  dstdNavigation: createEnvironmentFlags(true, true, true), // COMMERCE-1103
  reviewQuantityUpdate: createEnvironmentFlags(true, true, true), // COMMERCE-1142
  updateProductViewedEvent: createEnvironmentFlags(true, true, true), // COMMERCE-1177
  envelopeGuestAddressingTextResets: createEnvironmentFlags(true, true, false), // COMMERCE-1153 follow up
  updateAlbumViewedEvent: createEnvironmentFlags(true, true, true), // COMMERCE-1177
  digitalSaveTheDateExperiment: createEnvironmentFlags(false, false, false), // COMMERCE-1190
  csvUploadMapInModal: createEnvironmentFlags(true, true, true), // COMMERCE-1218
  dstdShareStepEmailHelperButtons: createEnvironmentFlags(false, false, false), // COMMERCE-1379
  cardsExtraCustomization: createEnvironmentFlags(true, true, true), // COMMERCE-1387
  resetCustomizationButton: createEnvironmentFlags(false, false, false), // COMMERCE-1476
  fullSuitePLP: createEnvironmentFlags(true, true, true), // COMMERCE-1449
  invitesHeroAnimated: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1594
  dstdAnimations: createEnvironmentFlags(true, true, true), // COMMERCE-1611
  cardsPlpSeoFaq: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1714
  cardsPlpSeoSchemas: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1602
  dstdEmailTemplate: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1665
  newPaperShapes: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1637
  newPaperShapesPLPFilter: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1860
  newPaperShapesConfirmation: createEnvironmentFlags(true, true, false), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2081
  albumsPricingUpdate: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2101
  cardsReversePrinting: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2179
  cardsAdditionalLayouts: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2384
  plpPhotoPreview: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2107
  plpPhotoPreviewSamplesFlow: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2107
  cardsPlpFullWidthPromoHero: createEnvironmentFlags(true, true, false), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2231
  yourWeddingRebrand: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-2314
  plpRedesign: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2250
  plpShapesPreviewAndFiltering: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2186
  thankYouNoteFonts: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2238
  cardsQRCodeExpansion: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2399
  universalLanding: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2411
  websiteTransitonModalEnabled: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-2610
  ordersAndDraftsV3: createEnvironmentFlags(true, true, false), // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2564
  albumCheckoutQuantity: createEnvironmentFlags(true, true, true), //  https://newamsterdamlabs.atlassian.net/browse/COMMERCE-2606
  websiteV2MobileImgs: createEnvironmentFlags(true, true, true),
  publicHomeAnimations: createEnvironmentFlags(true, true, true),
  paperZendeskChat: createEnvironmentFlags(true, true, true),
  dstdWebsiteFixes: createEnvironmentFlags(true, true, true),
  paperILAPI: createEnvironmentFlags(true, true, true),
  shopHolidays2022: createEnvironmentFlags(false, false, false),
  paperInvitationsPetite: createEnvironmentFlags(true, true, false),
  websiteV2EventsDateFix: createEnvironmentFlags(true, true, true),
  singlePageWebsites: createEnvironmentFlags(true, false, false),
  pcoyleOnboardFixes4: createEnvironmentFlags(true, true, false),
  websitePreviewStyleRefactor: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-2861
  websiteImageSizeStandardizing: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-2887
};

type Environments = 'development' | 'staging' | 'production';
export const getEnvironment = (): Environments => {
  const zolaEnv: string | undefined =
    (typeof window !== 'undefined' && window.zola && window.zola.env) ||
    process.env.ZOLA_ENV ||
    process.env.NODE_ENV;
  switch (zolaEnv) {
    case 'test':
    case 'development':
    case 'dev':
      return 'development';
    case 'staging':
    case 'stage':
      return 'staging';
    case 'production':
    case 'prod':
      return 'production';
    default:
      return 'production';
  }
};

export type FeatureFlag = keyof typeof flags;

const get = (flag: FeatureFlag): boolean => {
  if (flags[flag]) {
    return Boolean(flags[flag][getEnvironment()]);
  }
  return false;
};

export default {
  get,
  getEnvironment,
};
