import type { WCmsPageSummaryView, WCmsPageView } from '@zola/svc-web-api-ts-client';
import _keyBy from 'lodash/keyBy';
import { PageActions } from 'actions/website/PageActions';
import * as PageActionTypes from 'actions/types/website/PageActionTypes';
import { FILE_UPLOADED } from 'actions/types/website/FileUploadActionTypes';

export type PagesReducerState = {
  byId: Record<number, WCmsPageView>;
  typeToId: Record<string, number>;
  orderedIds: number[];
  hideablePagesById: Record<number, WCmsPageSummaryView>;
  orderedHideablePageIds: (number | undefined)[];
  activePage: { type?: string; id?: number };
  profileImage: string;
  activePageObject?: WCmsPageView;
  busy: boolean;
};

const initialState: PagesReducerState = {
  byId: {},
  typeToId: {},
  orderedIds: [],
  hideablePagesById: {},
  orderedHideablePageIds: [],
  activePage: {},
  profileImage: '',
  busy: false,
};

const pagesReducer = (
  state = initialState,
  action:
    | PageActions
    | {
        type: typeof FILE_UPLOADED;
        payload: Record<string, any>;
      }
): PagesReducerState => {
  switch (action.type) {
    case PageActionTypes.REQUEST_PAGES: {
      return state;
    }
    case PageActionTypes.RECEIVE_PAGES: {
      const byId: Record<number, WCmsPageView> = {};
      const typeToId: Record<string, number> = {};
      action.payload.pages.forEach((page: WCmsPageView) => {
        if (page.id) {
          byId[page.id] = page;
        }
      });
      action.payload.pages.forEach((page: WCmsPageView) => {
        if (page.type) {
          typeToId[page.type] = page.id as number;
        }
      });
      const orderedIds = action.payload.pages.map((page: WCmsPageView) => page.id);
      return Object.assign({}, state, { byId, typeToId, orderedIds });
    }
    case PageActionTypes.RECEIVE_PAGE: {
      const updatedPage = action.payload.page;
      const copy = Object.assign({}, state.byId);
      copy[action.payload.page.id as number] = updatedPage;
      return Object.assign({}, state, { byId: copy });
    }
    case PageActionTypes.RECEIVE_HIDDEN_PAGE_SUGGESTIONS: {
      const { hiddenSuggestions }: { hiddenSuggestions: WCmsPageSummaryView[] } = action.payload;
      const orderedHideablePageIds = hiddenSuggestions.map((page: WCmsPageSummaryView) => page.id);
      const hideablePagesById = _keyBy(hiddenSuggestions, 'id');
      return Object.assign({}, state, { orderedHideablePageIds, hideablePagesById });
    }
    case PageActionTypes.TOGGLE_PAGE: {
      const copy = Object.assign({}, state.byId);
      copy[action.payload.id] = Object.assign({}, state.byId[action.payload.id], {
        hidden: !copy[action.payload.id].hidden,
      });
      return Object.assign({}, state, { byId: copy });
    }
    case PageActionTypes.MOVE_PAGE: {
      const copy = Object.assign([], state.orderedIds);
      copy.splice(action.newIndex, 0, copy.splice(action.oldIndex, 1)[0]);
      return Object.assign({}, state, { orderedIds: copy });
    }
    case PageActionTypes.ACTIVATE_PAGE: {
      const activePageId = state.typeToId[action.activePage];
      const activePageObject = state.byId[activePageId];
      const activePage = { type: action.activePage, id: activePageId };
      return Object.assign({}, state, { activePage, activePageObject });
    }
    case PageActionTypes.RECEIVE_PROFILE_IMAGE: {
      const profileImage = action.payload || '';
      return { ...state, profileImage, busy: false };
    }
    case FILE_UPLOADED: {
      const { uuid, url } = action.payload[`header_image_${state.activePage.type}`];
      const activePageId = state.typeToId[state.activePage.type as string];
      const copy = Object.assign({}, state.byId);
      copy[activePageId] = Object.assign({}, state.byId[activePageId], {
        header_image_id: uuid,
        header_image_url: url,
      });
      return Object.assign({}, state, { byId: copy });
    }
    default:
      return state;
  }
};

export default pagesReducer;
