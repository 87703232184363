import _get from 'lodash/get';

export const getWebsitePublished = state => _get(state, 'wedding.account.published', false);

export const getWebsitePasscode = state => _get(state, 'wedding.account.passcode');

export const getWebsiteLastPublishedAt = state => _get(state, 'wedding.account.last_published_at');

export const getWeddingSlug = state => _get(state, 'wedding.wedding.slug');

export const getWeddingTheme = state => _get(state, 'wedding.theme', {});

export const getWeddingThemeKey = state => _get(state, 'wedding.theme.key');

export const getWeddingThemeName = state => _get(state, 'wedding.theme.name');

export const getWeddingThemeGroupTitle = state => _get(state, 'wedding.themeGroupTitle');

export const getWeddingPreviewUrl = state => {
  const slug = getWeddingSlug(state);

  if (!slug) return '';

  return `/wedding/${slug}`;
};

export const getCurrentWebsitePageType = state =>
  _get(state, 'website.pages.activePageObject.type');

export const getCurrentWebsitePageImage = state =>
  _get(state, 'website.pages.activePageObject.header_image_url');

export const getCmsBannerEnabled = state => _get(state, 'wedding.account.enable_cms_banner');

export const getWeddingAccount = state => _get(state, 'wedding.account', {});

export const getCustomDomain = state => _get(state, 'wedding.wedding.custom_domain');

export const getThemePreviewThumbnail = state => _get(state, 'wedding.theme.preview_images[0]');

export default {
  getWebsitePasscode,
  getWebsitePublished,
  getWeddingSlug,
  getWeddingPreviewUrl,
  getWeddingTheme,
  getWeddingThemeKey,
  getWeddingThemeGroupTitle,
  getCurrentWebsitePageImage,
  getCmsBannerEnabled,
  getWeddingAccount,
  getCustomDomain,
  getThemePreviewThumbnail,
};
