export const ActionTypes = {
  TRACK_CARD_CUSTOMIZATION_EVENT: 'zola/cards/tracking/TRACK_CARD_CUSTOMIZATION_EVENT',
  TRACK_CARD_LIST_VIEWED: 'zola/cards/tracking/TRACK_CARD_LIST_VIEWED',
  TRACK_CARD_PRODUCT_VIEWED: 'zola/cards/tracking/TRACK_CARD_PRODUCT_VIEWED',
  TRACK_CARD_PRODUCT_CLICKED: 'zola/cards/tracking/TRACK_CARD_PRODUCT_CLICKED',
  TRACK_CARD_PRODUCT_ADDED_TO_CART: 'zola/cards/tracking/TRACK_CARD_PRODUCT_ADDED_TO_CART',
  TRACK_TYNG_RECOMMENDATION_VIEWED: 'zola/cards/tracking/TRACK_TYNG_RECOMMENDATION_VIEWED',
  TRACK_TYNG_RECOMMENDATION_SELECTED: 'zola/cards/tracking/TRACK_TYNG_RECOMMENDATION_SELECTED',
};

export function trackThankYouRecommendationViewed(meta) {
  return {
    type: ActionTypes.TRACK_TYNG_RECOMMENDATION_VIEWED,
    payload: meta,
  };
}

export function trackThankYouRecommendationSelected(meta) {
  return {
    type: ActionTypes.TRACK_TYNG_RECOMMENDATION_SELECTED,
    payload: meta,
  };
}

export function trackCardCustomizationEvent(meta) {
  return {
    type: ActionTypes.TRACK_CARD_CUSTOMIZATION_EVENT,
    payload: meta,
  };
}

export function trackCardListEvent(meta) {
  return {
    type: ActionTypes.TRACK_CARD_LIST_VIEWED,
    payload: meta,
  };
}

export function trackCardProductViewed(meta) {
  return {
    type: ActionTypes.TRACK_CARD_PRODUCT_VIEWED,
    payload: meta,
  };
}

export function trackCardProductClicked(meta) {
  return {
    type: ActionTypes.TRACK_CARD_PRODUCT_CLICKED,
    payload: meta,
  };
}

export function trackCardProductAddedToCart(meta) {
  return {
    type: ActionTypes.TRACK_CARD_PRODUCT_ADDED_TO_CART,
    payload: meta,
  };
}
