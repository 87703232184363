import type {
  WHomeModuleView,
  WProfileView,
  WSnapshotView,
  WStatusCenterView,
} from '@zola/svc-web-api-ts-client';

export const REQUEST_DASHBOARD = 'zola/dashboard/REQUEST_DASHBOARD';
export const RECEIVE_DASHBOARD = 'zola/dashboard/RECEIVE_DASHBOARD';
export const RECEIVE_HOME = 'zola/dashboard/RECEIVE_HOME';

type RequestDashboardActionType = {
  type: typeof REQUEST_DASHBOARD;
};

type ReceiveDashboardActionType = {
  type: typeof RECEIVE_DASHBOARD;
  payload: WProfileView;
};

type ReceiveHomeActionType = {
  type: typeof RECEIVE_HOME;
  payload: {
    status_center_view: WStatusCenterView;
    snapshot_view: WSnapshotView;
    modules: WHomeModuleView[];
  };
};

export type DashboardActionTypes =
  | RequestDashboardActionType
  | ReceiveDashboardActionType
  | ReceiveHomeActionType;
