import { combineReducers } from 'redux';
import file from './file';
import modal from './modal';
import cardCatalog from './cardCatalog';
import recipients from './recipients';
import cardCustomizationBar from './cardCustomizationBar';
import formHistory from './formHistory';
import trackedEvents from './tracking';
import drafts from './draftsReducer';
import orders from './ordersReducer';
import digitalDrafts from './digitalDraftsReducer';
import digitalOrders from './digitalOrdersReducer';
import extraCustomization from './extraCustomization';
import project from './projectReducer';
import notes from './notesReducer';
import product from './productReducer';
import { reducer as customization } from './customization';
import { reducer as pdp } from './pdp';
import { reducer as pricing } from './pricing';
import { tycContentSuggestion } from './tycContentSuggestion';
import { reducer as placecardGuestList } from './placecardGuestList';
import { reducer as samplesFlow } from './samplesFlow';
import draftsAndOrders from './draftsAndOrdersReducer';
import invitesPaperUpsell from './invitesPaperUpsell';
import qrCode from './qrCode';
import reversePrintingLayouts from './reversePrintingLayouts';
import plpPhotoPreview from './plpPhotoPreview';

const cards = combineReducers({
  cardCustomizationBar,
  cardCatalog,
  project,
  notes,
  drafts,
  orders,
  digitalDrafts,
  digitalOrders,
  draftsAndOrders,
  extraCustomization,
  file,
  modal,
  recipients,
  formHistory,
  trackedEvents,
  customization,
  pdp,
  pricing,
  product,
  tycContentSuggestion,
  placecardGuestList,
  samplesFlow,
  invitesPaperUpsell,
  qrCode,
  reversePrintingLayouts,
  plpPhotoPreview,
});

export default cards;
