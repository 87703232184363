import { SamplesFlowState } from 'cards/reducers/samplesFlow/types';
import { RootState } from 'reducers/index';
import { createSelector } from 'reselect';

export const getFlowData = (state: RootState): SamplesFlowState => state.cards.samplesFlow;

export const getAllLPOrFoil = createSelector(getFlowData, flowData => flowData.allLPOrFoil);

export const getCardSuiteDetailsSet = createSelector(
  getFlowData,
  flowData => flowData.cardSuiteDetailsSet
);

export const getCardType = createSelector(getFlowData, flowData => flowData.cardType);

export const getDesignCustomizationsSet = createSelector(
  getFlowData,
  flowData => flowData.designCustomizationsSet
);

export const getPaperCustomizations = createSelector(
  getFlowData,
  flowData => flowData.paperCustomizations
);

export const getErrors = createSelector(getFlowData, flowData => flowData.errors);

export const getHasLPOrFoil = createSelector(getFlowData, flowData => flowData.hasLPOrFoil);

export const getWeddingDetails = createSelector(getFlowData, flowData => flowData.weddingDetails);

export const getOrderNumber = createSelector(getFlowData, flowData => flowData.orderNumber);

export const getShippingDetailsFetched = createSelector(
  getFlowData,
  flowData => flowData.shippingDetails.fetched
);

export const getSamplesAvailable = createSelector(
  getFlowData,
  flowData => flowData.samplesAvailable
);

export const getShippingDetails = createSelector(getFlowData, flowData => flowData.shippingDetails);

export const getSilhouette = createSelector(
  getPaperCustomizations,
  flowData => flowData.activeSelections.silhouette
);

export const getStepKey = createSelector(getFlowData, flowData => flowData.stepKey);

export const getTemplatesSet = createSelector(getFlowData, flowData => flowData.templatesSet);
